<template>
  <div>
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.sign')"
        @click="sign"
      />
    </div>
  </div>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    getXmlToSign: {
      type: String,
      default: '',
    },
  },
  methods: {
    async sign() {
      this.$globalLoading.show();
      const resWithXml = await requestWrapper.call(this, {
        url: this.getXmlToSign,
        method: 'post',
      });
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = resWithXml.data;
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        // Ошибка при подключении к NCALayer. Перезапустите программу и обновите страницу.
        return;
      }
      webSocket.onopen = (event) => {
        callback = async (response) => {
          const signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: this.action,
            method: 'post',
            data: { signXml },
          });
          if (res.error) {
            this.$globalLoading.hide();
          }
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          // Соединение с NCALayer было прервано. Попробуйте авторизоваться еще раз или обновить страницу.
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
  },
};
</script>
