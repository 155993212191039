import {
  QBtn,
  QDialog,
  QForm,
  QInput,
  QSlideTransition,
  QSpinnerGears,
  QToggle,
  QTooltip,
} from '@quasar/components';
import { mapGetters } from 'vuex';

export default {
  components: {
    QSpinnerGears,
    QTooltip,
    QInput,
    QToggle,
    QForm,
    QBtn,
    QDialog,
    QSlideTransition,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      showQuestions: false,
    };
  },
  beforeCreate() {
    this.$trans.add([
      'test',
    ]);
  },
  computed: {
    ...mapGetters([
      'getQuestionEditId',
      'getAnswerEditId',
      'getGroup',
    ]),
  },
  methods: {
    async changeActivity(isActive) {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: this.question.actions.changeActivity,
        data: {
          isActive,
        },
      });

      if (!res.error) {
        this.$emit('changeActive');
      }

      this.$globalLoading.hide();
    },
  },
};
