
import ServiceInfoStep from './ServiceInfoStep.vue';

export default {
  name: 'StatementServiceSteps',
  components: {
    ServiceInfoStep,
  },

  props: {
    locale: String,
    nationalities: {
      type: Array,
      default: [],
    },
    citizenships: {
      type: Array,
      default: () => ([]),
    },
    uriCheckIin: String,
    uriCheckChildIin: String,
    uriSaveUser: String,
    uriConfirmIinExists: String,
    uriAddChild: String,
    type: Number,
    children: {
      type: Array,
      default: () => ([]),
    },
    regions: {
      type: Array,
      default: () => ([]),
    },
    user: Object,
    isRehabilitation: false,
    hideLiter: false,
    checkContingent: {
      type: Boolean,
      default: true,
    },
    uriStore: String,
    placeOfResidence: String,
    actualResidence: String,
    childrenInfos: Array,
    uriChildrenInfos: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      step: 2,
      child: null,
      forAdult: null,
    };
  },

  created() {
    if ((this.user.iin || this.user.document_number) && this.type !== 13) this.step = 3;
  },

  methods: {
    step3finish(child, forAdult) {
      this.child = child;
      this.forAdult = forAdult;
      this.step = 4;
    },
  },
};
