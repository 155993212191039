export default {
  methods: {
    numberFormatter(number) {
      const digits = 0;
      let formattedNumber = number;
      if (typeof number !== 'number') {
        formattedNumber = Number(number);
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(formattedNumber)) {
        formattedNumber = 0;
      }

      return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }).format(formattedNumber);
    },
  },
};
