import Vue from 'vue';

import BtnMixin from './btn-mixin';

import QIcon from '../icon/QIcon';
import QBtn from './QBtn';
import QBtnGroup from './QBtnGroup';
import QMenu from '../menu/QMenu';

import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QBtnDropdown',

  mixins: [BtnMixin],

  props: {
    value: Boolean,
    split: Boolean,
    dropdownIcon: String,

    contentClass: [Array, String, Object],
    contentStyle: [Array, String, Object],

    cover: Boolean,
    persistent: Boolean,
    autoClose: Boolean,
    menuAnchor: {
      type: String,
      default: 'bottom right',
    },
    menuSelf: {
      type: String,
      default: 'top right',
    },
    menuOffset: {
      type: Array,
      default: undefined,
    },
    transitionShow: {
      type: String,
      default: 'fade',
    },

    transitionHide: {
      type: String,
      default: 'fade',
    },

    disableMainBtn: Boolean,
    disableDropdown: Boolean,
  },

  data() {
    return {
      showing: this.value,
    };
  },

  watch: {
    value(val) {
      this.$refs.menu !== undefined && this.$refs.menu[val ? 'show' : 'hide']();
    },
  },

  mounted() {
    this.value === true && this.show();
  },

  methods: {
    toggle(evt) {
      this.$refs.menu && this.$refs.menu.toggle(evt);
    },
    show(evt) {
      this.$refs.menu && this.$refs.menu.show(evt);
    },
    hide(evt) {
      this.$refs.menu && this.$refs.menu.hide(evt);
    },
  },

  render(h) {
    const label = this.$scopedSlots.label !== undefined
      ? this.$scopedSlots.label()
      : [];

    const Arrow = [
      h(QIcon, {
        props: {
          name: this.dropdownIcon || this.$q.iconSet.arrow.dropdown,
        },
        staticClass: 'sn-btn-dropdown__arrow s-ico--animate-self',
        class: {
          'is-active': this.showing,
          'sn-btn-dropdown__arrow-container': this.split === false,
        },
      }),
    ];

    this.disableDropdown !== true && Arrow.push(
      h(QMenu, {
        ref: 'menu',
        props: {
          cover: this.cover,
          fit: true,
          persistent: this.persistent,
          autoClose: this.autoClose,
          anchor: this.menuAnchor,
          self: this.menuSelf,
          offset: this.menuOffset,
          transitionShow: this.transitionShow,
          transitionHide: this.transitionHide,
          contentClass: this.contentClass,
          contentStyle: this.contentStyle,
          separateClosePopup: true,
          withoutHorizontalFloor: true,
        },
        on: {
          'before-show': (e) => {
            this.showing = true;
            this.$emit('before-show', e);
          },
          show: (e) => {
            this.$emit('show', e);
            this.$emit('input', true);
          },
          'before-hide': (e) => {
            this.showing = false;
            this.$emit('before-hide', e);
          },
          hide: (e) => {
            this.$emit('hide', e);
            this.$emit('input', false);
          },
        },
      }, slot(this, 'default')),
    );

    if (this.split === false) {
      return h(QBtn, {
        class: 'sn-btn-dropdown sn-btn-dropdown--simple',
        props: {
          ...this.$props,
          disable: this.disable === true || this.disableMainBtn === true,
          noWrap: true,
          round: false,
        },
        on: {
          click: (e) => {
            this.$emit('click', e);
          },
        },
      }, label.concat(Arrow));
    }

    const Btn = h(QBtn, {
      class: 'sn-btn-dropdown--current',
      props: {
        ...this.$props,
        disable: this.disable === true || this.disableMainBtn === true,
        noWrap: true,
        iconRight: this.iconRight,
        round: false,
      },
      on: {
        click: (e) => {
          this.hide();
          this.$emit('click', e);
        },
      },
    }, label);

    return h(QBtnGroup, {
      props: {
        outline: this.outline,
        flat: this.flat,
        rounded: this.rounded,
        push: this.push,
        unelevated: this.unelevated,
        glossy: this.glossy,
        stretch: this.stretch,
      },
      staticClass: 'sn-btn-dropdown sn-btn-dropdown--split sn--no-wrap sn-btn-item',
    }, [
      Btn,

      h(QBtn, {
        staticClass: 'sn-btn-dropdown__arrow-container',
        props: {
          disable: this.disable === true || this.disableDropdown === true,
          outline: this.outline,
          flat: this.flat,
          rounded: this.rounded,
          push: this.push,
          size: this.size,
          color: this.color,
          stretch: this.stretch,
          textColor: this.textColor,
          dense: this.dense,
          ripple: this.ripple,
        },
      }, Arrow),
    ]);
  },
});
