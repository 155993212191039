import axios from 'axios';
import { trim } from 'vue-resource/src/util';

export default {
  name: 'ServiceAdoptionInfoStep',
  props: {
    locale: String,
    type: Number,
    child: Number,
    regions: {
      type: Array,
      default: () => ([]),
    },
    user: Number,
    checkContingent: {
      type: Boolean,
      default: true,
    },
    uriStore: String,
  },

  data() {
    return {
      loading: false,
      isContingent: false,
      multiselectArray: {},
      selected: {},
      departments: [],
      localities: [],
      schools: [],

      errors: {},
    };
  },

  computed: {
    isValid() {
      const { selected } = this;
      for (const prop in selected) {
        if (typeof selected[prop] === 'object') {
          if (selected[prop].length === 0) delete selected[prop];
        } else if (selected[prop].trim() === '') {
          delete selected[prop];
        }
      }
      return Object.keys(selected).length === 7;
    },
  },

  beforeCreate() {
    this.$trans.add(['statement_service', 'warning_text', 'label', 'button', 'notice', 'fields']);
  },
  created() {
  },

  methods: {

    async getDepartments(value) {
      this.departments = [];
      // this.selected.to_region_id = value.id;

      try {
        this.loading = true;

        const { data: departments } = await axios.get('/departments', {
          params: {
            id: value.id,
          },
        });
        this.departments = departments;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async submit() {
      this.loading = true;
      try {
        const { child: child_id, user: user_id, type } = this;
        const data = await axios.post(
          this.uriStore,
          {
            child_id,
            user_id,
            type,
            ...this.selected,
          },
          { headers: { 'X-Requested-With': 'XMLHttpRequest' } },
        ).then((response) => {
          window.location = response.data.redirect;
        }).catch((error) => {
          // this.errors = ((error.response || {}).data || {}).errors;
          if (error.response.data.errors) {
            const { errors } = error.response.data;
            for (const key of Object.keys(errors)) {
              errors[key].forEach(error_message => show_notice(error_message, 'error'));
            }
          }
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
