import { Form } from '@common/mixins';
import { requestWrapper } from '@vjs/helpers';
import _ from 'lodash';

export default {
  mixins: [Form],
  props: {
    action: {
      type: String,
      required: true,
    },
    classes: {
      type: Array,
      required: true,
    },
  },
  beforeCreate() {
    this.$trans.add(['table', 'fields']);
  },
  data() {
    return {
      selectedClassId: null,
      fields: {
        contract_number: {
          labelTransKey: 'label.contract_number',
          value: null,
          name: 'contract_number',
          component: 'text-field',
          required: false,
        },
        receipt_number: {
          labelTransKey: 'label.receipt_number',
          value: null,
          name: 'receipt_number',
          required: true,
          component: 'text-field',
        },
        order_date: {
          labelTransKey: 'table.order_date',
          value: null,
          name: 'order_date',
          component: 'q-date-field',
          required: false,
        },
      },
    };
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const data = {};

      _.forEach(this.fields, (field) => {
        data[field.name] = field.value;
      });

      data.class_id = this.selectedClassId;

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data,
      });

      if (res.error && res.errorType !== 302) {
        this.$globalLoading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
      }
    },
    selectClass(classId) {
      this.selectedClassId = classId;
    },
  },
};
