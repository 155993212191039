import { StoreTranslator } from '@common/mixins';
import {
  QInput, QTime, QPopupProxy, QIcon,
} from '@quasar/components';

export default {
  mixins: [StoreTranslator],
  components: {
    QInput, QTime, QPopupProxy, QIcon,
  },
  props: {
    value: {},
    withMinutes: {
      type: Boolean,
      default: true,
    },
    extraBinds: {
      type: Object,
      default: () => ({}),
    },
    error: Boolean,
    errorMessage: String,
  },
  data() {
    return {
      localValue: null,
    };
  },

  beforeCreate() {
    this.$trans.add(['label', 'placeholder']);
  },
  mounted() {
    this.setInitialValue();
  },
  methods: {
    emitChange(value = null) {
      this.$emit('input', value);
    },
    setInitialValue() {
      if (this.value) {
        this.localValue = this.value;
      }
    },
  },
  watch: {
    localValue(val) {
      this.emitChange(val);
    },
    value(value) {
      // update value
      this.setInitialValue();
    },
  },
};
