<template>
  <div>
    <q-btn
      color="green"
      no-caps
      :label="trans('egov_sign.phone.btn_mobile')"
      :to="phoneLink"
      type="a"
      target="_blank"
      class="s-w-100 mb-30"
    />
    <q-btn
      color="green"
      no-caps
      :label="trans('egov_sign.phone.btn_business')"
      :to="phoneLinkBusiness"
      type="a"
      target="_blank"
      class="s-w-100"
    />
  </div>
</template>

<script>

export default {
  props: {
    phoneLink: {
      type: String,
      required: true,
    },
    phoneLinkBusiness: {
      type: String,
      required: true,
    },
  },
};
</script>
