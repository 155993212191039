<template>
  <select :name="name">
    <slot v-if="!options" />
  </select>
</template>

<script>
import $ from 'jquery';
import 'select2';

export default {
  props: {
    value: {
      type: Number | String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      // update value
      $(this.$el).val(value).trigger('change');
    },
    options(options) {
      // update options
      $(this.$el).empty().select2({
        data: options,
        // placeholder: {!! json_encode(trans('placeholder.choose')) !!},
        minimumResultsForSearch: -1,
      })
        .val(this.value)
        .trigger('change')
        .on('change', () => {
          vm.$emit('input', '');
        });
    },
  },
  mounted() {
    const vm = this;
    $(vm.$el).select2({
      // placeholder: {!! json_encode(trans('placeholder.choose')) !!},
      minimumResultsForSearch: this.search ? 0 : -1,
    }).val(vm.value).trigger('change')
      // emit event on change.
      .on('change', function () {
        // emits only string value due to select2 rotten nature
        vm.$emit('input', this.value);
      });
  },
  destroyed() {
    $(this.$el).off().select2('destroy');
  },
};
</script>
