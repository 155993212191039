import _ from 'lodash';
import { requestWrapper } from '@vjs/helpers';

export default {
  props: {
    uriGenders: {
      type: String,
      default: '',
    },
    uriChildren: {
      type: String,
      default: '',
    },
    uriNationalities: {
      type: String,
      default: '',
    },
    uriCitizenships: {
      type: String,
      default: '',
    },
    uriRegions: {
      type: String,
      default: '',
    },
    uriDepartments: {
      type: String,
      default: '',
    },
    uriSchools: {
      type: String,
      default: '',
    },
    uriLanguages: {
      type: String,
      default: '',
    },
    uriClasses: {
      type: String,
      default: '',
    },
    uriDictionaryItems: {
      type: String,
      default: '',
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async requestGetOptions({
      type = '', params = {}, customUrl = null, noDataNotice = '',
    }) {
      if (!_.isEmpty(type)) {
        this.$emit('loading', true);
        const url = customUrl ?? this[`uri${_.upperFirst(type)}`];

        const res = await requestWrapper.call(this, {
          method: 'get',
          url,
          params,
        });

        if (!res.error) {
          this.options[type] = res.data ?? res;

          if (res?.status === 'error' && res?.message) {
            this.$notify({
              text: res.message,
              type: 'error',
            });
          } else if (!this.options[type].length && noDataNotice) {
            this.$notify({
              text: noDataNotice,
              type: 'error',
            });
          }
          this.$emit('loading', false);
          return res;
        }
        this.handleRequestError(res);
        this.$emit('loading', false);
      }
    },
    handleRequestError(res) {
      if (res?.data?.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
    },
  },
};
