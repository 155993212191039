import {
  QTable,
  QTh,
  QTr,
  QTd,
} from '@quasar/components';
import NewTableFilters from '@common/views/NewTableWithFilters/NewTableFilters';
import VMonitoringTreeBody from '../Components/VMonitoringTreeBody';

export default {
  name: 'VMonitoringTree',
  components: {
    NewTableFilters,
    QTable,
    QTh,
    QTr,
    QTd,
    VMonitoringTreeBody,
  },
  props: {
    uriGetItems: {
      type: String,
      required: true,
    },
    uriGetFilters: {
      type: String,
      required: true,
    },
    storageName: {
      type: String,
      required: false,
    },
    informMessage: '',
  },
  data() {
    return {
      rows: [],
      columns: [],
      links: {},
      meta: {},
      pagination: {
        rowsPerPage: 0,
      },
    };
  },
  async mounted() {
    await this.$trans.add(['label']);
  },

  methods: {
    updateItems({
      rows, generatedLinks, meta, cols,
    }) {
      this.rows = rows;
      this.columns = cols;
      this.links = generatedLinks;
      this.meta = meta;
    },
    async toggleLoader(enable = false) {
      await this.$trans.add('label');
      if (enable && !this.loading) {
        this.loading = true;
        this.$globalLoading.show();
      } else if (!enable && this.loading) {
        this.$globalLoading.hide();
        this.loading = false;
      }
    },
  },
};
