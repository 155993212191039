import './polyfills';
import History from './history';
import Lang from './lang';
import Body from './body';
import IconSet from './icon-set';
import * as plugins from './plugins';

export const $q = {};

export default function (Vue, opts = {
  plugins: {
    AppFullscreen: plugins.AppFullscreen,
  },
}) {
  const cfg = opts.config || {};

  plugins.Platform.install($q);
  Body.install($q);
  plugins.Screen.install($q);
  // plugins.Notify.install($q);
  History.install();
  Lang.install($q, opts.lang);
  IconSet.install($q);


  opts.components && Object.keys(opts.components).forEach((key) => {
    const c = opts.components[key];
    if (typeof c === 'function') {
      Vue.component(c.options.name, c);
    }
  });

  opts.directives && Object.keys(opts.directives).forEach((key) => {
    const d = opts.directives[key];
    if (d.name !== undefined && d.unbind !== undefined) {
      Vue.directive(d.name, d);
    }
  });

  if (opts.plugins) {
    const param = { $q, cfg };
    Object.keys(opts.plugins).forEach((key) => {
      const p = opts.plugins[key];
      if (typeof p.install === 'function' && p !== plugins.Platform && p !== plugins.Screen) {
        p.install(param);
      }
    });
  }

  Vue.prototype.$q = $q;
}
