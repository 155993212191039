import Vue from 'vue';

import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QMarkupTable',

  props: {
    dense: Boolean,
    dark: Boolean,
    flat: Boolean,
    bordered: Boolean,
    square: Boolean,
    separator: {
      type: String,
      default: 'horizontal',
      validator: v => ['horizontal', 'vertical', 'cell', 'none'].includes(v),
    },
    wrapCells: Boolean,
  },

  computed: {
    classes() {
      return `sn-table--${this.separator}-separator${
        this.dark === true ? ' sn-table--dark sn-table__card--dark' : ''
      }${this.dense === true ? ' sn-table--dense' : ''
      }${this.flat === true ? ' sn-table--flat' : ''
      }${this.bordered === true ? ' sn-table--bordered' : ''
      }${this.square === true ? ' sn-table--square' : ''
      }${this.wrapCells === false ? ' sn-table--no-wrap' : ''}`;
    },
  },

  render(h) {
    return h('div', {
      staticClass: 'sn-markup-table sn-table__container sn-table__card',
      class: this.classes,
      on: this.$listeners,
    }, [
      h('table', { staticClass: 'sn-table' }, slot(this, 'default')),
    ]);
  },
});
