import {
  QTable,
  QBtn,
  QTr,
  QTh,
  QTd,
  QPagination,
  QTooltip,
  QPopupEdit,
  QInput,
  QDialog,
} from '@quasar/components';

import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';
import VModal from '@vjs/components/VModal';
import VServiceLimitFilters from '@app_mektep/components/Views/VServiceLimitFilters/VServiceLimitFilters.vue';

export default {
  props: {
    defaultRoute: {
      type: String,
      default: '',
    },
    defaultServiceType: {
      type: String,
      required: true,
    },
  },
  components: {
    QTable,
    QBtn,
    QTr,
    QTh,
    QTd,
    QPagination,
    VServiceLimitFilters,
    QTooltip,
    QPopupEdit,
    QInput,
    VConfirmDialog,
    QDialog,
    VModal,
  },
  data() {
    return {
      serviceOwners: [],
      serviceTypes: [],
      pagination: { rowsPerPage: 20 },
      loading: false,
      currentServiceType: null,
      defaultLocale: 'kz',
      editable: true,
      showPopup: false,
      chosenRow: [],
      limitValue: 0,
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'table',
      'notice',
    ]);
    await this.getServiceLimitData();

    const { core_project } = window;
    this.locale = core_project.locale || this.defaultLocale;
  },
  computed: {
    columns() {
      return [
        {
          label: this.trans('table.name'), field: 'name', name: 'name', width: '56rem',
        },
        {
          label: this.trans('table.received_statements_count'), field: 'count', name: 'count', width: '19rem',
        },
        {
          label: this.trans('table.left_places'), field: 'left_places', name: 'left_places', width: '13rem',
        },
        {
          label: this.trans('table.limit'), field: 'limit', name: 'limit', width: '9rem',
        },
        {
          label: this.trans('table.operations'), field: 'action', name: 'action', width: '12rem',
        },
      ];
    },
  },
  methods: {
    async handleList(val) {
      this.currentServiceType = val;
      await this.getServiceLimitData();
    },

    openDialog(chosenRow) {
      this.chosenRow = chosenRow;
      this.limitValue = chosenRow.limit;
      this.showPopup = true;
    },
    async getServiceLimitData() {
      this.$globalLoading.show();
      let route = this.defaultRoute;

      if (this.currentServiceType) {
        const routeTemplate = this.defaultRoute;
        route = routeTemplate.replace(/\d{1}$/, this.currentServiceType);
      }

      this.loading = true;
      await this.$axios({
        method: 'get',
        url: route,
      })
        .then((response) => {
          this.serviceOwners = response.data.serviceOwners;
          this.serviceTypes = response.data.serviceTypes;
        })
        .catch((error) => {
          this.$notify({
            text: this.trans('notice.error_on_server'),
            type: 'error',
          });
        });
      this.loading = false;
      this.$globalLoading.hide();
    },
    async updateServiceLimit() {
      if (this.limitValue < this.chosenRow.count) {
        this.$notify({
          text: this.trans('notice.limit_must_not_be_less_than_statements_count'),
          type: 'error',
        });

        this.limitValue = this.chosenRow.limit;
        return;
      }

      const url = this.chosenRow.updateRoute;

      this.$globalLoading.show();
      await this.$axios(
        {
          url,
          method: 'patch',
          data: {
            serviceLevel: this.chosenRow.level,
            limitRecordId: this.chosenRow.limitRecordId,
            newLimit: this.limitValue,
          },
        },
      )
        .then((response) => {
          this.$notify({
            type: 'success',
            title: window.L.message,
            text: response.data.message,
          });
          window.location.reload();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const vm = this;
            _.forEach(error.response.data.messages, (message) => {
              vm.$notify({
                type: 'error',
                title: window.L.message,
                text: message,
              });
            });
            return;
          }
          this.$notify({
            text: this.trans('notice.error_on_server'),
            type: 'error',
          });
          this.$globalLoading.hide();
        });
    },
  },
};
