import { eventBus, StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator],
  props: {
    value: {},
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    extraBinds: {
      type: Object,
      default() {
        return {};
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  beforeCreate() {
    this.$trans.add(['select', 'placeholder']);
  },
  methods: {
    setInitialSelectValue() {
      if (this.value !== '' && this.value !== null) {
        const options = _.cloneDeep(this.options);
        if (!this.multiple) {
          this.selectValue = _.find(options, { id: this.value });
        } else {
          this.selectValue = this.value;
        }
      } else {
        this.selectValue = null;
      }
    },
    changeValue(value) {
      // // update value
      if (this.multiple) {
        this.$emit('input', value);
      } else if (value && value.hasOwnProperty('id')) {
        this.$emit('input', value.id);
      } else {
        this.$emit('input', null);
      }
    },
  },
  data() {
    return {
      loadedValues: [],
      selectValue: [],
    };
  },

  created() {

  },
  mounted() {
    this.setInitialSelectValue();
  },
  watch: {
    value(value) {
      // update value
      this.setInitialSelectValue();
    },
    options(value) {
      // update value
      this.setInitialSelectValue();
    },
  },
  computed: {
    defaultAttributes() {
      return {
        'minimum-results-for-search': 20,
        placeholder: this.trans('placeholder.choose'),
      };
    },

    extraAttributes() {
      const attrs = this.extraBinds;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
