import Vue from 'vue';
import store from '@vjs/store';
import VOrganizationsListView from '@app_mektep/views/VOrganizationsListView';

if (document.getElementById('v-organizations-list-view')) {
  window.vOrganizationsListView = new Vue({
    el: '#v-organizations-list-view',
    components: { VOrganizationsListView },
    store,
  });
}
