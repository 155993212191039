import {
  QBtn,
  QTable,
  QTh,
  QTr,
  QTd,
  QPopupEdit,
  QInput,
  QIcon,
  QSelect,
} from '@quasar/components';
import _ from 'lodash';

export default {
  components: {
    QBtn,
    QTable,
    QTh,
    QTr,
    QTd,
    QInput,
    QIcon,
    QSelect,
    QPopupEdit,
  },
  props: {
    rows: {
      type: Array,
      default: [],
    },
    columns: {
      type: Array,
      default: [],
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    columnFilter: {
      type: Boolean,
      default: true,
    },
    organizationBookId: {
      type: Number,
      default: -1,
    },
    getRfidPath: {
      type: String,
      default: '',
    },
    booksUpdatePath: {
      type: String,
      default: '',
    },
  },
  beforeCreate() {
    this.$trans.add([
      'placeholder',
      'label',
      'rfid',
      'button',
    ]);
  },
  data: () => ({
    visibleColumns: [],
    filter: '',
    pagination: {
      rowsPerPage: 20,
    },
    dataRows: [],
  }),
  methods: {
    async getBooksRfid() {
      try {
        const { data } = await this.$axios.get(this.getRfidPath);
        if (this.rows.length === Object.keys(data[0]).length) {
          _.forEach(this.dataRows, (value, key) => {
            value.rfid = data[0][key];
          });
        } else {
          this.$notify({
            text: `${this.trans('rfid.books_quantity_error')}<b>${Object.keys(data[0]).length}</b>`,
            type: 'error',
          });
        }
      } catch (error) {
        this.$notify({
          text: this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
    },
    async updateBooksRfid() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.booksUpdatePath,
        method: 'post',
        data: {
          book: this.dataRows,
          organization_book_id: this.organizationBookId,
        },
      });
      if (res.error) {
        this.$notify({
          text: this.trans('notice.error_on_server'),
          type: 'error',
        });
        this.$globalLoading.hide();
      }
    },
  },
  mounted() {
    this.visibleColumns = this.columns.map(v => v.name);
    this.dataRows = this.rows;
  },
};
