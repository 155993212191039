import VStepParent from './VStepParent';
import VStepChild from './VStepChild';
import VStepStatement from './VStepStatement';
import VStepCongratulations from './VStepCongratulations';
import VStepsBody from './VStepsBody';
import Stepper from './Stepper';
import OptionsMixin from './mixins/OptionsMixin';

export default {
  name: 'VSchoolCertificateStatementForm',

  mixins: [OptionsMixin],
  components: {
    VStepParent,
    VStepChild,
    VStepStatement,
    VStepCongratulations,
    VStepsBody,
    Stepper,
  },
  props: {
    uriAction: {
      type: String,
      required: true,
    },
    uriCheckIinParent: {
      type: String,
      default: '',
    },
    uriConfirmIinExistsParent: {
      type: String,
      default: '',
    },
    uriStoreUserInfo: {
      type: String,
      default: '',
    },
    uriStatic: {
      type: String,
      default: '',
    },
    hasUser: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    informMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      uriStatement: '',

      steps: {
        PARENT: 2,
        CHILD: 3,
        STATEMENT: 4,
        FINISH: 5,
      },
    };
  },

  beforeCreate() {
    this.$trans.add([
      'statement',
      'label',
      'button',
      'placeholder',
      'validation',
      'notice',
      'warning_text',
      'statement_service',
      'main',
      'fields',
    ]);
  },

  mounted() {
    let currentStep = this.steps.PARENT;
    if (this.hasUser) {
      currentStep = this.steps.CHILD;
    }
    this.$store.commit('SET_CURRENT_STEP', currentStep);
  },

  computed: {
    current() {
      return this.$store.state.vSteps.currentStep;
    },
    optionsProps() {
      return {
        uriGenders: this.uriGenders,
        uriChildren: this.uriChildren,
        uriNationalities: this.uriNationalities,
        uriCitizenships: this.uriCitizenships,
      };
    },
  },

  methods: {
    getStepTitle() {
      switch (this.current) {
        case this.steps.PARENT: return this.trans('statement_service.about_parents');
        case this.steps.CHILD: return this.trans('statement_service.about_child');
        case this.steps.STATEMENT: return this.trans('statement_service.statement_info');
        default: return '';
      }
    },
    loadingProcessing(status) {
      this.loading = status;
      if (status) {
        this.$globalLoading.show();
      } else {
        this.$globalLoading.hide();
      }
    },
  },
};
