export default (template, count) => {
  const arr = [];
  let duplicate = {};

  for (let i = 0; i < count; i += 1) {
    if (template && template.id !== undefined) {
      duplicate = { ...template, id: i };
    }

    arr.push(duplicate);
  }

  return arr;
};
