<template>
  <section
    class="sm-main-page__section sm-main-page__statistic"
  >
    <div class="sm-main-page__statistic--title">
      {{ trans('main.statistic_title') }}
    </div>
    <div class="sm-main-page__statistic--items-wrapper">
      <div
        v-for="item in list"
        :key="item.name"
        class="sm-main-page__statistic--item"
      >
        <div class="sm-main-page__statistic--item-image">
          <img :src="`${uriStatic}/mektep/images/main-page/statistic-${item.name}.png`">
        </div>
        <div
          :ref="`statistic-${item.name}-ref`"
          class="sm-main-page__statistic--item-count"
        >
          {{ formatNumberValue(counts[item.name]) }}
        </div>
        <div class="sm-main-page__statistic--item-label">
          {{ item.label }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/* eslint-disable camelcase */
export default {
  name: 'Statistic',
  props: {
    uriStatic: { type: String, required: true },
    regionStats: { type: Object, required: true },
  },
  data() {
    const { statements_total, school_count, statements_accepted } = this.regionStats;
    return {
      counts: {
        statements: statements_total > 400 ? statements_total - 400 : 0,
        schools: school_count > 400 ? school_count - 400 : 0,
        children: statements_accepted > 400 ? statements_accepted - 400 : 0,
      },
    };
  },
  computed: {
    list() {
      return [
        { name: 'statements', label: this.trans('label.statements_in_queue') },
        { name: 'schools', label: this.trans('label.organizations_in_system') },
        { name: 'children', label: this.trans('label.accepted_childs') },
      ];
    },
  },
  async mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.$on('hook:beforeDestroy', this.removeScrollHandler);
    this.onScroll();
    await this.$trans.add([
      'label', 'main',
    ]);
  },
  methods: {
    formatNumberValue(number) {
      let formattedNumber = number;
      if (typeof number !== 'number') {
        formattedNumber = Number(number);
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(formattedNumber)) {
        formattedNumber = 0;
      }

      return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(formattedNumber);
    },
    onScroll(e) {
      const { statements_total, school_count, statements_accepted } = this.regionStats;
      if (
        (window.innerHeight + window.pageYOffset)
        >= this.$el.offsetTop + this.$refs['statistic-statements-ref'][0].offsetTop
      ) {
        this.removeScrollHandler();

        if (statements_total !== 0) {
          const intervalStatements = setInterval(() => {
            if (++this.counts.statements >= statements_total) {
              clearInterval(intervalStatements);
            }
          }, 1500 / statements_total);
        }

        if (school_count !== 0) {
          const intervalSchools = setInterval(() => {
            if (++this.counts.schools >= school_count) {
              clearInterval(intervalSchools);
            }
          }, 1500 / school_count);
        }

        if (statements_accepted !== 0) {
          const intervalChildren = setInterval(() => {
            if (++this.counts.children >= statements_accepted) {
              clearInterval(intervalChildren);
            }
          }, 1500 / statements_accepted);
        }
      }
    },
    removeScrollHandler() {
      window.removeEventListener('scroll', this.onScroll);
    },
  },
};

</script>
