<template>
  <div
    class="s-info-card"
  >
    <div
      class="s-w-100 s-info-card--card"
    >
      <div class="s-info-card--wrapper s-w-100">
        <div
          class="s-info-card--title"
        >
          <span
            class="s-str-ico-fa s-str-ico-fa--thm-list"
          >
            {{ trans('label.status') }}
          </span>
        </div>
        <div class="s-application-block__body d-flex flex-column align-items-center">
          <step
            v-for="(step, key) in data"
            :key="key"
            :number="key+1"
            :step="step"
            :with-arrow="(key+1) !== data.length"
            :is-arrow-on-right="((key+1) % 2) === 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media screen and ( min-width: 768px ) {
  .s-info-card.row {
    .s-info-card--card {
      padding-right: 1rem;
    }
  }
}
</style>

<script>
import Step from './Step';

export default {
  name: 'StatementShowStepsCard',
  components: { Step },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
