<template>
  <section class="s-home-container-wrapper mb-40">
    <div
      class="s-warning-message-block s-b-white"
      style="background: #FFFFFF;"
    >
      <div class="s-warning-message-block__image" />
      <div class="s-warning-message-block__content-wrapper">
        <div
          class="s-warning-message-block__description"
          v-html="trans('main.banner_snation')"
        />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Banner',
};

</script>
