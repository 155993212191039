// t = current time
// b = start value
// c = change in value
// d = duration
function easeInOutQuad(t, b, c, d) {
  t /= d / 2;

  if (t < 1) {
    return c / 2 * t * t + b;
  }

  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
}

export default function scrollTop(element, start, to, duration) {
  const change = to - start;
  const increment = 20;
  let currentTime = 0;
  const animateScroll = () => {
    currentTime += increment;
    element.scrollTo(0, easeInOutQuad(currentTime, start, change, duration));

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}
