import History from '../history';

export default {
  methods: {
    __addHistory() {
      this.__historyEntry = {
        condition: () => this.hideOnRouteChange === true,
        handler: this.hide,
      };
      History.add(this.__historyEntry);
    },

    __removeHistory() {
      if (this.__historyEntry !== void 0) {
        History.remove(this.__historyEntry);
        this.__historyEntry = void 0;
      }
    },
  },

  beforeDestroy() {
    this.showing === true && this.__removeHistory();
  },
};
