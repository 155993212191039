import { setBrand } from './utils/colors';

function getMobilePlatform(is) {
  if (is.ios === true) return 'ios';
  if (is.android === true) return 'android';
  if (is.winphone === true) return 'winphone';
}

function getBodyClasses({ is, has, within }) {
  const cls = [
    is.desktop ? 'desktop' : 'mobile',
    has.touch ? 'touch' : 'no-touch',
  ];

  if (is.mobile === true) {
    const mobile = getMobilePlatform(is);
    if (mobile !== void 0) {
      cls.push(`platform-${mobile}`);
    }
  }

  within.iframe === true && cls.push('within-iframe');

  return cls;
}

function bodyInit(Platform) {
  const cls = getBodyClasses(Platform);

  if (Platform.is.ie === true && Platform.is.versionNumber === 11) {
    cls.forEach(c => document.body.classList.add(c));
  } else {
    document.body.classList.add.apply(document.body.classList, cls);
  }

  if (Platform.is.ios === true) {
    // needed for iOS button active state
    document.body.addEventListener('touchstart', () => {});
  }
}

export default {
  install($q) {
    bodyInit($q.platform);
  },
};
