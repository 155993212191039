import CardMixin from '../CardMixin';
import CardTableMixin from '../CardTableMixin';

export default {
  mixins: [CardMixin, CardTableMixin],

  data() {
    return {
      newRowTemplate: {
        type: {},
        employment: {},
      },
    };
  },

  computed: {
    cols() {
      let result = [
        {
          label: this.trans('personnel.blocks.fields.family_type'),
          name: 'type',
          field: 'type',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.full_name'),
          name: 'full_name',
          field: 'full_name',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.birth_date'),
          name: 'birth_date',
          field: 'birth_date',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.employment'),
          name: 'employment',
          field: 'employment',
          align: 'center',
        },
        {
          label: this.trans('label.operations'),
          name: 'actions',
          field: 'actions',
          align: 'center',
          width: 140,
        },
      ];
      if (this.disabled) {
        result = result.filter(itm => itm.field !== 'actions');
      }
      return result;
    },
  },
};
