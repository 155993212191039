import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import {
  BalabaqshaBalloon, CollegeBalloon, DamuBalloon, JumysBalloon, MektepBalloon,
} from './Balloons';

import {
  BalabaqshaLegend, CollegeLegend, DamuLegend, JumysLegend, MektepLegend,
} from './Legends';

export default {
  components: {
    yandexMap,
    ymapMarker,
    BalabaqshaBalloon,
    CollegeBalloon,
    DamuBalloon,
    JumysBalloon,
    MektepBalloon,
    BalabaqshaLegend,
    CollegeLegend,
    DamuLegend,
    JumysLegend,
    MektepLegend,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    balloonExtraBinds: {
      type: Object,
      default: () => ({}),
    },
    contentConfig: {
      type: Object,
      default: () => ({
        content: null,
        offset: [-50, 27],
        hint: 'name',
      }),
    },
  },
  data() {
    const { cdnStatic } = window.core_project;
    return {
      balloonComponent: `${_.startCase(this.type)}Balloon`,
      legendComponent: `${_.startCase(this.type)}Legend`,
      cdnStatic,
      coords: [0, 0],
      zoom: 6,
      clusterOptions: {
        1: {
          clusterIconContentLayout: '<span class=s-c-white>{{ properties.geoObjects.length }}</span>',
          clusterIcons: [{
            href: `${cdnStatic}/base/images/map-markers/cluster.svg`,
            size: [46, 46],
            offset: [-12, -12],
          }],
        },
      },
    };
  },
  mounted() {
    if (this.items.filter(el => el.coords !== null)?.length === 0) {
      this.zoom = 0;
    }
  },
  methods: {
    // На яндекс картах у баллунов по дефолту блокируются абсолютно все ивенты
    // и работают только через ре-бинд ивента по айдишке
    // https://yandex.ru/dev/maps/ymapsml/doc/1.x/guide/concepts/templates.html
    // табличка "Ограничения на использование HTML в шаблонах"
    // https://vue-yandex-maps.github.io/guide/Marker.html#slots
    // дока именно по vue-картам

    // TODO: если вдруг понадобится для других проектов кроме садиков, нужно что-нибудь придумать
    // например распихать кучу ифок на каждый проект
    bindListener(id) {
      if (this.type === 'balabaqsha') {
        const balloonRef = this.$refs[`balloon-${id}`][0];
        const event = balloonRef.openQueueModal;
        const el = document.getElementById('select_org_button');
        if (el) {
          el.addEventListener('click', event);
        }
      }
    },
    unbindListener(id) {
      if (this.type === 'balabaqsha') {
        const balloonRef = this.$refs[`balloon-${id}`][0];
        const event = balloonRef.openQueueModal;
        const el = document.getElementById('select_org_button');
        if (el) {
          el.removeEventListener('click', event);
        }
      }
    },
  },
  watch: {
    zoom(val) {
      if (val > 21) {
        this.zoom = 18;
      }
    },
  },
};
