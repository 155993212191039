import axios from 'axios';
import VFormInform from '@vjs/components/VFormInform';
import Offer from '@vjs/modals/Offer';
import Validation from '@vjs/mixins/Validation';
import {
  QInput, QBtn, QForm, QSlideTransition, QCheckbox,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'VBinomConfirmationIinForm',
  mixins: [Validation],
  components: {
    QInput, QBtn, QForm, QSlideTransition, QCheckbox, VFormInform,
  },
  props: {
    urls: {
      type: Object,
      default: () => ({
        sendCode: '',
        submitCode: '',
        changePass: '',
      }),
    },
  },

  data: () => ({
    form: {
      phone: '',
      code: '',
      iin: '',
      password: '',
      password_confirmation: '',
      checkOfferta: false,
    },
    isCodeSended: false,
    currentStep: 1,
  }),
  beforeCreate() {
    this.$trans.add(['auth', 'button', 'label', 'placeholder', 'validation', 'warning_text']);
  },
  computed: {
    validateForm() {
      if (this.currentStep === 1) {
        if (this.isCodeSended) {
          return this.form.code.length === 6 && this.form.checkOfferta;
        }
        return this.validateIin(this.form.iin) && this.form.checkOfferta;
      }
      return !!this.form.password && !!this.form.password_confirmation;
    },
  },
  methods: {
    async handleSubmit() {
      switch (this.currentStep) {
        case 1:
          if (!this.isCodeSended) {
            const res = await this.handleRequest(this.urls.sendCode);
            if (!res.error) {
              this.form.phone = res.phone;
              this.isCodeSended = true;
            }
            break;
          } else {
            const res = await this.handleRequest(this.urls.submitCode);

            if (!res.error) {
              this.currentStep = 2;
            }
            break;
          }
        case 2:
          await this.handleRequest(this.urls.changePass);
          break;
        default:
      }
    },
    async handleRequest(url = '') {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url,
        method: 'POST',
        data: this.form,
      });

      if (this.currentStep === 1 || (res.error && res.errorType !== 302)) {
        this.$globalLoading.hide();
      }
      return res;
    },
    offertaButton() {
      const vm = this;
      vm.$set(vm, 'checkOfferta', !vm.checkOfferta);
    },
    async offerInfo() {
      const res = await axios.get(this.urls.offerModal);
      this.$sModal.open('offer', {
        size: '800',
        binds: {
          text: res.data,
        },
        component: Offer,
      });
    },
  },
};
