import {
  QPopupProxy, QIcon, QField,
} from '@quasar/components';
import moment from 'moment';


// Месяцы в moment начинаются с 0, но если парсить - все нормально
// поэтому в localValue записывается допустим март (3), а в month - март (2)
export default {
  components: { QPopupProxy, QIcon, QField },
  props: {
    value: null,
    borderless: {
      type: Boolean,
      default: false,
    },
    altStyle: Boolean,
    label: String,
    required: Boolean,
  },
  data() {
    return {
      localValue: null,
      tmpYear: null,
      selectedYear: null,
      selectedMonth: null,
      displayedValue: null,
      defaultFormat: 'MM.YYYY',
    };
  },
  computed: {
    localValueMoment() {
      return moment(this.localValue, this.defaultFormat);
    },
    months() {
      return Array.from({ length: 12 }, (e, i) => ({
        label: _.upperFirst(moment().month(i).format('MMM')).substring(0, 3),
        value: i,
      }));
    },
    years() {
      const year = moment().year();
      const from = 2020;
      const to = year + 1;
      const years = [];
      for (let i = from; i < to + 1; i += 1) {
        years.push({ label: i, value: i });
      }

      return years;
    },
  },
  beforeCreate() {
    moment.locale(window.core_project.locale === 'kz' ? 'kk' : 'ru');
  },
  mounted() {
    this.setInitialValue();
  },
  methods: {
    emitChange(value = null) {
      this.$emit('input', value);
    },
    setInitialValue() {
      if (this.value) {
        this.localValue = this.value;
      } else {
        this.localValue = moment().format(this.defaultFormat);
      }
      this.emitChange(this.localValue);
    },
    handleSelectMonth(monthId) {
      this.localValue = moment().month(monthId).year(this.tmpYear).format(this.defaultFormat);
      this.tmpYear = null;
      this.$refs.popupProxy.hide();
      this.emitChange(this.localValue);
    },
    handleYearDirectionBtn(direction = true) {
      const firstYear = this.years[0]?.value;
      const lastYear = this.years[this.years.length - 1]?.value;
      if (direction) {
        this.tmpYear = this.tmpYear === lastYear ? firstYear : this.tmpYear + 1;
      } else {
        this.tmpYear = this.tmpYear === firstYear ? lastYear : this.tmpYear - 1;
      }
    },
    handleDirectionBtn(direction = true) {
      const firstYear = this.years[0]?.value;
      const lastYear = this.years[this.years.length - 1]?.value;
      let tmpMonth = 0;
      let tmpYear = this.selectedYear;
      if (this.selectedMonth === 0) {
        tmpMonth = direction ? this.selectedMonth + 1 : 11;
        tmpYear = !direction && this.selectedYear === firstYear ? lastYear
          : direction ? this.selectedYear : this.selectedYear - 1;
      } else if (this.selectedMonth === 11) {
        tmpMonth = direction ? 0 : this.selectedMonth - 1;
        tmpYear = direction && this.selectedYear === lastYear ? firstYear
          : !direction ? this.selectedYear : this.selectedYear + 1;
      } else {
        tmpMonth = direction ? this.selectedMonth + 1 : this.selectedMonth - 1;
      }
      this.localValue = moment().month(tmpMonth).year(tmpYear).format(this.defaultFormat);
      this.emitChange(this.localValue);
    },
  },
  watch: {
    localValueMoment(value) {
      this.selectedYear = value.year();
      this.selectedMonth = value.month();
      this.displayedValue = _.upperFirst(value.format('MMMM YYYY'));
    },
    value(val, oldval) {
      if (!_.isEqual(val, oldval)) {
        this.setInitialValue();
      }
    },
  },
};
