export default {
  isoName: 'kz',
  nativeName: 'Қазақ тілі',
  label: {
    clear: 'Тазалау',
    ok: 'OK',
    cancel: 'Болдырмау',
    close: 'Жабу',
    set: 'Орнату',
    select: 'Таңдау',
    reset: 'Өшіру',
    remove: 'Жою',
    update: 'Өзгерту',
    create: 'Құру',
    search: 'Іздеу',
    filter: 'Сүзгі',
    refresh: 'Жаңарту',
    select_no_options: 'Тізім бос',
  },
  date: {
    days: 'Жексенбі_Дүйсенбі_Сейсенбі_Сәрсенбі_Бейсенбі_Жұма_Сенбі'.split('_'),
    daysShort: 'Жк_Дс_Сс_Ср_Бс_Жм_Сн'.split('_'),
    months: 'Қаңтар_Ақпан_Наурыз_Сәуір_Мамыр_Маусым_Шілде_Тамыз_Қыркүйек_Қазан_Қараша_Желтоқсан'.split('_'),
    monthsShort: 'Қаң_Ақп_Нау_Сәу_Мам_Мау_Шіл_Там_Қыр_Қаз_Қар_Жел'.split('_'),
    firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
  },
  table: {
    noData: 'Деректер жоқ',
    noResults: 'Сәйкестіктер табылмады',
    loading: 'Жүктеу...',
    selectedRecords(rows) {
      return rows === 1
        ? '1 жазба таңдалды.'
        : `${rows} жазбалары таңдалды.`;
    },
    recordsPerPage: 'Беттегі жолдар саны:',
    allRows: 'Барлығы',
    pagination(start, end, total) {
      return `${start}-${end} / ${total}`;
    },
    columns: 'Бағандар',
  },
  editor: {
    url: 'URL',
    bold: 'Жартылай қалың',
    italic: 'Курсив',
    strikethrough: 'Сызылған',
    underline: 'Асты сызылған',
    unorderedList: 'Таңбаланған тізім',
    orderedList: 'Нөмірленген тізім',
    subscript: 'Жол асты',
    superscript: 'Жол үсті',
    hyperlink: 'Гиперсілтеме',
    toggleFullscreen: 'Толық экран режимі',
    quote: 'Дәйексөз',
    left: 'Сол жақ жиегі бойынша туралау',
    center: 'Ортасына туралау',
    right: 'Оң жақ жиегі бойынша туралау',
    justify: 'Ені бойынша туралау',
    print: 'Басып шығару',
    outdent: 'Шегіністі азайту',
    indent: 'Шегіністі ұлғайту',
    removeFormat: 'Пішімдеуді өшіру',
    formatting: 'Пішімдеу',
    fontSize: 'Қаріп өлшемі',
    align: 'Туралау',
    hr: 'Көлденең сызықты кірістіру',
    undo: 'Болдырмау',
    redo: 'Қайталау',
    header1: 'Тақырып 1',
    header2: 'Тақырып 2',
    header3: 'Тақырып 3',
    header4: 'Тақырып 4',
    header5: 'Тақырып 5',
    header6: 'Тақырып 6',
    paragraph: 'Параграф',
    code: 'Код',
    size1: 'Өте кішкентай',
    size2: 'Кішкентай',
    size3: 'Қалыпты',
    size4: 'Орташа',
    size5: 'Үлкен',
    size6: 'Өте үлкен',
    size7: 'Зор',
    defaultFont: 'Әдетті қаріп',
  },
  tree: {
    noNodes: 'Қол жетімді түйіндері жоқ',
    noResults: 'Сәйкестік табылмады',
  },
};
