import langRu from '../lang/ru';
import langKz from '../lang/kz';

const language = {
  ru: langRu,
  kz: langKz,
};

export default {
  install($q, lang) {
    this.set = (lang = language[(window.core_project && window.core_project.locale) || 'ru']) => {
      lang.set = this.set;
      lang.getLocale = this.getLocale;
      lang.rtl = lang.rtl || false;

      const el = document.documentElement;
      el.setAttribute('dir', lang.rtl ? 'rtl' : 'ltr');
      el.setAttribute('lang', lang.isoName);

      $q.lang = lang;


      this.isoName = lang.isoName;
      this.nativeName = lang.nativeName;
      this.props = lang;
    };

    this.set(lang);
  },

  getLocale() {
    const val = navigator.language
      || navigator.languages[0]
      || navigator.browserLanguage
      || navigator.userLanguage
      || navigator.systemLanguage;

    if (val) {
      return val.toLowerCase();
    }
  },
};
