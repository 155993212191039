import { QBtn, QIcon } from '@quasar/components';
import StatementOrganizationsTable from './components/StatementOrganizationsTable.vue';

export default {
  name: 'StatementOrganizations',

  components: {
    StatementOrganizationsTable,
    QBtn,
    QIcon,
  },

  props: {
    isAdmissionByAddress: {
      type: Boolean,
      required: true,
    },
    organizations: {
      type: Array,
      default: [],
    },
    organizationsByQuota: {
      type: Array,
      default: [],
    },
  },

  async mounted() {
    await this.$trans.add(['label', 'button', 'additional_education', 'table', 'search']);
  },

  data() {
    return {
      hasByAddressOrganizations: true,
      hasByQuotaOrganizations: true,
      spoilers: {
        byAddressOrganizations: false,
        byQuotaOrganizations: false,
      },
    };
  },
};
