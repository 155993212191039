import Vuex, { mapGetters } from 'vuex';
import pluginStoreConfig from './store';
import { PLUGIN_NAME } from './const';

const Translator = {
  install(_Vue, pluginOptions = {}) {
    let { store, eventBus } = pluginOptions;
    if (!eventBus) {
      eventBus = new _Vue();
    }
    if (store) {
      // если передали store - регистрируем в нем свой модуль
      store.registerModule(PLUGIN_NAME, pluginStoreConfig);
    } else {
      // не передали, для начала проверим, установлен ли Vuex в Vue
      if (!new _Vue({ store: {} }).$store) {
        // и если нет, установим
        _Vue.use(Vuex);
      }
      // и создадим новый store с единственным модулем - нашим
      store = new Vuex.Store({
        modules: {
          [PLUGIN_NAME]: pluginStoreConfig,
        },
      });
    }
    // немного упростим себе жизнь, чтоб не писать каждый раз наш нэймспейс
    // если что-то не используется, то можно убрать
    const boundStore = {
      commit(mutation, payload, options) {
        return store.commit(`${PLUGIN_NAME}/${mutation}`, payload, options);
      },
      dispatch(action, payload, options) {
        return store.dispatch(`${PLUGIN_NAME}/${action}`, payload, options);
      },
      watch(fn, cb, options) {
        return store.watch(
          (state, getters) => fn(state[PLUGIN_NAME],
            getters[PLUGIN_NAME]),
          cb,
          options,
        );
      },
    };
    _Vue.mixin({
      methods: {
        trans: (key = '', params) => store.getters[`${PLUGIN_NAME}/lang`](key, params),
      },
    });
    const trans = {
      add: async arrOfTrans => new Promise(async (res) => {
        const result = await boundStore.dispatch('addLoad', arrOfTrans);
        res(result);
      }),
      get: (key = '', params) => store.getters[`${PLUGIN_NAME}/lang`](key, params),
      existsLoaded: (key = '') => store.getters[`${PLUGIN_NAME}/existsLoaded`](key),
      eventBus,
    };
    _Vue.prototype.$trans = trans;
    _Vue.trans = trans;
  },
};

export default Translator;
