<template>
  <div class="row mb-20 s-pos-relative-position">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div
        class="s-str-ico-fa s-str-ico-fa--thm-map-marker-alt s-orgs-map-legend s-orgs-map-legend--gov"
      >
        {{ trans('label.map_markers_gov_org') }}
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div
        class="s-str-ico-fa s-str-ico-fa--thm-map-marker-alt s-orgs-map-legend s-orgs-map-legend--private"
      >
        {{ trans('label.map_markers_private_org') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
