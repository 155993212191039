import {
  QForm,
  QInput,
  QBtn,
  QIcon,
  QCheckbox,
  QSlideTransition,
  QDate,
  QPopupProxy,
  QOptionGroup,
  QField,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import eventHub from '@vjs/config/eventHub';
import Validation from '@vjs/mixins/Validation';
import VConfirmDialogNew from '@vjs/modals/VConfirmDialogNew.vue';
import { requestWrapper } from '@vjs/helpers';
import OptionsMixin from '../mixins/OptionsMixin';

export default {
  name: 'VStepParent',

  mixins: [OptionsMixin, Validation],
  components: {
    QForm,
    QInput,
    QBtn,
    QIcon,
    QCheckbox,
    QSlideTransition,
    QDate,
    QPopupProxy,
    QOptionGroup,
    QField,
    VFormInform,
    VConfirmDialogNew,
  },

  props: {
    uriCheckIinParent: {
      type: String,
      required: true,
    },
    uriConfirmIinExistsParent: {
      type: String,
      required: true,
    },
    uriStoreUserInfo: {
      type: String,
      required: true,
    },
    actualResidence: {
      type: String,
      default: '',
    },
    placeOfResidence: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      form: {
        resident: true,
        iin: null,
        citizenship_id: null,
        nationality_id: null,
        surname: null,
        firstname: null,
        lastname: null,
        born_date: null,
        sex: null,
        actual_residence: this.actualResidence,
      },
      isIinApproved: false,
      tmpUser: null,
      options: {
        citizenships: [],
        nationalities: [],
        genders: [],
      },
      kzCitizenshipId: '03dcc2de-10ec-434a-39b1-b70cc4fd5551',
    };
  },
  async mounted() {
    await this.requestGetOptions({ type: 'citizenships', params: { withLegacyId: true } });
    await this.requestGetOptions({ type: 'nationalities', params: { withLegacyId: true } });
    await this.requestGetOptions({ type: 'genders' });
  },

  created() {
    eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },

  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  computed: {
    citizenshipOptions() {
      return this.form.resident
        ? this.options.citizenships
        : this.options.citizenships.filter(item => item.id !== this.kzCitizenshipId);
    },
    validate() {
      return ((this.form.resident && this.isIinApproved) || !this.form.resident)
        && ![null, ''].includes(this.form.iin)
        && this.form.citizenship_id !== null
        && this.form.nationality_id !== null
        && ![null, ''].includes(this.form.surname)
        && ![null, ''].includes(this.form.firstname)
        && this.form.born_date !== null
        && this.form.actual_residence !== '';
    },
  },
  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS', val);
    },
  },

  methods: {
    clearForm() {
      this.tmpUser = null;
      this.isIinApproved = false;

      this.form.iin = null;
      this.form.citizenship_id = null;
      this.form.nationality_id = null;
      this.form.surname = null;
      this.form.firstname = null;
      this.form.lastname = null;
      this.form.sex = null;
      this.form.born_date = null;
    },
    async confirmMoveIin() {
      this.$emit('loading', true);
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriConfirmIinExistsParent,
        data: { iin: this.form.iin, withoutAbort: true, withoutNotice: true },
      });

      if (!res.error) {
        this.form = { ...this.form, ...this.tmpUser };
        this.isIinApproved = true;
        this.tmpUser = null;
        this.$notify({
          text: this.trans('notice.success_iin_confirmed'),
          type: 'success',
        });
      } else {
        this.handleRequestError(res);
      }
      this.$emit('loading', false);
    },
    async handleCheckIin() {
      this.$emit('loading', true);

      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriCheckIinParent,
        params: { iin: this.form.iin },
      });

      if (!res.error) {
        if (res.status === 'fail') {
          this.$notify({
            text: res.message,
            type: 'error',
          });
        } else if (res.status === 'exists') {
          this.tmpUser = res.data;
          this.tmpUser.citizenship_id = this.kzCitizenshipId;
        } else if (res.status === 'success') {
          const { data } = res;

          this.form = { ...this.form, ...data };
          this.form.citizenship_id = this.kzCitizenshipId;
          this.isIinApproved = true;
        }
      } else {
        this.handleRequestError(res);
      }

      this.$emit('loading', false);
    },
    async requestForm() {
      this.$emit('loading', true);

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriStoreUserInfo,
        data: { ...this.form, fromStatementService: true, withoutNotice: true },
      });

      if (!res.error) {
        this.$notify({
          text: this.trans('notice.user_profile_updated'),
          type: 'success',
        });
        this.$store.commit('SET_NEXT_STEP');
      } else {
        this.handleRequestError(res);
      }

      this.$emit('loading', false);
    },
  },
};
