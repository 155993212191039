import QLinearProgress from '../linear-progress/QLinearProgress';
import QCheckbox from '../checkbox/QCheckbox';
import QSeparator from '../separator/QSeparator';

export default {
  methods: {
    getGridBody(h) {
      const gridBottomItem = this.$scopedSlots['grid-bottom-item'];

      const item = this.$scopedSlots.item !== void 0
        ? this.$scopedSlots.item
        : (scope) => {
          const child = scope.cols.map(
            col => h('div', { staticClass: 'sn-table__grid-item-row' }, [
              h('div', { staticClass: 'sn-table__grid-item-title' }, [col.label]),
              h('div', { staticClass: 'sn-table__grid-item-value' }, [col.value]),
            ]),
          );

          if (gridBottomItem !== void 0) {
            child.push(gridBottomItem({ cols: this.computedCols }));
          }

          this.hasSelectionMode === true && child.unshift(
            h('div', { staticClass: 'sn-table__grid-item-row' }, [
              h(QCheckbox, {
                props: {
                  value: scope.selected,
                  color: this.color,
                  dark: this.dark,
                  dense: true,
                },
                on: {
                  input: (val) => {
                    scope.selected = val;
                  },
                },
              }),
            ]),

            h(QSeparator, { props: { dark: this.dark } }),
          );

          return h('div', {
            staticClass: 'sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-3',
            class: scope.selected === true ? 'sn-table__grid-item--selected' : null,
          }, [
            h('div', {
              staticClass: `sn-table__grid-item-card${this.cardDefaultClass}`,
              class: this.cardClass,
              style: this.cardStyle,
            }, child),
          ]);
        };

      const child = this.computedRows.map((row) => {
        const
          key = row[this.rowKey];
        const selected = this.isRowSelected(key);

        return item(this.addBodyRowMeta({
          key,
          row,
          cols: this.computedCols,
          colsMap: this.computedColsMap,
          __trClass: selected ? 'selected' : '',
        }));
      });

      if (gridBottomItem !== void 0) {
        child.push(gridBottomItem({ cols: this.computedCols }));
      }

      return h('div', { staticClass: 'row' }, child);
    },

    getGridHeader(h) {
      return h('div', { staticClass: 'sn-table__middle' }, [
        this.gridHeader === true
          ? h('table', { staticClass: 'sn-table' }, [
            this.getTableHeader(h),
          ])
          : (this.loading === true
            ? h(QLinearProgress, {
              staticClass: 'sn-table__linear-progress',
              props: {
                color: this.color,
                dark: this.dark,
                indeterminate: true,
              },
            })
            : null
          ),
      ]);
    },
  },
};
