import {
  QIcon,
  QOptionGroup,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import Validation from '@vjs/mixins/Validation';

export default {
  name: 'VStatementContractConcludeForm',
  mixins: [Validation],
  components: {
    QIcon,
    QOptionGroup,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        backButtonUrl: '',
        submitUrl: '',
        getData: '',
        staticUrl: '',
      }),
    },
    contractName: String,
    comment: String,
  },
  data() {
    return {
      form: {
        child_name: null,
        child_iin: null,
        child_born_date: null,
        child_sex: null,

        issuer_name: null,
        issuer_iin: null,
        issuer_passport: null,
        issuer_address: null,
        issuer_phone: null,
        issuer_work_place: null,
        issuer_work_position: null,
      },

      inputList: [
        'child_name',
        'child_iin',
        'child_born_date',
        'issuer_name',
        'issuer_iin',
        'issuer_passport',
        'issuer_address',
        'issuer_phone',
        'issuer_work_place',
        'issuer_work_position',
      ],

      disable: {
        issuer_passport: false,
        issuer_phone: false,
        issuer_work_place: false,
        issuer_work_position: false,
      },
    };
  },

  computed: {
    genderOptions() {
      return [
        {
          value: 'm',
          label: this.trans('label.male'),
        },
        {
          value: 'w',
          label: this.trans('label.female'),
        },
      ];
    },
    validate() {
      return this.form.issuer_passport !== null
        && this.form.issuer_address !== null
        && this.form.issuer_phone !== null
        && this.form.issuer_work_place !== null
        && this.form.issuer_work_position !== null;
    },
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'statement',
      'warning_text',
      'electronic_contract',
    ]);
    await this.requestGetData();
  },

  methods: {
    isInputDisabled(name) {
      if (this.disable.hasOwnProperty(name)) {
        return this.disable[name];
      }
      return true;
    },
    async requestGetData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        const { data } = res;
        this.form = { ...this.form, ...data.data };
        if (data.disabled && !_.isEmpty(data.disabled)) {
          _.forEach(this.disable, (value, name) => {
            this.disable[name] = data.disabled[name] ?? false;
          });
        }
      }
      this.$globalLoading.hide();
    },

    async requestAction() {
      this.$globalLoading.show();
      const data = { ...this.form };

      const res = await requestWrapper.call(this, {
        url: this.apiConfig.submitUrl,
        method: 'post',
        data,
      });

      if (res.error) {
        this.$globalLoading.hide();
      }
    },
  },
};
