import { QBtn, QForm } from '@quasar/components';
import { Form } from '@common/mixins';
import VFormInform from '@vjs/components/VFormInform';

export default {
  name: 'VOrganizationEditForm',
  components: {
    QBtn, QForm, VFormInform,
  },
  mixins: [Form],
  props: {
    resource: {
      type: [Array, Object],
      required: false,
    },
    backLink: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.$trans.add('warning_text');
  },
  data() {
    return {
      fields: this.resource,
    };
  },
};
