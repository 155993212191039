import _ from 'lodash';
import {
  QBtn,
  QField,
  QOptionGroup,
  QSelect,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import MXFilters from '@vjs/mixins/MXFilters';

export default {
  name: 'VClubsFilters',
  mixins: [MXFilters],
  components: {
    QBtn,
    QField,
    QOptionGroup,
    QSelect,
  },
  props: {
    uriGetFilters: {
      type: String,
      required: true,
    },
    uriGetList: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filterList: [],
      filterModels: [],
    };
  },
  async mounted() {
    this.loading = true;
    this.$emit('filter-loading', true);
    await this.requestFilters();
    this.loadFiltersFromLocalStorage('clubsFilter');
    await this.requestClubs();
    this.loading = false;
    this.$emit('filter-loading', false);
  },
  computed: {
    filterParams() {
      const filterParams = {};
      this.filterModels.forEach((item) => {
        filterParams[item.key] = item.model;
      });
      return filterParams;
    },
  },
  methods: {
    // Сбрасывает установленные фильтры на значение по умолчанию
    async resetFilters() {
      this.filterModels.forEach((item) => {
        item.model = [];
      });

      if (localStorage && localStorage.getItem('clubsFilter')) {
        localStorage.removeItem('clubsFilter');
      }

      await this.requestClubs();
    },
    // Установка сохранённых фильтров или установка по умолчанию
    loadFiltersFromLocalStorage(keyName = '') {
      if (localStorage && localStorage.getItem(keyName)) {
        const keysObj = JSON.parse(localStorage.getItem(keyName));
        Object.keys(keysObj).forEach((item) => {
          const res = _.find(this.filterModels, filter => filter.key === item);
          if (res) {
            res.model = keysObj[item];
          }
        });
      }
    },
    // Функция для дополнительных фильтров, которые используются на фронте
    makeAdditionalFiltering(list) {
      if (
        !this.filterParams.school_client_type
        || _.isEmpty(this.filterParams.school_client_type)
      ) {
        return list;
      }

      const filters = this.filterParams.school_client_type;
      return list.filter(item => filters.includes(item.school_client_type));
    },
    async requestClubs() {
      this.loading = true;
      this.$emit('filter-loading', true);
      this.saveFiltersToLocalStorage(this.filterParams, 'clubsFilter');

      const clubsRes = await requestWrapper.call(this, {
        url: this.uriGetList,
        params: this.filterParams,
      });

      const result = {
        items: [],
        statistics: [],
      };

      if (!clubsRes.error) {
        result.items = this.makeAdditionalFiltering(clubsRes.data);
        result.statistics = clubsRes.statistics;
      }

      this.filterShow = false;
      this.loading = false;
      this.$emit('filter-loading', false);
      this.$emit('filtered-list', result);
    },
    async requestFilters() {
      const filtersRes = await requestWrapper.call(this, {
        url: this.uriGetFilters,
      });

      if (!filtersRes.error && Array.isArray(filtersRes)) {
        filtersRes.forEach((item) => {
          this.filterModels.push({
            model: [],
            key: item.model,
          });
        });
        this.filterList = filtersRes;
      }
    },
  },
};
