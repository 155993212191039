import Vue from 'vue';

import CheckboxMixin from '../../mixins/checkbox';

import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QCheckbox',

  mixins: [CheckboxMixin],

  props: {
    toggleIndeterminate: Boolean,
    indeterminateValue: { default: null },
  },

  computed: {
    isIndeterminate() {
      return this.value === undefined || this.value === this.indeterminateValue;
    },

    classes() {
      return {
        'sn--disabled': this.disable,
        disabled: this.disable,
        'sn-checkbox--dark': this.dark,
        'sn-checkbox--dense': this.dense,
        'sn--cursor-pointer': !this.disable,
        reverse: this.leftLabel,
      };
    },

    innerClass() {
      if (this.isTrue === true) {
        return `sn-checkbox__inner--active${
          this.color !== undefined ? ` s-c-${this.color}` : ''}`;
      } if (this.isIndeterminate === true) {
        return `sn-checkbox__inner--indeterminate${
          this.color !== undefined ? ` s-c-${this.color}` : ''}`;
      } if (this.keepColor === true && this.color !== undefined) {
        return `s-c-${this.color}`;
      }
    },
  },

  render(h) {
     return h('div', {
      staticClass: 'sn-checkbox sn--no-outline sn--row sn--inline sn--no-wrap',
      class: this.classes,
      attrs: { tabindex: this.computedTabindex },
      on: {
        click: this.toggle,
        keydown: this.__keyDown,
      },
    }, [
       this.leftLabel === true && (this.label !== undefined || this.$scopedSlots.default !== undefined)
         ? h('div', {
           staticClass: 'sn-checkbox__label sn-anchor--skip',
         }, (this.label !== undefined ? [this.label] : []).concat(slot(this, 'default')))
         : null,
      h('div', {
        staticClass: 'sn-checkbox__inner s-pos-relative-position',
        class: this.innerClass,
      }, [
        this.disable !== true
          ? h('input', {
            staticClass: 'sn-checkbox__native sn-ma-none sn-pa-none sn--invisible',
            attrs: {
              type: 'checkbox',
            },
            on: { change: this.toggle },
          })
          : null,

        h('div', {
          staticClass: 'sn-checkbox__bg s-pos-absolute',
        }, [
          h('svg', {
            staticClass: 'sn-checkbox__check sn--fit s-pos-absolute-full',
            attrs: { viewBox: '0 0 24 24' },
          }, [
            h('path', {
              attrs: {
                fill: 'none',
                d: 'M1.73,12.91 8.1,19.28 22.79,4.59',
              },
            }),
          ]),

          h('div', { staticClass: 'sn-checkbox__check-indet s-pos-absolute' }),
        ]),
      ]),

       !this.leftLabel && (this.label !== undefined || this.$scopedSlots.default !== undefined)
        ? h('div', {
          staticClass: 'sn-checkbox__label sn-anchor--skip',
        }, (this.label !== undefined ? [this.label] : []).concat(slot(this, 'default')))
        : null,
    ]);
  },
});
