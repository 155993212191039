import Viewer from 'v-viewer';
import Vue from 'vue';

Vue.use(Viewer);
export default {
  name: 'img-viewer',
  data() {
    return {
      options: {
        url: 'data-source',
        navbar: false,
        title: false,
      },
      index: 0,
      images: [],
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
      this.$viewer.view(this.index);
    },
    view(index) {
      this.index = index;
      this.$viewer.view(this.index);
    },
    show(images, index = 0) {
      if (this.images === images) {
        this.view(index);
        return;
      }
      this.images = images;
      this.index = index;
    },
  },
};
