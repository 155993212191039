import {
  QBtn,
} from '@quasar/components';
import eventHub from '@vjs/config/eventHub';

export default {
  name: 'VStepsBody',

  components: {
    QBtn,
  },

  props: {
    accessPrev: Boolean,
    accessNext: Boolean,
    loading: Boolean,
  },

  data() {
    return {
      direction: 'swipe-left',
    };
  },

  methods: {
    handleNext() {
      this.direction = 'swipe-left';
      eventHub.$emit('SET_NEXT_STEP');
    },

    // Рендер блока с transition-group и слотом "content"
    createContent(h) {
      return h('transition-group', {
        staticClass: 's-steps-body__content',
        props: {
          tag: 'div',
          name: this.direction,
        },
      }, this.$scopedSlots.content());
    },

    // Рендер блока с col-auto и кнопкой направления
    createBtn(h, iconDirection, iconName, label, color, disable, handle, style, loading) {
      const props = {
        [iconDirection]: iconName,
        label,
        color,
        disable,
        noCaps: true,
      };

      if (loading) {
        props.loading = this.loading;
      }

      return h('div', {
        staticClass: this.$q.screen.width > 380 ? 'col-auto' : 'col-12 mb-15',
        style,
      }, [
        h('q-btn', {
          props,
          staticClass: this.$q.screen.width > 380 ? '' : 's-w-100',
          on: {
            click: handle,
          },
        }),
      ]);
    },

    // Рендер блока-обёртки с кнопками направления
    createControls(h) {
      return this.$store.state.vSteps.currentStep !== 6 && h('div', {
        staticClass: 'row justify-content-end mt-20',
      }, [
        this.createBtn(
          h,
          'icon-right',
          'angle-right',
          this.trans('statement_service.forward'),
          'green',
          !this.$store.state.vSteps.access,
          this.handleNext,
          'margin-left: auto;',
          true,
        ),
      ]);
    },
  },

  render(h) {
    return h('div', {
      staticClass: 's-steps-body-wrapper',
    }, [
      this.createContent(h),
      this.createControls(h),
    ]);
  },
};
