import { QCheckbox } from '@quasar/components';
import Validation from '@vjs/mixins/Validation';
import VInfoCard from '@common/components/VInfoCard';

export default {
  name: 'VRegistrationCheck',
  components: { QCheckbox, VInfoCard },
  mixins: [Validation],
  props: {
    uriSubmit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      vm: this,
      requestGetDataHelper,
      cards: [],
      form: {
        isChild: false,
        childIin: null,
        parentIin: null,
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'button', 'validation']);
  },
  computed: {
    isIinChecked() {
      return !!this.cards.length;
    },
  },
  methods: {
    clear() {
      this.isIinChecked = false;
      this.cards = [];
      this.form.isChild = false;
      this.form.childIin = null;
      this.form.parentIin = null;
    },
  },
};
