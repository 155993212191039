import _ from 'lodash';
import {
  QSelect,
  QInput,
  QTable,
  QTr,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VApplicationTable',

  components: {
    QSelect,
    QInput,
    QTable,
    QTr,
    QTd,
    QBtn,
    QTooltip,
    VNoResults,
  },

  props: {
    typeTable: {
      type: String,
      required: true,
    },
    uriRequest: {
      type: String,
      required: true,
    },
    uriFilters: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      filters: {
        statusFilter: null,
        type: null,
        lang: null,
        search: null,
      },
      columns: [],
      rows: [],
      meta: {},
      metaLinks: {},
      pagination: {
        rowsPerPage: 15,
      },
      options: {
        statuses: [],
        types: [],
        langs: [],
      },
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'table',
      'main',
      'warning_text',
    ]);
    await this.getItems();
    await this.getFilters();
  },

  computed: {
    paginationOptions() {
      const options = [10, 15, 25, 50, 100];
      return options.map(item => ({
        value: item,
        label: item,
      }));
    },
    paginationBtns() {
      return [
        {
          icon: 'angle-double-left',
          disabled: this.meta.current_page === 1,
          type: 'first',
        },
        {
          icon: 'angle-left', disabled: !this.metaLinks.prev, type: 'prev',
        },
        {
          icon: 'angle-right', disabled: !this.metaLinks.next, type: 'next',
        },
        {
          icon: 'angle-double-right',
          disabled: this.meta.last_page === 1 || this.meta.current_page === this.meta.last_page,
          type: 'last',
        },
      ];
    },
  },

  methods: {
    async resetFilters() {
      this.options.statuses = [];
      this.filters.statusFilter = '';
      this.filters.type = '';
      this.filters.lang = '';
      this.filters.search = null;
      await this.getItems();
    },
    async getFilters(onlyStatuses = false) {
      this.$globalLoading.show();
      const params = {
        status: this.typeTable,
      };
      if (onlyStatuses) {
        this.filters.status = '';
        this.options.statuses = [];
        params.type = this.filters.type;
      }

      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriFilters,
        params,
      });

      if (!res.error) {
        if (onlyStatuses) {
          this.options.statuses = res.data.statuses;
          this.filters.statusFilter = this.options.statuses?.[0]?.value ?? '';
        } else {
          this.options.types = res.data.types;
          this.filters.type = this.options.types[0].value;
          this.options.langs = res.data.langs;
          this.filters.lang = this.options.langs[0].value;
        }
      }
      this.$globalLoading.hide();
    },
    async getItems(type = 'default') {
      this.$globalLoading.show();
      let url = '';
      if (['default', 'changeRowsPerPage'].includes(type)) {
        url = this.uriRequest;
        if (type === 'default') this.pagination.rowsPerPage = 15;
      } else {
        url = this.metaLinks[type];
      }

      const params = {
        ...this.filters,
        status: this.typeTable,
        rowsPerPage: this.pagination.rowsPerPage,
      };

      const res = await requestWrapper.call(this, {
        method: 'get',
        url,
        params,
      });

      if (!res.error) {
        this.rows = res.data.rows ?? [];
        this.columns = res.data.columns ?? [];
        this.meta = res.data.meta ?? {};
        this.metaLinks = res.data.metaLinks ?? {};
      }
      this.$globalLoading.hide();
    },
    getCellClass(field) {
      switch (field) {
        case 'createdAt': return 'tbl-col-2';
        case 'status': return 'tbl-col-4';
        case 'eduLang': return 'tbl-col-3';
        default: return 'tbl-col-5';
      }
    },
  },
};
