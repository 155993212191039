import { QBtn } from '@quasar/components';
import { NumberFormatter } from '@common/mixins';

export default {
  name: 'VMonitoringTreeBody',

  components: { QBtn },
  mixins: [NumberFormatter],

  props: {
    cols: {
      type: Array,
      default: () => ([]),
    },
    rows: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      localRows: [],
      renderComponents: [],
      tmpRenderComponents: [],
    };
  },

  watch: {
    rows: {
      handler() {
        this.initRender();
      },
      deep: true,
    },
  },

  mounted() {
    this.initRender();
  },

  methods: {
    initRender() {
      this.localRows = this.rows;
      this.renderComponents = [];
      _.forEach(this.localRows, row => this.renderRow(row));
      this.renderComponents = this.tmpRenderComponents;
      this.tmpRenderComponents = [];
    },
    renderTd(value, name, withChilds, level, rowData, colType) {
      let paddingLeft = '1rem';
      if (name === 'name' && level && level !== 1) {
        if (withChilds) {
          paddingLeft = `${1 + level}rem`;
        } else {
          paddingLeft = `${level + level + 2}rem`;
        }
      }

      const customClass = {
        's-to-center': name !== 'name',
        's-c-green': name === 'surplus' && value > 0,
        's-c-red': name === 'deficit' && value > 0,
      };

      return (
        <td
          class={customClass}
          style={{ paddingLeft }}
        >
          <div>
          {
            withChilds && name === 'name'
              ? <q-btn
                round
                dense
                icon={rowData.expand ? 'minus' : 'plus'}
                color="green"
              />
              : null
          }
          {this.getValue(colType, value)}
          </div>
        </td>
      );
    },

    getValue(type, value) {
      if (type === 'label') {
        return value;
      }
      return this.numberFormatter(value);
    },

    // eslint-disable-next-line consistent-return
    renderRow(rowData, level = 1) {
      const vm = this;
      const { tmpRenderComponents } = this;

      const withChildren = (rowData.children && rowData.children.length);

      tmpRenderComponents.push(
        (
          <tr
            onClick={async () => {
              rowData.expand = !rowData.expand;
              _.forEach(vm.localRows, row => this.renderRow(row));
              this.renderComponents = this.tmpRenderComponents;
              this.tmpRenderComponents = [];
            }}
          >
            {
              this.cols.map(col => this.renderTd(rowData[col.name], col.name, withChildren, level, rowData, col.type))
            }
          </tr>
        ),
      );
      if (withChildren && rowData.expand) {
        _.forEach(rowData.children, child => this.renderRow(child, level + 1));
      }
    },
  },

  render() {
    return (
      <tbody>
      { this.renderComponents }
    </tbody>
    );
  },
};
