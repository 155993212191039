import Vue from 'vue';
import componentsForm from '@common/components/Form';
import store from '@app_mektep/store';
import bootstrap from '@app_mektep/bootstrap';
import componentsViews from '@app_mektep/components/Views';
import componentsIndex from '@app_mektep/components/Index';
import componentsModals from '@app_mektep/components/Modals';
import componentsLegacy from '@app_mektep/components/Legacy';

window.Vue = Vue;
bootstrap(Vue);
componentsViews(Vue);
componentsIndex(Vue);
componentsForm(Vue);
componentsModals(Vue);
componentsLegacy(Vue);

[
  'v-footer-app',
  'mkt-vue-container',
  'mkt-head-vue-container',
  'apply-statement-form',
  'add_edu_contingent_pupils_list',
]
  .forEach((element) => {
    if (document.getElementById(element)) {
      const config = {};
      config.store = store;
      const vm = new Vue(config);
      vm.$mount(`#${element}`);
    }
  });
