import Vue from 'vue';

import slot from '../../utils/slot.js';

export default Vue.extend({
  name: 'QBtnGroup',

  props: {
    unelevated: Boolean,
    outline: Boolean,
    flat: Boolean,
    rounded: Boolean,
    push: Boolean,
    stretch: Boolean,
    glossy: Boolean,
    spread: Boolean,
  },

  computed: {
    classes() {
      return ['unelevated', 'outline', 'flat', 'rounded', 'push', 'stretch', 'glossy']
        .filter(t => this[t] === true)
        .map(t => `sn-btn-group--${t}`).join(' ');
    },
  },

  render(h) {
    return h('div', {
      staticClass: `sn-btn-group sn--row sn--no-wrap ${
        this.spread === true ? 'sn-btn-group--spread' : 'sn--inline'}`,
      class: this.classes,
      on: this.$listeners,
    }, slot(this, 'default'));
  },
});
