import Vue from 'vue';

import uid from '../../utils/uid';
import QIcon from '../icon/QIcon';
import RippleMixin from '../../mixins/ripple';

import { stop } from '../../utils/event';
import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QTab',

  mixins: [RippleMixin],

  inject: {
    tabs: {
      default() {
        console.error('QTab/QRouteTab components need to be child of QTabsBar');
      },
    },
    __activateTab: {},
  },

  props: {
    icon: String,
    label: [Number, String],

    alert: [Boolean, String],

    name: {
      type: [Number, String],
      default: () => uid(),
    },

    noCaps: Boolean,

    tabindex: [String, Number],
    disable: Boolean,
  },

  computed: {
    isActive() {
      return this.tabs.current === this.name;
    },

    classes() {
      return {
        [`sn-tab--${this.isActive ? '' : 'in'}active`]: true,
        [`s-c-${this.tabs.activeColor}`]: this.isActive && this.tabs.activeColor,
        [`s-b-${this.tabs.activeBgColor}`]: this.isActive && this.tabs.activeBgColor,
        'sn-tab--full': this.icon && this.label && !this.tabs.inlineLabel,
        'sn-tab--no-caps': this.noCaps === true || this.tabs.noCaps === true,
        'sn-focusable sn-hoverable sn--cursor-pointer': !this.disable,
        disabled: this.disable,
      };
    },

    computedTabIndex() {
      return this.disable === true || this.isActive === true ? -1 : this.tabindex || 0;
    },
  },

  methods: {
    __activate(e, keyboard) {
      keyboard !== true && this.$refs.blurTarget !== void 0 && this.$refs.blurTarget.focus();

      if (this.disable !== true) {
        this.$listeners.click !== void 0 && this.$emit('click', e);
        this.__activateTab(this.name);
      }
    },

    __onKeyup(e) {
      e.keyCode === 13 && this.__activate(e, true);
    },

    __getContent(h) {
      const
        narrow = this.tabs.narrowIndicator;
      const content = [];
      const indicator = h('div', {
        staticClass: 'sn-tab__indicator',
        class: this.tabs.indicatorClass,
      });

      this.icon !== void 0 && content.push(h(QIcon, {
        staticClass: 'sn-tab__icon',
        props: { name: this.icon },
      }));

      this.label !== void 0 && content.push(h('div', {
        staticClass: 'sn-tab__label',
      }, [this.label]));

      this.alert !== false && content.push(h('div', {
        staticClass: 'sn-tab__alert',
        class: this.alert !== true ? `s-c-${this.alert}` : null,
      }));

      narrow && content.push(indicator);

      const node = [
        h('div', { staticClass: 'sn-focus-helper', attrs: { tabindex: -1 }, ref: 'blurTarget' }),

        h('div', {
          staticClass: 'sn-tab__content sn--self-stretch sn--flex-center s-pos-relative-position sn--no-pointer-events sn-anchor--skip sn--non-selectable',
          class: this.tabs.inlineLabel === true ? 'sn--row sn--no-wrap sn-tab__content--inline' : 'sn--column',
        }, content.concat(slot(this, 'default'))),
      ];

      !narrow && node.push(indicator);

      return node;
    },

    __renderTab(h, tag, props) {
      const data = {
        staticClass: 'sn-tab s-pos-relative-position sn--self-stretch sn--flex sn--flex-center sn--text-center',
        class: this.classes,
        attrs: {
          tabindex: this.computedTabIndex,
          role: 'tab',
          'aria-selected': this.isActive,
        },
        directives: this.ripple !== false && this.disable === true ? null : [
          { name: 'ripple', value: this.ripple },
        ],
        [tag === 'div' ? 'on' : 'nativeOn']: {
          input: stop,
          ...this.$listeners,
          click: this.__activate,
          keyup: this.__onKeyup,
        },
      };

      if (props !== void 0) {
        data.props = props;
      }

      return h(tag, data, this.__getContent(h));
    },
  },

  render(h) {
    return this.__renderTab(h, 'div');
  },
});
