export default {
  computed: {
    marginalsProps() {
      return {
        pagination: this.computedPagination,
        pagesNumber: this.pagesNumber,
        isFirstPage: this.isFirstPage,
        isLastPage: this.isLastPage,
        prevPage: this.prevPage,
        nextPage: this.nextPage,

        inFullscreen: this.inFullscreen,
        toggleFullscreen: this.toggleFullscreen,
      };
    },
  },

  methods: {
    getTop(h) {
      const
        { top } = this.$scopedSlots;
      const topLeft = this.$scopedSlots['top-left'];
      const topRight = this.$scopedSlots['top-right'];
      const topSelection = this.$scopedSlots['top-selection'];
      const hasSelection = this.hasSelectionMode === true
          && topSelection !== void 0
          && this.rowsSelectedNumber > 0;
      const staticClass = 'sn-table__top s-pos-relative-position sn--row sn--items-center';
      const child = [];

      if (top !== void 0) {
        return h('div', { staticClass }, [top(this.marginalsProps)]);
      }

      if (hasSelection === true) {
        child.push(topSelection(this.marginalsProps));
      } else if (topLeft !== void 0) {
        child.push(
          h('div', { staticClass: 'sn-table-control' }, [
            topLeft(this.marginalsProps),
          ]),
        );
      } else if (this.title) {
        child.push(
          h('div', { staticClass: 'sn-table__control' }, [
            h('div', { staticClass: 'sn-table__title' }, this.title),
          ]),
        );
      }

      if (topRight !== void 0) {
        child.push(h('div', { staticClass: 'sn-table__separator sn--col' }));
        child.push(
          h('div', { staticClass: 'sn-table__control' }, [
            topRight(this.marginalsProps),
          ]),
        );
      }

      if (child.length === 0) {
        return;
      }

      return h('div', { staticClass }, child);
    },
  },
};
