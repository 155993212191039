import axios from 'axios';

export default {
  name: 'ApplyStatementFormComponent',
  props: {
    uriGetData: {
      type: String,
    },
    uriDestination: {
      type: String,
    },
    uriRedirection: {
      type: String,
      required: true,
    },
    childId: [Number, String],
    revisionUriDestination: String,
  },
  data() {
    return {
      childrenList: [],
      selectedChildId: 0,
      files: {
        certificate: '',
        medicalCard: '',
      },
      step: 1,
      loading: false,
    };
  },
  beforeCreate() {
    this.$trans.add(['breadcrumb', 'additional_education', 'title', 'label', 'warning_text', 'button', 'notice']);
  },
  async mounted() {
    const childrenRes = await this.requestChildren();
    if (!childrenRes.error) {
      this.$set(this, 'childrenList', childrenRes.data);
    } else {
      this.error = childrenRes.message;
    }
    if (this.childId) {
      this.step = 2;
      this.selectedChildId = this.childId;
    }
  },
  computed: {
    isSubmitButtonDisabled() {
      return !this.files.medicalCard || !this.files.certificate;
    },
  },
  methods: {
    async requestChildren() {
      try {
        const childrenRes = await axios({
          url: this.uriGetData,
          data: {
            _token: window.core_project.csrfToken,
          },
        });
        return childrenRes.data;
      } catch (error) {
        if (error && error.response) {
          const {
            status, data,
          } = error.response;

          switch (status) {
            case 422: return {
              error: true,
              errorType: 422,
              data,
            };
            default: return {
              error: true,
              errorType: 666,
              message: (data && data.message) || '',
            };
          }
        }
        return error;
      }
    },
    onNextStep() {
      this.$set(this, 'step', this.step + 1);
    },
    onUploadFile(e) {
      this.files[e.target.name] = e.target.files[0];
    },
    async onSubmit() {
      this.$set(this, 'loading', true);
      const formData = new FormData();
      formData.append('child_id', this.selectedChildId);
      formData.append('medical_card', this.files.medicalCard);
      formData.append('certificate', this.files.certificate);
      formData.append('_token', window.core_project.csrfToken);

      axios.post(
        this.revisionUriDestination ? this.revisionUriDestination : this.uriDestination,
        formData,
      )
        .then(r => window.location.replace(this.uriRedirection))
        .catch((err) => {
          this.$set(this, 'loading', false);
          const { status, data } = err.response;
          switch (status) {
            case 422:
              Object.values(data).forEach((message) => {
                if (message !== status) show_notice(message, 'error');
              });
              break;
            case 400:
              window.location.replace(this.uriRedirection);
              break;
            default:
              show_notice(data.message, 'error');
              break;
          }
        });
    },
  },
};
