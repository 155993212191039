import { FormField, StoreTranslator } from '@common/mixins';
import {
  QTime, QPopupProxy, QInput, QIcon,
} from '@quasar/components';

export default {
  mixins: [StoreTranslator, FormField],
  components: {
    QTime, QPopupProxy, QInput, QIcon,
  },
  methods: {
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
  },
  computed: {
    defaultAttributes() {
      return {
        name: this.field.name,
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
