<template>
  <div>
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  props: {
    type: {
      type: String,
      default: 'event',
    },
    action: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: 'post',
    },
  },
  methods: {
    async approve() {
      switch (this.type) {
        case 'request':
          await this.request();
          break;
        case 'event':
        default:
          this.$emit('onApprove');
          break;
      }
    },
    async request() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        [this.method === 'get' ? 'params' : 'data']: { reason: this.reason },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
