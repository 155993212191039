import Vue from 'vue';

import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QItemSection',

  props: {
    avatar: Boolean,
    thumbnail: Boolean,
    side: Boolean,
    top: Boolean,
    noWrap: Boolean,
  },

  computed: {
    classes() {
      const side = this.avatar || this.side || this.thumbnail;

      return {
        'sn-item__section--top': this.top,
        'sn-item__section--avatar': this.avatar,
        'sn-item__section--thumbnail': this.thumbnail,
        'sn-item__section--side': side,
        'sn-item__section--nowrap': this.noWrap,
        'sn-item__section--main': !side,
        [`sn--justify-${this.top ? 'start' : 'center'}`]: true,
      };
    },
  },

  render(h) {
    return h('div', {
      staticClass: 'sn-item__section',
      class: this.classes,
      on: this.$listeners,
    }, slot(this, 'default'));
  },
});
