import { eventBus, StoreTranslator } from '@common/mixins';
import { Helper } from '@common/src/helpers';
import VFormInform from '@vjs/components/VFormInform';

export default {
  mixins: [StoreTranslator],
  components: {
    VFormInform,
  },
  props: {
    urlRefs: {
      type: String,
      required: true,
    },
    urlCheckIin: {
      type: String,
      required: true,
    },
    urlMove: {
      type: String,
      required: true,
    },
    urlActualize: {
      type: String,
      required: false,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    profile: {
      type: [Object, Array],
      required: false,
    },
    isParent: {
      type: Boolean,
      required: true,
    },
    isEmailEditEnabled: {
      type: Boolean,
      default: false,
    },
  },
  beforeCreate() {
    this.$trans.add('statement_service');
  },
  data() {
    const vm = this;
    const fields = {
      resident: {
        value: true,
        name: 'resident',
        component: 'boolean-field',
        checkboxLabelTransKey: 'label.kz_resident',
        extraAttributes: {
          disabled: false,
        },
        events: {
          input: vm.handleResidentCheck,
        },
      },
      iin: {
        value: '',
        name: 'iin',
        component: 'search-iin-field',
        labelTransKey: 'label.iin',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        events: {
          'on-search': vm.onClickSearch,
        },
        button: {
          name: 'actualize',
          class: 's-btn s-btn--thm-green mb-20 ',
          action: vm.actualize,
          labelTransKey: 'label.actualize',
          v_if: (vm) => {
            if (vm.form.fields && vm.profile.iin) {
              return vm.form.fields.iin.value && vm.form.fields.resident.value && vm.urlActualize;
            }
          },
        },
      },
      surname: {
        value: '',
        name: 'surname',
        component: 'text-field',
        labelTransKey: 'label.surname',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },

      },
      firstname: {
        value: '',
        name: 'firstname',
        component: 'text-field',
        labelTransKey: 'label.firstname',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      lastname: {
        value: '',
        name: 'lastname',
        component: 'text-field',
        labelTransKey: 'label.middle_name',
        extraAttributes: {
          disabled: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      born_date: {
        value: '',
        name: 'born_date',
        component: 'q-date-field',
        labelTransKey: 'label.born_date',
        required: true,
        extraAttributes: {
          disable: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },

      },
      gender: {
        value: '',
        name: 'sex',
        labelTransKey: 'label.gender',
        component: 'radio-field',
        extraAttributes: {
          disabled: false,
        },
        values: [
          {
            labelTransKey: 'label.male',
            value: 'm',
          },
          {
            labelTransKey: 'label.female',
            value: 'w',
          },
        ],
        required: true,
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      citizenship_id: {
        value: '',
        name: 'citizenship_id',
        component: 'q-select-field',
        labelTransKey: 'label.citizenship',
        values: [],
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      nationality_id: {
        value: '',
        name: 'nationality_id',
        component: 'q-select-field',
        labelTransKey: 'label.nationality',
        values: [],
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      address: {
        value: '',
        name: 'address',
        component: 'text-field',
        labelTransKey: 'label.address',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      actual_residence: {
        value: '',
        name: 'actual_residence',
        component: 'text-field',
        labelTransKey: 'statement_service.actual_address_resid',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      phone: {
        value: '',
        name: 'phone',
        component: 'phone-field',
        labelTransKey: 'label.phone',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      additional_phone: {
        value: '',
        name: 'additional_phone',
        component: 'phone-field',
        labelTransKey: 'label.additional_phone',
        extraAttributes: {
          disabled: false,
        },
      },
      email: {
        value: '',
        name: 'email',
        component: 'text-field',
        label: 'E-Mail',
        required: true,
        extraAttributes: {
          disabled: !this.isEmailEditEnabled,
        },
      },
    };
    if (vm.profile !== undefined) {
      _.forEach(vm.profile, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
          if (key === 'residentDisabled' && value) {
            fields.resident.extraAttributes.disabled = true;
          }
          if (value && !['actual_residence', 'email'].includes(key)) {
            fields[key].extraAttributes.disabled = true;
          }
        }
      });
    }
    return {
      refs: {
        citizenships: [],
        nationalities: [],
      },
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
      lastSearchIIN: '',
      foundUserIIN: '',
      validIIN: (fields.iin.value !== null && fields.iin.value !== ''),
      kzCitizenshipId: '03dcc2de-10ec-434a-39b1-b70cc4fd5551',
    };
  },
  mounted() {
    const vm = this;
    vm.loadRefs();
    if (vm.profile) {
      vm.handleResidentCheck(vm.profile.resident);
    }
    vm.changeStatusValidIIN(vm.validIIN);
  },
  watch: {
    citizenshipOptions(val) {
      this.form.fields.citizenship_id.values = val;
    },
    validIIN(newValue) {
      const vm = this;
      vm.changeStatusValidIIN(newValue);
    },
  },
  destroyed() {
  },
  computed: {
    citizenshipOptions() {
      return this.form.fields.resident.value
        ? this.refs.citizenships
        : this.refs.citizenships.filter(item => item.id !== this.kzCitizenshipId);
    },
  },
  methods: {
    handleResidentCheck(val) {
      if (val) {
        this.form.fields.iin.labelTransKey = 'label.iin';
        this.form.fields.citizenship_id.value = this.kzCitizenshipId;
        this.form.fields.citizenship_id.disabled = true;
        let iinComponent = 'search-iin-field';
        if (this.validIIN) {
          iinComponent = 'text-field';
        }
        this.$nextTick(() => {
          this.form.fields.iin.component = iinComponent;
        });
      } else {
        this.form.fields.iin.labelTransKey = 'label.doc_number_slash_iin';
        if (!this.profile.citizenship_id) {
          this.form.fields.citizenship_id.value = null;
          this.form.fields.citizenship_id.disabled = false;
        }
        this.$nextTick(() => {
          this.form.fields.iin.component = 'text-field';
        });
      }
    },
    loadRefs() {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlRefs, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm, 'refs', data);
          vm.$set(vm.form.fields.citizenship_id, 'values', data.citizenships);
          vm.$set(vm.form.fields.nationality_id, 'values', data.nationalities);
          if (this.profile?.citizenship_id) {
            vm.$set(vm.form.fields.citizenship_id, 'value', this.profile.citizenship_id);
          }
          if (this.profile?.nationality_id) {
            vm.$set(vm.form.fields.nationality_id, 'value', this.profile.nationality_id);
          }
        },
        (response) => {
          console.log(response);
        },
      );
    },
    changeStatusValidIIN(value) {
      const vm = this;

      if (value) {
        vm.form.fields.iin.component = 'iin-field';
        vm.form.fields.iin.extraAttributes.disabled = true;
        vm.form.fields.resident.extraAttributes.disabled = true;
        vm.form.fields.surname.extraAttributes.disabled = true;
        vm.form.fields.firstname.extraAttributes.disabled = true;
        vm.form.fields.lastname.extraAttributes.disabled = true;
        vm.form.fields.lastname.extraAttributes.disabled = true;
        vm.form.fields.born_date.extraAttributes.disable = true;
        vm.form.fields.gender.extraAttributes.disabled = true;
      } else {
        vm.form.fields.iin.component = 'search-iin-field';
        vm.form.fields.iin.extraAttributes.disabled = false;
        vm.form.fields.surname.extraAttributes.disabled = false;
        vm.form.fields.firstname.extraAttributes.disabled = false;
        vm.form.fields.lastname.extraAttributes.disabled = false;
        vm.form.fields.born_date.extraAttributes.disable = false;
        vm.form.fields.gender.extraAttributes.disabled = false;
      }
    },
    onClickSearch(newVal) {
      if (this.form.fields.resident.value) {
        this.validateIin(newVal);
      }
    },
    existsIIN() {
      return !this.form.fields.resident.value || (this.form.fields.resident.value && this.validIIN);
    },
    handleVif(data_field) {
      const vm = this;
      if (data_field.component === undefined && data_field.action === undefined) {
        return false;
      }
      if (data_field.v_if !== undefined) {
        if (_.isFunction(data_field.v_if)) {
          return data_field.v_if(vm);
        }
        return data_field.v_if;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        data[el.name] = el.value;
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
            form.errors = response.data.errors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return form.errors[field] !== undefined;
    },
    getError(form, field) {
      return _.first(form.errors[field]);
    },
    getErrors(form, field) {
      return form.errors[field] === undefined ? [] : form.errors[field];
    },
    validateIin(iin) {
      const vm = this;
      if (iin === vm.lastSearchIIN) {
        return;
      }
      vm.$set(vm, 'validIIN', false);
      const { form } = vm;
      vm.lastSearchIIN = iin;

      const config = {
        responseType: 'json',
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': window.core_project.csrfToken,
        },
      };
      const { fields } = form;

      vm.$http.post(
        vm.urlCheckIin,
        {
          iin,
        },
        config,
      )
        .then(
          (response) => {
            eventBus.$emit('all-change-loader', false);
            const { data } = response;
            if (data.status === 'exists') {
              vm.$set(vm, 'foundUserIIN', data.user.fullName);
              $('#modal-user-confirm-iin-rshep').modal('show');
            } else if (data.user !== undefined) {
              vm.$set(vm, 'validIIN', true);
              _.forEach(response.data.user, (value, key) => {
                if (fields.hasOwnProperty(key)) {
                  vm.$set(fields[key], 'value', value);
                }
              });
            }
          },
          (response) => {
            eventBus.$emit('all-change-loader', false);
            vm.lastSearchIIN = '';
            vm.$set(form, 'processSend', false);
            if (response.status === 422 || response.status === 423) {
              let errors = [];
              _.forEach(response.data.errors, (value, key) => {
                errors = errors.concat(value);
              });
              show_notice(errors, 'error');
              vm.$set(form, 'errors', response.data.errors);
            } else {
              console.log(response);
            }
          },
        );
    },
    cancelMove() {
      const vm = this;
      $('#modal-user-confirm-iin-rshep').modal('hide');
      vm.$set(vm, 'foundUserIIN', '');
      vm.$set(vm.form.fields.iin, 'value', '');
      vm.$set(vm, 'lastSearchIIN', '');
    },
    confirmMove(event) {
      const button = event.target;
      if (Helper.checkLoadingButton(button)) {
        return;
      }
      Helper.buttonLoader(button);

      const vm = this;

      const { form } = vm;

      const config = {
        responseType: 'json',
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': window.core_project.csrfToken,
        },
      };
      vm.$http.post(
        vm.urlMove,
        {
          iin: vm.lastSearchIIN,
        },
        config,
      )
        .then(
          (response) => {
            Helper.buttonLoader(button);
            const { data } = response;
            if (data.status === 'success') {
              window.location.reload();
            }
          },
          (response) => {
            Helper.buttonLoader(button);
            vm.cancelMove();
            if (response.status === 422 || response.status === 423) {
              let errors = [];
              _.forEach(response.data.errors, (value, key) => {
                errors = errors.concat(value);
              });
              show_notice(errors, 'error');
              vm.$set(form, 'errors', response.data.errors);
            } else {
              console.log(response);
            }
          },
        );
    },
    actualize() {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {},
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      vm.$http.post(vm.urlActualize, {}, config)
        .then((response) => {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        })

        .catch((response) => {
          if (response.status == 422) {
            const { data } = response;
            show_notice(data.errors, 'error');
          }
        });
    },
  },
};
