import _ from 'lodash';

export default {
  props: {
    transScopes: {
      type: [Array],
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  created() {
    const vm = this;
    if (_.size(vm.transScopes) !== 0) {
      vm.$trans.add(vm.transScopes);
    }
  },
  mounted() {

  },
  methods: {
    trans(key, params) {
      return this.$trans.get(key, params);
    },
  },
};
