import { FormField, StoreTranslator } from '@common/mixins';
import FakeFile from '@common/src/FakeFile';
import _ from 'lodash';

export default {
  mixins: [StoreTranslator, FormField],
  props: {},
  data() {
    return {
      file: {
        name: null,
        jsFile: null,
        selected: false,
        ext_config: [],
      },
    };
  },
  mounted() {
    this.field.fill = this.fill;
    this.$trans.add('validation');
  },
  methods: {
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
    fill(data) {
      if (this.file.jsFile) {
        _.set(data,
          this.nameToDot(this.field.name),
          new FakeFile(this.file.name, this.file.jsFile));
      }
    },
    onClickInputFile() {
      const el = this.$refs.input_file;
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onChangeInputFile(event) {
      const path = event.target.value;
      this.file.name = path.match(/[^\\/]*$/)[0];
      this.file.jsFile = this.$refs.input_file.files[0];
      this.file.selected = true;
      this.emitChange(this.file.jsFile);
    },
    emitChange(value = null) {
      this.$emit('input', value);
    },
    onRemoveFile() {
      this.file.jsFile = null;
      this.file.selected = false;
    },
  },
  watch: {
    'file.jsFile': function (val) {
      this.emitChange(val);
    },
  },
};
