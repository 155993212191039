import {
  QBtn,
  QTable,
  QTh,
  QTr,
  QTd,
  QPopupEdit,
  QInput,
  QIcon,
  QSelect, QTooltip,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';

export default {
  components: {
    QTooltip,
    QBtn,
    QTable,
    QTh,
    QTr,
    QTd,
    QInput,
    QIcon,
    QSelect,
    QPopupEdit,
  },
  props: {
    uriAction: String,
    uriRedirect: String,
    uriGetTable: String,
    uriExport: '',
    createBooksModalConfig: [],
    isShowButtons: false,
  },
  data: () => ({
    isAutoFillPopupOpened: false,
    autoFillPopupValue: null,
    pagination: {
      rowsPerPage: 20,
    },
    table: {
      rows: [],
      columns: [],
    },
  }),
  async mounted() {
    await this.$trans.add([
      'placeholder',
      'label',
      'rfid',
      'button',
      'book',
    ]);
    await this.requestGetTable();
  },
  methods: {
    async handleBtn(btn) {
      if (btn.type === 'button') {
        if (btn.modal === 's-modal' && btn['s-modal-config']) {
          const binds = {
            ...(btn['s-modal-config']?.binds ?? {}),
            meta: this.getRequestParams(),
          };

          this.$sModal.open(
            btn['s-modal-config'].component,
            { ...btn['s-modal-config'], binds },
          );
        }
      }
    },
    handleModal(action, row) {
      if (action.type === 'button') {
        if (action.modal === 's-modal' && action['s-modal-config']) {
          const config = action['s-modal-config'];
          const binds = config?.binds ?? {};
          binds.row = row;
          this.$sModal.open(
            action['s-modal-config'].id ?? action['s-modal-config'].component,
            { ...action['s-modal-config'], binds },
          );
        }
      }
    },
    async requestGetTable() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetTable,
      });

      if (!res.error) {
        this.table.rows = res.data.rows || [];
        this.table.columns = res.data.columns || [];
      }

      if (res.error) {
        this.$notify({
          text: this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      this.$globalLoading.hide();
    },
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAction,
        data: { books: this.table.rows },
      });
      if (res.error && res.errorType !== 302) {
        this.$globalLoading.hide();
      }
    },
  },
};
