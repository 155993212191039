import _ from 'lodash';

export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    labelClass: {
      type: String,
      default: null,
    },
    fieldName: { type: String },
    errors: {
      type: [Object, Array],
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showLabelLocal: this.showLabel,
    };
  },
  mounted() {
  },

  methods: {
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
  },
  computed: {
    fieldLabel() {
      // If the field name is purposefully an empty string, then
      // let's show it as such
      if (this.fieldName === '') {
        this.showLabelLocal = false;
        return '';
      }

      return this.fieldName || this.field.label;
    },
    fieldRequired() {
      return this.field.required || false;
    },
  },
};
