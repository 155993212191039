import CardMixin from '../CardMixin';
import CardTableMixin from '../CardTableMixin';

export default {
  mixins: [CardMixin, CardTableMixin],

  data() {
    return {
      newRowTemplate: {
        type: {},
      },
    };
  },

  computed: {
    cols() {
      let result = [
        {
          label: this.trans('personnel.blocks.fields.type'),
          name: 'type',
          field: 'type',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.descr'),
          name: 'descr',
          field: 'descr',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.date'),
          name: 'date',
          field: 'date',
          align: 'center',
        },
        {
          label: this.trans('label.operations'),
          name: 'actions',
          field: 'actions',
          align: 'center',
          width: 140,
        },
      ];
      if (this.disabled) {
        result = result.filter(itm => itm.field !== 'actions');
      }
      return result;
      if (this.disabled) {
        result = result.filter(itm => itm.field !== 'actions');
      }
      return result;
    },
  },
};
