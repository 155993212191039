<template>
  <div
    class="s-ad-banner"
  >
    <img
      class="s-ad-banner__image"
      :src="`${staticUrl}/base/images/banners/${banner.image}_${locale}.png`"
      :alt="banner.name"
    >
    <div class="s-ad-banner__btns-wrapper">
      <a
        class="s-ad-banner__btn"
        :href="banner.iosUrl"
        target="_blank"
      >
        <img
          :src="`${staticUrl}/base/images/logotypes/app_store.png`"
        >
      </a>
      <a
        class="s-ad-banner__btn"
        :href="banner.googleUrl"
        target="_blank"
      >
        <img
          :src="`${staticUrl}/base/images/logotypes/google_play.png`"
        >
      </a>
    </div>
  </div>
</template>

<script>
import mixin from '../mixins/PropMixin';

export default {
  name: 'VBanner',
  mixins: [mixin],
  props: {
    banner: { type: Object, default: () => ({}) },
  },
};
</script>
