import CardMixin from '../CardMixin';

export default {
  mixins: [CardMixin],

  data() {
    return {
      fieldList: [
        ['academicDegree', 'academicCategory'],
      ],
    };
  },

  methods: {
    getFieldGroup(fieldName) {
      const selects = ['academicDegree', 'academicCategory'];
      if (selects.includes(fieldName)) return 'select';

      return null;
    },
  },
};
