import _ from 'lodash';
import { FormField, StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator, FormField],
  methods: {
    onChange() {
      this.$emit('input', this.localValue);
    },
    setInitialValue() {
      this.localValue = !(this.field.value === undefined || this.field.value === null)
        ? this.field.value
        : false;
    },
    fieldEmitInput(value) {
      // Глушим метод который в mixin FormField
    },
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
    fieldLabel() {
      // If the field name is purposefully an empty string, then
      // let's show it as such
      if (this.field.label === '') {
        return '';
      }

      return this.field.label
        || (this.field.checkboxLabelTransKey
          && this.trans(this.field.checkboxLabelTransKey));
    },
  },
  computed: {
    isDisabled() {
      return _.get(this.field, 'extraAttributes.disabled', false);
    },
  },
  watch: {
    value(value) {
      this.$emit('change', value);
    },
  },
};
