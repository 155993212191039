import { QOptionGroup } from '@quasar/components/option-group';
import CardMixin from '../CardMixin';

export default {
  components: { QOptionGroup },
  mixins: [CardMixin],

  data() {
    return {};
  },

  computed: {},
  methods: {},
};
