import {
  QBtn,
  QTable,
  QTh,
  QTr,
  QTd,
  QInput,
  QIcon,
  QSelect,
  QTooltip,
} from '@quasar/components';

export default {
  components: {
    QBtn,
    QTable,
    QTh,
    QTr,
    QTd,
    QInput,
    QIcon,
    QSelect,
    QTooltip,
  },
  props: {
    rows: {
      type: Array,
      default: [],
    },
    columns: {
      type: Array,
      default: [],
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    columnFilter: {
      type: Boolean,
      default: true,
    },
    onFire: {
      type: Boolean,
      default: false,
    },
    separator: {
      type: String,
      default: 'horizontal',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    wrapCells: {
      type: Boolean,
      default: false,
    },
  },
  beforeCreate() {
    this.$trans.add([
      'placeholder',
      'label',
    ]);
  },
  data: () => ({
    visibleColumns: [],
    filter: '',
    pagination: {
      rowsPerPage: 20,
    },
  }),

  watch: {
    columns() {
      this.updateVisibleCols();
    },
  },
  methods: {
    updateVisibleCols() {
      this.visibleColumns = this.columns.map(v => v.name);
    },
  },

  mounted() {
    this.updateVisibleCols();
  },
};
