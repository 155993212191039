import { eventBus, FormField, StoreTranslator } from '@common/mixins';
import _ from 'lodash';
import TemplateOption from './TemplateOption';
import TemplateSingleLabel from './TemplateSingleLabel';

export default {
  mixins: [StoreTranslator, FormField],
  components: {
    'template-option': TemplateOption,
    'template-single-label': TemplateSingleLabel,
  },
  beforeCreate() {
    this.$trans.add(['select']);
  },
  methods: {
    emitChange(value = null) {
      if (this.field.extraAttributes && this.field.extraAttributes.multiple) {
        this.$emit('input', value);
      } else if (value && value.hasOwnProperty('id')) {
        this.$emit('input', value.id);
        this.$emit('change', value.id);
      } else {
        this.$emit('input', null);
        this.$emit('change', null);
      }
    },
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
    setEmpty() {
      const vm = this;
      vm.$set(vm, 'loadedValues', []);
      vm.setInitialSelectValue();
      vm.emitChange(this.selectValue);
    },
    loadValues() {
      const vm = this;
      if (vm.field.loadValues === undefined) {
        return;
      }
      const where = vm.field.loadValues.where ?? {};
      // TODO сделать кеш что бы не лазить каждый раз за тем что уже есть
      // надо подумать если ли смысл и не будет ли проблем с памятью браузера

      const config = {
        responseType: 'json',
        method: 'GET',
        params: where,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      if (vm.field.loadValues.headers) {
        _.each(vm.field.loadValues.headers, (value, key) => {
          config.headers[key] = value;
        });
      }
      let url = `/api/refs/${vm.field.loadValues.type}`;
      if (vm.field.loadValues.url) {
        url = vm.field.loadValues.url;
      }
      vm.$http.get(url, config).then(
        function (response) {
          const { data } = response;
          vm.$set(vm, 'loadedValues', data);
          vm.setInitialSelectValue();
          vm.emitChange(this.selectValue);
          this.$emit('values', vm.loadedValues);
        },
        (response) => {
          console.log(response);
        },
      );
    },
    getLoadValues() {
      return this.loadedValues;
    },
    setInitialSelectValue() {
      this.localValue = !(this.field.value === undefined || this.field.value === null)
        ? this.field.value
        : '';
      if (this.localValue !== '' && this.localValue !== null) {
        let options = _.cloneDeep(this.values);
        if (this.field.extraAttributes && this.field.extraAttributes.groupValues !== undefined) {
          options = [];
          _.forEach(this.values, (item) => {
            if (_.isArray(item.children)) {
              options.push(...item.children);
            }
          });
        }
        if (!(this.field.extraAttributes && this.field.extraAttributes.multiple)) {
          this.selectValue = _.find(options, { id: this.localValue });
        } else {
          this.selectValue = this.localValue;
        }
      } else {
        this.selectValue = null;
      }
    },
  },
  data() {
    return {
      options: [],
      loadedValues: [],
      selectValue: [],
    };
  },

  created() {
    eventBus.$on(`reload-refs:${this.field.name}`, () => {
      this.loadValues();
    });
  },
  mounted() {
    if (this.field.loadValues) {
      this.field.loadValues.reload = this.loadValues;
      this.field.loadValues.setEmpty = this.setEmpty;
      this.field.loadValues.getLoadValues = this.getLoadValues;
      if (this.field.loadValues.autoLoad === undefined || this.field.loadValues.autoLoad) {
        this.loadValues();
      }
    }
    this.setInitialSelectValue();
  },
  watch: {
    value(value) {
      // update value
      this.setInitialSelectValue();
    },
    'field.values': {
      handler() {
        this.setInitialSelectValue();
      },
    },
    // selectValue: function (value) {
    //   // update value
    //   if (value && value.hasOwnProperty('id')) {
    //     this.$emit('input', value.id);
    //   } else {
    //     this.$emit('input', null);
    //   }
    // },
  },
  computed: {
    values() {
      if (this.loadedValues.length) {
        return this.loadedValues;
      }
      if (this.field.values && this.field.values.length > 0) {
        this.options = _.forEach(this.field.values, (item) => {
          if (item.hasOwnProperty('nameTransKey')) {
            item.name = this.trans(item.nameTransKey);
          }
        });
      }
      if(this.field.values.length === 0) {
        return [];
      }
      return this.options;
    },
    defaultAttributes() {
      return {
        name: this.field.name,
        'minimum-results-for-search': 20,
        placeholder: this.trans('placeholder.choose'),
        disabled: _.get(this.field, 'disabled', false),
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
