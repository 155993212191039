<template>
  <div>
    <q-input
      v-model="searchValue"
      class="mb-30"
      :placeholder="trans('label.search_by_keyword')"
      @keypress.enter="handleSearch"
      @input="debounceSearch"
    >
      <template v-slot:append>
        <q-btn
          icon="search"
          color="mkt-blue"
          squire
          @click="handleSearch"
        />
      </template>
    </q-input>
    <table>
      <thead>
        <tr>
          <th
            v-for="col in cols"
            :key="`col-${col.name}`"
            :class="`s-to-${col.align || 'center'}`"
            :style="`width: ${col.width}`"
            v-html="col.label"
          />
        </tr>
      </thead>
      <tbody>
        <template v-for="(organization, organizationKey) in paginate">
          <tr
            :key="`organization-${organizationKey}`"
            :class="organizationKey % 2 === 0 ? 'grey-row' : ''"
          >
            <td
              v-for="col in cols"
              :key="`organization-${organizationKey}-${col.name}`"
              :class="`
              s-to-${col.align || 'center'}
               ${col.name !== 'organizationName' ? 's-medium' : ''}
                s-c-${col.name === 'freePlaces' ? 'green' : ''}
                `"
            >
              <template v-if="col.name !== 'action'">
                {{ organization[col.name] }}
              </template>
              <template v-else>
                <q-icon
                  v-if="organization.childOrganizations"
                  :name="`chevron-${
                    childSpoilers.find(itm => itm === organizationKey) !== undefined ? 'up' : 'down'
                  }`"
                  icon-class="s-ico-fa"
                  class="sn--cursor-pointer"
                  bold
                  color="#3248A5"
                  size="2.5rem"
                  @click.native="handleChildSpoiler(organizationKey)"
                />
                <q-btn
                  v-else
                  outline
                  color="blue"
                  style="background: #F7F7FB !important;"
                  no-caps
                  class="s-medium"
                  :class="organization.action.disable ? 'sn--disabled' : ''"
                  :width="$q.screen.width > 620 ? '22' : undefined"
                  :label="trans('button.send_statement')"
                  :to="organization.action.disable ? undefined : organization.action.to"
                  :type="organization.action.disable ? undefined : 'a'"
                  @click="handleBtn(organization.action)"
                />
              </template>
            </td>
          </tr>
          <template
            v-if="organization.childOrganizations && childSpoilers.find(
              itm => itm === organizationKey
            ) !== undefined"
          >
            <tr
              :key="`organization-${organizationKey}-child-thead`"
              :class="organizationKey % 2 === 0 ? 'grey-row' : ''"
            >
              <td
                v-for="childCol in organization.childOrganizations.cols"
                :key="`organization-${organizationKey}-child-thead-${childCol.name}`"
                :class="`s-to-${childCol.align}`"
                class="th"
                :colspan="childCol.colspan"
              >
                {{ childCol.label }}
              </td>
            </tr>
            <tr
              v-for="(child, childKey) in organization.childOrganizations.data"
              :key="`organization-${organizationKey}-child-row-${childKey}`"
              :class="organizationKey % 2 === 0 ? 'grey-row' : ''"
            >
              <td
                v-for="childCol in organization.childOrganizations.cols"
                :key="`organization-${organizationKey}-child-row-${childKey}-${childCol.name}`"
                :class="`
                s-to-${childCol.align}
                 s-c-${childCol.color === 'green' ? 'green' : ''}
                 `"
                class="s-medium"
                :colspan="childCol.colspan"
              >
                <template v-if="childCol.name !== 'action'">
                  {{ child[childCol.name] }}
                </template>
                <q-btn
                  v-else
                  outline
                  color="blue"
                  style="background: #F7F7FB !important;"
                  no-caps
                  class="s-medium"
                  :class="child.action.disable ? 'sn--disabled' : ''"
                  width="22"
                  :label="trans('button.send_statement')"
                  :to="child.action.disable ? undefined : child.action.to"
                  :type="child.action.type ? child.action.type : child.action.disable ? undefined : 'a'"
                  @click="handleBtn(child.action, organization.id, child)"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>

    <div
      v-if="countOfPages"
      class="row justify-content-center mt-30"
    >
      <div class="col-auto">
        <q-pagination
          v-model="currentPage"
          :max="countOfPages"
          :max-pages="5"
          :boundary-links="true"
          :input="countOfPages > 5"
          input-class="s-c-blue"
          @input="childSpoilers = []"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { QIcon, QInput, QPagination } from '@quasar/components';

export default {
  name: 'StatementOrganizationsTable',
  components: { QInput, QPagination, QIcon },
  props: {
    table: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      childSpoilers: [],
      currentPage: 1,
      countByPage: 5,
      searchValue: '',
      itemsFiltered: [],
    };
  },

  computed: {
    paginate() {
      const current = (this.currentPage - 1) * this.countByPage;
      return this.itemsFiltered.slice(current, current + this.countByPage);
    },
    countOfPages() {
      const count = Math.ceil(this.itemsFiltered.length / this.countByPage);
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
    cols() {
      return [
        {
          width: '36rem',
          name: 'organizationName',
          label: this.trans('search.school_name'),
          align: 'left',
        },
        { width: '15rem', name: 'additional' },
        {
          width: '15rem',
          name: 'totalPlaces',
          label: this.trans('table.places'),
        },
        {
          width: '15rem',
          name: 'freePlaces',
          label: this.trans('label.free_places'),
        },
        {
          width: '15rem',
          name: 'busyPlaces',
          label: this.trans('label.occupied_places'),
        },
        { width: '25rem', name: 'action' },
      ];
    },
    organizations() {
      if (this.$q.screen.width > 960) {
        return this.table;
      }
      return this.table.map(itm => _.cloneDeep(itm));
    },
  },
  watch: {
    organizations: {
      immediate: true,
      handler(val, oldVal) {
        this.itemsFiltered = val;
      },
    },
  },

  methods: {
    debounceSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(
        this.handleSearch,
        300,
      );
    },
    handleSearch() {
      this.childSpoilers = [];
      if (this.searchValue !== '') {
        const searchStr = this.searchValue.toLowerCase();
        this.itemsFiltered = this.organizations.filter((item) => {
          const name = item.organizationName || '';
          return name.toLowerCase().indexOf(searchStr) !== -1;
        });
      } else {
        this.itemsFiltered = this.organizations;
      }
    },
    handleBtn({
      disable, message, to, type,
    }, orgId, childItem) {
      if (type === 'button') {
        return eventBus.$emit('handleSelectOrg', orgId, childItem);
      }
      if (disable) {
        this.$notify({
          type: 'warn',
          text: message,
        });
      } else {
        window.location.href = to;
      }
    },
    handleChildSpoiler(key) {
      const index = _.findIndex(this.childSpoilers, itm => itm === key);
      if (index !== -1) {
        this.$delete(this.childSpoilers, index);
      } else {
        this.childSpoilers.push(key);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  table {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #525F99;
    border-collapse: collapse;

    .th,
    th {
      color: #525F9980;
      font-weight: 600;
      font-size: 14px;
      line-height: 173%;
    }

    td,
    th {
      padding: 1.5rem;
    }

    .grey-row {
      background: #3248A51A;
    }
  }

  @media screen and ( max-width: 620px ) {
    table {
      font-size: 11px;

      td.th, th {
        font-size: 11px;
      }

      td,
      th {
        padding: 0.75rem;
      }

      td .sn-btn {
        padding: 1rem 0.5rem 0.9rem;
      }
    }
  }
</style>
