import { requestWrapper } from '@vjs/helpers';
import { QBtn } from '@quasar/components';
import StatementShowCard from './Components/Card';
import StatementShowStepsCard from './Components/StepsCard';

export default {
  name: 'ParentStatementShow',
  components: {
    StatementShowCard,
    StatementShowStepsCard,
    QBtn,
  },
  props: {
    uriGetData: String,
  },
  data() {
    return {
      data: {
        cards: [],
        buttons: [],
        steps: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add('label');
    this.$globalLoading.show();
    const res = await requestWrapper.call(this, {
      url: this.uriGetData,
    });

    if (!res.error) {
      this.data = { ...res.data };
    }
    this.$globalLoading.hide();
  },
};
