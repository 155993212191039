import { Helper } from '@common/src/helpers';
import {
  QTable, QTd, QTh, QTr,
} from '@quasar/components/table';

export default {
  components: {
    QTd,
    QTh,
    QTr,
    QTable,
  },
  data() {
    return {
      editRowKey: null,
      tmpEditRow: null,
    };
  },

  computed: {
    _() {
      return _;
    },
  },

  methods: {
    handleSelectFullOption(val, field) {
      this.fields.items[this.editRowKey][field].label = val.name;
    },
    getBtns(key) {
      const result = [];
      if (this.disabled) {
        return result;
      }
      const {
        editRowKey, handleEditRow, handleSaveRow, handleDeleteRow,
      } = this;
      const trans = type => this.trans(`personnel.blocks.${type}_table_row`);
      const makeBtn = (color, icon, action, tooltip) => ({
        color, icon, action, tooltip,
      });

      if (editRowKey !== key) {
        result.push(makeBtn('orange', 'pencil', () => handleEditRow(key), trans('edit')));
      } else {
        result.push(makeBtn('green', 'check', handleSaveRow, trans('save')));
        result.push(makeBtn('red', 'trash', handleDeleteRow, trans('delete')));
      }
      return result;
    },
    async handleSaveRow() {
      this.$globalLoading.show();
      const row = this.fields.items[this.editRowKey];
      let action = this.actions.find(itm => itm.action === 'createItem');
      if (row.actions) {
        action = row.actions.find(itm => itm.action === 'updateItem');
      }

      const data = {};
      _.forEach(row, (itm, key) => {
        data[key] = itm?.value ?? itm;
      });

      const res = await requestWrapper.call(this, {
        ...action,
        data,
      });

      if (!res.error) {
        if (!row.actions) {
          this.fields.items[this.editRowKey] = res.data;
        }
        this.editRowKey = null;
        this.tmpEditRow = null;
        this.$forceUpdate();
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    handleAddRow() {
      if (!this.editRowKey && !this.fields.items[this.editRowKey]?.actions) {
        this.fields.items.push(_.cloneDeep(this.newRowTemplate) ?? {});
        this.editRowKey = this.fields.items.length - 1;
      }
    },
    handleEditRow(key) {
      if (this.editRowKey) {
        if (!this.fields.items[this.editRowKey]?.actions) {
          return;
        }
        this.fields.items[this.editRowKey] = _.cloneDeep(this.tmpEditRow);
      }
      this.tmpEditRow = _.cloneDeep(this.fields.items[key]);
      this.editRowKey = key;
    },
    handleDeleteRow() {
      const data = this.fields.items[this.editRowKey];
      const handleApprove = () => {
        this.$delete(this.fields.items, this.editRowKey);
        this.editRowKey = null;
        this.tmpEditRow = null;
      };

      const action = data?.actions?.find(itm => itm.action === 'deleteItem');

      if (action) {
        this.$sModal.open('VModelConfirm', {
          title: this.trans('label.are_you_sure_in_actions'),
          size: '480',
          component: 'v-modal-confirm',
          binds: {
            type: 'request',
            action: action.url,
            method: action.method,
          },
          events: {
            onApprove: handleApprove,
          },
        });
      } else {
        handleApprove();
      }
    },
  },
};
