import { QInput } from '@quasar/components';

export default {
  components: { QInput },

  props: {
    value: null,
    extraBinds: {
      type: Object,
      default: () => ({}),
    },
    error: Boolean,
    errorMessage: String,
  },

  data() {
    const config = this.getConfig();
    return {
      config,
      localValue: '',
    };
  },

  beforeMount() {
    this.$trans.add(['label', 'placeholder']);
  },

  mounted() {
    this.setInitialValue();
  },
  methods: {
    getConfig() {
      let config = {
        mask: '###',
        min: 0,
        max: 999,
      };
      if (this.extraBinds.hasOwnProperty('config')) {
        // eslint-disable-next-line prefer-destructuring
        config = this.extraBinds.config;
        if (config.max && !config.mask && !config.withoutMask) {
          config.mask = Array(String(config.max).length).fill('#').join('');
        }
      }
      return config;
    },
    emitChange(value = null) {
      this.$emit('input', value);
    },
    setInitialValue() {
      this.$set(this, 'localValue', this.value);
      this.$nextTick(this.$refs['q-number-field'].updateInnerValue);
    },
  },

  computed: {
    defaultAttributes() {
      return {
        placeholder: this.extraBinds.placeholder,
      };
    },

    extraAttributes() {
      const attrs = this.extraBinds;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },

  watch: {
    value() {
      this.setInitialValue();
    },
    'extraBinds.config': function () {
      this.config = this.getConfig();
    },
    localValue(value) {
      const { min, max } = this.config;
      const ref = this.$refs['q-number-field'];
      const nullValues = [null, undefined, ''];
      let tmpVal = String(value).match(/-?\d+|^-{1}/);
      if (_.isArray(tmpVal)) {
        tmpVal = tmpVal[0];
      }

      if (!nullValues.includes(value)) {
        if (!_.isEqual(tmpVal, value)) {
          this.localValue = tmpVal;
          setTimeout(ref.updateInnerValue, 0);
          return;
        }

        if (!nullValues.includes(min) && Number(value) < min) {
          this.localValue = min;
          setTimeout(ref.updateInnerValue, 0);
          return;
        }

        if (!nullValues.includes(max) && Number(value) > max) {
          this.localValue = max;
          setTimeout(ref.updateInnerValue, 0);
          return;
        }

        // if (value !== Number(value)) {
        // this.localValue = Number(value);
        // setTimeout(ref.updateInnerValue, 0);
        // }
      }
      this.emitChange(value);
    },
  },
};
