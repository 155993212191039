import _ from 'lodash';
import { requestWrapper } from '@vjs/helpers';
import moment from 'moment';

export default {
  name: 'VInsta',
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      posts: [],
      staticUrl: '',
      locale: window.core_project.locale,
      carouselInited: false,
      intervalId: null,
      videoHeight: '34rem',
      imgPostId: null,
    };
  },
  async mounted() {
    await this.$trans.add('notice');
    await this.pullPosts();
    this.intervalId = setInterval(() => {
      if ($ && this.$refs.carousel && !this.carouselInited) {
        this.carouselInited = true;
        this.inflictCarousel();
      }
    }, 100);
    this.handleUpdateVideoHeight();
    window.addEventListener('resize', this.handleUpdateVideoHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleUpdateVideoHeight);
  },
  watch: {
    carouselInited(val) {
      if (val) {
        clearInterval(this.intervalId);
      }
    },
  },
  methods: {
    async pullPosts() {
      const res = await requestWrapper.call(this, {
        url: this.url,
      });
      if (!res.error) {
        this.$set(this, 'posts', res.data ?? []);
        this.imgPostId = this.posts.find(itm => itm.media_type !== 'VIDEO')?.id;
        this.$set(this, 'staticUrl', res.staticUrl);
        this.posts.forEach((post, index) => {
          this.$set(this.posts[index], 'convertedDate', moment(post.timestamp).fromNow());
          if (post.media_type === 'VIDEO') {
            this.$set(this.posts[index], 'play', false);
          }
        });
      }
    },
    inflictCarousel() {
      $(document).ready(() => {
        $(this.$refs.carousel).owlCarousel({
          margin: 30,
          navText: ['', ''],
          nav: true,
          responsive: {
            280: {
              items: 1,
              margin: 25,
            },
            600: {
              items: 2,
              margin: 30,
            },
            900: {
              items: 3,
              margin: 30,
            },
          },
          dots: false,
          stagePadding: 15,
        });
      });
    },
    handleUpdateVideoHeight() {
      const interval = setInterval(() => {
        const imgEl = document.getElementById(`insta-img-${this.imgPostId}`);
        if (imgEl?.clientHeight) {
          this.videoHeight = imgEl?.clientHeight ? `${imgEl.clientHeight}px` : '34rem';
          clearInterval(interval);
        }
      }, 100);
    },
    cutString(text) {
      return _.truncate(text, {
        length: 200,
        separator: ' ',
      });
    },
    play(post) {
      this.$refs[`vid${post.id}`][0].play();
      this.$refs[`vid${post.id}`][0].volume = 0.33;
      post.play = true;
    },
    pause(post) {
      this.$refs[`vid${post.id}`][0].pause();
      post.play = false;
    },
  },
};
