import { scrollTop } from '@vjs/helpers';
import {
  AbroadTrip, Award, ContactInfo, Control, Disability, Education,
  Family, IdentityCard, Lang, Main, MilitaryRegistration,
  Qualification, ScientificWork, WorkExperience, Pension,
} from './components';

export default {
  components: {
    AbroadTrip,
    Award,
    ContactInfo,
    Control,
    Disability,
    Pension,
    Education,
    Family,
    IdentityCard,
    Lang,
    Main,
    MilitaryRegistration,
    Qualification,
    ScientificWork,
    WorkExperience,
  },

  props: {
    apiConfig: {
      type: Object,
      default: ({
        items: '',
        refs: '',
        refresh: '',
      }),
    },
    faceIdConfig: {
      type: Object,
      default: ({
        addPhoto: '',
        addAlbum: '',
        album: '',
        photo: '',
      }),
    },
    disabled: Boolean,
  },
  data() {
    return {
      refs: {
        control: {},
        main: {},
        identityCard: {},
        contactInfo: {},
        qualification: {},
        education: {},
        family: {},
        pension: {},
        workExperience: {},
        lang: {},
        disability: {},
        militaryRegistration: {},
        scientificWork: {},
        award: {},
        abroadTrip: {},
      },
      items: {
        control: {},
        main: {},
        identityCard: {},
        contactInfo: {},
        qualification: {},
        education: {},
        family: {},
        pension: {},
        workExperience: {},
        lang: {},
        disability: {},
        militaryRegistration: {},
        scientificWork: {},
        award: {},
        abroadTrip: {},
      },
      spoilers: {
        contactInfo: false,
        qualification: false,
        education: false,
        family: false,
        pension: false,
        workExperience: false,
        lang: false,
        disability: false,
        militaryRegistration: false,
        scientificWork: false,
        award: false,
        abroadTrip: false,
      },
    };
  },

  async mounted() {
    await this.$trans.add([
      'placeholder', 'label', 'button', 'personnel', 'select', 'text',
    ]);

    await requestGetDataHelper(this, { url: this.apiConfig.refresh });
    await requestGetDataHelper(this, { url: this.apiConfig.items, variableName: 'items' });
    await requestGetDataHelper(this, { url: this.apiConfig.refs, variableName: 'refs' });

    eventBus.$on('handleNavigation', this.scrollTo);
    eventBus.$on('handleSpoiler', this.handleSpoiler);
    eventBus.$on('handleAllSpoilers', this.handleAllSpoilers);
  },

  computed: {
    isAllSpoilersActive() {
      return !_.values(this.spoilers)?.includes(false);
    },
  },

  methods: {
    handleAllSpoilers() {
      if (this.isAllSpoilersActive) {
        this.spoilers = _.mapValues(this.spoilers, () => false);
      } else {
        this.spoilers = _.mapValues(this.spoilers, () => true);
      }
    },
    handleSpoiler(key) {
      this.spoilers[key] = !this.spoilers[key];
    },
    scrollTo(key) {
      this.$nextTick(() => {
        if (this.$refs[key]) {
          const scrollValue = window.scrollY + Math.abs(this.$refs[key].$el.getBoundingClientRect().top);

          scrollTop(
            window,
            window.scrollY,
            scrollValue,
            500,
          );
        }
      });
    },
  },
};
