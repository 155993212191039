import { QBtn, QIcon } from '@quasar/components';
import { requestWrapper, scrollTop } from '@vjs/helpers';
import VPhotoAlbum from '@common/components/VPhotoAlbum';
import VPassportTable from './components/VPassportTable';
import VClubsListContainerView from './components/VClubsListContainerView';

export default {
  name: 'VPassportPageView',

  components: {
    QBtn,
    QIcon,
    VPhotoAlbum,
    VPassportTable,
    VClubsListContainerView,
  },

  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        data: '',
        govServices: '',
        clubs: '',
      }),
    },
    teacherRegisterConfig: {
      type: Object,
      required: false,
    },
    schoolServiceAddressConfig: {
      type: Object,
      required: true,
    },
    classKitConfig: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      organization: {
        name: '',
        description: '',
        conditions: '',
        picItems: [],
        aboutOrg: [],
        govServices: [],
        clubs: [],
        mtbList: [],
      },
      hasTeacherRegister: true,
      hasSchoolServiceAddress: true,
      hasClassKit: true,
      spoilers: {
        teacherRegister: true,
        schoolServiceAddress: true,
        classKit: true,
      },
    };
  },
  computed: {
    navButtons() {
      const items = ['about'];

      if (this.organization.description) { items.push('description'); }
      if (this.organization.conditions) { items.push('conditions'); }
      if (this.organization.clubs.length > 0) { items.push('clubs'); }
      if (this.organization.govServices.length > 0) { items.push('govServices'); }
      if (this.organization.mtbList.length > 0) { items.push('mtbList'); }
      if (this.teacherRegisterConfig && this.hasTeacherRegister) { items.push('teacherRegister'); }
      if (this.hasSchoolServiceAddress) { items.push('schoolServiceAddress'); }
      if (this.classKitConfig && this.hasClassKit) { items.push('classKit'); }

      return items;
    },
  },

  async mounted() {
    await this.$trans.add(['label', 'button', 'additional_education', 'table', 'organization']);
    await this.handleRequest(this.apiConfig.data);
    await this.handleRequest(this.apiConfig.govServices);
    await this.handleRequest(this.apiConfig.clubs);
  },

  methods: {
    async handleRequest(url) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, { url });
      if (!res.error) {
        this.organization = { ...this.organization, ...res.data };
      }
      this.$globalLoading.hide();
    },
    scrollTo(name = '') {
      this.$nextTick(() => {
        if (this.$refs[name]) {
          const scrollValue = window.scrollY + Math.abs(this.$refs[name].getBoundingClientRect().top);

          scrollTop(
            window,
            window.scrollY,
            scrollValue,
            500,
          );
        }
      });
    },
  },
};
