<template>
  <div
    class="s-info-card"
  >
    <div
      class="s-w-100 s-info-card--card"
    >
      <div class="s-info-card--wrapper s-w-100">
        <div
          class="s-info-card--title"
          :class="data.iconLighter ? 's-info-card--title-light-icon' : ''"
        >
          <span
            :class="`s-str-ico-fa--thm-${data.icon}`"
            class="s-str-ico-fa"
          >
            {{ data.title }}
          </span>
        </div>
        <div class="s-application-block__body">
          <div
            v-if="data.info && data.info.length"
            class="row"
          >
            <div
              v-for="(info, key) in data.info"
              :key="key"
              :class="infoFlexboxClasses"
            >
              <div class="s-desc-item">
                <div class="s-desc-item__label mb-5">
                  {{ info.label }}
                </div>
                <div class="s-desc-item__value">
                  {{ info.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="data.table"
          class="mb-15"
        >
          <table
            class="s-table s-table--striped"
          >
            <thead class="s-table__head">
              <tr>
                <th
                  v-for="(col, key) in data.table.cols"
                  :key="key"
                >
                  {{ col.label }}
                </th>
              </tr>
            </thead>
            <tbody class="s-table__body">
              <tr
                v-for="(row, key) in data.table.rows"
                :key="key"
              >
                <template
                  v-for="(col, colKey) in data.table.cols"
                >
                  <td
                    v-if="col.name === 'index'"
                    :key="colKey"
                    :data-table-head="col.label"
                  >
                    {{ key + 1 }}
                  </td>
                  <td
                    v-else
                    :key="colKey"
                    :data-table-head="col.label"
                    v-html="row[col.name]"
                  />
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.s-table th {
  color: #8F98B0;
  font-weight: 600;
}

@media screen and ( max-width: 1008px ) {
  .s-table__body td {
    padding-left: unset;

    &::before {
      position: unset;
      text-align: unset;
      min-width: unset;
      max-width: 50%;
      width: 100%;
      padding-left: unset;
      padding-right: 1.5rem;
    }
  }
}

@media screen and ( min-width: 768px ) {
  .s-info-card.row {
    .s-info-card--card {
      padding-right: 1rem;
    }
  }
}

@media screen and ( max-width: 480px ) {
  .s-table__body td {
    padding-left: 1rem;

    &::before {
      max-width: unset;
      padding-left: unset;
      padding-right: unset;
    }
  }
}
</style>

<script>
export default {
  name: 'StatementShowCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    infoFlexboxClasses() {
      if (this.data.twoRows) {
        return 'col-lg-6 col-md-6 col-sm-6';
      }
      return 'col-lg-4 col-md-6 col-sm-6';
    },
  },
};
</script>
