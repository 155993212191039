import { ClosePopup } from '@quasar/directives';

export default {
  name: 'VModal',
  directives: { ClosePopup },
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
    },
    noClose: Boolean,
    inBody: {
      type: Boolean,
      default: true,
    },
  },
};
