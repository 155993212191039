<template>
  <q-dialog
    :value="active"
    :persistent="persistent"
    @hide="hideHandler"
  >
    <v-modal
      :title="title || trans('notice.confirm')"
      :class="{'s-light': lightText}"
      :size="size"
      :in-body="inBody"
      @modal-close="$emit('modal-close')"
    >
      <slot />
      <div class="row-sort-between-btn">
        <q-btn
          v-close-popup
          icon="close"
          width="20"
          :label="trans('button.cancel')"
          label-wider
          no-caps
          :color="declineColor"
          @click="$emit('modal-close')"
        />
        <q-btn
          icon="check"
          width="20"
          :label="trans('button.approve')"
          label-wider
          no-caps
          :color="submitColor"
          :loading="loading"
          @click="confirm"
        />
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import VModal from '@vjs/components/VModal';
import { QDialog } from '@quasar/components';
import { ClosePopup } from '@quasar/directives';

export default {
  name: 'VConfirmDialogNew',
  directives: { ClosePopup },
  components: { VModal, QDialog },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    onHide: {
      type: Function,
      default: () => {},
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    inBody: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    lightText: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    confirm: {
      type: Function,
      required: true,
    },
    declineColor: {
      type: String,
      default: 'grey',
    },
    submitColor: {
      type: String,
      default: 'orange',
    },
  },
  methods: {
    hideHandler() {
      this.onHide();
    },
  },
};
</script>
