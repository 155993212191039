import {
  QBtn,
  QTable,
  QTh,
  QTr,
  QTd,
  QPopupEdit,
  QInput,
  QIcon,
  QSelect,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';

export default {
  components: {
    QBtn,
    QTable,
    QTh,
    QTr,
    QTd,
    QInput,
    QIcon,
    QSelect,
    QPopupEdit,
  },
  props: {
    uriAction: String,
    uriRedirect: String,
    uriGetTable: String,
    isEdit: Boolean,
  },
  data: () => ({
    isAutoFillPopupOpened: false,
    autoFillPopupValue: null,
    pagination: {
      rowsPerPage: 20,
    },
    table: {
      rows: [],
      columns: [],
    },
  }),
  async mounted() {
    await this.$trans.add([
      'placeholder',
      'label',
      'rfid',
      'button',
      'book',
    ]);
    await this.requestGetTable();
  },
  methods: {
    getCellStyle(cellName) {
      switch (cellName) {
        case 'inventory_number': return 'min-width: 17rem;';
        case 'created_at': return 'min-width: 20rem;';
        case 'book_author': return 'min-width: 22.5rem;';
        case 'book_name': return 'min-width: 22.5rem;';
        default: return '';
      }
    },
    handleAutoFill() {
      const val = this.autoFillPopupValue ?? '';
      _.forEach(this.table.rows, (row, key) => {
        const stringKey = String(key + 1);
        const uuid = stringKey.padStart(4, '0');
        row.inventory_number = `${val}-${uuid}`;
      });
      this.$refs['auto-fill-popup-ref'].cancel();
    },
    async requestGetTable() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetTable,
      });

      if (!res.error) {
        this.table.rows = res.data.rows || [];
        this.table.columns = res.data.columns || [];
      }

      if (res.error) {
        this.$notify({
          text: this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      this.$globalLoading.hide();
    },
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAction,
        data: { book: this.table.rows },
      });
      if (res.error && res.errorType !== 302) {
        this.$globalLoading.hide();
      }
    },
  },
};
