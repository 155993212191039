import Vue from 'vue';

import api from '@vjs/helpers/api';
import { stopAndPrevent } from '../../utils/event.js';
import slot from '../../utils/slot.js';
import { getAllChildren } from '../../utils/vm.js';

export default Vue.extend({
  name: 'QForm',

  props: {
    autofocus: Boolean,
    noErrorFocus: Boolean,
    noResetFocus: Boolean,
    greedy: Boolean,

    action: String,
    headers: {
      type: Object,
      default: () => ({}),
    },
    response: {
      type: Function,
      default: () => {},
    },
    reject: Function,
    method: {
      type: String,
      default: 'post',
    },
  },

  mounted() {
    this.validateIndex = 0;
    this.autofocus === true && this.focus();
  },

  methods: {
    validate(shouldFocus) {
      const promises = [];
      const focus = typeof shouldFocus === 'boolean'
        ? shouldFocus
        : this.noErrorFocus !== true;

      this.validateIndex++;

      const components = getAllChildren(this);
      const emit = (res) => {
        this.$emit(`validation-${res === true ? 'success' : 'error'}`);
      };

      for (let i = 0; i < components.length; i++) {
        const comp = components[i];

        if (typeof comp.validate === 'function') {
          const valid = comp.validate();

          if (typeof valid.then === 'function') {
            promises.push(
              valid.then(
                v => ({ valid: v, comp }),
                error => ({ valid: false, comp, error }),
              ),
            );
          } else if (valid !== true) {
            if (this.greedy === false) {
              emit(false);

              if (focus === true && typeof comp.focus === 'function') {
                comp.focus();
              }

              return Promise.resolve(false);
            }

            promises.push({ valid: false, comp });
          }
        }
      }

      if (promises.length === 0) {
        emit(true);
        return Promise.resolve({ status: true, components });
      }

      const index = this.validateIndex;

      return Promise.all(promises).then((res) => {
        if (index === this.validateIndex) {
          const { valid, comp } = res[0];

          emit(valid);

          if (
            focus === true
              && valid !== true
              && typeof comp.focus === 'function'
          ) {
            comp.focus();
          }

          return { status: valid, components };
        }
      });
    },

    resetValidation() {
      this.validateIndex++;

      getAllChildren(this).forEach((comp) => {
        if (typeof comp.resetValidation === 'function') {
          comp.resetValidation();
        }
      });
    },

    submit(evt) {
      if (evt !== undefined) {
        stopAndPrevent(evt);
      }

      this.validate().then((val) => {
        if (val.status === true) {
          // Если форме передана ссылка action, то обрабатываем запрос в форме
          if (this.action && this.method) {
            const data = {};
            val.components.forEach((item) => {
              if (item.name !== undefined && item.value !== undefined) {
                data[item.name] = item.value;
              }
            });
            api(
              this.action,
              data,
              this.response,
              this.headers,
              this.reject ? this.reject : (err) => {
                // Обработка ошибок с сервера через валидацию
                const res = err.response;
                if (res
                  && res.status === 422
                  && res.data
                  && res.data.errors
                  && Object.keys(res.data.errors).length > 0) {
                  val.components.forEach((item) => {
                    if (res.data.errors[item.name]
                      && item.validate
                      && item.innerError !== undefined) {
                      item.innerError = true;
                      item.innerErrorMessage = res.data.errors[item.name][0];
                    }
                  });
                }
              },
            );
          } else {
            this.$emit('submit', evt);
          }
        }
      });
    },

    reset(evt) {
      evt !== void 0 && stopAndPrevent(evt);

      this.$emit('reset');

      this.$nextTick(() => { // allow userland to reset values before
        this.resetValidation();
        if (this.autofocus === true && this.noResetFocus !== true) {
          this.focus();
        }
      });
    },

    focus() {
      const target = this.$el.querySelector('[autofocus]') || this.$el.querySelector('[tabindex]');
      target !== null && target.focus();
    },
  },

  render(h) {
    return h('form', {
      staticClass: 'sn-form',
      on: {
        ...this.$listeners,
        submit: this.submit,
        reset: this.reset,
      },
    }, slot(this, 'default'));
  },
});
