import Vue from 'vue';
import store from '@vjs/store';
import VStatementServiceDocs
from '@app_mektep/views/VStatmentServiceDocs/VStatementServiceDocs.vue';
import Notify from '@vjs/components/Notify/Notify.vue';

if (document.getElementById('v-statement-service-docs-app')) {
  window.VStatementServiceDocsApp = new Vue({
    el: '#v-statement-service-docs-app',
    components: { VStatementServiceDocs, Notify },
    store,
  });
}
