import axios from 'axios';
import _ from 'lodash';
import { Helper } from '@common/src/helpers';
import Offer from '@vjs/modals/Offer';
import {
  QInput,
  QCheckbox,
  QBtn,
  QForm,
  QSlideTransition,
  QIcon,
  QField,
  QOptionGroup,
} from '@quasar/components';
import Validation from '@vjs/mixins/Validation';
import VFormInform from '@vjs/components/VFormInform';

export default {
  name: 'registration-sms',
  components: {
    QInput,
    QCheckbox,
    QBtn,
    QForm,
    QSlideTransition,
    VFormInform,
    QIcon,
    QField,
    QOptionGroup,
  },
  mixins: [Validation],
  props: {
    urls: {
      type: Object,
      default: () => ({
        success: '',
        redirect: '',
        offerModal: '',
      }),
    },
    project: {
      type: Number,
    },
    sizeCode: {
      type: Number,
      default: 4,
    },
  },

  data: () => ({
    currentStep: 1,
    lang: window.core_project.locale,
    errors: {},
    iin: '',
    show: false,
    is_resident: true,
    code: '',
    sendCode: false,
    phone: '',
    time: '',
    name: '',
    count: 0,
    timer: null,
    lastname: '',
    gender: '',
    surname: '',
    password: '',
    password_confirmation: '',
    processSend: false,
    checkOfferta: false,
    hash: '',
    debounceTimeout: null,
    showIinFields: true,
    isGenderFieldDisabled: true,
  }),
  computed: {
    codeMask() {
      return '#'.repeat(this.sizeCode);
    },
    computedPasswords() {
      return [this.password, this.password_confirmation];
    },
    seconds() {
      return this.count;
    },
    checkCodeButtonDisabled() {
      return !this.checkOfferta || this.phone.length < 18;
    },
    checkFillIin() {
      return this.currentStep === 3
        && (
          !this.is_resident
          || !this.validateIin(this.iin)
          || !this.show
        );
    },
    checkDisabled() {
      return !this.checkOfferta
        || this.checkFillIin
        || (this.currentStep === 3 && (!this.showIinFields || !this.gender))
        || (this.sendCode && this.code.length !== this.sizeCode)
        || (this.currentStep === 2
          && (this.password.length < 8 || this.password !== this.password_confirmation));
    },
    genderOptions() {
      return [
        {
          value: 'm',
          label: this.trans('label.male'),
        },
        {
          value: 'w',
          label: this.trans('label.female'),
        },
      ];
    },
  },
  async mounted() {
    await this.$trans.add(['auth', 'button', 'label', 'placeholder', 'validation']);
  },
  watch: {
    computedPasswords(val) {
      const [password, password_confirmation] = val;
      if (
        password && password_confirmation
        && password.length > 7
        && password_confirmation.length > 7
        && password !== password_confirmation
      ) {
        this.validation.password = [this.trans('validation.password_confirm_not_equal')];
      } else if (_.isEqual(this.validation.password, [this.trans('validation.password_confirm_not_equal')])) {
        delete this.validation.password;
      }
    },
    iin(value) {
      if (this.is_resident && this.validateIin(value)) {
        this.debounceTimeout = setTimeout(() => {
          this.checkIin(value);
        }, 1000);
      }
    },
  },
  methods: {
    timerStart() {
      const vm = this;
      clearInterval(vm.timer);
      vm.count = 90;
      this.timer = setInterval(() => {
        if (vm.count > 0) {
          vm.count--;
        } else {
          clearInterval(vm.timer);
        }
      }, 1000);
    },
    showErrors(attr) {
      let errors = '';
      if (errors.hasOwnProperty(attr)) {
        _.forEach(errors[attr], (i, v) => {
          errors += `${v}<br/>`;
        });
      }
    },
    takeCode(useCheck) {
      const vm = this;
      if (useCheck && vm.checkDisabled) return;
      this.send(
        '/api/v1/registration/phone',
        {
          phone: vm.phone,
        },
        (response) => {
          if (response.redirect) {
            window.location = response.redirect;
          }
          if (response.message) {
            Helper.showNotice(response.message, 'success');
          }
          vm.sendCode = true;
          vm.timerStart();
        },
      );
    },
    nextStep() {
      const vm = this;
      if (vm.checkDisabled) return;
      switch (vm.currentStep) {
        case 1:
          if (vm.checkOfferta) {
            if (!vm.sendCode) {
              this.takeCode(true);
              break;
            }
            this.send(
              '/api/v1/registration/check-code',
              {
                code: vm.code,
                phone: vm.phone,
              },
              (response) => {
                vm.$set(vm, 'hash', vm.code);
                vm.$set(vm, 'currentStep', vm.currentStep + 1);
                if (response.redirect) {
                  window.location = response.redirect;
                }
                if (response.message) {
                  Helper.showNotice(response.message, 'success');
                }
              },
            );
          }
          break;
        case 2:

          vm.$set(vm, 'currentStep', 3);

          break;
        case 3:
          this.showIinFields = false;
          this.send(
            '/api/v1/registration/store-user',
            {
              phone: vm.phone,
              password: vm.password,
              password_confirmation: vm.password_confirmation,
              code: vm.code,
              project_id: vm.project,
              iin: vm.iin,
              name: vm.name,
              born_date: vm.time,
              surname: vm.surname,
              sex: vm.gender,
            },
            async (response) => {
              if (response.message) {
                Helper.showNotice(response.message, 'success', window.L.message);
              }

              vm.$globalLoading.show();
              try {
                await axios({
                  method: 'POST',
                  url: '/auth/login-token',
                  data: {
                    token: response.token,
                  },
                });
                handleYm('registration');
                window.location.href = vm.urls.success;
              } catch (err) {
                vm.processSend = false;
                vm.$globalLoading.hide();
                if (Helper.handlerResponseError(err.response)) {
                  if (err.response.status === 200) {
                    window.location.reload();
                  }
                }
              }
            },
          );

          break;
        default:
      }
    },
    async send(url, body, success) {
      const vm = this;
      if (vm.processSend) {
        return;
      }
      vm.$globalLoading.show();
      try {
        const res = await axios({
          method: 'POST',
          url,
          data: body,
        });
        const { data } = res;
        success(data);
        vm.$globalLoading.hide();
      } catch (err) {
        if (Helper.handlerResponseError(err.response)) {
          if (err.response.status === 200) {
            window.location.reload();
          }
        }
      } finally {
        vm.processSend = false;
        vm.$globalLoading.hide();
      }
    },
    offertaButton() {
      const vm = this;
      vm.$set(vm, 'checkOfferta', !vm.checkOfferta);
    },
    async offerInfo() {
      const res = await axios.get(this.urls.offerModal);
      this.$sModal.open('offer', {
        size: '800',
        binds: {
          text: res.data,
        },
        component: Offer,
      });
    },
    async checkIin(iin) {
      try {
        const res = await axios({
          method: 'POST',
          url: '/api/v1/registration/check-iin',
          data: {
            iin,
            born_date: this.time,
            resident: this.is_resident,
            fromRegistration: true,
          },
        });
        const { data } = res;
        this.$set(this, 'surname', '');
        this.$set(this, 'name', '');
        this.$set(this, 'showIinFields', true);

        switch (data.status) {
          case 'exists':
            Helper.showNotice(data.message, 'warn');
            this.show = false;
            this.showIinFields = false;
            break;
          default:
            this.name = data.user.firstname;
            this.surname = data.user.surname;
            this.lastname = data.user.lastname;
            this.gender = data.user.gender;
            if (!this.gender) {
              this.isGenderFieldDisabled = false;
              this.validation.gender = [this.trans('validation.required', { attribute: this.trans('label.gender') })];
            } else {
              this.isGenderFieldDisabled = true;
              delete this.validation.gender;
            }
            this.time = data.user.born_date;
            this.show = true;
            break;
        }
      } catch (err) {
        if (Helper.handlerResponseError(err.response)) {
          this.$set(this, 'surname', '');
          this.$set(this, 'name', '');
          this.$set(this, 'show', false);
          this.$set(this, 'showIinFields', false);
        }
      }
    },
  },
};
