<template>
  <div>
    <template v-for="field in fields">
      <component
        :is="field.component"
        :key="field.name"
        v-model="field.value"
        :field="field"
        :field-name="field.labelTransKey?trans(field.labelTransKey):field.label"
        :errors="getErrors(field.name)"
        v-on="field.events?field.events:{}"
      />
    </template>
    <div class="row-sort-between-btn">
      <q-btn
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        width="15"
        color="green"
        :label="trans('button.approve')"
        type="a"
        :to="!fields.language.value ? undefined : `${redirect}/${fields.language.value}`"
        :disable="!fields.language.value"
      />
    </div>
  </div>
</template>

<script>
import { Form } from '@common/mixins';

export default {
  mixins: [Form],
  props: {
    redirect: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        language: {
          value: this.languages[0].value,
          name: 'language',
          component: 'q-radio-field',
          labelTransKey: 'label.select_contract_language',
          inline: true,
          required: true,
          options: this.languages,
        },
      },
    };
  },
};
</script>
