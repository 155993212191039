import _ from 'lodash';


export default {
  name: 'CheckIinModal',
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      default: 'POST',
      required: true,
    },
    fullname: {
      type: String,
      required: true,
    },
    iin: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      processSend: false,
    };
  },
  methods: {
    handleAjaxFormSubmit() {
      const vm = this;
      const { action } = vm;
      const { method } = vm;
      if (vm.processSend === true) {
        return false;
      }
      vm.$set(vm, 'processSend', true);
      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      const data = { iin: this.iin };
      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(vm, 'processSend', false);
          if (response.body && response.body.status === 'success') {
            // window.location.reload()
            // vm.$emit('submit')
          }
          $('#modal-iin-exists').modal('hide');
        },
        (response) => {
          if (response.status === 422 || response.status === 423) {
            vm.$set(vm, 'processSend', false);
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            vm.errors = formErrors;
          } else {
            vm.$set(vm, 'processSend', false);
            console.log(response);
          }
        },
      );
    },
  },
};
