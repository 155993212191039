import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import { eventBus } from '@common/mixins';

export default {
  props: {
    uriGetData: String,
  },
  data: () => ({ buttons: [], tableData: [] }),
  async mounted() {
    this.$globalLoading.show();
    const res = await requestWrapper.call(this, {
      url: this.uriGetData,
    });

    if (!res.error) {
      this.tableData = res.table;
      this.buttons = res.buttons;
    }
    this.$globalLoading.hide();
  },
  methods: {
    async handleBtnRequest(btn = {}) {
      if (btn.type === 'button' && btn.to && btn.method) {
        this.$globalLoading.show();

        const resConfig = {
          method: btn.method,
          url: btn.to,
        };

        const res = await requestWrapper.call(this, resConfig);

        this.$globalLoading.hide();
        if (!res.error) {
          eventBus.$emit('modalRequestSuccess');
          this.$emit('onClose');
          Helper.handlerResponseError({ data: res, status: res.status });
        } else {
          Helper.handlerResponseError({ data: res.data, status: res.errorType });
        }
      }
    },
  },
};
