import _ from 'lodash';
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      required: false,
    },
    method: {
      type: String,
      default: 'POST',
    },
  },

  data: () => ({
    processSend: false,
    errors: [],
  }),


  mounted() {

  },

  destroyed() {
  },

  methods: {

    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      // TODO перейти на vShow
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }
      if (field.vShow !== undefined) {
        if (_.isFunction(field.vShow)) {
          return field.vShow(vm);
        }
        return field.vShow;
      }
      return true;
    },

    // eslint-disable-next-line no-unused-vars
    appendForm(data) {

    },
    // eslint-disable-next-line no-unused-vars
    successSubmit(response) {

    },
    handleAjaxFormSubmit() {
      const vm = this;
      const data = {};
      _.forEach(vm.fields, (el) => {
        if (Object.prototype.hasOwnProperty.call(el, 'fill')) {
          el.fill(data);
        } else {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      this.appendForm(data);
      const { action } = vm;
      const { method } = vm;
      if (vm.processSend === true) {
        return;
      }
      vm.$set(vm, 'processSend', true);
      vm.$globalLoading.show();

      vm.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
      config.headers['accept-language'] = window.core_project.locale;

      let request;
      const formData = Helper.genFormDataArray(data);
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, formData, config);
          break;
        case 'delete':
          formData.append('_method', 'DELETE');
          request = vm.$http.post(action, formData, config);
          break;
        case 'patch':
          formData.append('_method', 'patch');
          request = vm.$http.post(action, formData, config);
          break;
        default:
          return;
      }
      request.then(
        (response) => {
          if (Helper.handlerResponseError(response)) {
            vm.$globalLoading.hide();
            vm.$set(vm, 'processSend', false);
            vm.errors = [];
            vm.successSubmit(response);
          }
        },
        (response) => {
          if (Helper.handlerResponseError(response)) {
            vm.$globalLoading.hide();
            vm.$set(vm, 'processSend', false);
            if (response.status === 422 || response.status === 423) {
              let errors = [];
              const formErrors = {};
              _.forEach(response.data.errors, (value, key) => {
                errors = errors.concat(value);
                _.set(formErrors, key, value);
              });
              vm.errors = formErrors;
            } else {
              console.log(response);
            }
          }
        },
      );
    },
    hasError(field) {
      return _.has(this.errors, field);
    },
    getError(field) {
      return _.first(_.get(this.errors, field));
    },
    getErrors(fieldName) {
      return _.get(this.errors, this.nameToDot(fieldName), []);
    },
    clearErrors(fieldName) {
      if (_.get(this.errors, this.nameToDot(fieldName))) {
        _.unset(this.errors, this.nameToDot(fieldName));
      }
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
  computed: {},
};
