import CardMixin from '../CardMixin';

export default {
  mixins: [CardMixin],

  data() {
    return {};
  },

  computed: {},
  methods: {},
};
