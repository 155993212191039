import {
  QDialog,
  QBtn,
  QSpinner,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import VModal from '@vjs/components/VModal';
import _ from 'lodash';

export default {
  name: 'VPhotoAlbumAddModal',

  components: {
    QDialog,
    QBtn,
    QSpinner,
    VModal,
  },

  props: {
    isCustom: Boolean,
    modal: Boolean,
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'notice',
      'button',
    ]);
  },

  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
    triggerFile() {
      this.$refs.fileInput.click();
    },
    async requestAddFile() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        if (!this.isCustom) {
          const formData = new FormData();
          formData.append('avatar[0]', file);
          const res = await requestWrapper.call(this, {
            method: 'post',
            url: '/api/v1/control/organization/createAvatar',
            data: formData,
          });
          if (res.error) {
            if (res?.data?.errors) {
              this.$notify({
                type: 'error',
                title: window.L.error,
                text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
              });
            }
            this.$refs.fileInput.value = null;
            return;
          }
        }

        this.$eventHub.$emit('photo-added', file);
        this.handleModal();
      }
    },
  },
};
