import Vue from 'vue';
import VFormResetPassword from '@common/views/FormResetPassword';
import store from '@vjs/store';

if (document.getElementById('v-reset-pass-app')) {
  window.VFormResetPassword = new Vue({
    el: '#v-reset-pass-app',
    components: { VFormResetPassword },
    store,
  });
}
