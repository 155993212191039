export default {
  install(Vue) {
    if (!Vue.prototype.$eventHub) {
      const eventBus = new Vue();
      Vue.prototype.$eventHub = eventBus;
      Vue._eventHub = eventBus;
    }
    const notify = (object = {
      type: '',
      title: '',
      text: '',
    }, position = 'top-right') => {
      // Для переноса в конец выполнения
      setTimeout(() => {
        Vue.prototype.$eventHub.$emit(`v-notify-${position}`, {
          ...object,
          position,
        });
      }, 0);
    };
    Vue.prototype.$notify = notify;
    Vue.notify = notify;
  },
};
