import { FormField, StoreTranslator } from '@common/mixins';
import { QField } from '@quasar/components';

export default {
  mixins: [StoreTranslator, FormField],
  components: { QField },
  props: {},
  beforeMount() {
    this.$trans.add(['label', 'placeholder']);
  },
  methods: {
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
  },
  computed: {
    defaultAttributes() {
      return {
        type: this.field.type || 'text',
        placeholder: this.field.placeholder,
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
