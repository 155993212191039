import { Form } from '@common/mixins';
import { requestWrapper } from '@vjs/helpers';
import _ from 'lodash';

export default {
  mixins: [Form],
  props: {
    action: {
      type: String,
      required: true,
    },
    bookId: {
      type: Number,
      required: true,
    },
    fund: {
      type: Boolean,
      default: false,
    },
  },
  beforeCreate() {
    this.$trans.add(['book']);
  },
  data() {
    return {
      fields: {
        quantity: {
          labelTransKey: 'book.specify_quantity',
          value: null,
          name: 'quantity',
          component: 'q-number-field',
          required: true,
          extraAttributes: { counter: false },
          config: { mask: '####', min: 1, max: 9999 },
        },
      },
    };
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const data = {};

      _.forEach(this.fields, (field) => {
        data[field.name] = field.value;
      });

      data.bookId = this.bookId;
      data.fund = this.fund;

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data,
      });

      if (res.error) {
        if (res?.data?.errors) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
          this.$globalLoading.hide();
        }
      }
    },
  },
};
