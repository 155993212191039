export const confirmed = (targetField, message) => (val) => {
  if (typeof targetField === 'string' && targetField === val) {
    return true;
  }

  if (targetField.value === val) {
    return true;
  }

  return message;
};
