import {
  QBtn,
  QIcon,
  QSelect,
  QTable,
  QTd,
  QTh,
  QTooltip,
  QTr,
  QCheckbox,
  QPopupProxy, QDate,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';
import VConfirmDialog from '@vjs/modals/VConfirmDialog';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import ImageViewer from '@common/components/ImageViewer';

export default {
  name: 'NewTable',
  components: {
    QDate,
    QBtn,
    VNoResults,
    QIcon,
    QTable,
    QTh,
    QTr,
    QTd,
    QCheckbox,
    QTooltip,
    QSelect,
    QPopupProxy,
    VConfirmDialog,
    ImageViewer,
  },
  props: {
    cols: {
      type: Array,
      default: () => ([]),
    },
    rows: {
      type: Array,
      default: () => ([]),
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
    paginationBtns: {
      type: Array,
      default: () => ([]),
    },
    adaptiveWhen: {
      type: Number,
      default: 1200,
    },
    separator: {
      type: String,
      default: 'horizontal',
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    visibleCols: Array,
    bottomRow: Array,
    bottomRowAdaptive: Array,
    noResultTitle: String,
    noResultText: String,
    clickableRows: Boolean,
    hideBottom: Boolean,
    withNoResultText: Boolean,
    selectableRows: Boolean,
    eventBus: null,
  },
  data() {
    return {
      openModal: false,
      modalMethod: 'post',
      uriConfirm: '',
      loadingModal: false,
      hoveredRowId: null,
      checkedRows: [],
      pagination: {
        rowsPerPage: 15,
      },
      localEventBus: this.eventBus,
    };
  },
  computed: {
    customHeaderProps() {
      if (!this.isHeaderMultiRow) {
        return false;
      }

      const result = [];
      _.forEach(this.cols, (row, rowKey) => {
        const colsMap = {};
        const cols = [];
        _.forEach(row, (itm, key) => {
          colsMap[`${rowKey}-${itm.name}`] = { ...itm, field: itm.name };
          cols[key] = { ...itm, field: itm.name };
        });
        result.push({ header: true, cols, colsMap });
      });
      return result;
    },
    checkableRows() {
      return this.rows.filter(itm => itm.isCheckable);
    },
    isAllRowsChecked() {
      if (this.checkedRows.length === this.checkableRows.length) {
        return true;
      }
      if (this.checkedRows.length === 0) {
        return false;
      }
      return null;
    },
    isHeaderMultiRow() {
      return !!this.cols[0]?.length;
    },
    tablePropCols() {
      if (this.isHeaderMultiRow) {
        return this.cols[this.cols.length - 1];
      }
      return this.cols;
    },
    headerCols() {
      if (this.isHeaderMultiRow) {
        const result = _.cloneDeep(this.cols);
        result.pop();
        return result;
      }
      return this.cols;
    },
    rowBtnStyles() {
      const refName = `row-${this.hoveredRowId}`;
      const ref = this.$refs?.[refName];
      if (ref) {
        const bodyRect = document.body.getBoundingClientRect();
        const refRect = ref.$el.getBoundingClientRect();
        const top = (refRect.top + (refRect.height / 2) - 15) - bodyRect.top;
        return { top: `${top}px`, left: `${refRect.left - 15}px` };
      }
      return null;
    },
    window() {
      return window;
    },
    paginationOptions() {
      const options = [10, 15, 25, 50, 100];
      return options.map(item => ({
        value: item,
        label: item,
      }));
    },
    isMetaEmpty() {
      return _.isEmpty(this.meta);
    },
  },
  mounted() {
    if (!this.localEventBus) {
      this.localEventBus = window.eventBus;
    }
    if (this.isMetaEmpty) {
      this.pagination.rowsPerPage = 0;
    } else {
      this.pagination.rowsPerPage = this.meta.per_page;
    }
  },
  methods: {
    handleHeadCheckbox() {
      if (!this.isAllRowsChecked) {
        this.checkedRows = this.checkableRows.map(itm => itm.id);
      } else {
        this.checkedRows = [];
      }
    },
    async emitGetItems(type = 'default') {
      await this.localEventBus.$emit('sendRowsPerPage', this.pagination.rowsPerPage);
      await this.localEventBus.$emit('getItems', type);
      this.checkedRows = [];
    },
    getCellClass(name) {
      const col = this.tablePropCols.find(column => column.name === name);
      let style = '';

      if (col) {
        if (col.align && !col.alignOnlyHead) {
          style += `s-to-${col.align} `;
        }
        if (col.fontWeight) {
          style += `s-${col.fontWeight} `;
        }
      }
      return style;
    },
    handleModal(action, row) {
      if (action.type === 'button') {
        if (action.modal === 'confirm' && action.modalLink && action.method) {
          this.openModal = true;
          this.uriConfirm = action.modalLink;
          this.modalMethod = action.method;
        }

        if (action.modal === 'image' && action.modalLink) {
          const tmpImages = [{ thumbnail: action.modalLink, source: action.modalLink }];
          this.$refs.viewer.show(tmpImages);
        }

        if (action.modal === 's-modal' && action['s-modal-config']) {
          const config = action['s-modal-config'];
          const binds = config?.binds ?? {};
          const events = {};
          if (config.getItemsOnSuccess) {
            const func = async () => { await this.emitGetItems(); };
            events.onApprove = func;
            events.onSuccess = func;
          }
          binds.row = row;
          this.$sModal.open(
            action['s-modal-config'].id ?? action['s-modal-config'].component,
            { ...action['s-modal-config'], binds, events },
          );
        }
      }
    },

    async requestConfirmModal() {
      this.$emit('toggleLoaders', true);
      const res = await requestWrapper.call(this, {
        method: this.modalMethod,
        url: this.uriConfirm,
      });

      if (!res.error) {
        this.openModal = false;
        if (res.data && res.data.message) {
          Helper.showNotice(res.data.message, 'success', this.trans('notice.message'));
        }
        Helper.handlerResponseError({ data: res, status: res.status });
        await this.localEventBus.$emit('getItems', 'default');
      } else {
        Helper.handlerResponseError({ data: res.data, status: res.errorType });
      }
      this.$emit('toggleLoaders');
    },
  },
  watch: {
    checkedRows: {
      deep: true,
      handler(val) {
        this.localEventBus.$emit('table-checked-rows-change', val);
      },
    },
  },
};
