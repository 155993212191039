import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru.js';

export default {
  props: {
    value: null,
    extraBinds: {
      type: Object,
      default: () => ({}),
    },
    error: Boolean,
    errorMessage: String,
  },

  data() {
    const vm = this;
    const toolbar = {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
      ],
    };
    return {
      localValue: '',

      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar,
      },
      loadedCkeditor: false,
      UploadAdapter: function UploadAdapter(loader) {
        this.loader = loader;

        this.upload = () => this.loader.file
          .then(file => new Promise((resolve, reject) => {
            const body = new FormData();
            body.append('file', file);

            const config = {
              responseType: 'json',
              method: 'POST',
              headers: {},
            };
            config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
            vm.$http.post('/ru/tko/upload-content', body, config).then(
              response => resolve(response.data),
              (response) => {
                if (response.status === 422 || response.status === 423) {
                  let errors = [];
                  _.forEach(response.data.errors, (value, key) => {
                    errors = errors.concat(value);
                  });
                  show_notice(errors, 'error');
                } else {
                  console.log(response);
                }
                return reject('error');
              },
            );
          }));
        this.abort = () => {
          console.log('Abort upload.');
        };
      },
    };
  },

  beforeMount() {
    this.$trans.add(['label', 'placeholder']);
  },
  methods: {
    onEditorReady(instance) {
      const { UploadAdapter } = this;
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new UploadAdapter(loader);
      }
      this.loadedCkeditor = true;
      this.setInitialValue();
    },
    setInitialValue() {
      if (this.loadedCkeditor) {
        this.localValue = !(this.value === undefined || this.value === null)
          ? this.value
          : '';
      }
    },

  },

  computed: {
    defaultAttributes() {
      return {
        placeholder: this.extraBinds.placeholder,
      };
    },

    extraAttributes() {
      const attrs = this.extraBinds;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },

  watch: {
    value(val) {
      if (val !== this.localValue) {
        this.setInitialValue();
      }
    },
    localValue(val) {
      this.$emit('input', val);
    },
  },
};
