import CardMixin from '../CardMixin';
import CardTableMixin from '../CardTableMixin';

export default {
  mixins: [CardMixin, CardTableMixin],

  data() {
    return {
      newRowTemplate: {
        educationType: {},
        academicDegree: {},
      },
    };
  },

  computed: {
    cols() {
      let result = [
        {
          label: this.trans('personnel.blocks.fields.educationType'),
          name: 'educationType',
          field: 'educationType',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.orgName'),
          name: 'orgName',
          field: 'orgName',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.edu_dateStart'),
          name: 'dateStart',
          field: 'dateStart',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.edu_dateEnd'),
          name: 'dateEnd',
          field: 'dateEnd',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.professionName'),
          name: 'professionName',
          field: 'professionName',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.academicDegree'),
          name: 'academicDegree',
          field: 'academicDegree',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.diplomaQualification'),
          name: 'diplomaQualification',
          field: 'diplomaQualification',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.edu_number'),
          name: 'number',
          field: 'number',
          align: 'center',
        },
        {
          label: this.trans('label.operations'),
          name: 'actions',
          field: 'actions',
          align: 'center',
          width: 140,
        },
      ];
      if (this.disabled) {
        result = result.filter(itm => itm.field !== 'actions');
      }
      return result;
    },
  },
};
