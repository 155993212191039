<template>
  <div>
    <div
      v-if="label"
      class="sn-field__label"
      style="margin-bottom: 0;"
      v-html="`${label}: ${required ? '<i>*</i>' : '' }`"
    />
    <div
      class="s-two-field-wrapper"
      :class="contentClass"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VTwoFieldWrapper',
  props: {
    label: String,
    required: Boolean,
    contentClass: [Array, String, Object],
  },
};
</script>
