import Validation from '@vjs/mixins/Validation';

export default {
  name: 'VElectronicContractCreate',
  mixins: [Validation],
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        backButtonUrl: '',
        storeApiUrl: '',
        getData: '',
      }),
    },
  },
  data() {
    return {
      form: {
        statement_id: null,
        is_service: null,
        school_id: null,
        user_id: null,
        child_id: null,
        contract_number: null,
        language: null,
        region_name: null,
        department_name: null,
        locality_name: null,
        school_name: null,
        address: null,
        director_fio: null,
        bin: null,
      },
      inputList: [
        'contract_number',
        'region_name',
        'department_name',
        'locality_name',
        'school_name',
        'address',
        'director_fio',
        'bin',
      ],
      disable: {
        contract_number: false,
        address: false,
        director_fio: false,
        bin: false,
        locality_name: true,
      },
    };
  },

  computed: {
    validate() {
      return this.form.contract_number !== null
        && this.form.address !== null
        && this.form.director_fio !== null
        && this.form.bin !== null;
    },
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'statement',
      'warning_text',
      'electronic_contract',
    ]);
    await this.requestGetData();
  },

  methods: {
    isInputRequired(name) {
      switch (name) {
        case 'locality_name': return false;
        default: return true;
      }
    },
    isInputDisabled(name) {
      if (this.disable.hasOwnProperty(name)) {
        return this.disable[name];
      }
      return true;
    },
    async requestGetData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        const { data } = res;
        this.form = { ...this.form, ...data.data };
        this.options = data.options;
        if (data.disabled && !_.isEmpty(data.disabled)) {
          _.forEach(this.disable, (value, name) => {
            this.disable[name] = data.disabled[name] ?? false;
          });
        }
      }
      this.$globalLoading.hide();
    },

    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.storeApiUrl,
        method: 'post',
        data: { ...this.form },
      });

      if (res.error) {
        this.$globalLoading.hide();
      }
    },
  },
};
