export default {
  name: 'VElectronicContractShow',
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        editUrl: null,
        createUrl: null,
        signApiUrl: null,
        submitUrl: null,
        downloadApiUrl: null,
        getData: null,
        staticUrl: null,
      }),
    },
    contractName: String,
  },
  data() {
    return {
      table: [],
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'statement',
      'warning_text',
      'electronic_contract',
    ]);
    await this.requestGetData();
  },

  methods: {
    handleLoading(value = true) {
      if (value) {
        this.$globalLoading.show();
      } else {
        this.$globalLoading.hide();
      }
    },
    async requestGetData() {
      this.handleLoading();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        this.table = res.data;
      }
      this.handleLoading(false);
    },
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.submitUrl,
        method: 'post',
        data: { ...this.form },
      });

      if (res.error) {
        this.$globalLoading.hide();
      }
    },
    async handleSendBtn() {
      if (this.apiConfig.submitUrl && !this.apiConfig.signApiUrl) {
        await this.requestAction();
      }
      if (this.apiConfig.signApiUrl && !this.apiConfig.submitUrl) {
        await this.sign();
      }
    },
    async sign() {
      this.handleLoading();
      const resWithXml = await requestWrapper.call(this, {
        url: this.apiConfig.getXmlToSign,
        method: 'post',
      });
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = resWithXml.data;
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        // Ошибка при подключении к NCALayer. Перезапустите программу и обновите страницу.
        return;
      }
      webSocket.onopen = (event) => {
        callback = async (response) => {
          const signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: this.apiConfig.signApiUrl,
            method: 'post',
            data: { signXml },
          });
          if (res.error) {
            this.handleLoading(false);
          }
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          // Соединение с NCALayer было прервано. Попробуйте авторизоваться еще раз или обновить страницу.
          this.handleLoading(false);
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.handleLoading(false);
          }
        }
      };
    },
  },
};
