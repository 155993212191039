import { eventBus, FormField, StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator, FormField],
  methods: {
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
    loadValues() {
      const vm = this;
      if (vm.field.loadValues === undefined) {
        return;
      }
      const where = vm.field.loadValues.where ?? {};
      // TODO сделать кеш что бы не лазить каждый раз за тем что уже есть
      // надо подумать если ли смысл и не будет ли проблем с памятью браузера

      const config = {
        responseType: 'json',
        method: 'GET',
        params: where,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(`/api/refs/${vm.field.loadValues.type}`, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm, 'loadedValues', data);
        },
        (response) => {
          console.log(response);
        },
      );
    },
  },
  data() {
    return {
      loadedValues: [],
    };
  },
  created() {
    eventBus.$on(`reload-refs:${this.field.name}`, () => {
      this.loadValues();
    });
  },
  mounted() {
    this.loadValues();
  },
  computed: {
    values() {
      if (this.loadedValues.length) {
        return this.loadedValues;
      }
      return this.field.values;
    },
    defaultAttributes() {
      return {
        name: this.field.name,
        'minimum-results-for-search': 20,
        placeholder: this.trans('placeholder.choose'),
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
