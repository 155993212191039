import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import VFormInform from '@vjs/components/VFormInform';
import { QCheckbox, QInput, QOptionGroup } from '@quasar/components';
import Stepper from '../Stepper/Stepper.vue';
import CheckIinModal from '../StatementParentForm/CheckIinModal';
import SpecialClassForm from './SpecialClassForm.vue';
import StatementOrganizations from '../StatementOrganizations/StatementOrganizations.vue';

export default {
  beforeCreate() {
    this.$trans.add(['statement_service', 'warning_text', 'label', 'button', 'notice']);
  },
  components: {
    QInput,
    StatementOrganizations,
    QCheckbox,
    QOptionGroup,
    Stepper,
    CheckIinModal,
    SpecialClassForm,
    VFormInform,
  },
  data() {
    return {
      loading: true,
      selected_child: null,
      current_step: !this.isService && !this.school ? 3 : 4,
      iin: '',
      isAddChild: false,
      dataFromIin: false,
      isResident: false,
      citizenship_id: null,
      nationality_id: null,
      sur_name: '',
      first_name: '',
      middle_name: '',
      birth_date: '',
      actual_residence: null,
      sex: 'm',
      errors: {
        iin: false,
      },
      error_message: '',
      child: null,
      selected_school: this.school,
      selected_class: null,
      is_new: false,
      selected_language: null,
      language_list: [],
      group_list: [],
      modalFullname: '',
      multiselectValues: {},
      forAdult: false,
      childrenInfos: [],
      isDisabledActualizationLocal: false,
      isSpecialNeeds: false,
      street: null,
      streets: [],
      streetsLoading: false,
      currentStreetsPage: 1,
      streetsLastPage: 1,
      streetSelectInputValue: '',
      selectedStreetOption: null,
      house: null,
      houses: [],
      housesLoading: false,
      currentHousesPage: 1,
      housesLastPage: 1,
      houseSelectInputValue: '',
      selectedHouseOption: null,
      selectedRegion: null,
      selectedDepartment: null,
      selectedLocality: null,
      organizations: [],
      organizationsByQuota: [],
      organizationsOptions: [],
      departments: [],
      localities: [],
      consts: {
        SCHOOL_DELIVERY: 1, // Развозка учеников
        INDIVIDUAL_EDUCATION: 11, // Индивидуальное обучение
      },
    };
  },
  props: {
    type: {
      type: Number,
      required: false,
    },
    organizationsUri: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    selectChildWarnMessage: {
      type: String,
      default: '',
    },
    school: {
      default: '',
    },
    schoolName: {
      default: '',
    },
    classNumber: {
      default: '',
    },
    lang: {
      default: '',
    },
    isBinom: {
      type: Boolean,
      default: false,
    },
    children: {
      default: [],
    },
    citizenships: {
      default: [],
    },
    nationalities: {
      default: [],
    },
    uriCheckIin: {
      type: String,
      default: '',
    },
    uriCheckChildIin: {
      type: String,
      default: '',
    },
    uriAddChild: {
      type: String,
      default: '',
    },
    uriGetGroups: {
      type: String,
      default: '',
    },
    uriGetDepartments: {
      type: String,
      default: '',
    },
    uriGetLocalities: {
      type: String,
      default: '',
    },
    uriGetLangs: {
      type: String,
      default: '',
    },
    uriStatementStore: {
      type: String,
      default: '',
    },
    schools: {
      default: '[]',
    },
    eduGroups: {
      default: '[]',
    },
    uriConfirmIinExists: {
      type: String,
      required: true,
    },
    specialSchool: Number,
    generalSchool: Number,
    testTypes: {
      default: null,
      type: [Object, Array],
    },
    regions: {
      default: () => [],
      type: Array,
    },
    locations: {
      default: () => {},
      type: Object,
    },
    isService: {
      type: Boolean,
      default: false,
    },
    adultAbility: false,
    adoption: false,
    uriChildrenInfos: {
      type: String,
      required: true,
    },
    uriActualization: {
      type: String,
      default: '',
    },
    isDisabledActualization: false,
    uriGetStreets: {
      type: String,
      required: false,
    },
    uriGetHouses: {
      type: String,
      required: false,
    },
    activeRegion: {
      type: Number,
      required: false,
    },
    activeDepartment: {
      type: Number,
      required: false,
    },
  },
  computed: {
    isDisabledBtn() {
      if (this.type === this.consts.SCHOOL_DELIVERY || this.type === this.consts.INDIVIDUAL_EDUCATION) {
        return !this.selected_child;
      }
      if (!this.selected_school) {
        return !this.selected_child || !this.street || !this.house || !this.selectedRegion || !this.selectedDepartment || !this.selectedLocality;
      }
      return this.forAdult ? !this.forAdult : !this.selected_child;
    },
    childOptions() {
      const result = [];
      if (typeof this.parsed_children === 'object') {
        _.map(this.parsed_children, (itm) => {
          const option = {
            id: itm.user_id,
            name: `${this.getFullName(itm)} ${this.$options.filters.getAge(itm.born_date, this.trans)} ${itm.hasContingent ? `(${itm.schoolName})` : ''}`,
            disable: itm.hasContingent,
          };
          result.push(option);
        });
      }
      return result;
    },
    selectedChild() {
      return this.childrenInfos.find(
        itm => itm.value === this.selected_child,
      );
    },
    isAdmissionByAddress() {
      return this.localities.find(
        itm => itm.id === this.selectedLocality,
      )?.is_admission_by_address;
    },
    childActualResidence() {
      return this.selectedChild?.actualResidence;
    },
    placeOfResidence() {
      return this.selectedChild?.placeOfResidence;
    },
    isMissingAddress() {
      return this.selectedChild?.isMissingGovAddress;
    },
    isDisabledActualize() {
      if (this.selectedChild?.canActualize) {
        return this.selectedChild?.isDisabledActualize;
      }
      return this.isDisabledActualization;
    },
    validate() {
      return this.selected_school !== null
          && this.selected_class !== null
          && this.selected_language !== null
          && this.loading !== true;
    },
    parsed_children() {
      if (typeof this.children === 'string') return JSON.parse(this.children);
      return this.children;
    },
    parsed_citizenships() {
      if (typeof this.citizenships === 'object' && this.citizenships.length) return this.citizenships;
      return JSON.parse(this.citizenships);
    },
    parsed_nationalities() {
      if (typeof this.nationalities === 'object' && this.nationalities.length) return this.nationalities;
      return JSON.parse(this.nationalities);
    },
    parsed_eduGroups() {
      return Object.keys(JSON.parse(this.eduGroups));
    },
    parsed_schools() {
      return JSON.parse(this.schools);
    },
    schoolInfo() {
      return this.parsed_schools.find(
        itm => itm.id === this.selected_school,
      );
    },
  },
  filters: {
    getLangByLocale(value, locale) {
      const lang = value[0];

      if (!lang || !lang.education_language) return;

      if (locale == 'ru') {
        return lang.education_language.ru_name;
      } if (locale == 'kz') {
        return lang.education_language.kk_name;
      }
    },
    translateCitizenShip(value, locale) {
      if (!value) return;

      if (locale == 'ru') {
        return value.ru_name;
      } if (locale == 'kz') {
        return value.kk_name;
      }
    },
    getAge(value, trans) {
      if (!value) return '-';

      const date = moment.unix(value, 'YYYY');
      const diff = moment().diff(date, 'years');

      return `(${trans('label.age')}: ${diff})`;
    },
  },
  async mounted() {
    if (this.school) {
      this.selected_school = Number(this.school);
      this.selected_class = Number(this.classNumber);
      this.selected_language = Number(this.lang);
      await this.getGroups(false);
      await this.getLangs(false);
    }
    if (this.activeRegion) {
      this.selectedRegion = this.activeRegion;
      await this.getDepartments();
    }
    if (this.activeDepartment) {
      this.selectedDepartment = this.activeDepartment;
      await this.getLocalities();
    }
    await this.getChildrenInfos();
    this.loading = false;
    eventBus.$on('handleSelectOrg', async (orgId, childItem) => await this.handleSelectOrg(orgId, childItem));
  },
  methods: {
    async handleSelectOrg(orgId, { className, eduLangId }) {
      this.is_new = true;
      this.selected_class = Number(className);
      this.selected_language = eduLangId;
      this.selected_school = orgId;
      const region = this.regions.find(itm => itm.id === this.selectedRegion)?.name;
      const department = this.departments.find(itm => itm.id === this.selectedDepartment)?.name;
      const locality = this.localities.find(itm => itm.id === this.selectedLocality)?.name;
      const street = this.isAdmissionByAddress ? this.streets.find(itm => itm.id === this.street)?.name : this.street;
      this.actual_residence = `${region} ${department} ${locality} ${street} ${this.house}`;
      await this.getGroups(false);
      await this.getLangs(false);
      this.current_step++;
    },
    async requestSearchStreets(val, update) {
      this.streetsLoading = true;
      this.currentStreetsPage = 1;
      this.selectedHouseOption = null;
      await this.requestGetStreetsByFilter().then((res) => {
        update(() => {
          this.streets = res.items;
          this.streetsLoading = false;
          this.streetsLastPage = res.lastPage;
        });
      }, () => {});
    },
    async requestSearchHouses(val, update) {
      this.housesLoading = true;
      this.currentHousesPage = 1;
      await this.requestGetHousesByFilter().then((res) => {
        update(() => {
          this.houses = res.items;
          this.housesLoading = false;
          this.housesLastPage = res.lastPage;
        });
      }, () => {});
    },
    async requestGetStreetsByFilter() {
      return new Promise(async (res, rej) => {
        const search = this.streetSelectInputValue.toLowerCase();
        if (search) {
          const result = await requestWrapper.call(this, {
            url: this.uriGetStreets,
            params: {
              locality: this.selectedLocality,
              page: 1,
              search,
            },
          });

          if (!result.error) {
            res(result);
          } else {
            rej(result);
          }
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          rej(false);
        }
      });
    },
    async requestGetHousesByFilter() {
      return new Promise(async (res, rej) => {
        const search = this.houseSelectInputValue.toLowerCase();
        if (search) {
          const result = await requestWrapper.call(this, {
            url: this.uriGetHouses,
            params: {
              page: 1,
              search,
              street_id: this.street,
            },
          });

          if (!result.error) {
            res(result);
          } else {
            rej(result);
          }
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          rej(false);
        }
      });
    },

    async requestGetStreets(offLoading = true) {
      this.streetsLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetStreets,
        params: {
          locality: this.selectedLocality,
          page: this.currentStreetsPage,
          search: this.streetSelectInputValue,
        },
      });

      if (!res.error) {
        this.streets = [...this.streets, ...res.items];
        this.streetsLastPage = res.lastPage;
      }
      if (offLoading) {
        this.streetsLoading = false;
      }
    },

    async requestGetHouses(offLoading = true) {
      this.housesLoading = true;
      this.house = null;
      const res = await requestWrapper.call(this, {
        url: this.uriGetHouses,
        params: {
          page: this.currentHousesPage,
          search: this.houseSelectInputValue,
        },
      });

      if (!res.error) {
        this.houses = [...this.houses, ...res.items];
        this.housesLastPage = res.lastPage;
      }
      if (offLoading) {
        this.housesLoading = false;
      }
    },

    async onStreetScroll({ index }) {
      if (!this.streetsLoading
        && this.currentStreetsPage < this.streetsLastPage
        && index >= (this.streets.length - 21)
      ) {
        this.currentStreetsPage += 1;

        await this.requestGetStreets(false);
        this.$nextTick(() => {
          this.$refs.streetSelect.$refs['q-select-wrapper-ref'].refresh();
          this.streetsLoading = false;
        });
      }
    },
    async onHouseScroll({ index }) {
      if (!this.housesLoading
        && this.currentHousesPage < this.housesLastPage
        && index >= (this.houses.length - 21)
      ) {
        this.currentHousesPage += 1;

        await this.requestGetHouses(false);
        this.$nextTick(() => {
          this.$refs.houseSelect.$refs['q-select-wrapper-ref'].refresh();
          this.housesLoading = false;
        });
      }
    },
    getFullName(child) {
      let fullName = '';

      fullName += (child.surname) ? `${child.surname} ` : '';
      fullName += (child.firstname) ? `${child.firstname} ` : '';
      fullName += (child.lastname) ? child.lastname : '';

      return fullName;
    },
    toggleAddChild() {
      this.resetErrors();
      this.isAddChild = !this.isAddChild;
      this.selected_child = null;
    },
    async nextStep(e) {
      e.preventDefault();
      try {
        this.loading = true;
        const response = await axios.post(`${this.uriAddChild}`, {
          resident: this.isResident,
          iin: this.iin,
          citizenship_id: this.citizenship_id,
          nationality_id: this.nationality_id,
          surname: this.sur_name,
          firstname: this.first_name,
          secondname: this.middle_name,
          sex: this.sex,
          born_date: this.birth_date,
          actual_residence: this.actual_residence,
        }, { headers: { 'X-Requested-With': 'XMLHttpRequest' } });

        if (response.data && response.data.redirect) window.location = response.data.redirect;

        if ((response.data || {}).status === 'success') {
          this.selectChild(response.data.child);
        } else {
          console.log('Response status not success');
        }
      } catch (err) {
        console.log(err);
        if (err.response.data.errors) {
          const { errors } = err.response.data;
          for (const key of Object.keys(errors)) {
            errors[key].forEach(error_message => show_notice(error_message, 'error'));
          }
        }
      } finally {
        this.loading = false;
      }
    },
    selectChild(childId) {
      this.child = childId;
      this.actual_residence = this.childActualResidence;
      this.current_step++;
      this.$emit('nextStep', childId, this.forAdult);
    },
    validateIIN() {
      if (!this.iin) {
        this.errors.iin = true;
        this.error_message = 'Введите ИИН';
        return true;
      }

      if (this.iin.length < 12 || (this.iin.split('_').length > 1)) {
        this.errors.iin = true;
        this.error_message = 'Неправильный ИИН';
        return true;
      }

      return false;
    },
    resetErrors() {
      this.error_message = '';
      this.errors = {
        iin: false,
      };
    },
    async checkIIN() {
      if (this.validateIIN()) return;
      this.resetErrors();

      try {
        this.loading = true;
        const response = await axios.get(`${this.uriCheckChildIin}`, {
          params: {
            iin: this.iin,
          },
        });

        if (response.data.status === 'fail') {
          show_notice(response.data.message, 'error');
        } else if (response.data.status === 'exists') {
          this.modalFullname = response.data.fullName;
          $('#modal-iin-exists').modal('show');
        } else if (response.data.status === 'success') {
          const { data } = response.data;

          this.sur_name = data.surname;
          this.first_name = data.firstname;
          this.middle_name = data.secondname;
          this.sex = data.gender;
          this.birth_date = data.birth_day;
          this.citizenship_id = data.citizenship;
          this.nationality_id = data.nationality;

          this.dataFromIin = true;
        }
      } catch (err) {
        this.dataFromIin = false;
        console.log(err);
        if (err.response && err.response.data.errors) {
          const { errors } = err.response.data;
          for (const key of Object.keys(errors)) {
            errors[key].forEach(error_message => show_notice(error_message, 'error'));
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async getGroups(isReset = true) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetGroups,
        params: { school: this.selected_school },
      });

      if (res.error) {
        this.handleRequestError(res);
      } else {
        if (isReset) {
          this.selected_class = null;
          this.selected_language = null;
        }
        this.group_list = res;
      }
      this.$globalLoading.hide();
    },
    async getDepartments() {
      this.$globalLoading.show();
      this.selectedDepartment = null;
      this.selectedLocality = null;
      this.street = null;
      this.house = null;
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetDepartments,
        params: { region_id: this.selectedRegion },
      });

      if (res.error) {
        this.handleRequestError(res);
      } else {
        this.departments = res;
      }
      this.$globalLoading.hide();
    },
    async getOrganizations() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.organizationsUri,
        params: { street: this.street, house: this.house, locality_id: this.selectedLocality },
      });

      if (res.error) {
        this.handleRequestError(res);
      } else {
        if (this.isAdmissionByAddress) {
          this.organizations = res.data.filter(item => item.isAdmissionByAddress);
          this.organizationsByQuota = res.data.filter(item => !item.isAdmissionByAddress);
        } else {
          this.organizations = res.data;
        }

        this.organizationsOptions = res.data.map(
          itm => ({ id: itm.id, name: itm.organizationName }),
        );
      }
      this.$globalLoading.hide();
    },
    async getLocalities() {
      this.$globalLoading.show();
      this.selectedLocality = null;
      this.street = null;
      this.house = null;
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetLocalities,
        params: { department_id: this.selectedDepartment },
      });

      if (res.error) {
        this.handleRequestError(res);
      } else {
        this.localities = res;
      }
      this.$globalLoading.hide();
    },
    async getStreets() {
      this.$globalLoading.show();
      if (this.isAdmissionByAddress) {
        this.street = null;
        this.selectedStreetOption = null;
        this.house = null;
        const res = await requestWrapper.call(this, {
          method: 'get',
          url: this.uriGetStreets,
          params: { locality_id: this.selectedLocality },
        });

        if (res.error) {
          this.handleRequestError(res);
        } else {
          this.streets = res.items;
        }
      } else {
        await this.getOrganizations();
      }
      this.$globalLoading.hide();
    },
    async getLangs(isReset = true) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetLangs,
        params: { school: this.selected_school, groupName: this.selected_class },
      });

      if (res.error) {
        this.handleRequestError(res);
      } else {
        if (isReset) {
          this.selected_language = null;
        }
        this.language_list = res;
      }
      this.$globalLoading.hide();
    },
    async finishStepFive(e) {
      e.preventDefault();

      try {
        this.loading = true;
        const data = {
          child_id: this.child,
          school_class_name: this.selected_class,
          lang: this.selected_language,
          place_of_residence: this.placeOfResidence,
          is_special_needs: this.isSpecialNeeds,
          street: this.street,
          house: this.house,
        };
        if (this.isService || !this.isBinom) {
          data.actual_residence = this.actual_residence;
        }
        const uri = this.is_new
          ? `${this.uriStatementStore}/${this.selected_school}`
          : `${this.uriStatementStore}`;
        const response = await axios.post(
          uri, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          },
        );

        if (response.data.status === 'fail') {
          show_notice(response.data.message, 'error');
        } else if (response.data.status === 'success') {
          window.location.href = response.data.route;
        }
      } catch (err) {
        console.log(err);
        if (err.response.data.errors) {
          const { errors } = err.response.data;
          for (const key of Object.keys(errors)) {
            errors[key].forEach(error_message => show_notice(error_message, 'error'));
          }
        }
      } finally {
        this.loading = false;
      }
    },
    adultChecked() {
      this.isAddChild = false;
      this.selected_child = null;
    },
    async getChildrenInfos() {
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriChildrenInfos,
      });

      if (res.error) {
        this.handleRequestError(res);
      } else {
        this.childrenInfos = res;
      }
    },
    async handleActualization() {
      this.$globalLoading.show();
      const data = {};
      if (this.selectedChild?.canActualize) {
        data.user_id = this.selectedChild?.value;
      }
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriActualization,
        data,
      });

      if (res.error) {
        this.handleRequestError(res);
      } else {
        await this.getChildrenInfos();
        this.isDisabledActualizationLocal = true;
        this.$notify({
          type: 'success',
          title: window.L.message,
          text: this.trans('notice.basic_saved_changes'),
        });
      }
      this.$globalLoading.hide();
    },
    handleRequestError(res) {
      if (res?.data?.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
    },
    async getHouses() {
      this.house = null;
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetHouses,
        params: {
          street_id: this.street,
          withoutPaginate: true,
        },
      });
      if (res.error) {
        this.handleRequestError(res);
      } else {
        this.houses = res;
        this.$globalLoading.hide();
      }
    },
    handleGoBackOrgs() {
      this.current_step = this.current_step - 1;
      this.child = null;
    },
    handleGoBack() {
      this.current_step = this.current_step - 1;
      this.selected_class = null;
      this.selected_language = null;
      this.selected_school = null;
    },
  },
};
