import CardMixin from '../CardMixin';

export default {
  mixins: [CardMixin],

  data() {
    return {
      fieldList: [
        ['disability_status', 'from'],
        ['relation', 'group'],
        ['cert_number', 'cert_series'],
        ['to'],
      ],
    };
  },

  methods: {
    getFieldGroup(fieldName) {
      const selects = ['disability_status', 'relation', 'group'];
      if (selects.includes(fieldName)) return 'select';

      const inputs = ['cert_number', 'cert_series'];
      if (inputs.includes(fieldName)) return 'input';

      if (['from', 'to'].includes(fieldName)) return 'date';

      return null;
    },
    handleIf(field) {
      if (field !== 'disability_status') {
        return this.fields.disability_status === 'is';
      }
      return true;
    },
  },
};
