import axios from 'axios';
import eventHub from '@vjs/config/eventHub';

async function api(
  url = '',
  body = {},
  success = () => {},
  headers = {},
  reject = () => {},
  method = 'post',
) {
  try {
    eventHub.$emit('ajax-sending', true);
    // console.log(body)
    const res = await axios({
      method,
      url,
      headers,
      [method === 'get' ? 'params' : 'data']: body,
    });

    if (typeof success === 'function') {
      success(res);
    }

    const { data } = res;

    if (data && data.redirect) {
      window.location.href = data.redirect;
    }
  } catch (err) {
    if (typeof reject === 'function') {
      reject(err);
    }
    console.error(err.response && err.response);
  } finally {
    eventHub.$emit('ajax-sending', false);
  }
}

export default api;
