import Pagination
from '@app_mektep/components/Legacy/components/pagination/main.vue';
import Regions
from '@app_mektep/components/Legacy/components/regions/regions.vue';
import SalaryCalc from './components/SalaryCalc/SalaryCalc.vue';
import Sel2 from './components/Sel2/Sel2.vue';
import ApplyStatementFormComponent
from './components/AdditionalEducation/ApplyStatementFormComponent.vue';
import Stepper from './components/Stepper/Stepper.vue';
import StatementParentForm
from './components/StatementParentForm/StatementParentForm.vue';
import StatementChildForm
from './components/StatementChildForm/StatementChildForm.vue';
import StatementDocsForm
from './components/StatementDocsForm/StatementDocsForm.vue';
import ServiceAdoptionInfoStep
from './components/StatementServiceSteps/ServiceAdoptionInfoStep.vue';
import StatementServiceSteps
from './components/StatementServiceSteps/StatementServiceSteps.vue';

import TrainingScheduleForm from './components/TrainingSchedule/Form';
import TrainingScheduleShow from './components/TrainingSchedule/Show';
import ManageSubclass from './components/ManageSubclass';

import SelectOrganizationForm from './components/Views/SelectOrganization/Form';
import SelectRoleForm from './components/Views/SelectRole/Form';
import LoadingForm from './components/Views/Loading/Form';

export default function (Vue) {
  Vue.component('SalaryCalc', SalaryCalc);
  Vue.component('Sel2', Sel2);
  Vue.component('apply-statement-form-component', ApplyStatementFormComponent);
  Vue.component('vue-training-schedule-form', TrainingScheduleForm);
  Vue.component('vue-training-schedule-show', TrainingScheduleShow);
  Vue.component('vue-managesubclass', ManageSubclass);
  Vue.component('vue-loading-form', LoadingForm);
  Vue.component('vue-select-organization-form', SelectOrganizationForm);
  Vue.component('vue-select-role-form', SelectRoleForm);

  Vue.component('Stepper', Stepper);
  Vue.component('statement-parent-form', StatementParentForm);
  Vue.component('statement-child-form', StatementChildForm);
  Vue.component('statement-docs-form', StatementDocsForm);

  Vue.component('Stepper', Stepper);
  Vue.component('statement-parent-form', StatementParentForm);
  Vue.component('statement-service-steps', StatementServiceSteps);
  Vue.component('service-adoption-info-step', ServiceAdoptionInfoStep);

  Vue.component('pagination', Pagination);
  Vue.component('regions', Regions);
}
