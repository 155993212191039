import Vue from 'vue';

import SizeMixin from '../../mixins/size';
import QIcon from '../icon/QIcon';
import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QAvatar',

  mixins: [SizeMixin],

  props: {
    fontSize: String,

    color: String,
    textColor: String,

    icon: String,
    square: Boolean,
    rounded: Boolean,
  },

  computed: {
    contentClass() {
      return {
        [`s-b-${this.color}`]: this.color,
        [`s-c-${this.textColor} sn-chip--colored`]: this.textColor,
        'sn-avatar__content--square': this.square,
        'sn--rounded-borders': this.rounded,
      };
    },

    contentStyle() {
      if (this.fontSize) {
        return { fontSize: this.fontSize };
      }
    },
  },

  methods: {
    __getContent(h) {
      return this.icon !== void 0
        ? [h(QIcon, { props: { name: this.icon } })].concat(slot(this, 'default'))
        : slot(this, 'default');
    },
  },

  render(h) {
    return h('div', {
      staticClass: 'sn-avatar',
      style: this.sizeStyle,
      on: this.$listeners,
    }, [
      h('div', {
        staticClass: 'sn-avatar__content sn--row sn--flex-center sn--overflow-hidden',
        class: this.contentClass,
        style: this.contentStyle,
      }, [
        this.__getContent(h),
      ]),
    ]);
  },
});
