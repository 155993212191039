import {
  QDialog,
  QSpinner,
} from '@quasar/components';
import { convertToRem } from '@vjs/helpers/text';

export default {
  name: 'VPhotoAlbumFullscreenModal',

  components: {
    QDialog,
    QSpinner,
  },

  props: {
    modal: Boolean,
    curPic: {
      type: Object,
      required: true,
    },
    oldPic: {
      type: Object,
      required: true,
    },
    changingPic: Boolean,
    withDirections: Boolean,
  },

  computed: {
    picSize() {
      let pic = this.curPic;

      if (this.curPic.imgLoading) {
        pic = this.oldPic;
      }

      return {
        width: convertToRem(pic.vWidth),
        height: convertToRem(pic.vHeight),
      };
    },
  },

  methods: {
    convertToRem,
    handleModal() {
      this.$emit('modal-close', false);
    },
  },
};
