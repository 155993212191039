import { Form } from '@common/mixins';
import { requestWrapper } from '@vjs/helpers';
import _ from 'lodash';

export default {
  mixins: [Form],
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        withdrawReason: {
          labelTransKey: 'label.withdraw_reason',
          value: null,
          name: 'withdraw_reason',
          component: 'textarea-field',
          required: true,
        },
      },
    };
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const data = {};

      _.forEach(this.fields, (field) => {
        data[field.name] = field.value;
      });

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data,
      });

      if (res.error && res.errorType !== 302) {
        this.$globalLoading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
      }
    },
  },
};
