import { StoreTranslator } from '@common/mixins';
import _ from 'lodash';
import { Helper } from '@common/src/helpers';
import uuidv4 from 'uuid/v4';
import { Upload as TusUpload } from 'tus-js-client';
import VFormInform from '@vjs/components/VFormInform';

export default {
  mixins: [StoreTranslator],
  components: { VFormInform },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    urlFiles: {
      type: String,
      required: true,
    },
    urlFilesStore: {
      type: String,
      required: false,
      default: '',
    },
    maxLoadFiles: {
      type: Number,
      required: false,
      default: 1,
    },
    fileConfig: {
      type: Object,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      processSend: false,
      files: [],
      selectedFiles: [],
      defaultHumaneTypes: [
        'pdf', 'png', 'bmp', 'jpeg', 'jpg', 'zip', 'doc', 'docx',
      ],
      defaultMimeTypes: [
        'image/jpeg', 'image/webp', 'image/svg+xml', 'image/gif', 'image/png', 'image/bmp', 'image/x-ms-bmp',
        'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'text/plain',
      ],
    };
  },
  beforeCreate() {
    this.$trans.add([
      'main',
      'validation',
    ]);
  },
  mounted() {
    window.addEventListener('keyup', (ev) => {
      if (ev.key === 'Escape') {
        this.modalClose();
      }
      return true;
    });
    window.addEventListener('keyup', (ev) => {
      if (ev.key === 'Enter') {
        this.onSaveSelect();
      }
      return true;
    });
  },
  destroyed() {
  },
  watch: {
    async openModal(newValue) {
      const vm = this;
      if (newValue) {
        this.selectedFiles = _.cloneDeep(this.fileConfig?.value ?? []);
        $('#modal-user-files').modal('show');
      } else {
        $('#modal-user-files').modal('hide');
      }

      if (!this.files.length) {
        await vm.loadFiles();
      }
    },
  },

  methods: {
    isFileSelected(file) {
      return !!_.find(this.selectedFiles, loopFile => loopFile.id === file.id);
    },
    async loadFiles() {
      const vm = this;
      vm.$globalLoading.show();
      const config = {
        responseType: 'json',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      await vm.$http.get(vm.urlFiles, config).then(
        (response) => {
          vm.$set(vm, 'processSend', false);
          vm.files = _.cloneDeep(response.data);
        },
        (response) => {
          vm.$set(vm, 'processSend', false);

          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
      vm.$globalLoading.hide();
    },
    modalClose() {
      this.$emit('select-close');
      this.selectedFiles = [];
    },
    onDblclickSelectFile(index) {
      this.onChangeSelectFile(index);
      this.onSaveSelect();
    },
    onChangeSelectFile(index) {
      const file = this.files[index];

      if (this.isFileSelected(file)) {
        this.files[index].selected = false;
        this.selectedFiles.forEach((selectedFile, key) => {
          if (selectedFile.id === file.id) {
            this.selectedFiles.splice(key, 1);
          }
        });
      } else if (this.selectedFiles.length < this.maxLoadFiles) {
        this.files[index].selected = true;
        this.selectedFiles.push(file);
      }
    },
    onSaveSelect() {
      this.$emit('input', this.selectedFiles);
      this.modalClose();
    },
    onClickInputFile() {
      const el = document.querySelector('#user-file-upload-input');
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onChangeInputFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      e.target.value = null;
      this.upload(file);
    },
    async upload(file) {
      await this.tusUpload(file);
    },

    async tusUpload(file) {
      const vm = this;
      vm.$globalLoading.show();
      const size = 1024 * 1024 * 4;
      if (!this.getFileMimes().includes(file.type)) {
        vm.$globalLoading.hide();
        Helper.showNotice(
          this.trans('validation.mimes',
            {
              attribute: this.fileConfig.name,
              values: this.getFileTypes().join(', '),
            }),
        );

        return;
      }
      if (typeof size !== 'undefined' && file.size > size) {
        vm.$globalLoading.hide();
        Helper.showNotice(this.trans('validation.max.file_mb', { max: size / 1024 / 1024 }));
        return;
      }
      const extension = Helper.getFileExtension(file.name);
      const metaData = {
        full_name: Helper.base64Encode(file.name),
        filename: `${uuidv4()}.${extension}`,
        origin_name: file.name,
      };
      if (file.type) {
        metaData.filetype = file.type;
      }
      const upload = new TusUpload(file, {
        endpoint: '/tus/',
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: metaData,
        onError() {
          vm.$globalLoading.hide();
        },
        onProgress(bytesUploaded, bytesTotal) {
          // let percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
        },
        onSuccess(xhr) {
          if (vm.processSend === true) {
            return false;
          }
          vm.$set(vm, 'processSend', true);
          const formData = new FormData();

          const uuid = xhr.getHeader('X-File-UUID');
          if (!uuid) {
            if (_.find(vm.files, { uuid })) {
              return vm.showFileExistedNotice();
            }
            return vm.showFileExistedNotice();
          }

          formData.uuid = uuid;
          formData.name = upload.file.name;

          const config = {
            responseType: 'json',
            headers: {
              'X-CSRF-TOKEN': window.core_project.csrfToken,
              'Accept-Language': window.core_project.locale,
            },
          };
          vm.$http.post(vm.urlFilesStore, { file: formData }, config).then(
            (response) => {
              vm.$globalLoading.hide();
              vm.$set(vm, 'processSend', false);
              const localFiles = _.cloneDeep(vm.files);
              const isFileExists = localFiles.find(file => file.id === response.data.id);
              if (!isFileExists) {
                localFiles.unshift(response.data);
              } else {
                vm.$notify({
                  text: vm.trans('notice.file_already_exists'),
                  type: 'warn',
                });
              }
              vm.$set(vm, 'files', localFiles);
            },
            (response) => {
              vm.$globalLoading.hide();
              vm.$set(vm, 'processSend', false);

              Helper.handlerResponseError(response);
            },
          );
        },
      });

      upload.start();
    },
    getFileTypes() {
      return _.get(this.fileConfig, 'allowed_types', this.defaultHumaneTypes);
    },

    getFileMimes() {
      return _.get(this.fileConfig, 'allowed_mimes', this.defaultMimeTypes);
    },
    getFileAccepts() {
      // По mimes не работает корректно, на всякий сделал и тип и mime
      return this.getFileTypes().map(fileType => `.${fileType}`).concat(this.getFileMimes());
    },
  },
};
