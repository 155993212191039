import { FormField, StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator, FormField],
  props: {},
  data() {
    return {
      config: this.field.configComponent,
      fromValue: this.field.configComponent.fieldFrom.value,
      toValue: this.field.configComponent.fieldTo.value,
    };
  },
  mounted() {
    // Add a default fill method for the field
    this.field.fill = this.fill;
    this.field.clearValue = this.clearValue;
    this.field.hasValue = this.hasValue;
  },
  computed: {},
  methods: {
    fill(body) {
      _.set(body, this.nameToDot(this.config.fieldFrom.name), this.fromValue);
      _.set(body, this.nameToDot(this.config.fieldTo.name), this.toValue);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
    clearValue() {
      this.fromValue = null;
      this.toValue = null;
    },
    hasValue() {
      if (this.fromValue || this.toValue) {
        return true;
      }
      return false;
    },
  },
  watch: {
    fromValue(value) {
      // update value
      this.field.configComponent.fieldFrom.value = value;
    },
    toValue(value) {
      // update value
      this.field.configComponent.fieldTo.value = value;
    },
  },
};
