import moment from 'moment';
import { eventBus } from '@common/mixins';

export default {
  name: 'VMeetingsCalendarHeader',
  props: {
    stateOptions: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => {
    moment.locale(window.core_project.locale === 'kz' ? 'kk' : 'ru');
    return {
      dateMonth: null,
      state: null,
      currentWeekDay: _.upperFirst(moment().format('dddd')),
      currentDate: moment().format('DD MMMM YYYY'),
    };
  },
  watch: {
    dateMonth() {
      this.emitInitCalendar();
    },
  },
  methods: {
    emitInitCalendar() {
      const parsedDate = moment(this.dateMonth, 'MM YYYY');
      const data = {
        year: parsedDate.year(),
        month: parsedDate.month(),
        state: this.state,
      };
      eventBus.$emit('initCalendar', data);
    },
  },
};
