import { eventBus, FormField, StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator, FormField],
  data() {
    return {
      processSend: false,
    };
  },
  methods: {
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
    onClickSearch() {
      const vm = this;
      const newVal = _.replace(vm.localValue, new RegExp('_', 'g'), '');
      if (newVal.length === 12) {
        vm.$set(vm, 'processSend', true);
        this.$emit('on-search', newVal);
      }
    },
  },
  created() {
    eventBus.$on('all-change-loader', (value) => {
      this.$set(this, 'processSend', value);
    });
  },
  computed: {
    defaultAttributes() {
      return {
        type: this.field.type || 'text',
        placeholder: this.field.placeholder,
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
