import Vue from 'vue';
import VFeedbackForm from '@vjs/views/VFeedbackForm';
import store from '@vjs/store';

if (document.getElementById('v-feedback-app')) {
  window.vFeedbackApp = new Vue({
    el: '#v-feedback-app',
    components: { VFeedbackForm },
    store,
  });
}
