export default {
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  methods: {
    changePage(link) {
      this.$emit('pageUpdated', link);
    },
  },
};
