export default {
  props: {
    staticUrl: {
      type: String,
      default: '',
    },
    uriLocality: {
      type: String,
      default: '',
    },
    uriStatistic: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      currentPage: 1,
      countByPage: 10,
      items: [],
      itemsFiltered: [],
      statisticItems: [],
    };
  },
  computed: {
    // Мониторит список на пустоту
    empty() {
      return this.itemsFiltered.length === 0 && !this.loading;
    },
  },
};
