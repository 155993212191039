import _ from 'lodash';

export default {
  state: {
    ru: {},
    kz: {},
  },
  mutations: {
    setLang(state, payload = {}) {
      state[window.core_project.locale] = {
        ...state[window.core_project.locale],
        ...payload,
      };
    },
  },
  actions: {
    setLang({ commit }, payload = {}) {
      commit('setLang', payload);
    },
  },
  getters: {
    trans: state => (str = '', attrs = {}) => {
      let strTrans = _.get(state[window.core_project.locale], str);

      if (strTrans !== undefined && typeof strTrans === 'string') {
        if (_.isObject(attrs)) {
          _.forEach(attrs, (value, key) => {
            strTrans = strTrans.replace(`:${key}`, value);
          });
          return strTrans;
        }
      }

      return '';
    },
  },
};
