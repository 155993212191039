const getTrue = () => true;

export default {
  __history: [],
  add: () => {},
  remove: () => {},

  install() {
    this.add = (entry) => {
      if (entry.condition === void 0) {
        entry.condition = getTrue;
      }
      this.__history.push(entry);
    };
    this.remove = (entry) => {
      const index = this.__history.indexOf(entry);
      if (index >= 0) {
        this.__history.splice(index, 1);
      }
    };

    document.addEventListener('deviceready', () => {
      document.addEventListener('backbutton', () => {
        if (this.__history.length) {
          const entry = this.__history[this.__history.length - 1];

          if (entry.condition() === true) {
            this.__history.pop();
            entry.handler();
          }
        } else {
          window.history.back();
        }
      }, false);
    });
  },
};
