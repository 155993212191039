import { Helper } from '@common/src/helpers';
import VInfoCard from '@common/components/VInfoCard';
import VTwoFieldWrapper from '@common/components/VTwoFieldWrapper';
import { QIcon } from '@quasar/components/icon';

export default {
  components: { VInfoCard, VTwoFieldWrapper, QIcon },
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    refs: {
      type: Object,
      default: () => ({}),
    },
    spoiler: false,
    disabled: false,
  },
  data() {
    return {
      fields: {},
    };
  },

  computed: {
    actions() { return this.card?.actions ?? []; },
  },

  methods: {
    handleSpoiler() {
      eventBus.$emit('handleSpoiler', this.card.key);
    },
    initFields() {
      this.$set(this, 'fields', _.cloneDeep(this.card?.data ?? {}));
    },
    getComponentName(fieldName) {
      if (!_.isString(fieldName) && _.isArray(fieldName) && fieldName.length === 2) return 'two-field';

      const fieldGroup = this.getFieldGroup(fieldName);
      switch (fieldGroup) {
        case 'select': return 'v-wrapper-q-select';
        case 'input': return 'q-input';
        case 'date': return 'v-wrapper-q-date';
        default: return null;
      }
    },
    getFieldComponentBinds(fieldName) {
      const fieldGroup = this.getFieldGroup(fieldName);

      const binds = {
        extraBinds: { },
        label: this.trans(`personnel.blocks.fields.${fieldName}`),
        disable: this.disabled,
      };
      binds.extraBinds.label = binds.label;
      binds.extraBinds.disable = binds.disable;

      switch (fieldGroup) {
        case 'select':
          binds.options = this.refs[fieldName] ?? [];
          break;
        case 'input':
          binds.placeholder = this.trans('placeholder.input_placeholder');
          break;
        default:
          break;
      }

      return binds;
    },
    async handleSave() {
      this.$globalLoading.show();

      const data = {};
      _.forEach(this.fields, (itm, key) => {
        if (key !== 'items' && (!this.handleIf || this.handleIf(key))) {
          data[key] = itm;
        }
      });

      const action = this.actions.find(itm => itm.action === 'submit');
      const res = await requestWrapper.call(this, {
        ...action,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        if (!_.isEqual(val, oldVal)) {
          this.initFields();
        }
      },
    },
  },
};
