import VFormInform from '@vjs/components/VFormInform';
import CalendarHeader from './Components/CalendarHeader';
import CalendarTable from './Components/CalendarTable';

export default {
  name: 'VMeetingsCalendar',
  components: {
    VFormInform,
    CalendarHeader,
    CalendarTable,
  },
  props: {
    uriGetData: String,
    stateOptions: Array,
  },
  data: () => ({}),
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'warning_text',
      'table',
    ]);
  },
};
