import VClubsItem from '@app_mektep/components/Views/VClubsItem';
import MXListView from '@vjs/mixins/MXListView';

export default {
  name: 'VClubsListContainerView',
  mixins: [MXListView],
  components: { VClubsItem },
  props: {
    clubs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({ countByPage: 8 }),
  computed: {
    paginate() {
      const current = (this.currentPage - 1) * this.countByPage;
      return this.clubs.slice(0, current + this.countByPage);
    },
    countOfPages() {
      const count = Math.ceil(this.clubs.length / this.countByPage);
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    onShowMore() { this.currentPage = this.currentPage + 1; },
  },
};
