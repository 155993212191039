
export default {
  props: {
    urlBack: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    model: {
      type: [Array, Object],
      required: false,
    },
    uriRefs: {
      type: String,
      required: true,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      parallel: {
        value: null,
        name: 'parallel',
        component: 'v-multi-select-field',
        labelTransKey: 'label.parallel',
        values: [],
        required: true,
        v_if: this.isTemplate,
      },
      group: {
        value: null,
        name: 'group_id',
        component: 'v-multi-select-field',
        labelTransKey: 'label.class',
        values: [],
        required: true,
        v_if: !this.isTemplate,
      },
      quarter: {
        value: '',
        name: 'quarter',
        component: 'text-field',
        labelTransKey: 'label.quarter',
        required: true,
      },
      education_language: {
        value: null,
        name: 'education_language',
        component: 'v-multi-select-field',
        labelTransKey: 'label.edu_lang',
        values: [],
        required: true,
        v_if: this.isTemplate,
      },
      date_quarter_start: {
        value: '',
        name: 'date_quarter_start',
        component: 'date-field',
        labelTransKey: 'label.date_quarter_start',
        required: true,
        v_if: this.isTemplate,
      },
      date_quarter_end: {
        value: '',
        name: 'date_quarter_end',
        component: 'date-field',
        labelTransKey: 'label.date_quarter_end',
        required: true,
        v_if: this.isTemplate,
      },
    };
    if (vm.model !== undefined) {
      _.forEach(vm.model, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
    }
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'menu', 'training_schedule']);
  },
  mounted() {
    const vm = this;
    vm.loadRefs();
  },

  destroyed() {
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    loadRefs() {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'X-CSRF-TOKEN': window.core_project.csrfToken,
        },
        params: {
          isTemplate: Number(this.isTemplate),
        },
      };

      vm.$http.get(vm.uriRefs, config)
        .then((response) => {
          const { data } = response;
          vm.$set(vm.form.fields.parallel, 'values', data.parallels);
          vm.$set(vm.form.fields.education_language, 'values', data.education_languages);
          vm.$set(vm.form.fields.group, 'values', data.groups);
        });
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
  watch: {
    'form.fields.type.value': function (newValue, oldValue) {
      if (newValue == 'for_individual') {
        this.form.fields.group_id.v_if = false;
        this.form.fields.name.v_if = true;
      } else {
        this.form.fields.group_id.v_if = true;
        this.form.fields.name.v_if = false;
      }
    },
  },
};
