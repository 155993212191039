import FunctionalComponentOption from './FunctionalComponentField';

export default new FunctionalComponentOption();
//
// let obj={
//   functional: true,
//   props: {
//     field: {
//       type: Object,
//       required: true
//     },
//     option: {}
//   },
//
//   render(h, context) {
//     function supplant(template, o) {
//       return template.replace(/{([^{}]*)}/g,
//         function (a, b) {
//           if (_.get(o, b)) {
//             let r = _.get(o, b);
//             return typeof r === 'string' || typeof r === 'number' ? r : a;
//           }
//           return ''
//         }
//       );
//     }
//
//     if (context.props.field.hasOwnProperty('renderOption')) {
//       return context.props.field.renderOption(h, context.props.option);
//     }
//     if (context.props.field.hasOwnProperty('templateOption')) {
//       return h('div',
//         {
//           domProps: {
//             innerHTML: supplant(context.props.field.templateOption, context.props.option)
//           }
//         });
//     }
//     return false;
//   },
//   methods: {}
// }
// export default obj
