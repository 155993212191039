import {
  QBtn,
  QPopupEdit,
  QInput,
  QIcon,
  QSelect,
  QItem,
  QItemSection,
  QForm,
  QRadio,
  QField,
  QCheckbox,
} from '@quasar/components';
import { required } from '@vjs/validations';
import { requestWrapper } from '@vjs/helpers';
import Validation from '@vjs/mixins/Validation';
import { Helper } from '@common/src/helpers';

const KZ_CITIZENSHIP_ID = 126;

export default {
  mixins: [Validation],
  components: {
    QBtn,
    QInput,
    QIcon,
    QSelect,
    QPopupEdit,
    QItem,
    QItemSection,
    QForm,
    QRadio,
    QField,
    QCheckbox,
  },
  props: {
    checkIinUrl: {
      type: String,
      default: '',
    },
    getRefsUrl: {
      type: String,
      default: '',
    },
    storeUrl: {
      type: String,
      default: '',
    },
    isLibrary: {
      type: Boolean,
      default: false,
    },
  },
  beforeCreate() {
    this.$trans.add([
      'placeholder',
      'label',
      'button',
      'notice',
      'validation',
    ]);
  },
  data: () => ({
    isValidIin: false,
    iin: '',
    iinChecked: true,
    userData: {},
    refs: {},
    groupsFiltered: [],
    citizenship: null,
    migrationStatus: null,
    nationality: null,
    eduLang: null,
    showGroups: false,
    group: null,
    sex: '',
    address: '',
    phone: '',
    noResident: false,
  }),
  watch: {
    eduLang(val) {
      if (val) {
        this.group = null;
        this.showGroups = true;
        this.groupsFiltered = this.refs.groups.filter(
          item => item.edu_lang === val,
        );
      } else {
        this.group = null;
        this.showGroups = false;
      }
    },
    iin() {
      if (this.iin.length === 12) {
        this.$set(this, 'iinChecked', true);
      }
    },
  },
  methods: {
    required: message => val => required(message)(val),
    async sendIin() {
      if (this.iin.length === 12 || (this.noResident && this.iin.length > 3)) {
        try {
          const { data } = await this.$axios.post(this.checkIinUrl, {
            iin: this.iin,
            noResident: this.noResident,
          });
          this.getRefs();
          this.$set(this, 'userData', data);
          this.$set(this, 'isValidIin', true);
          this.$set(this, 'sex', this.userData.sex);
          if (this.noResident) {
            this.$set(this, 'address', '');
          } else {
            this.$set(this, 'address',
              `${this.userData.street}, ${this.userData.building}`
              + `${this.userData.flat ? `, ${this.userData.flat}` : ''}`);
            this.citizenship = KZ_CITIZENSHIP_ID;
          }
        } catch (error) {
          this.$notify({
            text: error.response.data.message,
            type: 'error',
          });
        }
      } else {
        this.$set(this, 'iinChecked', false);
      }
    },
    async getRefs() {
      try {
        const { data } = await this.$axios.get(this.getRefsUrl);
        this.$set(this, 'refs', data.data);
      } catch (error) {
        this.$notify({
          text: this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
    },
    async saveContingent() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.storeUrl,
        data: {
          contingent: {
            citizenship_id: this.citizenship,
            nationality_id: this.nationality,
            migration_status_id: this.migrationStatus,
            edu_language: this.eduLang,
            group_id: this.group,
          },
          user: {
            surname: this.userData.surname,
            firstname: this.userData.firstname,
            lastname: this.userData.lastname,
            born_date: this.userData.born_date,
            sex: this.sex,
            iin: this.iin,
            phone: this.phone,
            address: this.address,
          },
          no_resident: this.noResident,
          is_library: this.isLibrary,
        },
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
    reset() {
      this.$set(this, 'iinChecked', true);
    },
  },
};
