import Vue from 'vue';
import StoreTranslator from './StoreTranslator';
import Form from './Form';
import FormField from './FormField';
import SelectUserFileHandler from './SelectUserFileHandler';
import Files from './Files';
import NumberFormatter from './NumberFormatter';

const eventBus = new Vue();
export {
  StoreTranslator,
  SelectUserFileHandler,
  Form,
  FormField,
  NumberFormatter,
  eventBus,
  Files,
};
