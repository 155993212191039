<template>
  <section
    class="sm-main-page__section"
    :class="{ 'sm-main-page__section--alt': isAlt }"
  >
    <div class="sm-main-page__section--head">
      <div class="sm-main-page__section--head-image">
        <img :src="`${uriStatic}/mektep/images/main-page/${name}${!isAlt ? '-alt' : ''}.png`">
      </div>
      <div class="sm-main-page__section--title-wrapper">
        <div
          class="sm-main-page__section--title"
          v-html="trans(`main.section_${name}_title`)"
        />
        <div
          class="sm-main-page__section--subtitle"
          v-html="trans(`main.section_${name}_subtitle`)"
        />
        <div
          v-if="link && !features.length"
          class="sm-main-page__section--button"
        >
          <a :href="link">
            <span class="s-str-ico-fa-rev s-str-ico-fa--thm-arrow-right">
              {{ trans('main.go_to') }}
            </span>
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="!link && features.length"
      class="sm-main-page__section--features-wrapper"
    >
      <a
        v-for="feature in features"
        :key="feature.type"
        class="sm-main-page__section--feature"
        :href="feature.url"
      >
        <q-icon
          icon-class="s-ico-fa"
          name="circle"
          size="8px"
        />
        <span class="s-str-ico-fa-rev s-str-ico-fa--thm-arrow-right">
          {{ trans(`label.feature_blocks.${feature.type}-title`) }}
        </span>
      </a>
    </div>
  </section>
</template>
<script>
import { QIcon } from '@quasar/components';

export default {
  name: 'Section',
  components: { QIcon },
  props: {
    name: { type: String, required: true },
    uriStatic: { type: String, required: true },
    link: { type: String, default: '' },
    isAlt: { type: Boolean, required: true },
    features: { type: Array, default: () => [] },
  },
};

</script>
