import {
  QInput,
  QBtn,
  QPagination,
} from '@quasar/components';
import { genArray } from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import MXListView from '@vjs/mixins/MXListView';
import VStatisticPanel from '@vjs/components/VStatisticPanel';
import VClubsItem from '@app_mektep/components/Views/VClubsItem';
import VClubsFilters from '@app_mektep/components/Views/VClubsFilters';

export default {
  name: 'VClubsListView',
  mixins: [MXListView],
  components: {
    QInput,
    QBtn,
    QPagination,
    VStatisticPanel,
    VClubsItem,
    VClubsFilters,
    VNoResults,
  },
  props: {
    uriGetFilters: {
      type: String,
      required: true,
    },
    uriGetList: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchValue: '',
      debounceTimeout: null,
    };
  },
  beforeCreate() {
    this.$trans.add(['additional_education']);
  },
  watch: {
    // Сбрасывает отфильтрованный список при удалении строки в поиске
    searchValue(val) {
      if (val === '') {
        this.itemsFiltered = this.items;
      }
    },
  },
  computed: {
    // Отдаёт кол-во очереди на страницу
    paginate() {
      if (this.loading) {
        return genArray({
          id: 1,
          age_from: 0,
          age_to: 0,
          img_src: '',
          week_days: '',
          cost: '',
          name: '',
          school_name: '',
          category: '',
          lead_fullname: '',
          free_places: 0,
          link: '',
        }, 6);
      }

      const current = (this.currentPage - 1) * this.countByPage;
      return this.itemsFiltered.slice(current, current + this.countByPage);
    },
    // Считает кол-во страниц в пагинации по выставленным в data параметрам
    countOfPages() {
      const count = Math.ceil(this.itemsFiltered.length / this.countByPage);
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    // Для обработки реактивного поиска
    debounceSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(
        this.handleSearch,
        300,
      );
    },
    // Обычный поиск по запросу
    handleSearch() {
      if (this.searchValue !== '') {
        const searchStr = this.searchValue.toLowerCase();
        this.itemsFiltered = this.items.filter((item) => {
          const name = item.name || '';
          const schoolName = item.school_name || '';
          return name.toLowerCase().indexOf(searchStr) !== -1
            || schoolName.toLowerCase().indexOf(searchStr) !== -1;
        });
      } else {
        this.itemsFiltered = this.items;
      }
    },
    // Принимает и обрабатывает список от фильтра
    handleFilteredList(arr) {
      this.itemsFiltered = this.items = arr.items;
      this.statisticItems = arr.statistics;
      this.searchValue = '';
    },
  },
};
