<template>
  <div>
    <v-form-inform
      v-if="configs[activeTab].informText"
      :message="configs[activeTab].informText"
    />
    <div
      v-if="configs.length > 1"
      class="s-group mb-20"
    >
      <q-btn
        v-for="(config, key) in configs"
        :key="key"
        :label="config.tabName"
        :color="activeTab === key ? 'blue' : 'grey'"
        no-caps
        @click="activeTab = key"
      />
    </div>
    <vue-new-table-with-filters
      :uri-get-items="configs[activeTab].uriGetItems"
      :uri-get-filters="configs[activeTab].uriGetFilters"
      :storage-name="configs[activeTab].storageName"
      :no-result-title="configs[activeTab].noResultTitle"
      without-auto-render-filters
      use-custom-event-bus
      :two-filter-at-row="!!configs[activeTab].twoFilterAtRow"
      :selectable-rows="configs[activeTab].selectableRows"
    />
    <div class="row-sort-end">
      <q-btn
        v-if="configs[activeTab].actionConfig?.action"
        class="s-btn--w-150 mr-15"
        color="green"
        :label="configs[activeTab].actionConfig?.label"
        @click="send"
      />
      <q-btn
        class="s-btn--w-150"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    configs: {
      type: Array,
      required: true,
    },
    closeId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checkedRowsIds: [],
      activeTab: 0,
    };
  },
  mounted() {
    if (this.closeId) {
      this.$sModal.close(this.closeId);
    }
    eventBus.$on('table-checked-rows-change', (event) => { this.checkedRowsIds = event; });
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.configs[this.activeTab].actionConfig.action,
        data: { ids: this.checkedRowsIds },
      });

      if (res.error && res.errorType !== 302) {
        this.$globalLoading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
      }
    },
  },
};
</script>
