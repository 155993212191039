import { requestWrapper } from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import _ from 'lodash';

export default {
  name: 'VStepStatement',

  props: {
    type: {
      type: Number,
      required: true,
    },
    uriAction: {
      type: String,
      required: true,
    },
  },

  created() {
    this.$store.commit('SET_ACCESS', true);
    eventHub.$on('SET_NEXT_STEP', this.openConfirmModal);
  },

  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.openConfirmModal);
  },

  computed: {
    selectedChild() {
      return this.$store.getters.getSelectedChild;
    },
  },
  methods: {
    openConfirmModal() {
      this.$sModal.open('v-modal-confirm', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        events: { onApprove: this.requestForm },
      });
    },
    async requestForm() {
      this.$emit('loading', true);
      // eslint-disable-next-line camelcase
      const { child_id } = this.$store.state.vSteps;
      const resData = {
        child_id,
        type: this.type,
      };
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAction,
        data: resData,
      });


      if (res.errorType === 302) {
        return;
      }

      if (res.error) {
        if (res?.data?.errors) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
      } else {
        this.$emit('setUriStatement', res.uriStatement);
        this.$sModal.closeAll();
        this.$store.commit('SET_NEXT_STEP');
      }
      this.$emit('loading', false);
    },
  },
};
