import { Form } from '@common/mixins';
import _ from 'lodash';

export default {
  mixins: [Form],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      default: 'post',
    },
    reasons: {
      type: Array,
      default: () => {},
    },
    messageTriggerValue: {
      type: String,
      default: 'post',
    },
  },
  data() {
    return {
      fields: {
        reason: {
          labelTransKey: 'label.select_unsign_reason',
          value: null,
          values: this.reasons,
          name: 'reason',
          component: 'q-select-field',
          required: true,
          events: {
            change: () => {
              if (this.fields.reason.value === this.messageTriggerValue) {
                this.fields.message.v_if = true;
              } else {
                this.fields.message.v_if = false;
              }
            },
          },
        },
        message: {
          labelTransKey: 'label.message',
          value: null,
          name: 'message',
          component: 'q-text-field',
          required: true,
          v_if: false,
        },
      },
    };
  },
  methods: {
    async send() {
      this.$globalLoading.show();
      const data = {};

      _.forEach(this.fields, (field) => {
        data[field.name] = field.value;
      });

      const res = await requestWrapper.call(this, {
        method: this.method,
        url: this.action,
        data,
      });

      if (res.error && res.errorType !== 302) {
        this.$globalLoading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
      }
    },
  },
};
