import { requestWrapper } from '@vjs/helpers';
import { QBtn, QCheckbox } from '@quasar/components';
import VPhotoAlbum from '@common/components/VPhotoAlbum';
import VPieChart from '@vjs/components/VPieChart';
import { Helper } from '@common/src/helpers';

export default {
  components: {
    QBtn,
    QCheckbox,
    VPhotoAlbum,
    VPieChart,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    uriSaveData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEdit: false,
      name: '',
      fields: [],
      // statistics: {
      //   rows: [],
      //   pie: {},
      // },
      // description: '',
      // orgType: null,
      // images: [],
    };
  },
  async mounted() {
    await this.$trans.add(['organization']);
    await this.requestGetData();
  },
  methods: {
    async requestAction() {
      this.$globalLoading.show();
      const data = {};
      _.forEach(this.fields, (itm) => {
        data[itm.type] = itm.value;
      });

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSaveData,
        data: { fields: data },
      });
      if (res.error) {
        Helper.handlerResponseError({ status: res.errorType, data: res.data });
      }
      this.$globalLoading.hide();
    },

    async requestGetData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
      });

      if (!res.error) {
        this.name = res.data.name;
        this.fields = res.data.fields;
      }
      this.$globalLoading.hide();
    },
  },
};
