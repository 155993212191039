import CardMixin from '../CardMixin';

export default {
  mixins: [CardMixin],

  data() {
    return {
      fieldList: [
        ['military_status', 'suitable'],
        ['group', 'category'],
        ['compound', 'speciality'],
        ['rank', 'number'],
      ],
    };
  },

  methods: {
    getFieldGroup(fieldName) {
      const selects = ['military_status', 'suitable'];
      if (selects.includes(fieldName)) return 'select';

      const inputs = ['group', 'category', 'compound', 'speciality', 'rank', 'number'];
      if (inputs.includes(fieldName)) return 'input';

      return null;
    },
    handleIf(field) {
      if (field !== 'military_status') {
        return this.fields.military_status === 'liable';
      }
      return true;
    },
  },
};
