import {
  QBtn,
} from '@quasar/components';

export default {
  name: 'VClubsItem',
  components: {
    QBtn,
  },
  props: {
    loading: Boolean,
    ageFrom: Number,
    ageTo: Number,
    imgSrc: String,
    weekDays: String,
    cost: String,
    name: String,
    schoolName: String,
    icon: String,
    leadFullname: String,
    freePlaces: Number,
    link: String,
  },
  methods: {
    getFlagStyle() {},
  },
};
