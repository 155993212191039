import Vue from 'vue';
import Vuex from 'vuex';
import Trans from '@vjs/store/translator';
import vSteps from './VSteps';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Trans,
    vSteps,
  },
});
