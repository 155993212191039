import Vue from 'vue';

import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QTimeline',

  provide() {
    return {
      __timeline: this,
    };
  },

  props: {
    color: {
      type: String,
      default: 'primary',
    },
    side: {
      type: String,
      default: 'right',
      validator: v => ['left', 'right'].includes(v),
    },
    layout: {
      type: String,
      default: 'dense',
      validator: v => ['dense', 'comfortable', 'loose'].includes(v),
    },
    dark: Boolean,
  },

  computed: {
    classes() {
      return {
        'sn-timeline--dark': this.dark,
        [`sn-timeline--${this.layout}`]: true,
        [`sn-timeline--${this.layout}--${this.side}`]: true,
      };
    },
  },

  render(h) {
    return h('ul', {
      staticClass: 'sn-timeline',
      class: this.classes,
      on: this.$listeners,
    }, slot(this, 'default'));
  },
});
