<template>
  <section
    class="sm-main-page__jumbotron"
  >
    <div class="sm-main-page__jumbotron--title-wrapper">
      <div class="sm-main-page__jumbotron--title">
        {{ trans('main.jumbotron_title') }}
      </div>
      <div class="sm-main-page__jumbotron--subtitle">
        {{ trans('main.jumbotron_subtitle') }}
      </div>
    </div>
    <div class="sm-main-page__jumbotron--image">
      <img :src="`${uriStatic}/mektep/images/main-page/jumbotron.png`">
    </div>
  </section>
</template>

<script>
export default {
  name: 'Jumbotron',
  props: { uriStatic: { type: String, required: true } },
};
</script>
