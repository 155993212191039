import FunctionalComponentField from './FunctionalComponentField';

class TemplateSingleLabel extends FunctionalComponentField {
  renderFunc(context) {
    if (!context.props.field.hasOwnProperty('renderSingleLabel')) {
      return false;
    }
    return context.props.field.renderSingleLabel;
  }

  template(context) {
    if (!context.props.field.hasOwnProperty('templateSingleLabel')) {
      return false;
    }
    return context.props.field.templateSingleLabel;
  }
}

export default new TemplateSingleLabel();
