import {
  QInput,
  QToggle,
  QForm,
  QBtn,
  QDialog,
  QSlideTransition,
  QDate,
  QPopupProxy,
  QIcon,
} from '@quasar/components';

import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';

import {
  QCard,
  QCardSection,
} from '@quasar/components/card';

export default {
  name: 'StatementDisposeDialog',
  components: {
    QInput,
    QToggle,
    QForm,
    QBtn,
    QDialog,
    QSlideTransition,
    VModal,
    QCard,
    QCardSection,
    QDate,
    QPopupProxy,
    QIcon,
  },
  directives: {
    ClosePopup,
  },
  props: {
    modal: Boolean,
    disposeUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      openModal: false,
      form: {
        disposalReason: '',
        disposalDate: '',
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'notice']);
  },
  methods: {
    async handleModal() {
      this.$emit('modal-close', false);
    },
    async dispose() {
      this.$axios.patch(this.disposeUrl, {
        disposal_reason: this.form.disposalReason,
        disposal_date: this.form.disposalDate,
      })
        .then((response) => {
          this.$notify({
            type: 'success',
            title: window.L.message,
            text: response.data.message,
          });
          this.handleModal();
          this.statusChanged();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const vm = this;
            _.forEach(error.response.data.messages, (message) => {
              vm.$notify({
                type: 'error',
                title: window.L.message,
                text: message,
              });
            });
            return;
          }
          this.$notify({
            text: this.trans('notice.error_on_server'),
            type: 'error',
          });
        });
    },
    statusChanged() {
      this.$emit('status-changed');
    },
  },
};
