<template>
  <div class="s-egov-sign__item">
    <div class="s-egov-sign__head">
      <img
        class="s-egov-sign__img--ncalayer"
        :src="`${staticUrl}/base/images/egov/ncalayer.png`"
      >
      <span class="s-egov-sign__title">{{ trans('egov_sign.ncalayer.title') }}</span>
      <span class="s-egov-sign__subtitle">{{ trans('egov_sign.ncalayer.subtitle') }}</span>
    </div>
    <div class="s-egov-sign__body">
      <template v-if="webSocketOpen">
        <div
          class="s-egov-sign__body--text"
          v-html="trans('egov_sign.ncalayer.text')"
        />
        <q-btn
          class="s-w-100"
          color="green"
          :label="trans('egov_sign.ncalayer.button')"
          no-caps
          @click="sign"
        />
      </template>
      <div
        v-else
        class="s-egov-sign__alert"
      >
        <q-icon
          class="mb-20"
          name="exclamation-triangle"
          color="#c70028"
          size="7rem"
          boldest
          icon-class="s-ico-fa"
        />
        <div class="mb-20">
          {{ trans('egov_sign.ncalayer.alert') }}
        </div>
        <a
          class="s-egov-sign__alert--link s-c-blue"
          target="_blank"
          :href="`https://pki.gov.kz/docs/nl_${locale === 'ru' ? 'ru' : 'kaz'}/windows/`"
        >
          {{ trans('egov_sign.ncalayer.instruction') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { QIcon } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  components: { QIcon },
  props: {
    action: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      default: () => ({
        method: 'signXml',
        args: ['PKCS12', 'SIGNATURE', '', '', ''],
      }),
    },
  },
  data() {
    return {
      locale: window.core_project.locale,
      staticUrl: window.core_project.cdnStatic,

      webSocket: null,
      webSocketOpen: true,
    };
  },

  mounted() {
    this.webSocket = new WebSocket('wss://127.0.0.1:13579/');
    this.webSocket.onclose = (event) => { this.webSocketOpen = false; };
  },
  beforeDestroy() {
    this.webSocket.close();
    this.webSocket = null;
  },

  methods: {
    async sign() {
      this.$globalLoading.show();
      const { webSocket } = this;

      const { method, args } = this.config;

      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        // Ошибка при подключении к NCALayer. Перезапустите программу и обновите страницу.
        this.$globalLoading.hide();
        return;
      }

      webSocket.send(JSON.stringify(
        {
          module: 'kz.gov.pki.knca.commonUtils',
          method,
          args,
        },
      ));

      webSocket.onclose = (event) => {
        this.webSocketOpen = false;
        if (event.wasClean) {
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          // Соединение с NCALayer было прервано. Попробуйте авторизоваться еще раз или обновить страницу.
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = async (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            const signXml = result.responseObject;
            await this.requestAction(signXml);
            this.$globalLoading.hide();
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
    async requestAction(result) {
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: { result },
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
</script>
