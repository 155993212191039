import axios from 'axios';
import Notify from '@vjs/components/Notify/Notify.vue';

export default {
  name: 'VStatementServiceDocs',

  props: {
    route: String,
    type: String,
    documentNumber: String,
  },

  components: {
    Notify,
  },

  data() {
    return {
      birth_certificates: null,
      school_certificate: null,
      loading: false,
      success: false,
    };
  },

  beforeCreate() {
    this.$trans.add(['statement_service', 'warning_text', 'label', 'button']);
  },

  methods: {
    handleFile(val) {
      this[val] = this.$refs[val].files[0];
    },
    async submit(e) {
      e.preventDefault();

      this.loading = true;

      const formData = new FormData();
      if (this.documentNumber) formData.append('number_akt', this.documentNumber);
      if (this.birth_certificates) formData.append('birth_certificates', this.birth_certificates);
      // formData.append('birth_certificates', this.birth_certificates);
      formData.append('school_certificate', this.school_certificate);
      formData.append('type', this.type);

      try {
        const response = await axios.post(`${this.route}`, formData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } });
        window.location = response.data;
      } catch (er) {
        const { errors, message } = er.response.data;
        if (message && !errors) {
          this.$notify({
            type: 'error',
            text: message,
          });
        }
        if (errors?.birth_certificates) {
          this.$notify({
            type: 'error',
            text: errors.birth_certificates[0] || errors.birth_certificates,
          });
        }
        if (errors?.school_certificate) {
          this.$notify({
            type: 'error',
            text: errors.school_certificate[0] || errors.school_certificate,
          });
        }
      }
      this.loading = false;
    },
  },
};
