import Vue from 'vue';

import QIcon from '../icon/QIcon';
import QSpinner from '../spinner/QSpinner';

import BtnMixin from './btn-mixin';

import slot from '../../utils/slot';
import { stopAndPrevent } from '../../utils/event';

export default Vue.extend({
  name: 'QBtn',

  mixins: [BtnMixin],

  props: {
    percentage: {
      type: Number,
      validator: v => v >= 0 && v <= 100,
    },
    darkPercentage: Boolean,
  },

  computed: {
    hasLabel() {
      return this.label !== undefined && this.label !== null && this.label !== '';
    },
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.__onKeyupAbort);
  },

  methods: {
    click(e) {
      if (this.pressed === true) { return; }

      if (e !== undefined) {
        // focus button if it came from ENTER on form
        // prevent the new submit (already done)
        if (this.type === 'submit') {
          const el = document.activeElement;

          if (
            (
              el !== document.body
              && this.$el.contains(el) === false
              // required for iOS and desktop Safari
              && el.contains(this.$el) === false
            )
            || (this.$q.platform.is.ie === true && (e.clientX < 0 || e.clientY < 0))
          ) {
            stopAndPrevent(e);
            this.$el.focus();
            return;
          }
        }

        if (e.qKeyEvent !== true && this.$refs.blurTarget !== undefined) {
          this.$refs.blurTarget.focus();
        }

        if (e.defaultPrevented === true) {
          return;
        }

        if (this.type !== 'a' && this.hasRouterLink === true) {
          stopAndPrevent(e);
        }
      }

      if (this.type !== 'a') {
        const go = () => {
          const res = this.$router[this.replace === true ? 'replace' : 'push'](this.to);

          // vue-router now throwing error if navigating
          // vue-router now throwing error if navigating
          // to the same route that the user is currently at
          // https://github.com/vuejs/vue-router/issues/2872
          if (res !== void 0 && typeof res.catch === 'function') {
            res.catch(() => {});
          }
        };
        this.$emit('click', e, go);
        this.hasRouterLink === true && e.navigate !== false && go();
      }
    },

    __onKeydown(e) {
      if ([13, 32].includes(e.keyCode) === true) {
        // focus external button if the focus helper was focused before
        this.$el.focus();

        stopAndPrevent(e);

        if (this.pressed !== true) {
          this.pressed = true;
          this.$el.classList.add('sn-btn--active');
          document.addEventListener('keyup', this.__onKeyupAbort);
        }
      }

      this.$emit('keydown', e);
    },

    __onKeyup(e) {
      if ([13, 32].includes(e.keyCode) === true) {
        this.__onKeyupAbort();

        // for click trigger
        const evt = new MouseEvent('click', e);
        evt.qKeyEvent = true;
        e.defaultPrevented === true && evt.preventDefault();
        this.$el.dispatchEvent(evt);

        stopAndPrevent(e);

        // for ripple
        e.qKeyEvent = true;
      }

      this.$emit('keyup', e);
    },

    __onKeyupAbort() {
      this.pressed = false;
      document.removeEventListener('keyup', this.__onKeyupAbort);
      this.$el && this.$el.classList.remove('sn-btn--active');
    },
  },

  render(h) {
    const inner = [].concat(slot(this, 'default'));
    const data = {
      staticClass: 'sn-btn sn--inline sn-btn-item sn--non-selectable',
      class: this.classes,
      style: this.style,
      attrs: { ...this.$attrs, ...this.attrs },
    };

    if (this.isDisabled === false) {
      data.on = {
        ...this.$listeners,
        click: this.click,
        keydown: this.__onKeydown,
        keyup: this.__onKeyup,
      };

      if (this.ripple !== false) {
        data.directives = [{
          name: 'ripple',
          value: this.ripple,
          modifiers: { center: this.isRound },
        }];
      }
    }

    if (this.hasLabel === true) {
      inner.unshift(
        h('div', {
          style: this.labelWider ? 'margin-left: auto; margin-right: auto;' : '',
        }, [this.label]),
      );
    }

    if (this.icon !== undefined) {
      inner.unshift(
        h(QIcon, {
          props: { name: this.icon, iconClass: this.useFaIcons ? 's-ico-fa' : undefined, left: this.stack === false && this.hasLabel === true },
        }),
      );
    }

    if (this.iconRight !== undefined && this.isRound === false) {
      inner.push(
        h(QIcon, {
          props: { name: this.iconRight, iconClass: this.useFaIcons ? 's-ico-fa' : undefined, right: this.stack === false && this.hasLabel === true },
        }),
      );
    }

    return h(this.isLink ? 'a' : 'button', data, [
      h('div', {
        staticClass: 'sn-focus-helper',
        ref: 'blurTarget',
        attrs: { tabindex: -1 },
      }),

      this.loading === true && this.percentage !== undefined
        ? h('div', {
          staticClass: 'sn-btn__progress s-pos-absolute-full',
          class: this.darkPercentage ? 'sn-btn__progress--dark' : null,
          style: { transform: `scale3d(${this.percentage / 100},1,1)` },
        })
        : null,

      h('div', {
        staticClass: 'sn-btn__content sn--text-center sn--col sn--items-center sn-anchor--skip',
        class: this.innerClasses,
      }, inner),

      this.loading !== null
        ? h('transition', {
          props: { name: 'sn-transition--fade' },
        }, this.loading === true ? [
          h('div', {
            key: 'loading',
            staticClass: 's-pos-absolute-full sn--flex sn--flex-center',
          }, this.$scopedSlots.loading !== undefined ? this.$scopedSlots.loading() : [h(QSpinner)]),
        ] : undefined)
        : null,
    ]);
  },
});
