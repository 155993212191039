import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import VFormInform from '@vjs/components/VFormInform';
import {
  QCheckbox,
  QIcon,
  QInput,
  QTable,
  QTd,
  QTh,
  QTr,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';

export default {
  components: {
    VNoResults,
    QTable,
    QTh,
    QTr,
    QTd,
    QCheckbox,
    QInput,
    QIcon,
    VFormInform,
  },
  props: {
    getRefs: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filter: '',
      refs: {
        receivers: [],
        rows: [],
        organizations: [],
        reasons: [],
      },
      form: {
        organization: null,
        manualReceiverName: false,
        receiverId: null,
        receiverName: null,
        reason: null,
        selectedBooks: [],
      },
      pagination: {
        rowsPerPage: 10,
      },
    };
  },
  async mounted() {
    await this.$trans.add(['book', 'button', 'label', 'warning_text']);
    this.refs = { ...this.refs, ...(await this.requestGetRefs()) };
  },

  computed: {
    validate() {
      return !this.form.organization
      || !this.form.receiverName
      || !this.form.reason
      || !this.form.selectedBooks.length;
    },
    isAllRowsChecked() {
      if (this.form.selectedBooks.length === this.refs.rows.length) {
        return true;
      }
      if (this.form.selectedBooks.length === 0) {
        return false;
      }
      return null;
    },
    cols() {
      const cols = [
        {
          label: '',
          field: 'isChecked',
          width: 55,
        },
        {
          label: this.trans('book.library_act.show_author'),
          field: 'author',
        },
        {
          label: this.trans('book.library_act.inventory_number'),
          field: 'inventory_number',
        },
        {
          label: this.trans('book.library_act.copies_count'),
          field: 'count',
        },
        {
          label: this.trans('book.library_act.show_price'),
          field: 'price',
        },
        {
          label: this.trans('book.library_act.residual_price'),
          field: 'residual_price',
        },
      ];

      return cols.map((col) => {
        const tmpCol = col;
        tmpCol.name = col.field;
        return tmpCol;
      });
    },
  },

  methods: {
    async handleFieldChange(field) {
      const value = this.form[field];
      switch (field) {
        case 'reason':
          this.refs.rows = [];
          this.form.selectedBooks = [];
          this.refs.rows = await this.requestGetRefs({ [field]: value });
          break;
        case 'organization':
          this.form.manualReceiverName = false;
          this.form.receiverId = null;
          this.form.receiverName = null;
          this.refs.receivers = [];
          this.refs.receivers = await this.requestGetRefs({ [field]: value });
          break;
        case 'manualReceiverName':
          this.form.receiverId = null;
          this.form.receiverName = null;
          break;
        default: break;
      }
    },
    handleHeadCheckbox() {
      if (!this.isAllRowsChecked) {
        this.form.selectedBooks = this.refs.rows.map(itm => itm.id);
      } else {
        this.form.selectedBooks = [];
      }
    },
    async requestGetRefs(params = {}) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, { url: this.getRefs, params });
      this.$globalLoading.hide();
      if (!res.error) {
        return res.data;
      }
      Helper.handlerResponseErrorNew(this, res);
    },
    async submit() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
