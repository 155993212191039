import Vue from 'vue';

import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QItemLabel',

  props: {
    overline: Boolean,
    caption: Boolean,
    header: Boolean,
    lines: [Number, String],
  },

  computed: {
    classes() {
      return {
        'sn-item__label--overline sn--text-overline': this.overline,
        'sn-item__label--caption sn--text-caption': this.caption,
        'sn-item__label--header': this.header,
        ellipsis: parseInt(this.lines, 10) === 1,
      };
    },

    style() {
      if (this.lines !== void 0 && parseInt(this.lines, 10) > 1) {
        return {
          overflow: 'hidden',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': this.lines,
        };
      }
    },
  },

  render(h) {
    return h('div', {
      staticClass: 'sn-item__label',
      style: this.style,
      class: this.classes,
      on: this.$listeners,
    }, slot(this, 'default'));
  },
});
