<template>
  <div
    class="sm-statement-show-step"
    :class="`status-${step.status}`"
  >
    <template v-if="withArrow">
      <div
        class="sm-statement-show-step--arrow-horizontal-line"
        :class="{
          right: isArrowOnRight,
          disabled: step.status !== 'done'
        }"
      />
      <div
        class="sm-statement-show-step--arrow-vertical-line"
        :class="{
          right: isArrowOnRight,
          disabled: step.status !== 'done'
        }"
      />
      <div
        class="sm-statement-show-step--arrow-horizontal-line bottom"
        :class="{
          right: isArrowOnRight,
          disabled: step.status !== 'done'
        }"
      />
      <div
        class="sm-statement-show-step--arrow-arrow"
        :class="{
          right: isArrowOnRight,
          disabled: step.status !== 'done'
        }"
      />
    </template>
    <div
      class="sm-statement-show-step--wrapper"
    >
      <div class="sm-statement-show-step--head d-flex align-items-center">
        <div class="sm-statement-show-step--head-number">
          {{ number }}
          <div
            v-if="step.status === 'done'"
            class="sm-statement-show-step--head-check-circle"
          />
        </div>
        <div class="sm-statement-show-step--head-label">
          {{ trans('label.common_step') }}
        </div>
      </div>
      <div class="sm-statement-show-step--label">
        {{ step.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatementShowStepsStep',
  props: {
    step: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    withArrow: Boolean,
    isArrowOnRight: Boolean,
  },
};
</script>
