import { Form } from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [Form],
  props: {
    refs: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    uriBack: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
      default: () => ({
      }),
    },
    method: {
      type: String,
      default: 'post',
    },
    academicYear: {
      type: String,
      required: true,
    },
  },
  beforeCreate() {
    this.$trans.add(['fields', 'book']);
  },
  data() {
    return {
      fields: {
        parallel: {
          labelTransKey: 'fields.filters.book_plans.parallel',
          value: this.resource.parallel,
          values: this.refs.classes,
          name: 'parallel',
          component: 'q-select-field',
          required: !this.isEdit,
          extraAttributes: {
            disabled: this.isEdit,
            useInput: false,
          },
        },
        letter: {
          labelTransKey: 'fields.filters.book_plans.letter',
          value: this.resource.letter,
          name: 'letter',
          component: 'q-text-field',
          required: !this.isEdit,
          extraAttributes: {
            disabled: this.isEdit,
          },
        },
        edu_lang: {
          labelTransKey: 'fields.filters.book_plans.edu_lang',
          value: this.resource.edu_lang,
          values: this.refs.languages,
          name: 'edu_lang',
          component: 'q-select-field',
          required: true,
        },
        direction: {
          labelTransKey: 'fields.filters.book_plans.direction',
          value: this.resource.direction,
          values: this.refs.directions,
          name: 'direction',
          component: 'q-select-field',
          required: false,
        },
        contingent_count: {
          labelTransKey: 'fields.filters.book_plans.contingent_count',
          value: this.resource.contingent_count,
          name: 'contingent_count',
          component: 'q-number-field',
          required: true,
          extraAttributes: { counter: false },
          config: { max: 9999 },
        },
        plan: {
          labelTransKey: 'fields.filters.book_plans.plan',
          value: this.resource.plan,
          name: 'plan',
          component: 'q-number-field',
          required: true,
          extraAttributes: { counter: false },
          config: { max: 9999 },
        },
        academic_year: {
          labelTransKey: 'fields.filters.book_plans.academic_year',
          value: this.academicYear,
          name: 'academic_year',
          component: 'q-text-field',
          required: true,
          extraAttributes: { disabled: true },
        },
      },
    };
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const data = {};

      _.forEach(this.fields, (field) => {
        data[field.name] = field.value;
      });

      const res = await requestWrapper.call(this, {
        method: this.method,
        url: this.action,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
