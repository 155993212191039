import {
  QForm,
  QInput,
  QBtn,
  QIcon,
  QCheckbox,
  QSlideTransition,
  QDate,
  QPopupProxy,
  QOptionGroup,
  QField,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import Validation from '@vjs/mixins/Validation';
import _ from 'lodash';
import eventHub from '@vjs/config/eventHub';
import OptionsMixin from '../mixins/OptionsMixin';

export default {
  name: 'VStepChild',

  mixins: [OptionsMixin, Validation],
  components: {
    QForm,
    QInput,
    QBtn,
    QIcon,
    QCheckbox,
    QSlideTransition,
    QDate,
    QPopupProxy,
    QOptionGroup,
    QField,
    VFormInform,
  },

  props: {
    informMessage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      form: {
        selectedChild: null,
      },
      options: {
        children: [],
      },
    };
  },
  async mounted() {
    await this.requestGetOptions({ type: 'children' });
  },

  created() {
    eventHub.$on('SET_NEXT_STEP', this.handleNextStep);
  },

  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.handleNextStep);
  },

  computed: {
    validate() {
      return !!this.form.selectedChild;
    },
    childrenOptions() {
      let result = [];
      result = this.options.children.map((item) => {
        const tmpItm = item;
        tmpItm.disable = !item.hasContingent;
        return tmpItm;
      });
      result = _.sortBy(result, itm => itm.disable);
      return result;
    },
  },
  watch: {
    validate: {
      handler(val) {
        this.$store.commit('SET_ACCESS', val);
      },
      immediate: true,
    },
  },

  methods: {
    handleNextStep() {
      this.$store.commit('SET_SELECTED_CHILD', this.childrenOptions.find(itm => itm.value === this.form.selectedChild));
      this.$store.commit('SET_DATA', { name: 'child_id', data: this.form.selectedChild });
      this.$store.commit('SET_NEXT_STEP');
    },
  },
};
