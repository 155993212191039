import Vue from 'vue';

import CheckboxMixin from '../../mixins/checkbox';
import QIcon from '../icon/QIcon';
import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QToggle',

  mixins: [CheckboxMixin],

  props: {
    icon: String,
    checkedIcon: String,
    uncheckedIcon: String,
  },

  computed: {
    classes() {
      return {
        disabled: this.disable,
        'sn-toggle--dark': this.dark,
        'sn-toggle--dense': this.dense,
        'sn--reverse': this.leftLabel,
      };
    },

    innerClass() {
      if (this.isTrue === true) {
        return `sn-toggle__inner--active${
          this.color !== void 0 ? ` s-c-${this.color}` : ''}`;
      } if (this.keepColor === true && this.color !== void 0) {
        return `s-c-${this.color}`;
      }
    },

    computedIcon() {
      return (this.isTrue === true ? this.checkedIcon : this.uncheckedIcon) || this.icon;
    },
  },

  render(h) {
    return h('div', {
      staticClass: 'sn-toggle sn--cursor-pointer sn--no-outline sn--row sn--inline sn--no-wrap sn--items-center',
      class: this.classes,
      attrs: { tabindex: this.computedTabindex },
      on: {
        click: this.toggle,
        keydown: this.__keyDown,
      },
    }, [
      h('div', {
        staticClass: 'sn-toggle__inner s-pos-relative-position',
        class: this.innerClass,
      }, [
        this.disable !== true
          ? h('input', {
            staticClass: 'sn-toggle__native s-pos-absolute sn-ma-none sn-pa-none sn--invisible',
            attrs: { type: 'toggle' },
            on: { change: this.toggle },
          })
          : null,

        h('div', { staticClass: 'sn-toggle__track' }),
        h('div', { staticClass: 'sn-toggle__thumb-container s-pos-absolute' }, [
          h('div', {
            staticClass: 'sn-toggle__thumb sn--row sn--flex-center',
          }, this.computedIcon !== void 0
            ? [h(QIcon, { props: { name: this.computedIcon } })]
            : null),
        ]),
      ]),

      h('div', {
        staticClass: 'sn-toggle__label sn-anchor--skip',
      }, (this.label !== void 0 ? [this.label] : []).concat(slot(this, 'default'))),
    ]);
  },
});
