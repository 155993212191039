import { Helper } from '@common/src/helpers';

export default {
  data() {
    return {
      freeChildren: [],
      subGroupsChildren: [],
      subclassGroup: [],
      selectedFreeChild: null,
      subGroups: [],
      selectedSubGroup: null,
      isEditInputs: [],
    };
  },
  props: {
    subclassId: {
      type: Number,
      default: null,
    },
    urls: {
      type: Object,
      required: true,
      default() {
        return {
          getData: '',
          postData: '',
        };
      },
    },
  },
  beforeCreate() {
    this.$trans.add(['button', 'subclass']);
  },
  methods: {
    async getChildren() {
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      const action = Helper.urlGenerate(this.urls.getData, {
        subclass: this.subclassId,
      });

      try {
        const { body } = await this.$http.get(action, [], config);

        this.freeChildren = body.freeChildren;
        this.subGroupsChildren = body.subGroupsChildren;
        this.subclassGroup = body.subclassGroup;

        this.isEditInputs = this.subclassGroup.map(() => false);
      } catch (err) {
        console.log(err);
      }
    },
    toggleEdit(group_index) {
      (this.isEditInputs[group_index]) ? this.$set(this.isEditInputs, group_index, false) : this.$set(this.isEditInputs, group_index, true);
    },
    addChildInGroup() {
      const child_index = _.findIndex(this.freeChildren, ['id', this.selectedFreeChild]);
      if (child_index === -1) return;
      const child = this.freeChildren.splice(child_index, 1)[0];

      this.selectedFreeChild = null;

      const group_index = _.findIndex(this.subclassGroup, ['id', this.selectedSubGroup]);

      this.subGroupsChildren[group_index].push(child);
    },
    removeChildFromGroup(group_index, child_index) {
      const child = this.subGroupsChildren[group_index].splice(child_index, 1)[0];
      this.freeChildren.push(child);
    },
    validateComponent() {
      if (!this.subGroupsChildren.length || this.freeChildren.length || this.isEditInputs.some(isEdit => isEdit === true)
          || this.subclassGroup.some(group => group.name == '')) return true;

      return false;
    },
    async applySettings() {
      const config = {
        responseType: 'json',
        method: 'POST',
        headers: {
          'Accept-Language': window.core_project.locale,
          'X-CSRF-TOKEN': window.core_project.csrfToken,
        },
      };

      const newSettings = {
        children: {},
        names: {},
      };

      try {
        this.subGroupsChildren.forEach((subGroup, i) => {
          newSettings.children[i + 1] = subGroup.map(child => child.id);
          newSettings.names[i + 1] = this.subclassGroup[i].name;
        });

        const action = Helper.urlGenerate(this.urls.postData, {
          subclass: this.subclassId,
        });

        const { body } = await this.$http.post(action, newSettings, config);

        if (body.status === 'success') {
          show_notice(body.message, 'notice');
        } else {
          show_notice(body.message, 'error');
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  async mounted() {
    await this.getChildren();
  },
};
