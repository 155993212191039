import {
  QInput,
  QBtn,
  QPagination,
} from '@quasar/components';
import { genArray } from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import MXListView from '@vjs/mixins/MXListView';
import VStatisticPanel from '@vjs/components/VStatisticPanel';
import VOrganizationsItem from '@app_mektep/components/Views/VOrganizationsItem';
import VClubsFilters from '@app_mektep/components/Views/VClubsFilters';
import VOrgsMap from '@common/components/VOrgsMap';

export default {
  name: 'VOrganizationsListView',
  mixins: [MXListView],
  components: {
    QInput,
    QBtn,
    QPagination,
    VStatisticPanel,
    VOrganizationsItem,
    VNoResults,
    VClubsFilters,
    VOrgsMap,
  },
  props: {
    uriGetFilters: {
      type: String,
      required: true,
    },
    uriGetList: {
      type: String,
      required: true,
    },
    staticUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchValue: '',
      debounceTimeout: null,
      tab: 'list',
    };
  },
  beforeCreate() {
    this.$trans.add(['organizations', 'additional_education', 'placeholder', 'label']);
  },
  watch: {
    searchValue(val) {
      if (val === '') {
        this.itemsFiltered = this.items;
      }
    },
  },
  computed: {
    swipeDirection() { return this.tab === 'list' ? 'swipe-right' : 'swipe-left'; },
    paginate() {
      if (this.loading) {
        return genArray({
          id: 1,
          camp_places_count: 0,
          delivery_places_count: 0,
          free_food_placesCount: 0,
          link: '',
          icon: '',
          name: '',
          icon_url: '',
          imgSrc: '',
        }, 4);
      }
      const current = (this.currentPage - 1) * this.countByPage;
      return this.itemsFiltered.slice(current, current + this.countByPage);
    },
    countOfPages() {
      const count = Math.ceil(this.itemsFiltered.length / this.countByPage);
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    debounceSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(
        this.handleSearch,
        300,
      );
    },
    handleSearch() {
      if (this.searchValue !== '') {
        const searchStr = this.searchValue.toLowerCase().trim();
        this.itemsFiltered = this.items.filter((item) => {
          const organizationName = item.name || '';
          return organizationName.toLowerCase().indexOf(searchStr) !== -1;
        });
      } else {
        this.itemsFiltered = this.items;
      }
    },
    handleFilteredList(filteredData) {
      this.itemsFiltered = this.items = filteredData.items;
      this.statisticItems = filteredData.statistics;
      this.searchValue = '';
    },
  },
};
