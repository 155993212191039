import { FormField, StoreTranslator } from '@common/mixins';
import _ from 'lodash';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru.js';

export default {
  mixins: [StoreTranslator, FormField],
  props: {},
  data() {
    const vm = this;
    const toolbar = {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
      ],
    };
    const toolbarRemoves = _.get(vm.field, 'config.toolbarRemoves', []);
    if (toolbarRemoves.length > 0) {
      const items = [];
      _.forEach(toolbar.items, (item) => {
        if (!_.includes(toolbarRemoves, item)) {
          items.push(item);
        }
      });
      toolbar.items = items;
    }

    const toolbarAdd = _.get(vm.field, 'config.toolbarAdd', []);
    if (toolbarAdd.length > 0) {
      const items = [];
      _.forEach(toolbarAdd, (item) => {
        if (!_.includes(toolbar.items, item)) {
          items.push(item);
        }
      });
      toolbar.items = [...toolbar.items, ...items];
    }

    return {
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar,
      },
      loadedCkeditor: false,
      UploadAdapter: function UploadAdapter(loader) {
        this.loader = loader;

        this.upload = () =>
        // TODO реализовать загрузку фото возращать надо ссылку на изображение
        // TODO переписать на vm.$http.post

          this.loader.file
            .then(file => new Promise((resolve, reject) => {
              const body = new FormData();
              body.append('file', file);

              const config = {
                responseType: 'json',
                method: 'POST',
                headers: {},
              };
              config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
              vm.$http.post('/ru/tko/upload-content', body, config).then(
                response => resolve(response.data),
                (response) => {
                  if (response.status === 422 || response.status === 423) {
                    let errors = [];
                    _.forEach(response.data.errors, (value, key) => {
                      errors = errors.concat(value);
                    });
                    show_notice(errors, 'error');
                  } else {
                    console.log(response);
                  }
                  return reject('error');
                },
              );
            }));
        this.abort = () => {
          console.log('Abort upload.');
        };
      },
    };
  },
  methods: {
    setInitialValue() {
      if (this.loadedCkeditor) {
        this.localValue = !(this.field.value === undefined || this.field.value === null)
          ? this.field.value
          : '';
      }
    },
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
    onEditorReady(instance) {
      const { UploadAdapter } = this;
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new UploadAdapter(loader);
      }
      this.loadedCkeditor = true;
      this.setInitialValue();
    },
  },
  computed: {

    defaultAttributes() {
      return {
        name: this.field.name,
        placeholder: this.field.placeholder,
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
