import _ from 'lodash';
import {
  QForm,
  QInput,
  QBtn,
} from '@quasar/components';
import { required, confirmed } from '@vjs/validations';
import axios from 'axios';
import {
  requestWrapper,
} from '@vjs/helpers';
import * as Sentry from '@sentry/browser';
import { Helper } from '@common/src/helpers';

export default {
  components: {
    QForm,
    QInput,
    QBtn,
  },
  name: 'FormResetPassword',
  props: {
    urls: {
      type: Object,
      default: () => ({
        redirect: '',
      }),
    },
    sizeCode: {
      type: Number,
      default: 4,
    },
  },

  data: () => ({
    currentStep: 1,
    dataUrls: {
      actions: {
        1: '/api/v1/restore-pass-sms',
        2: '/api/v1/restore-pass-sms/code',
        3: '/api/v1/restore-pass-sms/password',
      },
    },
    form: {
      phone: '',
      code: null,
      password: '',
      password_confirmation: '',
    },
    errorMessages: {},
    count: 0,
    timer: null,
    sendCode: false,
    loading: false,
  }),

  computed: {
    codeMask() {
      return '#'.repeat(this.sizeCode);
    },
    seconds() {
      return this.count;
    },
    // Составной action для формы
    action() {
      const { actions } = this.dataUrls;
      return `${actions[this.currentStep]}`;
    },
  },

  beforeCreate() {
    this.$trans.add(['auth', 'label', 'placeholder', 'button', 'validation']);
  },

  created() {
    // Три action URL части заранее известны,
    // но есть возможность их переписать через props
    this.dataUrls = { ...this.dataUrls, ...this.$props.urls };
  },

  methods: {
    async sendCodeAgain() {
      this.$globalLoading.show();
      try {
        await axios({
          method: 'POST',
          url: this.dataUrls.actions[1],
          data: { phone: this.form.phone },
        });
      } catch (err) {
        if (!err.response) {
          Sentry.captureException(err);
          Sentry.configureScope(scope => scope.clear());
        }

        if (err.response.status === 422) {
          let errors = [];
          _.forEach(err.response.data.errors, (val) => {
            errors = errors.concat(val);
          });
          this.$notify({
            type: 'error',
            title: window.L.error,
            text: errors.toString(),
          });
          return false;
        }
      }
      this.$globalLoading.hide();
      this.timerStart();
    },
    timerStart() {
      clearInterval(this.timer);
      this.count = 90;
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    async onSubmit() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data: this.form,
      });
      if (!res.error) {
        if (res.status === 'success') {
          if (res.message) {
            // Если в ответе есть свойство "message",
            // то выводим его через Notify по шаблону
            this.$notify({
              type: res.status,
              title: window.L.message,
              text: res.message,
            });
          }

          if (this.currentStep === 1) {
            this.sendCode = true;
            this.timerStart();
          }

          // Если currentStep больше 3, то редиректим и прерываем скрипт
          if (this.currentStep === 3) {
            setTimeout(() => {
              window.location = this.dataUrls.redirect;
            }, 1000);
            return;
          }

          // Если запрос ушёл и сервер вернул "success",
          // то накручиваем один шаг
          if (this.currentStep < 3) {
            this.currentStep += 1;
          }
        }
      }

      if (res.error) {
        if (res.data.errors) {
          // set errors from response
          _.forIn(res.data.errors, (value, key) => {
            this.$set(this.errorMessages, key, value);
          });
          Helper.showResponseErrors(res.data.errors)
        }
      }
      this.$globalLoading.hide();
    },
    required: message => required(message),
    confirmed: (input, message) => confirmed(input, message),
  },
};
