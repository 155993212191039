import Vue from 'vue';
import store from '@vjs/store';
import VApplicationTable from '@app_mektep/views/VApplicationTable';
import VApplicationTableEgov from '@app_mektep/views/VApplicationTableEgov';

if (document.getElementById('v-application-tables')) {
  window.vApplicationTable = new Vue({
    el: '#v-application-tables',
    components: {
      VApplicationTable,
      VApplicationTableEgov,
    },
    store,
  });
}
