import {
  QSelect,
} from '@quasar/components';

export default {
  name: 'VServiceLimitFilters',
  components: {
    QSelect,
  },
  props: {
    serviceTypes: {
      type: Array,
      default: () => ([]),
    },
    defaultServiceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentServiceType: this.defaultServiceType,
    };
  },
  methods: {
  },
  mounted() {
    this.currentServiceType = parseInt(this.defaultServiceType);
  },
  watch: {
    currentServiceType(val) {
      if (typeof val !== 'object') {
        return;
      }

      this.currentServiceType = val.value;

      this.$emit('service-type-change', this.currentServiceType);
    },
  },
};
