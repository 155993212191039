<template>
  <section class="s-home-container-wrapper">
    <div
      v-if="!withoutTitle"
      class="s-title-section s-title--double-line"
    >
      <span>{{ trans('title.subscribe_command') }}</span>
    </div>
    <div class="s-home-container">
      <v-insta
        class="mb-30"
        :url="link"
      />
      <div class="row justify-content-center">
        <div class="col-auto">
          <a
            href="https://www.instagram.com/smartnation_kz/"
            class="s-btn s-btn--w-200 s-btn--thm-green s-bold s-upper"
            target="_blank"
          >
            {{ trans('button.subscribe_true') }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VInsta from '@vjs/views/VInsta/VInsta.vue';

export default {
  name: 'InstaWrapper',
  components: { VInsta },
  props: {
    link: {
      type: String,
      required: true,
    },
    withoutTitle: Boolean,
  },
};

</script>
