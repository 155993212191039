<template>
  <div class="s-map-balloon">
    <div class="s-map-balloon__title">
      {{ item.title }}
    </div>
    <div class="s-map-balloon__info">
      {{ item.address }}
    </div>
    <div class="s-map-balloon__item">
      <span class="s-map-balloon__item-label">
        {{ trans('label.org_free_places') }}:
      </span>
      <span class="s-map-balloon__item-value">
        {{ item.availablePlacesNumber }}
      </span>
    </div>
    <div class="s-map-balloon__item">
      <span class="s-map-balloon__item-label">
        {{ trans('label.org_throughput') }}:
      </span>
      <span class="s-map-balloon__item-value">
        {{ item.throughputOrg }}
      </span>
    </div>
    <div class="s-map-balloon__footer">
      <q-btn
        no-caps
        :label="trans('button.more_info')"
        color="blue"
        type="a"
        target="_blank"
        :href="item.linkItem"
      />
      <q-btn
        v-if="fromStatement"
        id="select_org_button"
        no-caps
        :label="trans('button.select_org')"
        color="orange"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    fromStatement: Boolean,
  },

  methods: {
    onModalApprove(lang) {
      const result = {
        id: this.item.id,
        name: this.item.title,
        linkQueueModal: this.item.linkQueueModal,
        lang,
      };
      eventBus.$emit('selectOrgOnMap', result);
    },
    openQueueModal() {
      this.$sModal.open('v-modal-select-map-org', {
        title: this.trans('notice.queue_statement_map_modal'),
        component: 'v-modal-select-map-org',
        size: 700,
        binds: { uriGetData: this.item.linkQueueModal },
        events: { onApprove: this.onModalApprove },
      });
    },
  },
};
</script>
