export default {
  currentStep: 1,
  steps: {
    information: {
      step: 1,
      visible: true,
      access: false,
      backward: false,
      label: 'statement.step_information',
    },
    authentication: {
      step: 2,
      visible: true,
      access: false,
      backward: false,
      label: 'statement.step_authentication',
    },
    userInfo: {
      step: 3,
      visible: true,
      access: false,
      backward: false,
      label: 'statement.step_user_info',
    },
    childInfo: {
      step: 4,
      visible: true,
      access: false,
      backward: false,
      label: 'statement.step_child_info',
    },
    statement: {
      step: 5,
      visible: true,
      access: false,
      backward: false,
      label: 'statement.step_statement',
    },
  },
};
