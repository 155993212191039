import Vue from 'vue'

import {
  getRatio,
  getModel,
  SliderMixin,
  keyCodes
} from './slider-utils.js'

import {between} from '../../utils/format.js'
import {stopAndPrevent} from '../../utils/event.js'

export default Vue.extend({
  name: 'QSlider',

  mixins: [SliderMixin],

  props: {
    value: {
      type: Number,
      required: true
    },

    labelValue: [String, Number]
  },

  data() {
    return {
      model: this.value,
      curRatio: 0
    }
  },

  watch: {
    value(v) {
      this.model = between(v, this.min, this.max)
    },

    min(v) {
      this.model = between(this.model, v, this.max)
    },

    max(v) {
      this.model = between(this.model, this.min, v)
    }
  },

  computed: {
    ratio() {
      return this.active === true ? this.curRatio : this.modelRatio
    },

    modelRatio() {
      return (this.model - this.min) / (this.max - this.min)
    },

    trackStyle() {
      return {width: (100 * this.ratio) + '%'}
    },

    thumbStyle() {
      return {
        [this.horizProp]: (100 * this.ratio) + '%'
      }
    },

    thumbClass() {
      return this.preventFocus === false && this.focus === true ? 'sn-slider--focus' : null
    },

    pinClass() {
      return 'sn-slider__pin absolute d-flex justify-content-center align-items-center' +
        (this.labelColor !== void 0 ? ` s-c-${this.labelColor}` : '')
    },

    pinTextClass() {
      return 'sn-slider__pin-value-marker-text' +
        (this.labelTextColor !== void 0 ? ` s-c-${this.labelTextColor}` : '')
    },

    events() {
      if (this.editable === true) {
        return this.$q.platform.is.mobile === true
          ? {click: this.__mobileClick}
          : {
            mousedown: this.__activate,
            focus: this.__focus,
            blur: this.__blur,
            keydown: this.__keydown,
            keyup: this.__keyup
          }
      }
    },

    computedLabel() {
      return this.labelValue !== void 0
        ? this.labelValue
        : this.model
    }
  },

  methods: {
    __updateValue(change) {
      if (this.model !== this.value) {
        this.$emit('input', this.model)
      }
      change === true && this.$emit('change', this.model)
    },

    __getDragging() {
      return this.$el.getBoundingClientRect()
    },

    __updatePosition(event, dragging = this.dragging) {
      const ratio = getRatio(
        event,
        dragging,
        this.$q.lang.rtl
      )

      this.model = getModel(ratio, this.min, this.max, this.step, this.decimals)
      this.curRatio = this.snap !== true || this.step === 0
        ? ratio
        : (this.model - this.min) / (this.max - this.min)
    },

    __focus() {
      this.focus = true
    },

    __keydown(evt) {
      if (!keyCodes.includes(evt.keyCode)) {
        return
      }

      stopAndPrevent(evt)

      const
        step = ([34, 33].includes(evt.keyCode) ? 10 : 1) * this.computedStep,
        offset = [34, 37, 40].includes(evt.keyCode) ? -step : step

      this.model = between(
        parseFloat((this.model + offset).toFixed(this.decimals)),
        this.min,
        this.max
      )

      this.__updateValue()
    }
  },

  render(h) {
    return h('div', {
      staticClass: 'sn-slider sn-field__wrapper sn-field--filled mb-10 ',
      attrs: {
        role: 'slider',
        'aria-valuemin': this.min,
        'aria-valuemax': this.max,
        'aria-valuenow': this.value,
        'data-step': this.step,
        'aria-disabled': this.disable,
        tabindex: this.computedTabindex
      },
      class: this.classes,
      on: this.events,
      directives: this.editable ? [{
        name: 'touch-pan',
        value: this.__pan,
        modifiers: {
          horizontal: true,
          prevent: true,
          stop: true,
          mouse: true,
          mouseAllDir: true
        }
      }] : null
    }, [
      h('div', {staticClass: 'sn-field'}, [
        h('div', {staticClass: 'sn-field__inner'}, [
          h('div', {
            staticClass: 'sn-field__control',
            style: {
              paddingLeft: '2rem',
              paddingRight: '2rem',
            }
          }, [
            h('div', {staticClass: 'sn-field__control-container q-anchor--skip'}, [
              h('div', {staticClass: 'sn-slider__track-container s-pos-absolute sn--no-scroll'}, [
                h('div', {
                  staticClass: 'sn-slider__track s-pos-absolute',
                  style: this.trackStyle
                }),

                this.markers === true
                  ? h('div', {
                    staticClass: 'sn-slider__track-markers s-pos-absolute-full s-w-100',
                    style: this.markerStyle
                  })
                  : null
              ]),

              h('div', {
                staticClass: 'sn-slider__thumb-container s-pos-absolute sn-non-selectable',
                class: this.thumbClass,
                style: this.thumbStyle
              }, [
                h('svg', {
                  staticClass: 'sn-slider__thumb s-pos-absolute',
                  attrs: {width: '21', height: '21'}
                }, [
                  h('circle', {
                    attrs: {
                      cx: '10.5',
                      cy: '10.5',
                      r: '7.875'
                    }
                  })
                ]),

                this.label === true || this.labelAlways === true ? h('div', {
                  class: this.pinClass
                }, [
                  h('div', {staticClass: 'sn-slider__pin-value-marker'}, [
                    h('div', {staticClass: 'sn-slider__pin-value-marker-bg'}),
                    h('div', {class: this.pinTextClass}, [
                      this.computedLabel
                    ])
                  ])
                ]) : null,

                h('div', {staticClass: 'sn-slider__focus-ring'})
              ])
            ])
          ])
        ])
      ])
    ])
  }
})
