<template>
  <div>
    <div class="mb-20">
      {{ trans('book.how_to_find_book') }}
    </div>
    <div class="row-sort-between-btn">
      <q-btn
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        no-caps
        @click="$emit('onClose')"
      />
      <q-btn
        width="15"
        color="blue"
        :label="trans('book.using_search')"
        no-caps
        @click="handleSearch"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    await this.$trans.add(['book']);
  },

  methods: {
    handleSearch() {
      this.$sModal.open('v-modal-select-book', {
        title: this.trans('book.select_school_book'),
        component: 'v-modal-table',
        size: 'fullscreen',
        binds: {
          config: this.config,
          closeId: 'v-modal-find-book',
        },
      });
    },
  },
};
</script>
