export default {
  props: {
    color: String,
    size: {
      type: [Number, String],
      default: '1em',
    },
  },

  computed: {
    classes() {
      if (this.color) {
        return `s-c-${this.color}`;
      }
    },
  },
};
