import _ from 'lodash';

function checkVisibleStep(state, step) {
  let check = null;

  if (step > 0 && step <= Object.keys(state.steps).length) {
    check = _.find(state.steps, { step });
  }

  return check && check.visible;
}

export default {
  SET_CURRENT_STEP: (state, payload) => {
    state.currentStep = payload;
  },

  SET_PREV_STEP: (state) => {
    for (let idx = state.currentStep; idx > 0; idx -= 1) {
      if (checkVisibleStep(state, idx - 1)) {
        state.currentStep = idx - 1;
        return;
      }
    }
  },

  SET_NEXT_STEP: (state) => {
    for (let idx = state.currentStep; idx <= Object.keys(state.steps).length; idx += 1) {
      if (checkVisibleStep(state, idx + 1)) {
        state.currentStep = idx + 1;
        return;
      }
    }
  },

  SET_VISIBLE_STEP: (state, payload = { step: 1, visible: true }) => {
    const step = _.find(state.steps, { step: payload.step });
    step.visible = payload.visible;
  },

  SET_ACCESS_STEP: (state, payload = { step: 1, access: true }) => {
    const step = _.find(state.steps, { step: payload.step });
    if (step) {
      step.access = payload.access;
    }
  },

  SET_BACKWARD_STEP: (state, payload = { step: 1, backward: true }) => {
    const step = _.find(state.steps, { step: payload.step });
    if (step) {
      step.backward = payload.backward;
    }
  },
};
