import axios from 'axios';

import CheckIinModal from './CheckIinModal';

export default {
  components: { CheckIinModal },
  beforeCreate() {
    this.$trans.add(['statement_service', 'warning_text', 'label', 'button', 'notice', 'table']);
  },
  props: {
    uriCheckIin: {
      type: String,
      default: '',
    },
    uriSaveUser: {
      type: String,
      default: '',
    },
    citizenships: {
      default: [],
    },
    nationalities: {
      default: [],
    },
    locale: {
      type: String,
      default: 'ru',
    },
    uriConfirmIinExists: {
      type: String,
      required: true,
    },
    isService: {
      type: Boolean,
      default: false,
    },
    type: Number,
    placeOfResidence: String,
    actualResidence: String,
  },
  data() {
    return {
      tmpUser: {},
      loading: true,
      isResident: false,
      iin: '',
      nationality_id: null,
      citizenship_id: null,
      sur_name: '',
      first_name: '',
      middle_name: '',
      document_type: '',
      address: '',
      series: '',
      document_number: '',
      issued_by: '',
      sex: 'm',
      birth_date: '',
      errors: {
        iin: false,
      },
      error_message: null,
      dataFromIin: false,
      modalFullname: '',
      multiselectValues: {},
      multiselectArray: {},
      documentTypeOptions: [
        {
          id: 'identity_card',
          text: 'identity_card',
        },
        {
          id: 'passport',
          text: 'passport',
        },
        {
          id: 'other_document',
          text: 'other_document',
        },
      ],
      actual_residence: this.actualResidence,
    };
  },
  mounted() {
    this.loading = false;
  },
  filters: {
    translateCitizenShip(value, locale) {
      if (!value) return;

      if (locale == 'ru') {
        return value.ru_name;
      } if (locale == 'kz') {
        return value.kk_name;
      }
    },
  },
  computed: {
    parsed_citizenships() {
      if (typeof this.citizenships === 'object' && this.citizenships.length) return this.citizenships;
      return JSON.parse(this.citizenships);
    },
    parsed_nationalities() {
      if (typeof this.nationalities === 'object' && this.nationalities.length) return this.nationalities;
      return JSON.parse(this.nationalities);
    },
  },
  methods: {
    validateIIN() {
      if (!this.iin) {
        this.errors.iin = true;
        this.error_message = this.trans('warning_text.enter_iin');
        return true;
      }

      if (this.iin.length < 12 || (this.iin.split('_').length > 1)) {
        this.errors.iin = true;
        this.error_message = this.trans('warning_text.not_correct_iin');
        return true;
      }

      return false;
    },
    resetErrors() {
      this.error_message = '';
      this.errors = {
        iin: false,
      };
    },
    async approvedMerge() {
      const data = this.tmpUser;
      // debugger;
      this.sur_name = data.surname;
      this.first_name = data.firstname;
      this.middle_name = data.secondname;
      this.sex = data.gender;
      this.birth_date = data.birth_day;
      this.citizenship_id = data.citizenship;
      this.nationality_id = data.nationality;
      this.address = data.address;
      this.document_number = data.document_number;
      this.issued_by = data.issued_by;

      this.dataFromIin = true;
    },
    async checkIIN() {
      if (this.validateIIN()) return;
      this.resetErrors();

      let data = {
        iin: this.iin,
      };

      if (this.type === 13) {
        data = { ...data, ...{ type: 13 } };
      }
      debugger;

      try {
        this.loading = true;
        const response = await axios.get(`${this.uriCheckIin}`, {
          params: data,
        });

        if (response.data.status === 'fail') {
          show_notice(response.data.message, 'error');
        } else if (response.data.status === 'exists') {
          this.modalFullname = response.data.fullName;
          $('#modal-iin-exists').modal('show');
          const { data } = response.data;
          const tmp = {};
          tmp.surname = data.surname;
          tmp.firstname = data.firstname;
          tmp.secondname = data.secondname;
          tmp.gender = data.gender;
          tmp.birth_day = data.birth_day;
          tmp.citizenship = data.citizenship;
          tmp.nationality = data.nationality;
          tmp.address = data.address;
          tmp.document_number = data.document_number;
          tmp.issued_by = data.issued_by;
          this.tmpUser = tmp;
        } else if (response.data.status === 'success') {
          const { data } = response.data;

          this.sur_name = data.surname;
          this.first_name = data.firstname;
          this.middle_name = data.secondname;
          this.sex = data.gender;
          this.birth_date = data.birth_day;
          this.citizenship_id = data.citizenship;
          this.nationality_id = data.nationality;
          this.address = data.address;
          this.document_number = data.document_number;
          this.issued_by = data.issued_by;

          this.dataFromIin = true;
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.data.errors) {
          const { errors } = err.response.data;
          for (const key of Object.keys(errors)) {
            errors[key].forEach(error_message => show_notice(error_message, 'error'));
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async nextStep(e) {
      e.preventDefault();
      if (this.validateIIN()) return;

      this.resetErrors();
      try {
        this.loading = true;
        let data = {
          resident: this.isResident,
          iin: this.iin,
          citizenship_id: this.citizenship_id,
          nationality_id: this.nationality_id,
          surname: this.sur_name,
          firstname: this.first_name,
          secondname: this.middle_name,
          gender: this.sex,
          born_date: this.birth_date,
          actual_residence: this.actual_residence,
          fromStatementService: true,
        };

        if (this.type === 13) {
          const adoptionData = {
            document_type: this.document_type,
            address: this.address,
            series: this.series,
            document_number: this.document_number,
            issued_by: this.issued_by,
            type: 13,
          };

          data = { ...data, ...adoptionData };
        }

        const result = await axios.post(`${this.uriSaveUser}`, data,
          { headers: { 'X-Requested-With': 'XMLHttpRequest' } });

        if (this.isService) this.$emit('nextStep');
        else window.location.reload();
      } catch (err) {
        console.log(err);
        if (err.response.data.errors) {
          const { errors } = err.response.data;
          for (const key of Object.keys(errors)) {
            errors[key].forEach(error_message => show_notice(error_message, 'error'));
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
