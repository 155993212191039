<template>
  <div>
    <table>
      <thead>
        <tr>
          <th
            v-for="col in cols"
            :key="`col-${col.name}`"
            :class="`s-to-${col.align || 'center'}`"
            :style="`width: ${col.width}`"
            v-html="col.label"
          />
        </tr>
      </thead>
      <tbody>
        <template v-for="(service, serviceKey) in services">
          <tr
            :key="`service-${serviceKey}`"
            :class="serviceKey % 2 === 0 ? 'grey-row' : ''"
          >
            <td
              v-for="col in cols"
              :key="`service-${serviceKey}-${col.name}`"
              :class="`
              s-to-${col.align || 'center'}
               ${col.name !== 'serviceName' ? 's-medium' : ''}
                s-c-${col.name === 'freePlaces' ? 'green' : ''}
                `"
            >
              <template v-if="col.name !== 'action'">
                {{ service[col.name] }}
              </template>
              <template v-else>
                <q-icon
                  v-if="service.childServices"
                  :name="`chevron-${childSpoilers.find(itm => itm === serviceKey) !== undefined ? 'up' : 'down'}`"
                  icon-class="s-ico-fa"
                  class="sn--cursor-pointer"
                  bold
                  color="#3248A5"
                  size="2.5rem"
                  @click.native="handleChildSpoiler(serviceKey)"
                />
                <q-btn
                  v-else
                  outline
                  color="blue"
                  style="background: #F7F7FB !important;"
                  no-caps
                  class="s-medium"
                  :class="service.action.disable ? 'sn--disabled' : ''"
                  :width="$q.screen.width > 620 ? '22' : undefined"
                  :label="trans('button.send_statement')"
                  :to="service.action.disable ? undefined : service.action.to"
                  :type="service.action.disable ? undefined : 'a'"
                  @click="handleBtn(service.action)"
                />
              </template>
            </td>
          </tr>
          <template v-if="service.childServices && childSpoilers.find(itm => itm === serviceKey) !== undefined">
            <tr
              :key="`service-${serviceKey}-child-thead`"
              :class="serviceKey % 2 === 0 ? 'grey-row' : ''"
            >
              <td
                v-for="childCol in service.childServices.cols"
                :key="`service-${serviceKey}-child-thead-${childCol.name}`"
                :class="`s-to-${childCol.align}`"
                class="th"
                :colspan="childCol.colspan"
              >
                {{ childCol.label }}
              </td>
            </tr>
            <tr
              v-for="(child, childKey) in service.childServices.data"
              :key="`service-${serviceKey}-child-row-${childKey}`"
              :class="serviceKey % 2 === 0 ? 'grey-row' : ''"
            >
              <td
                v-for="childCol in service.childServices.cols"
                :key="`service-${serviceKey}-child-row-${childKey}-${childCol.name}`"
                :class="`
                s-to-${childCol.align}
                 s-c-${childCol.color === 'green' ? 'green' : ''}
                 `"
                class="s-medium"
                :colspan="childCol.colspan"
              >
                <template v-if="childCol.name !== 'action'">
                  {{ child[childCol.name] }}
                </template>
                <q-btn
                  v-else
                  outline
                  color="blue"
                  style="background: #F7F7FB !important;"
                  no-caps
                  class="s-medium"
                  :class="child.action.disable ? 'sn--disabled' : ''"
                  width="22"
                  :label="trans('button.send_statement')"
                  :to="child.action.disable ? undefined : child.action.to"
                  :type="child.action.disable ? undefined : 'a'"
                  @click="handleBtn(child.action)"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { QIcon } from '@quasar/components';

export default {
  name: 'VPassportTable',
  components: { QIcon },
  props: {
    table: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      childSpoilers: [],
    };
  },

  computed: {
    cols() {
      let cols = [
        {
          width: '36rem', name: 'serviceName', label: this.trans('table.service_name'), align: 'left',
        },
        { width: '15rem', name: 'additional' },
        { width: '15rem', name: 'totalPlaces', label: this.trans('table.places') },
        { width: '15rem', name: 'freePlaces', label: this.trans('label.free_places') },
        { width: '15rem', name: 'busyPlaces', label: this.trans('label.occupied_places') },
        { width: '25rem', name: 'action' },
      ];
      if (this.$q.screen.width < 961) {
        cols = cols.filter(itm => ['serviceName', 'totalPlaces', 'action'].includes(itm.name));
      }

      if (this.$q.screen.width < 621) {
        cols = cols.filter(itm => ['serviceName', 'action'].includes(itm.name));
        cols[0].width = 'unset';
        cols[1].width = '13rem';
      }

      return cols;
    },
    services() {
      if (this.$q.screen.width > 960) {
        return this.table;
      }
      return this.table.map((itm) => {
        const tmpItm = _.cloneDeep(itm);
        delete tmpItm.childServices;
        return tmpItm;
      });
    },
  },

  methods: {
    handleBtn({ disable, message, to }) {
      if (disable) {
        this.$notify({
          type: 'warn',
          text: message,
        });
      } else {
        window.location.href = to;
      }
    },
    handleChildSpoiler(key) {
      const index = _.findIndex(this.childSpoilers, itm => itm === key);
      if (index !== -1) {
        this.$delete(this.childSpoilers, index);
      } else {
        this.childSpoilers.push(key);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  table {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #525F99;
    border-collapse: collapse;

    .th,
    th {
      color: #525F9980;
      font-weight: 600;
      font-size: 14px;
      line-height: 173%;
    }

    td,
    th {
      padding: 1.5rem;
    }

    .grey-row {
      background: #3248A51A;
    }
  }

  @media screen and ( max-width: 620px ) {
    table {
      font-size: 11px;

      th {
        font-size: 11px;
      }

      td,
      th {
        padding: 0.75rem;
      }

      td .sn-btn {
        padding: 1rem 0.5rem 0.9rem;
      }
    }
  }
</style>
