<template>
  <div class="s-no-results">
    <div
      class="s-no-results__image"
      :class="{[`s-no-results__image--${image}`]: !!image}"
    />
    <div class="s-no-results__message">
      <div
        v-if="title"
        class="s-no-results__message-title"
      >
        {{ title }}
      </div>
      <div
        v-if="text"
        class="s-no-results__message-text"
        v-html="text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VNoResults',
  props: {
    image: String,
    title: String,
    text: String,
  },
};
</script>
