<template>
  <div
    class="s-ad-banner"
  >
    <div class="s-ad-banner__title-wrapper">
      <img
        class="s-ad-banner__image"
        :src="`${staticUrl}/base/images/logotypes/${banner.name}.png`"
        :alt="banner.name"
      >
      <div class="s-ad-banner__title">
        {{ trans(`label.download_mobile_app`, {name: lodash.startCase(lodash.toLower(banner.name))}) }}
      </div>
    </div>

    <div class="s-ad-banner__btns-wrapper">
      <a
        class="s-ad-banner__btn"
        :href="banner.iosUrl"
        target="_blank"
      >
        <img
          :src="`${staticUrl}/base/images/logotypes/app_store.png`"
        >
      </a>
      <a
        class="s-ad-banner__btn"
        :href="banner.googleUrl"
        target="_blank"
      >
        <img
          :src="`${staticUrl}/base/images/logotypes/google_play.png`"
        >
      </a>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import mixin from '../mixins/PropMixin';

export default {
  name: 'VBanner',
  mixins: [mixin],
  props: {
    banner: { type: Object, default: () => ({}) },
  },
  computed: {
    lodash() {
      return _;
    },
  },
};
</script>
