import Vue from 'vue';
import store from '@vjs/store';
import VClubsListView from '@app_mektep/views/VClubsListView';


if (document.getElementById('v-app-clubs-list')) {
  window.vAppClubsList = new Vue({
    el: '#v-app-clubs-list',
    components: { VClubsListView },
    store,
  });
}
