import { QInput, QBtn, QField } from '@quasar/components';
import _ from 'lodash';
import { StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator],

  components: { QInput, QBtn, QField },

  props: {
    value: {},
    field: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
    },
    errors: {
      type: [Object, Array],
      default() {
        return [];
      },
    },
  },
  data: () => ({
    localValue: [],
    localValueSingle: '',
  }),
  mounted() {
    this.setInitialValue();
  },
  methods: {
    handleRemove(index) {
      this.$delete(this.localValue, index);
      this.$emit('optionRemoved');
    },
    handleAddValue() {
      this.localValue.push(this.localValueSingle);
      this.localValueSingle = '';
    },
    onChange() {
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
    setInitialValue() {
      this.localValue = _.isArray(this.field.value) ? this.field.value : [];
    },
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
  },
  computed: {
    fieldLabel() {
      if (this.field.label === '') {
        return '';
      }

      return this.field.label
        || (this.field.labelTransKey && this.trans(this.field.labelTransKey));
    },
    isDisabled() {
      return _.get(this.field, 'extraAttributes.disabled', false)
        || _.get(this.field, 'extraAttributes.disabled', false);
    },
  },
  watch: {
    localValue(newVal, oldVal) {
      this.$emit('change', newVal, oldVal, this.lastSingleVal);
      this.$emit('input', newVal, oldVal, this.lastSingleVal);
    },
    value() {
      this.setInitialValue();
    },
  },
};
