import _ from 'lodash';
import eventHub from '@vjs/config/eventHub';
import Notify from '@vjs/components/Notify/Notify.vue';
import {
  url,
} from '@vjs/helpers';
import Npa from './Npa.vue';

export default {
  name: 'NpaList',
  components: { Npa, Notify },
  props: {
    destinationUrl: String,
    removalString: String,
  },
  data() {
    return {
      list: [],
      years: [],
      removeUrl: url(this.removalString),
    };
  },
  created() {
    this.$trans.add([
      'organization',
      'button',
      'warning_text',
      'statistics',
      'label',
      'validation',
    ]);
  },
  async mounted() {
    eventHub.$on('file-remove-success', this.removeDoc);
    eventHub.$on('file-add-success', this.addDoc);
    {
      const { data } = await this.$axios({
        url: `/${window.core_project.locale}/api/control/organization/npa`,
      });
      const { items } = data;
      items.forEach((item, index) => {
        items[index].list = this.sortList(item.list);
      });
      this.list = items;
    }
    {
      const { data } = await this.$axios({
        url: `/${window.core_project.locale}/api/control/organization/npa-years`,
      });
      this.years = data;
    }
  },
  methods: {
    removeDoc(payload) {
      const curKind = _.find(this.list, o => o.kind.kind === payload.kind);
      curKind.list = curKind.list.filter(o => o.id !== payload.docID);
    },
    addDoc(data) {
      const curKind = _.find(this.list, o => o.kind.kind === data.kind);
      curKind.list.push(data.payload);
      curKind.list = this.sortList(curKind.list);
    },
    sortList(arr) {
      return _.sortBy(arr, ['name']).reverse();
    },
  },
};
