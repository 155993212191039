import Vue from 'vue';
import store from '@vjs/store';
import VServiceLimitEditView from '@app_mektep/views/VServiceLimitEditView';
import Notify from '@vjs/components/Notify/Notify.vue';


if (document.getElementById('v-service-limit-edit-view')) {
  window.vServiceLimitEditView = new Vue({
    el: '#v-service-limit-edit-view',
    components: { VServiceLimitEditView, Notify },
    store,
  });
}
