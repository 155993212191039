import { Form } from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [Form],
  props: {
    refs: {
      type: Object,
      default: () => {},
    },
    updateUrl: {
      type: String,
      default: '',
    },
    concludeUrl: {
      type: String,
      default: '',
    },
    resource: {
      type: Object,
      default: () => {},
    },
    method: {
      type: String,
      default: 'post',
    },
    fields: {
      type: Object,
      required: true,
    },
  },
  beforeCreate() {
    this.$trans.add(['fields', 'label', 'pmpc']);
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const data = {};

      // TODO decide how to handle fiiled and not filled
      _.forEach(this.fields, (field) => {
        data[field.name] = field.value;
      });

      const res = await requestWrapper.call(this, {
        method: this.method,
        url: this.updateUrl,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
    async conclude() {
      this.$globalLoading.show();

      const data = {};

      // TODO decide how to handle fiiled and not filled
      _.forEach(this.fields, (field) => {
        data[field.name] = field.value;
      });

      const res = await requestWrapper.call(this, {
        method: this.method,
        url: this.concludeUrl,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
