<template>
  <div class="s-table-with-filters__statistic">
    <div
      v-for="(table, tableKey) in items"
      :key="tableKey"
      class="mb-30"
    >
      <v-info-card
        :title="table.title"
        with-spoiler
      >
        <q-table
          :data="table.rows"
          :columns="[]"
          :pagination="{rowsPerPage: 0}"
          flat
          separator="cell"
          class="s-b-white"
          wrap-cells
          hide-bottom
          hide-header
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td>{{ props.row.label }}</q-td>
              <q-td style="width: 10.5rem; min-width: 10.5rem;">
                {{ props.row.value }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </v-info-card>
    </div>
  </div>
</template>

<script>
import { QTable, QTr, QTd } from '@quasar/components/table';
import VInfoCard from '@common/components/VInfoCard';

export default {
  name: 'NewTableStatisticRows',
  components: {
    QTd, QTr, QTable, VInfoCard,
  },
  props: {
    items: {
      type: [Object, Array],
      default: () => ([]),
    },
  },
};
</script>
