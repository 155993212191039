import Vue from 'vue';

export default Vue.extend({
  name: 'QSeparator',

  props: {
    dark: Boolean,
    spaced: Boolean,
    inset: [Boolean, String],
    vertical: Boolean,
    color: String,
  },

  computed: {
    classes() {
      return {
        [`s-b-${this.color}`]: this.color,
        'sn-separator--dark': this.dark,
        'sn-separator--spaced': this.spaced,
        'sn-separator--inset': this.inset === true,
        'sn-separator--item-inset': this.inset === 'item',
        'sn-separator--item-thumbnail-inset': this.inset === 'item-thumbnail',
        [`sn-separator--${this.vertical ? 'vertical sn--self-stretch' : 'horizontal sn--col-grow'}`]: true,
      };
    },
  },

  render(h) {
    return h('hr', {
      staticClass: 'sn-separator',
      class: this.classes,
    });
  },
});
