import { FormField, StoreTranslator } from '@common/mixins';
import { QInput } from '@quasar/components';

export default {
  mixins: [StoreTranslator, FormField],
  components: { QInput },
  props: {},
  data() {
    const config = this.getConfig();
    return {
      config,
    };
  },
  beforeMount() {
    this.$trans.add(['label', 'placeholder']);
  },
  methods: {
    getConfig() {
      let config = {
        mask: '###',
        min: 0,
        max: 999,
      };
      if (this.field.hasOwnProperty('config')) {
        config = this.field.config;
      }
      return config;
    },
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
  },
  computed: {
    defaultAttributes() {
      return {
        type: this.field.type || 'text',
        placeholder: this.field.placeholder,
        counter: true,
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
  watch: {
    'field.config': function () {
      this.config = this.getConfig();
    },
    localValue(value, oldVal) {
      const { min, max } = this.config;
      const ref = this.$refs['q-number-field'];
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(Number(value))) {
        this.localValue = oldVal;
        setTimeout(ref.updateInnerValue, 0);
        return;
      }
      if (![null, undefined, ''].includes(value)) {
        if (![null, undefined, ''].includes(min) && Number(value) < min) {
          this.localValue = min;
          setTimeout(ref.updateInnerValue, 0);
          return;
        }

        if (![null, undefined, ''].includes(max) && Number(value) > max) {
          this.localValue = max;
          setTimeout(ref.updateInnerValue, 0);
          return;
        }

        if (value !== Number(value)) {
          this.localValue = Number(value);
        }
      }
      this.fieldEmitInput(value);
    },
  },
};
