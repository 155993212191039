<template>
  <div class="s-map-balloon">
    <div class="s-map-balloon__title">
      {{ item.title }}
    </div>
    <div class="s-map-balloon__info">
      {{ item.address }}
    </div>
    <div class="s-map-balloon__info">
      {{ item.phoneNumber }}
    </div>
    <div class="s-map-balloon__item">
      <span class="s-map-balloon__item-label">
        {{ trans('label.statement_admission') }}:
      </span>
      <span class="s-map-balloon__item-value">
        {{ item.admissionActive ? trans('label.admission_active') : trans('label.admission_not_active') }}
      </span>
    </div>
    <div class="s-map-balloon__footer">
      <q-btn
        no-caps
        :label="trans('button.more_info')"
        color="blue"
        type="a"
        target="_blank"
        :href="item.linkItem"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};

</script>
