export default {
  props: {
    value: null,
    label: null,
    required: Boolean,
    disable: Boolean,
    equal: Boolean,
    config: {
      type: Object,
      default: () => ({
        max: 3,
      }),
    },
  },
  data() {
    return {
      files: [],
      activeIndex: null,
      max: this.config.max,
    };
  },
  mounted() {
    this.setInitialValue();
    this.$trans.add('validation');
  },
  methods: {
    setInitialValue() {
      if (this.value) {
        this.files = this.value;
      }
    },
    onClickInputFile(key) {
      if (this.disable) return;
      if (![null, undefined].includes(key)) this.activeIndex = key;
      const el = this.$refs.input_file;
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onChangeInputFile(e) {
      if (this.disable) return;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      if (![null, undefined].includes(this.activeIndex)) {
        this.$set(this.files, this.activeIndex, file);
      } else {
        this.files.push(file);
      }
      this.activeIndex = null;
      e.target.value = null;
    },
  },
  watch: {
    value() {
      this.setInitialValue();
    },
    files: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
  },
};
