import CardMixin from '../CardMixin';
import CardTableMixin from '../CardTableMixin';

export default {
  mixins: [CardMixin, CardTableMixin],

  data() {
    return {
      newRowTemplate: {
        position: {},
      },
    };
  },

  computed: {
    cols() {
      let result = [
        {
          label: this.trans('personnel.blocks.fields.orgName'),
          name: 'orgName',
          field: 'orgName',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.position'),
          name: 'position',
          field: 'position',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.startDate'),
          name: 'startDate',
          field: 'startDate',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.endDate'),
          name: 'endDate',
          field: 'endDate',
          align: 'center',
        },
        {
          label: this.trans('label.operations'),
          name: 'actions',
          field: 'actions',
          align: 'center',
          width: 140,
        },
      ];
      if (this.disabled) {
        result = result.filter(itm => itm.field !== 'actions');
      }
      return result;
    },
  },
};
