import { QIcon } from '@quasar/components';

export default {
  name: 'VOrganizationProfileAdditional',
  props: {
    teacherRegisterConfig: {
      type: Object,
      required: false,
    },
    schoolServiceAddressConfig: {
      type: Object,
      required: false,
    },
    uriAddAddress: {
      type: String,
      required: false,
    },
  },
  components: {
    QIcon,
  },
  data() {
    return {
      hasTeacherRegister: true,
      hasSchoolServiceAddress: true,
      spoilers: {
        teacherRegister: true,
        schoolServiceAddress: true,
      },
    };
  },
  async mounted() {
    await this.$trans.add(['label', 'button']);
  },
};
