import { Form } from '@common/mixins';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';

export default {
  mixins: [Form],
  props: {
    action: {
      type: String,
      required: true,
    },
    refs: {
      type: Object,
      required: true,
    },
    uriBack: {
      type: String,
      required: true,
    },
  },
  beforeCreate() {
    this.$trans.add('book');
  },
  data() {
    return {
      fields: {
        author: {
          labelTransKey: 'book.author',
          value: null,
          name: 'author',
          component: 'q-text-field',
          required: true,
        },
        name: {
          labelTransKey: 'book.name',
          value: null,
          name: 'name',
          component: 'q-text-field',
          required: true,
        },
        category: {
          labelTransKey: 'book.category',
          value: null,
          values: this.refs.categories,
          name: 'category',
          component: 'q-select-field',
          required: true,
        },
        direction: {
          labelTransKey: 'book.direction.title',
          value: null,
          values: this.refs.directions,
          name: 'direction',
          component: 'q-select-field',
          required: false,
        },
        subject_id: {
          labelTransKey: 'book.subject.title',
          value: null,
          values: this.refs.subjects,
          name: 'subject_id',
          component: 'q-select-field',
          required: false,
        },
        publisher: {
          labelTransKey: 'book.publisher',
          value: null,
          name: 'publisher',
          component: 'q-text-field',
          required: true,
        },
        published_date: {
          labelTransKey: 'book.published_date',
          value: null,
          name: 'published_date',
          component: 'q-number-field',
          required: true,
          extraAttributes: { counter: false },
          config: { mask: '####' },
        },
        isbn: {
          labelTransKey: 'book.isbn',
          value: null,
          name: 'isbn',
          component: 'q-text-field',
          required: false,
        },
        price: {
          labelTransKey: 'book.price',
          value: null,
          name: 'price',
          component: 'q-number-field',
          required: true,
          extraAttributes: { counter: false },
          config: { max: 99999 },
        },
        edu_lang_id: {
          labelTransKey: 'book.edu_lang_id',
          value: null,
          values: this.refs.languages,
          name: 'edu_lang_id',
          component: 'q-select-field',
          required: true,
        },
        is_from_to_class_number: {
          value: false,
          name: 'is_from_to_class_number',
          component: 'q-checkbox-field',
          checkboxLabelTransKey: 'book.is_from_to_class_number',
          required: false,
        },
        class_number: {
          labelTransKey: 'book.class_number',
          value: null,
          values: this.refs.classes,
          name: 'class_number',
          component: 'q-select-field',
          required: false,
          extraAttributes: {
            useInput: false,
          },
          v_if: () => !this.fields.is_from_to_class_number.value,
        },
        from_to_class_number: {
          labelTransKey: 'book.from_to_class_number',
          name: 'from_to_class_number',
          component: 'q-between-field',
          required: false,
          v_if: () => this.fields.is_from_to_class_number.value,
          configComponent: {
            component: 'q-select-field',
            extraAttributes: {
              useInput: false,
            },
            fieldFrom: {
              value: null,
              values: this.refs.classes,
              name: 'class_number_from',
            },
            fieldTo: {
              value: null,
              values: this.refs.classes,
              name: 'class_number_to',
            },
          },
        },
      },
    };
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const data = {};

      _.forEach(this.fields, (field) => {
        if (field.v_if === undefined || field.v_if(this)) {
          if (field.component === 'q-between-field') {
            data[field.configComponent.fieldFrom.name] = field.configComponent.fieldFrom.value;
            data[field.configComponent.fieldTo.name] = field.configComponent.fieldTo.value;
          } else {
            data[field.name] = field.value;
          }
        }
      });

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }
      return true;
    },
  },
};
