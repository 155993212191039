import Vue from 'vue';

import themify from '../icon-set/themify';

export default {
  __installed: false,
  install($q, iconSet) {
    this.set = (iconDef = themify) => {
      iconDef.set = this.set;

      if ($q.iconSet !== void 0) {
        $q.iconSet = iconDef;
      } else {
        Vue.util.defineReactive($q, 'iconSet', iconDef);
      }

      this.name = iconDef.name;
      this.def = iconDef;
    };

    this.set(iconSet);
  },
};
