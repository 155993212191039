import { Form } from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [Form],
  props: {
    refs: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    uriBack: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
      default: () => ({
        authors: null,
        name: null,
        class: null,
        type: null,
      }),
    },
    method: {
      type: String,
      default: 'post',
    },
  },
  beforeCreate() {
    this.$trans.add(['fields', 'book']);
  },
  data() {
    return {
      fields: {
        authors: {
          labelTransKey: 'fields.filters.library_briefcase_content.authors',
          value: this.resource.authors,
          name: 'authors',
          component: 'q-text-field',
          required: !this.isEdit,
          extraAttributes: {
            disabled: this.isEdit,
          },
        },
        name: {
          labelTransKey: 'fields.filters.library_briefcase_content.name',
          value: this.resource.name,
          name: 'name',
          component: 'q-text-field',
          required: !this.isEdit,
          extraAttributes: {
            disabled: this.isEdit,
          },
        },
        class: {
          labelTransKey: 'book.class_number',
          value: this.resource.class,
          name: 'class',
          component: 'q-text-field',
          required: !this.isEdit,
          extraAttributes: {
            disabled: this.isEdit,
          },
        },
        // class: {
        //   labelTransKey: 'book.class_number',
        //   value: this.resource.class,
        //   values: this.refs.classes,
        //   name: 'class',
        //   component: 'q-select-field',
        //   required: !this.isEdit,
        //   extraAttributes: {
        //     disabled: this.isEdit,
        //     useInput: false,
        //   },
        // },
        type: {
          labelTransKey: 'fields.filters.library_briefcase_content.type',
          value: this.resource.type,
          values: this.refs.types,
          name: 'type',
          component: 'q-select-field',
          required: !this.isEdit,
          extraAttributes: {
            disabled: this.isEdit,
            useInput: false,
          },
        },
      },
    };
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const data = {};

      _.forEach(this.fields, (field) => {
        data[field.name] = field.value;
      });

      const res = await requestWrapper.call(this, {
        method: this.method,
        url: this.action,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
