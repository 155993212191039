import _ from 'lodash';
import Vue from 'vue';
import { Helper } from '@common/src/helpers';
import { PLUGIN_NAME } from './const';

const pluginStoreConfig = {
  namespaced: true,
  state: {
    ru: {},
    kz: {},
    load: new Set(),
    loaded: new Set(),
    timeout: null,
    results: new Set(),
  },
  mutations: {
    setLang(state, payload = {}) {
      state[window.core_project.locale] = {
        ...state[window.core_project.locale],
        ...payload,
      };
      _.forEach(payload, (item, groupName) => {
        if (!state.loaded.has(groupName)) {
          state.loaded.add(groupName);
        }
      });
      Vue.trans.eventBus.$emit(`${PLUGIN_NAME}/loaded-translate`, payload);
    },
  },
  actions: {
    setLang({ commit }, payload = {}) {
      commit('setLang', payload);
    },
    addLoad({ state }, payload) {
      return new Promise((res) => {
        const exists = this.getters[`${PLUGIN_NAME}/exists`];
        if (_.isArray(payload)) {
          payload.forEach((item) => {
            if (!exists(item)) {
              if (!state.load.has(item)) {
                state.load.add(item);
              }
            }
          });
        } else if (_.isString(payload)) {
          if (!exists(payload)) {
            state.load.add(payload);
          }
        }

        state.results.add(res);
        clearTimeout(state.timeout);
        state.timeout = setTimeout(async () => {
          if (window?.core_project?.trans_v && window?.core_project?.trans_v !== 1 && state.results.length) {
            return;
          }
          if (state.load.size === 0) {
            return;
          }
          const needLoad = new Set();
          state.load.forEach((item) => {
            if (!state.loaded.has(item)) {
              needLoad.add(item);
            }
          });
          if (needLoad.size === 0) {
            state.results.forEach(result => result());
            state.results.clear();
            return;
          }
          if (Vue.globalLoading) {
            Vue.globalLoading.show(true);
          }
          const params = {
            v: window.core_project.trans_v,
            locale: window.core_project.locale,
            scopes: [...needLoad],
          };

          if (window?.core_project?.trans_v && window?.core_project?.trans_v !== 1) {
            delete params.scopes;
          }
          const onSuccess = (response) => {
            const { body } = response;
            if (Vue.globalLoading) {
              Vue.globalLoading.hide();
            }
            this.dispatch(`${PLUGIN_NAME}/setLang`, { ...body });
            state.results.forEach(result => result(body));
            state.results.clear();
          };

          const onError = (response) => {
            Helper.showErrors(response);
          };

          const requestParams = {
            responseType: 'json',
            method: 'GET',
            params,
            headers: {
              'Accept-Language': window.core_project.locale,
            },
          };

          const defaultRequest = async () => await Vue.http.get('/api/translate', requestParams)
            .then(onSuccess, onError);

          if (window.core_project.trans_url) {
            await Vue.http.get(window.core_project.trans_url, requestParams)
              .then(onSuccess, async () => { await defaultRequest(); });
          } else {
            await defaultRequest();
          }
        }, 100);
      });
    },
  },
  getters: {
    lang: state => (val = '', params) => {
      let str = _.get(state[window.core_project.locale], val, val);
      if (_.isObject(params) && str !== undefined) {
        _.forEach(params, (value, key) => {
          str = str.replace(`:${key}`, value);
        });
        return str;
      }
      return str;
    },
    trans: state => (val = '', params) => {
      let str = _.get(state[window.core_project.locale], val);
      if (_.isObject(params) && str !== undefined) {
        _.forEach(params, (value, key) => {
          str = str.replace(`:${key}`, value);
        });
        return str;
      }
      return str;
    },
    exists: state => groupName => state.load.has(groupName),
    existsLoaded: state => groupName => state.loaded.has(groupName),
  },
};
export default pluginStoreConfig;
