import Vue from 'vue';

import HistoryMixin from '../../mixins/history';
import ModelToggleMixin from '../../mixins/model-toggle';
import PortalMixin from '../../mixins/portal';
import PreventScrollMixin from '../../mixins/prevent-scroll';

import { childHasFocus } from '../../utils/dom';
import EscapeKey from '../../utils/escape-key';
import slot from '../../utils/slot';
import { create, stop, stopAndPrevent } from '../../utils/event';

let maximizedModals = 0;

const positionClass = {
  standard: 's-pos-fixed-full sn--flex-center',
  top: 's-pos-fixed-top sn--justify-center',
  bottom: 's-pos-fixed-bottom sn--justify-center',
  right: 's-pos-fixed-right sn--items-center',
  left: 's-pos-fixed-left sn--items-center',
};

const transitions = {
  standard: ['scale', 'scale'],
  top: ['slide-down', 'slide-up'],
  bottom: ['slide-up', 'slide-down'],
  right: ['slide-left', 'slide-right'],
  left: ['slide-right', 'slide-left'],
};

export default Vue.extend({
  name: 'QDialog',

  mixins: [HistoryMixin, ModelToggleMixin, PortalMixin, PreventScrollMixin],

  props: {
    persistent: Boolean,
    autoClose: Boolean,

    noEscDismiss: Boolean,
    noBackdropDismiss: Boolean,
    noRouteDismiss: Boolean,
    noRefocus: Boolean,
    noFocus: Boolean,

    seamless: Boolean,

    maximized: Boolean,
    fullWidth: Boolean,
    fullHeight: Boolean,

    square: Boolean,

    position: {
      type: String,
      default: 'standard',
      validator(val) {
        return val === 'standard' || ['top', 'bottom', 'left', 'right'].includes(val);
      },
    },

    transitionShow: String,
    transitionHide: String,
  },

  data() {
    return {
      transitionState: this.showing,
    };
  },

  computed: {
    classes() {
      return `${`sn-dialog__inner--${this.maximized === true ? 'maximized' : 'minimized'} `
        + `sn-dialog__inner--${this.position} ${positionClass[this.position]}`}${
        this.fullWidth === true ? ' sn-dialog__inner--fullwidth' : ''
      }${this.fullHeight === true ? ' sn-dialog__inner--fullheight' : ''
      }${this.square === true ? ' sn-dialog__inner--square' : ''}`;
    },

    transitionShowComputed() {
      return `sn-transition--${this.transitionShow === void 0 ? transitions[this.position][0] : this.transitionShow}`;
    },

    transitionHideComputed() {
      return `sn-transition--${this.transitionHide === void 0 ? transitions[this.position][1] : this.transitionHide}`;
    },

    transition() {
      return this.transitionState === true
        ? this.transitionHideComputed
        : this.transitionShowComputed;
    },

    useBackdrop() {
      return this.showing === true && this.seamless !== true;
    },

    hideOnRouteChange() {
      return this.persistent !== true
        && this.noRouteDismiss !== true
        && this.seamless !== true;
    },
  },

  watch: {
    showing(val) {
      if (this.transitionShowComputed !== this.transitionHideComputed) {
        this.$nextTick(() => {
          this.transitionState = val;
        });
      }
    },

    maximized(newV, oldV) {
      if (this.showing === true) {
        this.__updateState(false, oldV);
        this.__updateState(true, newV);
      }
    },

    useBackdrop(v) {
      this.__preventScroll(v);
      this.__preventFocusout(v);
    },
  },

  mounted() {
    this.__processModelChange(this.value);
  },

  beforeDestroy() {
    this.__cleanup();
  },

  methods: {
    focus() {
      let node = this.__getInnerNode();

      if (node === void 0 || node.contains(document.activeElement) === true) {
        return;
      }

      node = node.querySelector('[autofocus]') || node;
      node.focus();
    },

    shake() {
      this.focus();

      const node = this.__getInnerNode();

      if (node !== void 0) {
        node.classList.remove('sn--animate-scale');
        node.classList.add('sn--animate-scale');
        clearTimeout(this.shakeTimeout);
        this.shakeTimeout = setTimeout(() => {
          node.classList.remove('sn--animate-scale');
        }, 170);
      }
    },

    __getInnerNode() {
      return this.__portal !== void 0 && this.__portal.$refs !== void 0
        ? this.__portal.$refs.inner
        : void 0;
    },

    __show(evt) {
      this.__addHistory();

      // IE can have null document.activeElement
      this.__refocusTarget = this.noRefocus === false && document.activeElement !== null
        ? document.activeElement
        : void 0;

      this.$el.dispatchEvent(create('popup-show', { bubbles: true }));

      this.__updateState(true, this.maximized);

      EscapeKey.register(this, () => {
        if (this.seamless !== true) {
          if (this.persistent === true || this.noEscDismiss === true) {
            this.maximized !== true && this.shake();
          } else {
            this.$emit('escape-key');
            this.hide();
          }
        }
      });

      this.__showPortal();

      if (this.noFocus !== true) {
        // IE can have null document.activeElement
        document.activeElement !== null && document.activeElement.blur();
        this.__nextTick(this.focus);
      }

      this.__setTimeout(() => {
        this.$emit('show', evt);
      }, 300);
    },

    __hide(evt) {
      this.__removeHistory();
      this.__cleanup(true);

      // check null for IE
      if (this.__refocusTarget !== void 0 && this.__refocusTarget !== null) {
        this.__refocusTarget.focus();
      }

      this.$el.dispatchEvent(create('popup-hide', { bubbles: true }));

      this.__setTimeout(() => {
        this.__hidePortal();
        this.$emit('hide', evt);
      }, 300);
    },

    __cleanup(hiding) {
      clearTimeout(this.shakeTimeout);

      if (hiding === true || this.showing === true) {
        EscapeKey.pop(this);
        this.__updateState(false, this.maximized);
        if (this.useBackdrop === true) {
          this.__preventScroll(false);
          this.__preventFocusout(false);
        }
      }
    },

    __updateState(opening, maximized) {
      if (maximized === true) {
        if (opening === true) {
          maximizedModals < 1 && document.body.classList.add('sn-body--dialog');
        } else if (maximizedModals < 2) {
          document.body.classList.remove('sn-body--dialog');
        }
        maximizedModals += opening === true ? 1 : -1;
      }
    },

    __preventFocusout(state) {
      if (this.$q.platform.is.desktop === true) {
        const action = `${state === true ? 'add' : 'remove'}EventListener`;
        document.body[action]('focusin', this.__onFocusChange);
      }
    },

    __onAutoClose(e) {
      this.hide(e);
      this.$listeners.click !== void 0 && this.$emit('click', e);
    },

    __onBackdropClick(e) {
      if (this.persistent !== true && this.noBackdropDismiss !== true) {
        this.hide(e);
      } else {
        this.shake();
      }
    },

    __onFocusChange(e) {
      // the focus is not in a vue child component
      if (
        this.showing === true
        && this.__portal !== void 0
        && childHasFocus(this.__portal.$el, e.target) !== true
      ) {
        this.focus();
      }
    },

    __renderPortal(h) {
      const on = {
        ...this.$listeners,
        // stop propagating this events from children
        input: stop,
        'popup-show': stop,
        'popup-hide': stop,
      };

      if (this.autoClose === true) {
        on.click = this.__onAutoClose;
      }

      return h('div', {
        staticClass: 'sn-dialog s-pos-fullscreen sn--no-pointer-events',
        class: this.contentClass,
        style: this.contentStyle,
        attrs: this.$attrs,
      }, [
        h('transition', {
          props: { name: 'sn-transition--fade' },
        }, this.useBackdrop === true ? [
          h('div', {
            staticClass: 'sn-dialog__backdrop s-pos-fixed-full',
            on: {
              touchmove: stopAndPrevent, // prevent iOS page scroll
              click: this.__onBackdropClick,
            },
          }),
        ] : null),

        h('transition', {
          props: { name: this.transition },
        }, [
          this.showing === true ? h('div', {
            ref: 'inner',
            staticClass: 'sn-dialog__inner sn--flex sn--no-pointer-events',
            class: this.classes,
            attrs: { tabindex: -1 },
            on,
          }, slot(this, 'default')) : null,
        ]),
      ]);
    },
  },
});
