import datesGenerator from '@common/plugins/CalendarGenerator/datesGenerator';
import { eventBus } from '@common/mixins';
import _ from 'lodash';
import { QTable, QPopupProxy } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import VFormInform from '@vjs/components/VFormInform';
import VNoResults from '@vjs/components/VNoResults';
import moment from 'moment';

export default {
  name: 'CalendarTable',
  components: {
    VFormInform, QTable, VNoResults, QPopupProxy,
  },
  props: { uriGetData: String },
  data: () => ({
    calendarDates: [],
    calendarData: [],
    year: 0,
    month: 0,
    pagination: { rowsPerPage: 0 },
  }),
  computed: {
    cols() {
      return [
        {
          label: this.trans('label.date'),
          field: 'date',
        },
        {
          label: this.trans('label.weekday'),
          field: 'weekday',
        },
        {
          label: this.trans('table.meetings'),
          field: 'meetings',
        },
      ];
    },
    shortWeekDays() {
      const weekDays = [
        'monday', 'tuesday', 'wednesday', 'thursday',
        'friday', 'saturday', 'sunday',
      ];
      const result = [];
      _.forEach(weekDays, weekDay => result.push(this.trans(`label.week_days_short.${weekDay}`)));
      return result;
    },
    qWidth() {
      return this.$q.screen.width;
    },
  },
  async mounted() {
    eventBus.$on('initCalendar', this.initCalendar);
    eventBus.$on('modalRequestSuccess', () => this.initCalendar(this.year, this.month));
  },
  methods: {
    handleOpenMeetingInfoModal(uriGetData) {
      this.$sModal.open('v-modal-info-table-with-actions', {
        size: '480',
        title: this.trans('label.meeting_info'),
        binds: {
          uriGetData,
        },
        component: 'v-modal-info-table-with-actions',
      });
    },
    getAdaptiveValue(dateData, field) {
      switch (field) {
        case 'date':
          return _.upperFirst(moment(new Date(dateData.date)).format('DD MMMM Y'));
        case 'weekday':
          return _.upperFirst(moment(new Date(dateData.date)).format('dddd'));
        default:
          return null;
      }
    },
    getDateMeetings(date, forPopup) {
      const dateData = this.calendarData.find(
        item => (new Date(item.date)).getTime() === (new Date(date)).getTime(),
      );
      const dateDataMeetings = dateData?.meetings ?? [];
      let meetings;
      if (dateDataMeetings.length > 3) {
        meetings = forPopup
          ? dateDataMeetings.slice(2)
          : dateDataMeetings.slice(0, 2 - dateDataMeetings.length);
      } else {
        meetings = forPopup ? [] : dateDataMeetings;
      }
      return meetings ?? [];
    },
    isDayCurrent(date) {
      const currentDate = new Date();
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
      currentDate.setMilliseconds(0);

      return currentDate.getTime() === (new Date(date)).getTime();
    },
    async initCalendar({ year, month, state }) {
      this.calendarData = [];
      const body = {
        year,
        month,
        startingDay: 1,
      };

      const { dates } = datesGenerator(body);

      this.calendarDates = dates;
      this.year = year;
      this.month = month;

      await this.requestGetData(state);
    },
    async requestGetData(state = null) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
        params: {
          year: this.year,
          month: this.month + 1,
          state,
        },
      });

      if (!res.error) {
        this.calendarData = res.data;
      }
      this.$globalLoading.hide();
    },
  },
};
