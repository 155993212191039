import Validation from '@vjs/mixins/Validation';
import { QInput } from '@quasar/components/input';

export default {
  name: 'VSchoolServiceAddressForm',
  components: { QInput },
  mixins: [Validation],
  props: {
    school: {
      type: Object,
      required: true,
    },
    schoolServiceAddress: {
      type: Object,
      default: null,
    },
    apiConfig: {
      type: Object,
      default: () => ({
        backButtonUrl: '',
        actionApiUrl: '',
        uriGetStreets: '',
      }),
    },
  },
  data() {
    return {
      form: {
        street: this.schoolServiceAddress?.street || null,
        houses: this.schoolServiceAddress?.houses || null,
      },
      options: {
        streets: [],
      },
      selectedStreetOption: null,
      streetSelectInputValue: '',
      streetsLoading: false,
      currentStreetsPage: 1,
      streetsLastPage: 1,
    };
  },

  computed: {
    validate() {
      return this.form.address !== null && this.form.houses !== null;
    },
  },

  async mounted() {
    await this.$trans.add([
      'button',
      'warning_text',
      'fields',
    ]);
  },

  methods: {
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.actionApiUrl,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        this.$globalLoading.hide();
      }
    },
    async requestSearchStreets(val, update) {
      this.streetsLoading = true;
      this.currentStreetsPage = 1;
      await this.requestGetStreetsByFilter().then((res) => {
        update(() => {
          this.options.streets = res.items;
          this.streetsLoading = false;
          this.streetsLastPage = res.lastPage;
        });
      }, () => {});
    },
    async requestGetStreetsByFilter() {
      return new Promise(async (res, rej) => {
        const search = this.streetSelectInputValue.toLowerCase();
        if (search) {
          const result = await requestWrapper.call(this, {
            url: this.apiConfig.uriGetStreets,
            params: {
              locality: this.school.locality_id,
              page: 1,
              search,
            },
          });

          if (!result.error) {
            res(result);
          } else {
            rej(result);
          }
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          rej(false);
        }
      });
    },
    async onStreetScroll({ index }) {
      if (!this.streetsLoading
        && this.currentStreetsPage < this.streetsLastPage
        && index >= (this.options.streets.length - 21)
      ) {
        this.currentStreetsPage += 1;

        await this.requestGetStreets(false);
        this.$nextTick(() => {
          this.$refs.streetSelect.$refs['q-select-wrapper-ref'].refresh();
          this.streetsLoading = false;
        });
      }
    },
    async requestGetStreets(offLoading = true) {
      this.streetsLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.uriGetStreets,
        params: {
          locality: this.school.locality_id,
          page: this.currentStreetsPage,
          search: this.streetSelectInputValue,
        },
      });

      if (!res.error) {
        this.options.streets = [...this.options.streets, ...res.items];
        this.streetsLastPage = res.lastPage;
      }
      if (offLoading) {
        this.streetsLoading = false;
      }
    },
  },
};
