import VLoginForm from '@common/views/VLoginForm';
import RegistrationPhoneForm from '@common/views/FormRegistrationSms';
import VOrganizationProfile from '@common/views/VOrganizationProfile';
import VOrganizationEditForm from '@common/views/VOrganizationEditForm';
import VOrganizationInfo from '@common/views/VOrganizationInfo';
import NewTableWithFilters from '@common/views/NewTableWithFilters';
import VSupportTeamButton from '@vjs/balabaqsha/views/VSupportTeamButton';
import VProfileForm from './Profile/Form';
import VStatementServiceForm from './VStatementServiceForm';
import VBinomConfirmationIinForm from './Binom/VConfirmationIinForm';
import VContingentForm from './Contingent/Form';
import VBookCreateForm from './VBookCreate/Form';
import VElectronicContractCreate from './VElectronicContractCreate/Form';
import VSchoolServiceAddressForm from './VSchoolServiceAddressForm/Form';
import VLibraryStatementBookForm from './VLibraryStatementBookForm';
import VLibraryBriefcaseForm from './VLibraryBriefcaseForm';
import VLibraryBriefcaseContentForm from './VLibraryBriefcaseContentForm';
import VElectronicContractConclude from './VElectronicContractConclude';
import VElectronicContractShow from './VElectronicContractShow';
import ParentStatementShow from './ParentStatementShow';
import SubjectQuestions from './SubjectQuestions';
import VMainPage from './VMainPage';
import VPassportPageView from './VPassportPageView';
import VBooksAdditionAlt from './VBooksAdditionAlt';
import EditableTable from './EditableTable';
import VLibraryStatementBookTable from './VLibraryStatementBookTable';
import VInProgressAltBooksList from './VInProgressAltBooksList';
import VLibraryActArrivalShow from './VLibraryActArrivalShow';
import VLibraryActWriteOffShow from './VLibraryActWriteOffShow';
import VLibraryActTransferShow from './VLibraryActTransferShow';
import VMonitoringList from './VMonitoringList';
import VLibraryDebitCreditMonitoring from './Monitorings/VLibraryDebitCreditMonitoring';
import VMonitoringTree from './Monitorings/VMonitoringTree';
import VLibraryStatementShow from './VLibraryStatementShow';
import VLibraryBookPlansForm from './VLibraryBookPlansForm';
import VRegistrationCheck from './VRegistrationCheck';
import VOrganizationProfileAdditional from './VOrganizationProfileAdditional';
import VSchoolCertificateStatementForm from './VSchoolCertificateStatementForm';
import VPersonnelCardForm from './VPersonnelCardForm';
import VLaborReportForm from './VLaborReportForm';
import ProfileAvatar from './ProfileAvatar';
import VMeetingsCalendar from './VMeetingsCalendar';
import VCustomForm from './VCustomForm';
import VOrgMTB from './VOrgMTB';

export default function (Vue) {
  Vue.component('vue-registration-phone-form', RegistrationPhoneForm);
  Vue.component('vue-binom-confirmation-iin-form', VBinomConfirmationIinForm);
  Vue.component('v-login-form', VLoginForm);

  Vue.component('v-organization-profile', VOrganizationProfile);
  Vue.component('v-organization-info', VOrganizationInfo);
  Vue.component('v-organization-edit-form', VOrganizationEditForm);
  Vue.component('v-statement-service-form', VStatementServiceForm);
  Vue.component('v-profile-form', VProfileForm);
  Vue.component('vue-new-table-with-filters', NewTableWithFilters);
  Vue.component('v-contingent-form', VContingentForm);
  Vue.component('v-book-create-form', VBookCreateForm);
  Vue.component('v-electronic-contract-create', VElectronicContractCreate);
  Vue.component('v-school-service-address-form', VSchoolServiceAddressForm);
  Vue.component('v-library-statement-book-form', VLibraryStatementBookForm);
  Vue.component('v-library-briefcase-form', VLibraryBriefcaseForm);
  Vue.component('v-library-briefcase-content-form', VLibraryBriefcaseContentForm);
  Vue.component('v-electronic-contract-conclude', VElectronicContractConclude);
  Vue.component('v-electronic-contract-show', VElectronicContractShow);
  Vue.component('v-parent-statement-show', ParentStatementShow);
  Vue.component('v-subject-questions', SubjectQuestions);
  Vue.component('v-main-page', VMainPage);
  Vue.component('v-support-team-button', VSupportTeamButton);
  Vue.component('v-passport-page-view', VPassportPageView);
  Vue.component('v-books-addition-alt', VBooksAdditionAlt);
  Vue.component('editable-table', EditableTable);
  Vue.component('v-library-statement-book-table', VLibraryStatementBookTable);
  Vue.component('v-in-progress-alt-books-list', VInProgressAltBooksList);
  Vue.component('v-library-act-arrival-show', VLibraryActArrivalShow);
  Vue.component('v-library-act-write-off-show', VLibraryActWriteOffShow);
  Vue.component('v-library-act-transfer-show', VLibraryActTransferShow);
  Vue.component('v-monitoring-list', VMonitoringList);
  Vue.component('v-library-debit-credit-monitoring', VLibraryDebitCreditMonitoring);
  Vue.component('v-monitoring-tree', VMonitoringTree);
  Vue.component('v-library-statement-show', VLibraryStatementShow);
  Vue.component('v-registration-check', VRegistrationCheck);
  Vue.component('v-library-book-plans-form', VLibraryBookPlansForm);
  Vue.component('v-registration-check', VRegistrationCheck);
  Vue.component('v-organization-profile-additional', VOrganizationProfileAdditional);
  Vue.component('v-school-certificate-statement-form', VSchoolCertificateStatementForm);
  Vue.component('v-personnel-card-form', VPersonnelCardForm);
  Vue.component('v-labor-report-form', VLaborReportForm);
  Vue.component('vue-profile-avatar', ProfileAvatar);
  Vue.component('vue-profile-avatar', ProfileAvatar);
  Vue.component('v-meetings-calendar', VMeetingsCalendar);
  Vue.component('v-custom-form', VCustomForm);
  Vue.component('v-org-mtb', VOrgMTB);
}
