import VStepParent from './VStepParent';
import VStepChild from './VStepChild';
import VStepStatement from './VStepStatement';
import VStepDocuments from './VStepDocuments';
import VStepCongratulations from './VStepCongratulations';
import VStepsBody from './VStepsBody';
import Stepper from './Stepper';
import OptionsMixin from './mixins/OptionsMixin';

export default {
  name: 'VStatementServiceForm',

  mixins: [OptionsMixin],
  components: {
    VStepParent,
    VStepChild,
    VStepStatement,
    VStepDocuments,
    VStepCongratulations,
    VStepsBody,
    Stepper,
  },
  props: {
    uriAction: {
      type: String,
      required: true,
    },

    uriCheckIinParent: {
      type: String,
      default: '',
    },
    uriConfirmIinExistsParent: {
      type: String,
      default: '',
    },
    uriStoreUserInfo: {
      type: String,
      default: '',
    },

    uriCheckIinChild: {
      type: String,
      default: '',
    },
    uriConfirmIinExistsChild: {
      type: String,
      default: '',
    },
    uriAddChild: {
      type: String,
      default: '',
    },

    uriStatement: {
      type: String,
      default: '',
    },
    uriStatic: {
      type: String,
      default: '',
    },
    uriActualization: {
      type: String,
      default: '',
    },
    isBinom: {
      type: Boolean,
      default: false,
    },
    hasUser: {
      type: Boolean,
      default: false,
    },
    stepDocs: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    numberAkt: {
      type: [Number, String],
    },
    isChildResident: {
      type: Boolean,
    },
    withDocPurchaseAgreement: {
      type: Boolean,
    },
    actualResidence: {
      type: String,
      default: '',
    },
    placeOfResidence: {
      type: String,
      default: '',
    },
    className: {
      type: Number,
      default: -1,
    },
    isDisabledActualization: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,

      steps: {
        PARENT: 2,
        CHILD: 3,
        STATEMENT: 4,
        DOCS: 5,
        FINISH: 6,
      },
    };
  },

  beforeCreate() {
    this.$trans.add([
      'statement',
      'label',
      'button',
      'placeholder',
      'validation',
      'notice',
      'warning_text',
      'statement_service',
      'main',
      'fields',
    ]);
  },

  mounted() {
    let currentStep = this.steps.PARENT;
    if (this.hasUser) {
      currentStep = this.steps.CHILD;
    }
    if (this.stepDocs) {
      currentStep = this.steps.DOCS;
    }
    this.$store.commit('SET_CURRENT_STEP', currentStep);
  },

  computed: {
    current() {
      return this.$store.state.vSteps.currentStep;
    },
    optionsProps() {
      return {
        uriGenders: this.uriGenders,
        uriChildren: this.uriChildren,
        uriNationalities: this.uriNationalities,
        uriClasses: this.uriClasses,
        uriDepartments: this.uriDepartments,
        uriLanguages: this.uriLanguages,
        uriRegions: this.uriRegions,
        uriSchools: this.uriSchools,
        uriCitizenships: this.uriCitizenships,
        uriDictionaryItems: this.uriDictionaryItems,
      };
    },
  },

  methods: {
    getStepTitle() {
      let trans = 'statement_service.statement_info';

      if (this.type === 20) {
        trans = 'statement_service.pmpc_info';
      }

      switch (this.current) {
        case this.steps.PARENT: return this.trans('statement_service.about_parents');
        case this.steps.CHILD: return this.trans('statement_service.about_child');
        case this.steps.STATEMENT: return this.trans(trans);
        case this.steps.DOCS: return this.trans('statement_service.docs');
        default: return '';
      }
    },
    loadingProcessing(status) {
      this.loading = status;
      if (status) {
        this.$globalLoading.show();
      } else {
        this.$globalLoading.hide();
      }
    },
  },
};
