import axios from 'axios';
import _ from 'lodash';

export default {
  name: 'ServiceInfoStep',
  props: {
    locale: String,
    type: Number,
    child: Number,
    regions: {
      type: Array,
      default: () => ([]),
    },
    user: Number,
    isRehabilitation: false,
    hideLiter: false,
    checkContingent: {
      type: Boolean,
      default: true,
    },
    uriStore: String,
    childrenInfos: Array,
    parentActualResidence: String,
    forAdult: Boolean,
    parentPlaceOfResidence: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: false,
      isContingent: false,

      multiselectArray: {},
      selected: {},
      departments: [],
      localities: [],
      schools: [],
      isEmptySchools: false,

      documentTypeOptions: [
        {
          id: 'сertificate',
          text: 'сertificate',
        },
        {
          id: 'application_and_certificate',
          text: 'application_and_certificate',
        },
      ],
      statementReasonOptions: [
        {
          id: 'surname_change',
          text: 'surname_change',
        },
        {
          id: 'damage_to_education_certificate',
          text: 'damage_to_education_certificate',
        },
        {
          id: 'lost',
          text: 'lost',
        },
      ],
      errors: {},
      actual_residence: null,
    };
  },
  mounted() {
    this.actual_residence = this.forAdult
      ? this.parentActualResidence
      : this.childActualResidence;
  },
  computed: {
    selectedChild() {
      return this.childrenInfos.find(itm => itm.child_id === this.child);
    },
    childActualResidence() {
      return this.selectedChild?.actualResidence;
    },
    placeOfResidence() {
      const placeOfResidence = this.forAdult
        ? this.parentPlaceOfResidence
        : this.selectedChild?.placeOfResidence;

      return placeOfResidence || this.trans('label.data_not_found');
    },
    isValid() {
      const {
        type, selected, multiselectArray, isContingent, hideLiter,
      } = this;
      if (isContingent) {
        return this.type === 1
          ? Object.keys(multiselectArray).length === 4 && this.selected.class_name
          : Object.keys(multiselectArray).length === 3;
      }
      if (type === 9) return Object.keys(selected).length === 6;
      return (hideLiter ? true : typeof ((selected || {}).class_name) === 'string')
        && Object.keys(multiselectArray).length === 4;
    },
  },
  beforeCreate() {
    this.$trans.add(['statement_service', 'warning_text', 'label', 'button', 'notice', 'fields']);
  },
  created() {
    if (this.checkContingent && this.child) {
      this.getStatements();
    }
    if (this.type === 9 && this.child) this.statementReasonOptions = this.statementReasonOptions.slice(1, 3);
  },

  methods: {
    clearFields() {
      this.$set(this.multiselectArray, 'selected_department', undefined);
      this.$set(this.multiselectArray, 'locality_id', undefined);
      if (this.type !== 1) {
        this.$set(this.multiselectArray, 'school_id', undefined);
      }
    },
    async getStatements() {
      try {
        this.loading = true;

        const { data } = await axios.get(`/statement-service-options-api/getStatements/${this.child}`);
        this.isContingent = typeof data.statements === 'object' && data.statements !== null;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getDepartments(value) {
      this.departments = [];
      this.localities = [];
      this.schools = [];
      delete this.selected.school_id;
      this.selected.to_region_id = value.id;
      this.isEmptySchools = false;

      try {
        this.loading = true;

        const { data: departments } = await axios.get('/departments', {
          params: {
            id: value.id,
          },
          headers: {
            'X-Localization': this.locale,
          },
        });
        this.departments = departments;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getLocalities(value) {
      this.localities = [];
      this.schools = [];
      delete this.selected.school_id;
      this.selected.to_department_id = value.id;
      this.isEmptySchools = false;

      if (this.type === 9) {
        await this.getSchools();
        return;
      }

      try {
        this.loading = true;

        const { data: localities } = await axios.get('/localities', {
          params: {
            id: value.id,
          },
          headers: {
            'X-Localization': this.locale,
          },
        });
        this.localities = localities;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getSchools(locality) {
      try {
        this.loading = true;

        if (this.type === 9) {
          const { data } = await axios.get(`/get-locality-schools/${this.selected.to_department_id}`, {
            headers: {
              'Accept-Language': window.core_project.locale,
            },
          });
          this.schools = data.data;
          this.isEmptySchools = _.isEmpty(this.schools);
        } else if (this.isRehabilitation) {
          const { data } = await axios.get(`/get-rehabilitation-organizations/${this.selected.to_department_id}`, {
            headers: {
              'Accept-Language': window.core_project.locale,
            },
          });
          this.schools = (data || {}).data;
          this.isEmptySchools = _.isEmpty(this.schools);
        } else {
          const { data: schools } = await axios.get(`/get-service-schools/${this.selected.to_department_id}/${this.type}`, {
            headers: {
              'Accept-Language': window.core_project.locale,
            },
          });
          this.schools = Object.values((schools.data || {}));
          this.isEmptySchools = _.isEmpty(this.schools);
          this.selected.locality_id = locality.id;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getRehabilitations() {
      try {
        this.loading = true;

        const { data } = await axios.get(`/get-rehabilitation-organizations/${this.selected.to_department_id}`, {
          headers: {
            'Accept-Language': window.core_project.locale,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },

    async submit() {
      if (this.type === 9) {
        this.selected.to_school_id = this.selected.school_id;
        delete this.selected.schools;
        this.selected.from_who = this.forAdult ? 'adult' : 'child';
      }
      try {
        this.loading = true;
        const {
          child: child_id, user: user_id, type, actual_residence,
        } = this;
        const data = await axios.post(
          this.uriStore,
          {
            child_id,
            user_id,
            type,
            actual_residence,
            ...this.selected,
          },
          { headers: { 'X-Requested-With': 'XMLHttpRequest' } },
        ).then((response) => {
          window.location = response.data.redirect;
        }).catch((error) => {
          this.errors = ((error.response || {}).data || {}).errors;
          if (error.response && error.response.data.errors) {
            const { data } = error.response;
            let errors = '';
            _.forEach(data?.errors, (value) => {
              if (_.isArray(value)) {
                _.forEach(value, (message) => {
                  errors += `${message}<br/>`;
                });
              } else {
                errors += `${value}<br/>`;
              }
            });
            show_notice(errors, 'error');
          }
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
