<template>
  <div class="s-map-balloon">
    <div class="s-map-balloon__title">
      {{ item.name }}
    </div>
    <div class="s-map-balloon__info">
      {{ item.address }}
    </div>
    <div class="s-map-balloon__item">
      <span class="s-map-balloon__item-label">
        {{ trans('label.is_org_admission_open') }}:
      </span>
      <span class="s-map-balloon__item-value">
        {{ trans(`label.org_admission_is_${item.hasNewAdmission ? 'open' : 'close'}`) }}
      </span>
    </div>
    <div class="s-map-balloon__footer">
      <q-btn
        no-caps
        :label="trans('button.more_info')"
        color="blue"
        type="a"
        target="_blank"
        :href="item.link"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
