import {
  QDialog,
  QBtn,
} from '@quasar/components';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';
import api from '@vjs/helpers/api';

export default {
  name: 'SUploader',
  components: {
    QDialog,
    QBtn,
    VModal,
  },
  directives: {
    ClosePopup,
  },
  props: {
    identifier: Number || String,
    name: {
      type: String,
      default: '',
    },
    uploadLink: {
      type: String,
      default: '',
    },
    downloadLink: {
      type: String,
      default: '',
    },
    removeLink: String,
    /*
    * Status:
    *   - wait
    *   - process
    *   - uploaded
    * */
    status: String,
    readonly: Boolean,
  },
  data() {
    return {
      locStatus: this.status || 'wait',
      modalConfirmRemove: false,
    };
  },
  methods: {
    async removeFile() {
      await api(
        this.removeLink,
        {
          _method: 'delete',
        },
        this.removeSuccess,
        {},
        this.removeReject,
        'delete',
      );
    },
    removeSuccess({ data }) {
      this.$emit('file-remove-success', this.identifier);
      this.$notify({
        type: 'success',
        title: window.L.message,
        text: data.message,
        duration: 2000,
      });
      this.modalConfirmRemove = false;
    },
    removeReject(data) {
      this.$emit('file-remove-reject', this.identifier);
      this.$notify({
        type: 'error',
        title: window.L.error,
        text: data.message,
        duration: 10000,
      });
      this.modalConfirmRemove = false;
    },
  },
};
