import {
  NINTH_ELEVEN_CLASSES,
  SPECIAL_EDU_CONDITION_VAL_NO,
  PERFORMANCE_LEVEL_NO_ANNUAL_ASSESSMENT,
  NATURAL_MATHEMATICAL_PROGRAM_SUB_VAL_NO,
  STUDIES_FOREIGN_LANGUAGE_NOT_STUDY,
  NATIVE_LANG_AS_INDEPENDENT_SUB_VAL_NO,
  OPTIONAL_STUDY_NATIVE_LANG_VAL_NO,
  ATTENDS_CLUBS_THIS_ORG_VAL_NO,
  COMPETITIONS_CONTESTS_OLYMPIADS_EVENT_TYPE_NOT_PARTICIPATE,
  STANDS_ON_RECORD_INTRA_SCHOOL_ACC,
  STANDS_ON_RECORD_MINORS_ACC,
  STANDS_ON_RECORD_NOT_CONSIST,
  ABNORMALITIES_VAL_NO,
  EMPLOYMENT_TYPE_ID_LEFT_COUNTRY,
} from './consts';

export default {
  props: {
    urlBack: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    refs: {
      type: Object,
      required: true,
    },
  },
  data() {
    const vm = this;
    const fields = {
      group: {
        value: this.resource.contingent.group_id,
        name: 'contingent[group_id]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'main.group',
        values: this.refs.groups,
        required: true,
      },
      edu_lang: {
        value: this.resource.contingent.group.edu_lang,
        name: 'group[edu_lang]',
        component: 'text-field',
        labelTransKey: 'label.edu_lang',
        required: false,
        extraAttributes: {
          disabled: true,
        },
      },
      edu_form: {
        value: this.resource.contingent.group.edu_form,
        name: 'group[edu_form]',
        component: 'text-field',
        labelTransKey: 'label.edu_form',
        required: false,
        extraAttributes: {
          disabled: true,
        },
      },
      edu_shift: {
        value: this.resource.contingent.group.edu_shift,
        name: 'group[edu_shift]',
        component: 'text-field',
        labelTransKey: 'label.edu_shift',
        required: false,
        extraAttributes: {
          disabled: true,
        },
      },
      arrival_date: {
        value: this.resource.contingent.arrival_date,
        name: 'contingent[arrival_date]',
        component: 'q-date-field',
        labelTransKey: 'label.arrival_date',
        required: true,
      },
      arrival_number: {
        value: this.resource.contingent.arrival_number,
        name: 'contingent[arrival_number]',
        component: 'text-field',
        labelTransKey: 'label.arrival_number',
        required: true,
      },
      primary_school_licence_serial_number: {
        value: this.resource.contingent.primary_school_licence_serial_number,
        name: 'contingent[primary_school_licence_serial_number]',
        component: 'text-field',
        labelTransKey: 'label.primary_school_licence_serial_number',
        required: true,
        v_if: () => {
          if (this.resource.contingent.group.name) {
            return NINTH_ELEVEN_CLASSES.includes(this.resource.contingent.group.name);
          }
        },
      },
      primary_school_licence_number: {
        value: this.resource.contingent.primary_school_licence_number,
        name: 'contingent[primary_school_licence_number]',
        component: 'text-field',
        labelTransKey: 'label.primary_school_licence_number',
        required: true,
        v_if: () => {
          if (this.resource.contingent.group.name) {
            return NINTH_ELEVEN_CLASSES.includes(this.resource.contingent.group.name);
          }
        },
      },
      primary_school_licence_date: {
        value: this.resource.contingent.primary_school_licence_date,
        name: 'contingent[primary_school_licence_date]',
        component: 'q-date-field',
        labelTransKey: 'label.primary_school_licence_date',
        required: true,
        v_if: () => {
          if (this.resource.contingent.group.name) {
            return NINTH_ELEVEN_CLASSES.includes(this.resource.contingent.group.name);
          }
        },
      },
      reason_leaving_id: {
        value: this.resource.contingent.reason_leaving_id,
        name: 'contingent[reason_leaving_id]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.reason_leaving_id',
        values: this.refs.handbooks.leaving_reasons,
        required: false,
      },
      graduate_released: {
        value: this.resource.contingent.nedb_info.graduate_released,
        name: 'contingent_nedb[graduate_released]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.graduate_released',
        values: this.refs.nedb_handbooks.graduate_released,
        required: false,
      },
      retirement_date: {
        value: this.resource.contingent.retirement_date,
        name: 'contingent[retirement_date]',
        component: 'q-date-field',
        labelTransKey: 'label.retirement_date',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.reason_leaving_id || vm.form.fields.graduate_released) {
            return vm.form.fields.reason_leaving_id.value
                || vm.form.fields.graduate_released.value;
          }
        },
      },
      retirement_order_number: {
        value: this.resource.contingent.retirement_order_number,
        name: 'contingent[retirement_order_number]',
        component: 'text-field',
        labelTransKey: 'label.retirement_order_number',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.reason_leaving_id || vm.form.fields.graduate_released) {
            return vm.form.fields.reason_leaving_id.value
                || vm.form.fields.graduate_released.value;
          }
        },
      },
      new_curriculum: {
        value: this.resource.contingent.new_curriculum,
        name: 'contingent[new_curriculum]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.new_curriculum',
        values: this.refs.bool_values,
        required: true,
      },
      altyn_belgi_challenger: {
        value: this.resource.contingent.altyn_belgi_challenger,
        name: 'contingent[altyn_belgi_challenger]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.altyn_belgi_challenger',
        values: this.refs.bool_values,
        required: true,
        events: {
          input: (newVal) => {
            if (!newVal) {
              this.form.fields.altyn_belgi.value = null;
            }
          },
        },
      },
      altyn_belgi: {
        value: this.resource.contingent.altyn_belgi,
        name: 'contingent[altyn_belgi]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.altyn_belgi_confirmed',
        values: this.refs.bool_values,
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.altyn_belgi_challenger) {
            return vm.form.fields.altyn_belgi_challenger.value;
          }
        },
      },
      foreign_languages: {
        value: this.resource.contingent.foreign_languages,
        name: 'foreign_languages',
        component: 'q-multi-checkbox-field',
        labelTransKey: 'label.edu_foreign_langs',
        options: this.refs.handbooks.foreign_languages,
        inline: true,
      },
      material_assistance_types: {
        value: this.resource.contingent.material_assistance_type,
        name: 'material_assistance_types',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.material_assisted',
        values: this.refs.handbooks.material_assistance_types,
        required: true,
      },
      med_sport_treatment: {
        value: this.resource.contingent.med_sport_treatment,
        name: 'contingent[med_sport_treatment]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.med_sport_treatment',
        values: this.refs.bool_values,
        required: true,
      },
      speech_correction_therapy: {
        value: this.resource.contingent.speech_correction_therapy,
        name: 'contingent[speech_correction_therapy]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.speech_correction_therapy',
        values: this.refs.bool_values,
        required: true,
      },
      arrived: {
        value: this.resource.contingent.nedb_info.arrived,
        name: 'contingent_nedb[arrived]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.arrived',
        values: this.refs.nedb_handbooks.arrived,
        required: true,
      },
      attended_preschool_org: {
        value: this.resource.contingent.nedb_info.attended_preschool_org,
        name: 'contingent_nedb[attended_preschool_org]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.attended_preschool_org',
        values: this.refs.nedb_handbooks.attended_preschool_org,
        required: true,
      },
      class_kit: {
        value: this.resource.contingent.nedb_info.class_kit,
        name: 'contingent_nedb[class_kit]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.class_kit',
        values: this.refs.bool_values,
        required: true,
      },
      type_of_class: {
        value: this.resource.contingent.nedb_info.type_of_class,
        name: 'contingent_nedb[type_of_class]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.type_of_class',
        values: this.refs.nedb_handbooks.type_of_class,
        required: true,
      },
      evening_form_of_edu: {
        value: this.resource.contingent.nedb_info.evening_form_of_edu,
        name: 'contingent_nedb[evening_form_of_edu]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.evening_form_of_edu',
        values: this.refs.nedb_handbooks.evening_form_of_edu,
        required: true,
      },
      computer_availability: {
        value: this.resource.contingent.nedb_info.computer_availability,
        name: 'contingent_nedb[computer_availability]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.computer_availability',
        values: this.refs.nedb_handbooks.computer_availability,
        required: true,
      },
      internet_availability: {
        value: this.resource.contingent.nedb_info.internet_availability,
        name: 'contingent_nedb[internet_availability]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.internet_availability',
        values: this.refs.bool_values,
        required: true,
      },
      special_edu_condition: {
        value: this.resource.contingent.special_edu_condition,
        name: 'contingent[special_edu_condition]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.special_edu_condition',
        values: this.refs.nedb_handbooks.special_edu_condition,
        required: true,
        events: {
          input: (newVal) => {
            if (newVal === SPECIAL_EDU_CONDITION_VAL_NO) {
              this.form.fields.diagnose.value = null;
              this.form.fields.training_program.value = null;
              this.form.fields.period_individual_training_from.value = null;
              this.form.fields.period_individual_training_before.value = null;
            }
          },
        },
      },
      diagnose: {
        value: this.resource.contingent.nedb_info.diagnose,
        name: 'contingent_nedb[diagnose]',
        component: 'text-field',
        labelTransKey: 'contingent.diagnose',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.special_edu_condition.value) {
            return vm.form.fields.special_edu_condition.value !== SPECIAL_EDU_CONDITION_VAL_NO;
          }
        },
      },
      training_program: {
        value: this.resource.contingent.nedb_info.training_program,
        name: 'contingent_nedb[training_program]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.training_program,
        labelTransKey: 'contingent.training_program',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.special_edu_condition.value) {
            return vm.form.fields.special_edu_condition.value !== SPECIAL_EDU_CONDITION_VAL_NO;
          }
        },
      },
      period_individual_training_from: {
        value: this.resource.contingent.nedb_info.period_individual_training_from,
        name: 'contingent_nedb[period_individual_training_from]',
        component: 'q-date-field',
        labelTransKey: 'contingent.period_individual_training_from',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.special_edu_condition.value) {
            return vm.form.fields.special_edu_condition.value !== SPECIAL_EDU_CONDITION_VAL_NO;
          }
        },
      },
      period_individual_training_before: {
        value: this.resource.contingent.nedb_info.period_individual_training_before,
        name: 'contingent_nedb[period_individual_training_before]',
        component: 'q-date-field',
        labelTransKey: 'contingent.period_individual_training_before',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.special_edu_condition.value) {
            return vm.form.fields.special_edu_condition.value !== SPECIAL_EDU_CONDITION_VAL_NO;
          }
        },
      },
      distance_learning: {
        value: this.resource.contingent.nedb_info.distance_learning,
        name: 'contingent_nedb[distance_learning]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.bool_values,
        labelTransKey: 'contingent.distance_learning',
        required: true,
        events: {
          input: (newVal) => {
            if (newVal) {
              this.form.fields.info_about_delivery.value = null;
            } else {
              this.form.fields.complected_textbooks.value = null;
            }
          },
        },
      },
      info_about_delivery: {
        value: this.resource.contingent.nedb_info.info_about_delivery,
        name: 'contingent_nedb[info_about_delivery]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.info_about_delivery,
        labelTransKey: 'contingent.info_about_delivery',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.distance_learning.value !== null) {
            return !vm.form.fields.distance_learning.value;
          }
        },
      },
      complected_textbooks: {
        value: this.resource.contingent.complected_textbooks,
        name: 'contingent[complected_textbooks]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.complected_textbooks',
        values: this.refs.nedb_handbooks.complected_textbooks,
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.distance_learning) {
            return vm.form.fields.distance_learning.value;
          }
        },
      },
      performance_level: {
        value: this.resource.contingent.nedb_info.performance_level,
        name: 'contingent_nedb[performance_level]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.performance_level',
        values: this.refs.nedb_handbooks.performance_level,
        required: true,
        events: {
          input: (newVal) => {
            if (newVal !== PERFORMANCE_LEVEL_NO_ANNUAL_ASSESSMENT) {
              this.form.fields.studies_foreign_language.value = null;
              this.form.fields.studies_foreign_language_second.value = null;
            } else {
              this.form.fields.natural_mathematical_program_subject.value = null;
              this.form.fields.natural_mathematical_program_assessment.value = null;
            }
          },
        },
      },
      natural_mathematical_program_subject: {
        value: this.resource.contingent.nedb_info.natural_mathematical_program.subject,
        name: 'contingent_nedb[natural_mathematical_program][subject]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.natural_mathematical_program_subject',
        values: this.refs.nedb_handbooks.natural_mathematical_program_subject,
        required: true,
        events: {
          input: (newVal) => {
            if (newVal === NATURAL_MATHEMATICAL_PROGRAM_SUB_VAL_NO) {
              this.form.fields.natural_mathematical_program_assessment.value = null;
            }
          },
        },
        v_if: (vm) => {
          if (vm.form.fields.performance_level.value) {
            return vm.form.fields.performance_level.value
              !== PERFORMANCE_LEVEL_NO_ANNUAL_ASSESSMENT;
          }
        },
      },
      natural_mathematical_program_assessment: {
        value: this.resource.contingent.nedb_info.natural_mathematical_program.assessment,
        name: 'contingent_nedb[natural_mathematical_program][assessment]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.natural_mathematical_program_assessment',
        values: this.refs.nedb_handbooks.natural_mathematical_program_assessment,
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.natural_mathematical_program_subject.value) {
            return vm.form.fields.natural_mathematical_program_subject.value
              !== NATURAL_MATHEMATICAL_PROGRAM_SUB_VAL_NO;
          }
        },
      },
      studies_foreign_language: {
        value: this.resource.contingent.nedb_info.studies_foreign_language,
        name: 'contingent_nedb[studies_foreign_language]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.studies_foreign_language',
        values: this.refs.nedb_handbooks.studies_foreign_language,
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.performance_level) {
            return vm.form.fields.performance_level.value
              === PERFORMANCE_LEVEL_NO_ANNUAL_ASSESSMENT;
          }
        },
        events: {
          input: (newVal) => {
            if (STUDIES_FOREIGN_LANGUAGE_NOT_STUDY.includes(newVal)) {
              this.form.fields.studies_foreign_language_second.value = null;
            }
          },
        },
      },
      studies_foreign_language_second: {
        value: this.resource.contingent.nedb_info.studies_foreign_language_second,
        name: 'contingent_nedb[studies_foreign_language_second]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.studies_foreign_language_second',
        values: this.refs.nedb_handbooks.studies_foreign_language_second,
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.studies_foreign_language.value) {
            return !STUDIES_FOREIGN_LANGUAGE_NOT_STUDY.includes(
              vm.form.fields.studies_foreign_language.value,
            ) && vm.form.fields.performance_level.value === PERFORMANCE_LEVEL_NO_ANNUAL_ASSESSMENT;
          }
        },
      },
      course_retake: {
        value: this.resource.contingent.course_retake,
        name: 'contingent[course_retake]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.course_retake',
        values: this.refs.nedb_handbooks.course_retake,
        required: true,
      },
      studying_english_first_grade: {
        value: this.resource.contingent.nedb_info.studying_english_first_grade,
        name: 'contingent_nedb[studying_english_first_grade]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.bool_values,
        labelTransKey: 'contingent.studying_english_first_grade',
        required: true,
      },
      native_lang_as_independent_subject: {
        value: this.resource.contingent.nedb_info.native_lang_as_independent_subject,
        name: 'contingent_nedb[native_lang_as_independent_subject]',
        component: 'q-multi-checkbox-field',
        labelTransKey: 'contingent.native_lang_as_independent_subject',
        options: this.refs.nedb_handbooks.native_lang_as_independent_subject,
        inline: true,
        required: true,
        events: {
          input: (newVal, oldVal, singleVal) => {
            if (singleVal === NATIVE_LANG_AS_INDEPENDENT_SUB_VAL_NO) {
              if (newVal.length > oldVal.length) {
                this.form.fields.native_lang_as_independent_subject.value = [singleVal];
              }
            } else if (oldVal.includes(NATIVE_LANG_AS_INDEPENDENT_SUB_VAL_NO)) {
              this.form.fields.native_lang_as_independent_subject.value = newVal.filter(
                id => id !== NATIVE_LANG_AS_INDEPENDENT_SUB_VAL_NO,
              );
            }
          },
        },
      },
      optional_study_native_lang: {
        value: this.resource.contingent.nedb_info.optional_study_native_lang,
        name: 'contingent_nedb[optional_study_native_lang]',
        component: 'q-multi-checkbox-field',
        labelTransKey: 'contingent.optional_study_native_lang',
        options: this.refs.nedb_handbooks.optional_study_native_lang,
        inline: true,
        required: true,
        events: {
          input: (newVal, oldVal, singleVal) => {
            if (singleVal === OPTIONAL_STUDY_NATIVE_LANG_VAL_NO) {
              if (newVal.length > oldVal.length) {
                this.form.fields.optional_study_native_lang.value = [singleVal];
              }
            } else if (oldVal.includes(OPTIONAL_STUDY_NATIVE_LANG_VAL_NO)) {
              this.form.fields.optional_study_native_lang.value = newVal.filter(
                id => id !== OPTIONAL_STUDY_NATIVE_LANG_VAL_NO,
              );
            }
          },
        },
      },
      attends_clubs_this_org: {
        value: this.resource.contingent.nedb_info.attends_clubs_this_org,
        name: 'contingent_nedb[attends_clubs_this_org]',
        component: 'q-multi-checkbox-field',
        labelTransKey: 'contingent.attends_clubs_this_org',
        options: this.refs.nedb_handbooks.attends_clubs_this_org,
        inline: true,
        required: true,
        events: {
          input: (newVal, oldVal, singleVal) => {
            if (singleVal === ATTENDS_CLUBS_THIS_ORG_VAL_NO) {
              if (newVal.length > oldVal.length) {
                this.form.fields.attends_clubs_this_org.value = [singleVal];
              }
            } else if (oldVal.includes(ATTENDS_CLUBS_THIS_ORG_VAL_NO)) {
              this.form.fields.attends_clubs_this_org.value = newVal.filter(
                id => id !== ATTENDS_CLUBS_THIS_ORG_VAL_NO,
              );
            }
          },
        },
      },
      attends_additional_education_org: {
        value: this.resource.contingent.nedb_info.attends_additional_education_org,
        name: 'contingent_nedb[attends_additional_education_org]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.bool_values,
        labelTransKey: 'contingent.attends_additional_education_org',
        required: true,
      },
      competitions_contests_olympiads_event_type: {
        value: this.resource.contingent.nedb_info.competitions_contests_olympiads.event_type,
        name: 'contingent_nedb[competitions_contests_olympiads][event_type]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.competitions_contests_olympiads_event_type,
        labelTransKey: 'contingent.competitions_contests_olympiads_event_type',
        required: true,
        events: {
          input: (newVal) => {
            if (newVal === COMPETITIONS_CONTESTS_OLYMPIADS_EVENT_TYPE_NOT_PARTICIPATE) {
              this.form.fields.competitions_contests_olympiads_date_participation.value = null;
              this.form.fields.competitions_contests_olympiads_direction_type.value = null;
              this.form.fields.competitions_contests_olympiads_event_level.value = null;
              this.form.fields.competitions_contests_olympiads_reward.value = null;
            }
          },
        },
      },
      competitions_contests_olympiads_direction_type: {
        value: this.resource.contingent.nedb_info.competitions_contests_olympiads.direction_type,
        name: 'contingent_nedb[competitions_contests_olympiads][direction_type]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.competitions_contests_olympiads_direction_type,
        labelTransKey: 'contingent.competitions_contests_olympiads_direction_type',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.competitions_contests_olympiads_event_type.value) {
            return vm.form.fields.competitions_contests_olympiads_event_type.value
              !== COMPETITIONS_CONTESTS_OLYMPIADS_EVENT_TYPE_NOT_PARTICIPATE;
          }
        },
      },
      competitions_contests_olympiads_event_level: {
        value: this.resource.contingent.nedb_info.competitions_contests_olympiads.event_level,
        name: 'contingent_nedb[competitions_contests_olympiads][event_level]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.competitions_contests_olympiads_event_level,
        labelTransKey: 'contingent.competitions_contests_olympiads_event_level',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.competitions_contests_olympiads_event_type.value) {
            return vm.form.fields.competitions_contests_olympiads_event_type.value
              !== COMPETITIONS_CONTESTS_OLYMPIADS_EVENT_TYPE_NOT_PARTICIPATE;
          }
        },
      },
      competitions_contests_olympiads_date_participation: {
        value:
          this.resource.contingent.nedb_info.competitions_contests_olympiads.date_participation,
        name: 'contingent_nedb[competitions_contests_olympiads][date_participation]',
        component: 'q-date-field',
        labelTransKey: 'contingent.competitions_contests_olympiads_date_participation',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.competitions_contests_olympiads_event_type.value) {
            return vm.form.fields.competitions_contests_olympiads_event_type.value
              !== COMPETITIONS_CONTESTS_OLYMPIADS_EVENT_TYPE_NOT_PARTICIPATE;
          }
        },
      },
      competitions_contests_olympiads_reward: {
        value: this.resource.contingent.nedb_info.competitions_contests_olympiads.reward,
        name: 'contingent_nedb[competitions_contests_olympiads][reward]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.competitions_contests_olympiads_reward,
        labelTransKey: 'contingent.competitions_contests_olympiads_reward',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.competitions_contests_olympiads_event_type.value) {
            return vm.form.fields.competitions_contests_olympiads_event_type.value
              !== COMPETITIONS_CONTESTS_OLYMPIADS_EVENT_TYPE_NOT_PARTICIPATE;
          }
        },
      },
      is_participant_rukhani_zhangyru_program: {
        value: this.resource.contingent.nedb_info.is_participant_rukhani_zhangyru_program,
        name: 'contingent_nedb[is_participant_rukhani_zhangyru_program]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.bool_values,
        labelTransKey: 'contingent.is_participant_rukhani_zhangyru_program',
        required: true,
      },
      summer_holidays: {
        value: this.resource.contingent.nedb_info.summer_holidays,
        name: 'contingent_nedb[summer_holidays]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.bool_values,
        labelTransKey: 'contingent.summer_holidays',
        required: true,
        events: {
          input: (newVal) => {
            if (!newVal) {
              this.form.fields.summer_holidays_info_place.value = null;
              this.form.fields.summer_holidays_info_period.value = null;
              this.form.fields.summer_holidays_info_food.value = null;
            }
          },
        },
      },
      summer_holidays_info_place: {
        value: this.resource.contingent.nedb_info.summer_holidays_info.place,
        name: 'contingent_nedb[summer_holidays_info][place]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.summer_holidays_info_place,
        labelTransKey: 'contingent.summer_holidays_info_place',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.summer_holidays) {
            return vm.form.fields.summer_holidays.value;
          }
        },
      },
      summer_holidays_info_period: {
        value: this.resource.contingent.nedb_info.summer_holidays_info.period,
        name: 'contingent_nedb[summer_holidays_info][period]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.summer_holidays_info_period,
        labelTransKey: 'contingent.summer_holidays_info_period',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.summer_holidays) {
            return vm.form.fields.summer_holidays.value;
          }
        },
      },
      summer_holidays_info_food: {
        value: this.resource.contingent.nedb_info.summer_holidays_info.food,
        name: 'contingent_nedb[summer_holidays_info][food]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.summer_holidays_info_food,
        labelTransKey: 'contingent.summer_holidays_info_food',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.summer_holidays) {
            return vm.form.fields.summer_holidays.value;
          }
        },
      },
      stands_on_record: {
        value: this.resource.contingent.nedb_info.stands_on_record,
        name: 'contingent_nedb[stands_on_record]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.stands_on_record,
        labelTransKey: 'contingent.stands_on_record',
        required: true,
        events: {
          input: (newVal) => {
            if (newVal === STANDS_ON_RECORD_INTRA_SCHOOL_ACC) {
              this.form.fields.probation_reasons.value = [];
            } else if (newVal === STANDS_ON_RECORD_MINORS_ACC) {
              this.form.fields.school_probation_reasons.value = [];
            } else if (newVal === STANDS_ON_RECORD_NOT_CONSIST) {
              this.form.fields.school_probation_reasons.value = [];
              this.form.fields.probation_reasons.value = [];
            }
          },
        },
      },
      school_probation_reasons: {
        value: this.resource.contingent.school_probation_reasons,
        name: 'school_probation_reasons',
        component: 'q-multi-checkbox-field',
        labelTransKey: 'label.on_the_internal_list',
        options: this.refs.handbooks.school_probation_reasons,
        inline: true,
        required: true,
        // events: {
        //   input: (newVal, oldVal, singleVal) => {
        //     if (singleVal === 7) {
        //       if (newVal.length > oldVal.length) {
        //         this.form.fields.school_probation_reasons.value = [singleVal];
        //       }
        //     } else if (oldVal.includes(7)) {
        //       this.form.fields.school_probation_reasons.value = newVal.filter(
        //         id => id !== 7,
        //       );
        //     }
        //   },
        // },
        v_if: (vm) => {
          if (vm.form.fields.stands_on_record) {
            return vm.form.fields.stands_on_record.value === STANDS_ON_RECORD_INTRA_SCHOOL_ACC;
          }
        },
      },
      probation_reasons: {
        value: this.resource.contingent.probation_reasons,
        name: 'probation_reasons',
        component: 'q-multi-checkbox-field',
        labelTransKey: 'label.on_the_mia_list',
        options: this.refs.handbooks.probation_reasons,
        inline: true,
        required: true,
        // events: {
        //   input: (newVal, oldVal, singleVal) => {
        //     if (singleVal === 7) {
        //       if (newVal.length > oldVal.length) {
        //         this.form.fields.probation_reasons.value = [singleVal];
        //       }
        //     } else if (oldVal.includes(7)) {
        //       this.form.fields.probation_reasons.value = newVal.filter(
        //         id => id !== 7,
        //       );
        //     }
        //   },
        // },
        v_if: (vm) => {
          if (vm.form.fields.stands_on_record) {
            return vm.form.fields.stands_on_record.value === STANDS_ON_RECORD_MINORS_ACC;
          }
        },
      },
      with_deviant_behavior: {
        value: this.resource.contingent.nedb_info.with_deviant_behavior,
        name: 'contingent_nedb[with_deviant_behavior]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.bool_values,
        labelTransKey: 'contingent.with_deviant_behavior',
        required: true,
      },
      orphan_child: {
        value: this.resource.contingent.nedb_info.orphan_child,
        name: 'contingent_nedb[orphan_child]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.bool_values,
        labelTransKey: 'contingent.orphan_child',
        required: true,
        events: {
          input: (newVal) => {
            if (newVal) {
              this.form.fields.child_left_parental_care.value = null;
            }
          },
        },
      },
      child_left_parental_care: {
        value: this.resource.contingent.nedb_info.child_left_parental_care,
        name: 'contingent_nedb[child_left_parental_care]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.bool_values,
        labelTransKey: 'contingent.child_left_parental_care',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.orphan_child.value !== null) {
            return !vm.form.fields.orphan_child.value;
          }
        },
      },
      disabled: {
        value: this.resource.contingent.disabled,
        name: 'contingent[disabled]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.bool_values,
        labelTransKey: 'contingent.disabled',
        required: true,
        events: {
          input: (newVal) => {
            if (!newVal) {
              this.form.fields.disability_group.value = null;
              this.form.fields.cause_disability.value = null;
              this.form.fields.term_disability.value = null;
              this.form.fields.date_disability_establishment.value = null;
              this.form.fields.period_disability_until.value = null;
            }
          },
        },
      },
      disability_group: {
        value: this.resource.contingent.nedb_info.disability_group,
        name: 'contingent_nedb[disability_group]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.disability_group,
        labelTransKey: 'contingent.disability_group',
        required: false,
        v_if: (vm) => {
          if (vm.form.fields.disabled) {
            return vm.form.fields.disabled.value;
          }
        },
      },
      cause_disability: {
        value: this.resource.contingent.nedb_info.cause_disability,
        name: 'contingent_nedb[cause_disability]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.cause_disability,
        labelTransKey: 'contingent.cause_disability',
        required: false,
        v_if: (vm) => {
          if (vm.form.fields.disabled) {
            return vm.form.fields.disabled.value;
          }
        },
      },
      term_disability: {
        value: this.resource.contingent.nedb_info.term_disability,
        name: 'contingent_nedb[term_disability]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        values: this.refs.nedb_handbooks.term_disability,
        labelTransKey: 'contingent.term_disability',
        required: false,
        v_if: (vm) => {
          if (vm.form.fields.disabled) {
            return vm.form.fields.disabled.value;
          }
        },
      },
      date_disability_establishment: {
        value: this.resource.contingent.nedb_info.date_disability_establishment,
        name: 'contingent_nedb[date_disability_establishment]',
        component: 'q-date-field',
        labelTransKey: 'contingent.date_disability_establishment',
        required: false,
        v_if: (vm) => {
          if (vm.form.fields.disabled) {
            return vm.form.fields.disabled.value;
          }
        },
      },
      period_disability_until: {
        value: this.resource.contingent.nedb_info.period_disability_until,
        name: 'contingent_nedb[period_disability_until]',
        component: 'q-date-field',
        labelTransKey: 'contingent.period_disability_until',
        required: false,
        v_if: (vm) => {
          if (vm.form.fields.disabled) {
            return vm.form.fields.disabled.value;
          }
        },
      },
      abnormalities: {
        value: this.resource.contingent.abnormalities,
        name: 'abnormalities',
        component: 'q-multi-checkbox-field',
        labelTransKey: 'contingent.abnormalities',
        options: this.refs.handbooks.abnormalities,
        inline: true,
        required: true,
        events: {
          input: (newVal, oldVal, singleVal) => {
            if (newVal.includes(ABNORMALITIES_VAL_NO)) {
              this.form.fields.number_conclusion_pmpc.value = null;
              this.form.fields.date_conclusion_pmpc.value = null;
            }
            if (singleVal === ABNORMALITIES_VAL_NO) {
              if (newVal.length > oldVal.length) {
                this.form.fields.abnormalities.value = [singleVal];
              }
            } else if (oldVal.includes(ABNORMALITIES_VAL_NO)) {
              this.form.fields.abnormalities.value = newVal.filter(
                id => id !== ABNORMALITIES_VAL_NO,
              );
            }
          },
        },
      },
      number_conclusion_pmpc: {
        value: this.resource.contingent.nedb_info.number_conclusion_pmpc,
        name: 'contingent_nedb[number_conclusion_pmpc]',
        component: 'text-field',
        labelTransKey: 'contingent.number_conclusion_pmpc',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.abnormalities.value.length) {
            return !vm.form.fields.abnormalities.value.includes(ABNORMALITIES_VAL_NO);
          }
        },
      },
      date_conclusion_pmpc: {
        value: this.resource.contingent.nedb_info.date_conclusion_pmpc,
        name: 'contingent_nedb[date_conclusion_pmpc]',
        component: 'q-date-field',
        labelTransKey: 'contingent.date_conclusion_pmpc',
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.abnormalities.value.length) {
            return !vm.form.fields.abnormalities.value.includes(ABNORMALITIES_VAL_NO);
          }
        },
      },
      special_classes: {
        value: this.resource.contingent.special_classes,
        name: 'contingent[special_classes]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.special_classes',
        values: this.refs.nedb_handbooks.special_classes,
        required: true,
      },
      is_tsa_recipient: {
        value: this.resource.contingent.nedb_info.is_tsa_recipient,
        name: 'contingent_nedb[is_tsa_recipient]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.is_tsa_recipient',
        values: this.refs.bool_values,
        required: false,
      },
      employment_type_id: {
        value: this.resource.contingent.employment_type_id,
        name: 'contingent[employment_type_id]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.employment_type_id',
        values: this.refs.handbooks.employment_types,
        required: true,
        events: {
          input: (newVal) => {
            if (!EMPLOYMENT_TYPE_ID_LEFT_COUNTRY.includes(newVal)) {
              this.form.fields.migration_country_id.value = null;
            }
          },
        },
      },
      migration_country_id: {
        value: this.resource.contingent.migration_country_id,
        name: 'contingent[migration_country_id]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.migration_country_id',
        values: this.refs.handbooks.migration_countries,
        required: true,
        v_if: (vm) => {
          if (vm.form.fields.employment_type_id) {
            return EMPLOYMENT_TYPE_ID_LEFT_COUNTRY.includes(
              vm.form.fields.employment_type_id.value,
            );
          }
        },
      },
      hot_meal: {
        value: this.resource.contingent.hot_meal,
        name: 'contingent[hot_meal]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'label.hot_meal',
        values: this.refs.nedb_handbooks.hot_meal,
        required: false,
        events: {
          input: (newVal) => {
            if (newVal) {
              this.form.fields.buffet_meal.value = null;
            }
          },
        },
      },
      buffet_meal: {
        value: this.resource.contingent.buffet_meal,
        name: 'contingent[buffet_meal]',
        component: 'q-select-field',
        extraAttributes: {
          optionValue: 'value',
          optionLabel: 'label',
        },
        labelTransKey: 'contingent.buffet_meal',
        values: this.refs.nedb_handbooks.buffet_meal,
        required: false,
        events: {
          input: (newVal) => {
            if (newVal) {
              this.form.fields.hot_meal.value = null;
            }
          },
        },
      },
    };
    if (vm.model !== undefined) {
      _.forEach(vm.model, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
    }
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields',
      'warning_text',
      'placeholder',
      'label',
      'button',
      'main',
      'notice',
      'menu',
      'training_schedule',
      'contingent',
    ]);
  },
  mounted() {
    const vm = this;
    vm.loadRefs();
  },

  destroyed() {
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    loadRefs() {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'X-CSRF-TOKEN': window.core_project.csrfToken,
        },
        params: {
          isTemplate: Number(this.isTemplate),
        },
      };

      vm.$http.get(vm.uriRefs, config)
        .then((response) => {
          const { data } = response;
          // vm.$set(vm.form.fields.parallel, 'values', data.parallels);
          // vm.$set(vm.form.fields.education_language, 'values', data.education_languages);
          // vm.$set(vm.form.fields.group, 'values', data.groups);
        });
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
  watch: {
    'form.fields.type.value': function (newValue, oldValue) {
      if (newValue == 'for_individual') {
        this.form.fields.group_id.v_if = false;
        this.form.fields.name.v_if = true;
      } else {
        this.form.fields.group_id.v_if = true;
        this.form.fields.name.v_if = false;
      }
    },
  },
};
