import Vue from 'vue';
import store from '@vjs/store';
import VBooksAdditionRfid from '@app_mektep/views/VBooksAdditionRfid/VBooksAdditionRfid.vue';
import Notify from '@vjs/components/Notify/Notify.vue';

if (document.getElementById('v-books-addition-rfid-app')) {
  window.VBooksAdditionRfidApp = new Vue({
    el: '#v-books-addition-rfid-app',
    components: { VBooksAdditionRfid, Notify },
    store,
  });
}
