

export default {

  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
  },
  data() {
    const vm = this;
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        afterCallback(vm, response) {
          const { data } = response;
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields',
      'warning_text',
      'placeholder',
      'label',
      'button',
      'main',
      'notice',
      'cabinet',
      'curriculum',
      'personnel_dicts',
      'training_schedule',
      'menu',
    ]);
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },

    handleAjaxFormSubmit(form) {
      const vm = this;

      const data = {};
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          if (response.data.status === 'error') {
            show_notice(response.data.message, 'error');
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    }
    ,
  }
  ,
};
