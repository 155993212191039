import Multiselect from 'vue-multiselect';
import globalComponents from './globalComponents';
import VTreeSelectField from './VTreeSelectField';
import WrapperMultiSelect from './WrapperMultiSelect';
import DefaultField from './DefaultField';
import DateField from './DateField';
import TextField from './TextField';
import TextShowField from './TextShowField';
import CkeditorField from './CkeditorField';
import NumberField from './NumberField';
import RadioField from './RadioField';
import BooleanField from './BooleanField';
import VMultiSelectField from './VMultiSelectField';
import SelectTimeField from './SelectTimeField';
import PhoneField from './PhoneField';
import IINField from './IINField';
import SearchIINField from './SearchIINField';
import ListStatementsField from './ListStatementsField';
import BetweenField from './BetweenField';
import SelectUserFileField from './SelectUserFileField';
import EmptyField from './EmptyField';

import MultipleFilesField from './MultipleFilesField';
import FileField from './FileField';
import PhotoField from './PhotoField';
import TextAreaField from './TextAreaField';
import DatePicker from './DatePicker';
import VYearMonthPicker from './VYearMonthPicker';
import WrapperMultiFileField from './WrapperMultiFileField';
import WrapperFileField from './WrapperFileField';
import VRelatedTextField from './VRelatedTextField';
import WrapperCKEditorField from './WrapperCKEditorField';
import SelectFromListCheckboxField from './SelectFromListCheckboxField';
import WrapperTableMultiFileField from './WrapperTableMultiFileField';

// Quasar form components
import QBetweenField from './QBetweenField';
import QDateField from './QDateField';
import QTimeField from './QTimeField';
import QTextField from './QTextField';
import QTextFieldRawControl from './QTextFieldRawControl';
import QNumberField from './QNumberField';
import QSelectField from './QSelectField';
import WrapperQSelect from './WrapperQSelect';
import WrapperQDate from './WrapperQDate';
import WrapperQTime from './WrapperQTime';
import WrapperQDateTime from './WrapperQDateTime';
import WrapperQNumberInput from './WrapperQNumberInput';
import QMultiCheckboxField from './QMultiCheckboxField';
import VMultiQInputField from './VMultiQInputField';
import QCheckboxField from './QCheckboxField';
import QRadioField from './QRadioField';
import VYearMonthPickerField from './VYearMonthPickerField';


export default function (Vue) {
  globalComponents(Vue);

  Vue.component('v-tree-select-field', VTreeSelectField);
  Vue.component('v-multiselect', Multiselect);
  Vue.component('default-field', DefaultField);
  Vue.component('date-field', DateField);
  Vue.component('text-field', TextField);
  Vue.component('text-show-field', TextShowField);
  Vue.component('ckeditor-field', CkeditorField);
  Vue.component('number-field', NumberField);
  Vue.component('radio-field', RadioField);
  Vue.component('boolean-field', BooleanField);
  Vue.component('v-multi-select-field', VMultiSelectField);
  Vue.component('multiple-files-field', MultipleFilesField);
  Vue.component('file-field', FileField);
  Vue.component('photo-field', PhotoField);
  Vue.component('textarea-field', TextAreaField);
  Vue.component('v-wrapper-multi-select', WrapperMultiSelect);
  Vue.component('v-wrapper-table-multi-file-field', WrapperTableMultiFileField);

  Vue.component('between-field', BetweenField);

  Vue.component('select-time-field', SelectTimeField);
  Vue.component('phone-field', PhoneField);
  Vue.component('iin-field', IINField);
  Vue.component('search-iin-field', SearchIINField);
  Vue.component('list-statements-field', ListStatementsField);
  Vue.component('vue-date-picker', DatePicker);
  Vue.component('select-user-file-field', SelectUserFileField);
  Vue.component('v-year-month-picker', VYearMonthPicker);
  Vue.component('v-wrapper-multi-files-field', WrapperMultiFileField);
  Vue.component('v-wrapper-file-field', WrapperFileField);
  Vue.component('v-related-text-field', VRelatedTextField);
  Vue.component('v-wrapper-ckeditor-field', WrapperCKEditorField);
  Vue.component('v-select-from-list-checkbox-field', SelectFromListCheckboxField);
  Vue.component('v-empty-field', EmptyField);

  // Quasar form components
  Vue.component('q-between-field', QBetweenField);
  Vue.component('q-date-field', QDateField);
  Vue.component('q-time-field', QTimeField);
  Vue.component('q-select-field', QSelectField);
  Vue.component('v-wrapper-q-select', WrapperQSelect);
  Vue.component('v-wrapper-q-date', WrapperQDate);
  Vue.component('v-wrapper-q-time', WrapperQTime);
  Vue.component('v-wrapper-q-date-time', WrapperQDateTime);
  Vue.component('v-wrapper-q-number-input', WrapperQNumberInput);
  Vue.component('q-text-field', QTextField);
  Vue.component('q-text-field-raw-control', QTextFieldRawControl);
  Vue.component('q-number-field', QNumberField);
  Vue.component('q-multi-checkbox-field', QMultiCheckboxField);
  Vue.component('v-multi-q-input-field', VMultiQInputField);
  Vue.component('q-checkbox-field', QCheckboxField);
  Vue.component('q-radio-field', QRadioField);
  Vue.component('v-year-month-picker-field', VYearMonthPickerField);
}
