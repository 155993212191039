export default {
  name: 'VLibraryStatementShow',
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        data: null,
        download: null,
        approve: null,
        approveText: null,
        decline: null,
      }),
    },
  },
  data() {
    return {
      data: {
        table: [],
        name: null,
      },
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'library',
    ]);

    await requestGetDataHelper(this, {
      url: this.apiConfig.data,
      variableName: 'data',
      fullRes: true,
    });
  },
};
