import CardMixin from '../CardMixin';

export default {
  mixins: [CardMixin],

  data() {
    return {
      fieldList: [
        ['pension_status', 'date'],
      ],
    };
  },

  methods: {
    getFieldGroup(fieldName) {
      const selects = ['pension_status'];
      if (selects.includes(fieldName)) return 'select';

      if (['date'].includes(fieldName)) return 'date';

      return null;
    },
    handleIf(field) {
      if (field !== 'pension_status') {
        return this.fields.pension_status === 'yes';
      }
      return true;
    },
  },
};
