import {
  QSelect,
  QInput,
  QIcon,
} from '@quasar/components';
import _ from 'lodash';

export default {
  name: 'StatementServiceFilter',
  components: {
    QSelect,
    QInput,
    QIcon,
  },
  props: {
    statuses: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    serviceTypes: {
      type: Array,
      default: [],
    },
    clearFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterRows: {
        status: '',
        childName: '',
        schoolName: '',
        serviceType: '',
      },
    };
  },
  methods: {
    filterByInt(list, key, value) {
      return list.filter(item => item[key] === value);
    },
    filterByString(list, key, value) {
      return list.filter(item => item[key].toLowerCase().includes(value));
    },
  },
  watch: {
    filterRows: {
      handler(val) {
        const { status } = val;
        const childName = val.childName.toLowerCase().trim();
        const schoolName = val.schoolName.toLowerCase().trim();
        const { serviceType } = val;

        const result = {
          items: [],
        };

        let preFilter = this.items;

        if (status !== '') {
          preFilter = this.filterByInt(preFilter, 'state', status);
        }

        if (childName !== '') {
          preFilter = this.filterByString(preFilter, 'full_name', childName);
        }

        if (schoolName !== '') {
          preFilter = this.filterByString(preFilter, 'school_name', schoolName);
        }

        if (serviceType !== '') {
          preFilter = this.filterByInt(preFilter, 'type', serviceType);
        }

        result.items = preFilter;
        this.$emit('filtered-list', result);
      },
      deep: true,
    },
    clearFilters: {
      handler(val) {
        for (const prop in this.filterRows) {
          if (this.filterRows.hasOwnProperty(prop)) {
            this.filterRows[prop] = '';
          }
        }

        this.$emit('cleared');
      },
    },
  },
};
