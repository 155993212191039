import { eventBus } from '@common/mixins';

export default {
  data: () => ({
    currentFileConfig: null,
    isUserFileModalOpen: false,
  }),

  mounted() {
    eventBus.$on('open-user-files-modal', this.handleOpenUserModal);
  },

  methods: {
    handleOpenUserModal(fileConfig) {
      this.currentFileConfig = fileConfig;
      this.isUserFileModalOpen = true;
    },
    handleCloseUserModal() {
      this.currentFileConfig = null;
      this.isUserFileModalOpen = false;
      eventBus.$emit('user-files-modal-close');
    },
    handleUserModalInput(files) {
      eventBus.$emit('user-files-modal-input', files);
      this.handleCloseUserModal();
    },
  },
};
