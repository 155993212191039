export default {
  props: {
    uriLocality: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      modalRegionSelect: false,
      modalText: false,
      filterShow: true,
      localityOptions: [],
      localitySelected: { value: null },
    };
  },
  methods: {
    // Установка сохранённых фильтров или установка по умолчанию
    loadFiltersFromLocalStorage(rewriteKeysObj = {}, keyName = '', withValueCheck = false) {
      if (localStorage && localStorage.getItem(keyName)) {
        const keysObj = JSON.parse(localStorage.getItem(keyName));
        Object.keys(keysObj).forEach((item) => {
          if (rewriteKeysObj[item]) {
            keysObj[item] = rewriteKeysObj[item];
          }
          if (
            !withValueCheck
            || (withValueCheck && !this[item] && _.isEmpty(this[item] && this[item] !== 0))
          ) {
            this[item] = keysObj[item];
          }
        });
        return true;
      }
      return false;
    },
    // Сохранение выбранных фильтров в localStorage
    saveFiltersToLocalStorage(keysObj = {}, keyName = '') {
      if (localStorage) {
        localStorage.setItem(keyName, JSON.stringify(keysObj));
      }
    },
  },
};
