import { eventBus, SelectUserFileHandler, StoreTranslator } from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [StoreTranslator, SelectUserFileHandler],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    urlStore: {
      type: String,
      required: true,
    },
    urlStoreAlbum: {
      type: String,
      required: true,
    },
    urlAlbum: {
      type: String,
      required: true,
    },
    urlBlockFaceIdCard: {
      type: String,
      required: true,
    },
  },
  data() {
    const vm = this;
    return {
      processSend: false,
      currentFile: vm.value,
      currentAlbumFile: null,
    };
  },
  mounted() {
    this.$trans.add([
      'placeholder', 'label', 'button', 'personnel', 'select', 'text', 'personnel',
    ]);
    eventBus.$on('user-files-modal-input', this.handleFilesModalInput);
    eventBus.$on('user-files-modal-close', this.handleFilesModalClose);
  },

  destroyed() {
  },
  watch: {},
  computed: {
    styles() {
      const result = {};
      if (this.currentFile) {
        result.backgroundImage = `url(${this.currentFile})`;
      }
      return result;
    },
  },
  methods: {
    handleFilesModalClose() {
      this.isModalOpen = false;
    },
    async handleFilesModalInput(files) {
      if (!this.isModalOpen) {
        return;
      }

      this.$globalLoading.show();
      this.currentAlbumFile = files[0];

      const action = this.urlStoreAlbum;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: action,
        formData: true,
        data: {
          album: this.currentAlbumFile.id,
        },
      });
      if (!res.error) {
        this.currentFile = res.url;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
      this.$forceUpdate();
    },
    triggerAlbum() {
      this.isModalOpen = true;
      eventBus.$emit('open-user-files-modal', this.currentAlbumFile);
    },
    async blockFaceIdCard() {
      this.$globalLoading.show();

      const action = this.urlBlockFaceIdCard;
      await requestWrapper.call(this, {
        method: 'post',
        url: action,
      });
      this.$globalLoading.hide();
    },
    clearFile() {
      this.currentAlbumFile = null;
      this.emitChange();
    },
    showNewModal(config) {
      this.$sModal.open(config.id, {
        component: config.component,
        size: config.size,
        title: config.title,
        binds: config.binds,
      });
    },
    emitChange() {
      this.$emit('input', this.localFileValues);
      this.$emit('change', this.localFileValues);
    },
    setInitialValue() {
      this.localFileValues = this.field.value ?? [];
      this.emitChange();
    },
    onClickInputFile() {
      const el = this.$refs.inputFile;
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onChangeInputFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      e.target.value = null;
      this.upload(file);
    },
    async upload(file) {
      this.$globalLoading.show();
      const action = this.urlStore;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: action,
        formData: true,
        data: { file },
      });
      if (!res.error) {
        this.currentFile = res.url;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
      this.$forceUpdate();
    },
  },
};
