import {
  QBtn,
} from '@quasar/components';

export default {
  name: 'VOrganizationsItem',
  components: {
    QBtn,
  },
  props: {
    loading: Boolean,
    address: String,
    campPlacesCount: Number,
    deliveryPlacesCount: Number,
    freeFoodPlacesCount: Number,
    addEduPlacesCount: Number,
    link: String,
    hasNewAdmission: Boolean,
    hasExternalTransfer: Boolean,
    icon: String,
    name: String,
    iconUrl: String,
    imgSrc: String,
  },
  methods: {
    getFlagStyle() {},
    calcMeteringLabel(number) {
      const lastTwoDigits = number % 100;
      const lastDigit = lastTwoDigits % 10;
      let result = number;

      if (
        (lastTwoDigits > 10 && lastTwoDigits < 15)
        || (lastDigit > 4 && lastDigit < 10)
        || lastDigit === 0
      ) {
        result += ` ${this.trans('label.places_count')}`; // мест
      } else if (lastDigit === 1) {
        result += ` ${this.trans('label.places_count_neuter')}`; // место
      } else {
        result += ` ${this.trans('label.places_count_multiple')}`; // места
      }

      return result;
    },
  },
};
