export default class FunctionalComponentOption {
  constructor() {
    this.functional = true;
    this.props = {
      field: {
        type: Object,
        required: true,
      },
      option: {},
    };
    this.methods = {};
    this.render = (h, context) => {
      function supplant(template, o) {
        return template.replace(/{([^{}]*)}/g,
          (a, b) => {
            if (_.get(o, b)) {
              const r = _.get(o, b);
              return typeof r === 'string' || typeof r === 'number' ? r : a;
            }
            return '';
          });
      }

      const renderFunc = this.renderFunc(context);
      if (typeof renderFunc === 'function') {
        return renderFunc(h, context.props.option);
      }
      const template = this.template(context);
      if (template) {
        return h('div',
          {
            domProps: {
              innerHTML: supplant(template, context.props.option),
            },
          });
      }
      return false;
    };
  }

  renderFunc(context) {
    if (!context.props.field.hasOwnProperty('renderOption')) {
      return false;
    }
    return context.props.field.renderOption;
  }

  template(context) {
    if (!context.props.field.hasOwnProperty('templateOption')) {
      return false;
    }
    return context.props.field.templateOption;
  }
}
