import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import VFormInform from '@vjs/components/VFormInform';
import {
  QTable,
  QTh,
  QTr,
  QTd,
  QInput,
  QIcon,
  QCheckbox,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';

export default {
  components: {
    VNoResults,
    QTable,
    QTh,
    QTr,
    QTd,
    QCheckbox,
    QInput,
    QIcon,
    VFormInform,
  },
  props: {
    getRefs: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filter: '',
      refs: {
        rows: [],
        reasons: [],
      },
      form: {
        reason: null,
        selectedBooks: [],
      },
      pagination: {
        rowsPerPage: 10,
      },
    };
  },
  async mounted() {
    await this.$trans.add(['book', 'button', 'label', 'warning_text']);
    await this.requestGetData(this.getRefs, 'refs');
  },

  computed: {
    isAllRowsChecked() {
      if (this.form.selectedBooks.length === this.refs.rows.length) {
        return true;
      }
      if (this.form.selectedBooks.length === 0) {
        return false;
      }
      return null;
    },
    cols() {
      const cols = [
        {
          label: '',
          field: 'isChecked',
          width: 55,
        },
        {
          label: this.trans('book.library_act.show_author'),
          field: 'author',
        },
        {
          label: this.trans('book.library_act.inventory_number'),
          field: 'inventory_number',
        },
        {
          label: this.trans('book.library_act.copies_count'),
          field: 'count',
        },
        {
          label: this.trans('book.library_act.show_price'),
          field: 'price',
        },
      ];

      return cols.map((col) => {
        const tmpCol = col;
        tmpCol.name = col.field;
        return tmpCol;
      });
    },
  },

  methods: {
    handleHeadCheckbox() {
      if (!this.isAllRowsChecked) {
        this.form.selectedBooks = this.refs.rows.map(itm => itm.id);
      } else {
        this.form.selectedBooks = [];
      }
    },
    async requestGetData(url, variableName) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, { url });

      if (!res.error) {
        this.$set(this, variableName, res.data ?? {});
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    async submit() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
