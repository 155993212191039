<template>
  <div>
    <form
      v-if="compReady"
      class="s-form__edit--wide mb-40"
      @submit.prevent="submitForm"
      @keypress.enter.prevent
    >
      <section>
        <h5 class="s-combine-title mb-30">
          <span class="s-combine-title__label s-upper">{{ trans('salary.main_categories') }}</span>
          <div class="s-combine-title__line" />
        </h5>
        <div class="row">
          <div
            class="col-12 col-lg-6 mb-25"
            :class="{ 's-form__error': errors.position_class_id }"
          >
            <label class="s-form__label mb-10">
              {{ trans('salary.position_by_class') }}
              <i>*</i>
            </label>
            <sel2
              v-model.number="selected.position_class_id"
              search
            >
              <option :value="null">
                {{ trans('notice.not_selected') }}
              </option>
              <option
                v-for="(option, index) in position_class_id"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </sel2>
            <div
              v-for="error in errors.position_class_id"
              v-show="errors.position_class_id"
              class="s-form__error-message mt-10"
            >
              {{ error }}
            </div>
          </div>
          <div
            class="col-12 col-lg-3 mb-25"
            :class="{ 's-form__error': errors.experience }"
          >
            <label class="s-form__label mb-10">
              {{ trans('salary.experience') }}
              <i>*</i>
            </label>
            <sel2 v-model.number="selected.experience">
              <option :value="null">
                {{ trans('notice.not_selected') }}
              </option>
              <option
                v-for="(option, id) in experience"
                :value="id"
              >
                {{ option }}
              </option>
            </sel2>
            <div
              v-for="error in errors.experience"
              v-show="errors.experience"
              class="s-form__error-message mt-10"
            >
              {{ error }}
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="s-form__inform d-flex flex-column s-to-center">
              <label class="s-form__label mb-10">{{ trans('salary.coefficient') }}</label>
              <div class="flex-grow-1 d-flex align-items-center justify-content-center s-bold">
                {{ coeff }}
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-around">
          <div class="col-12 col-lg-3 mb-10 s-to-center s-form__inform d-flex flex-column">
            <label class="s-form__label mb-10 flex-grow-1">{{ trans('salary.rate_monthly') }}</label>
            <div class="s-bold">
              {{ resultMain | makePretty }}
              <span>тенге</span>
            </div>
          </div>
          <div class="col-12 col-lg-3 mb-10 s-to-center s-form__inform d-flex flex-column">
            <label class="s-form__label mb-10 flex-grow-1">{{ trans('salary.rate_hourly') }}</label>
            <div class="s-bold">
              {{ resultMainHourly | makePretty }}
              <span>тенге</span>
            </div>
          </div>
          <div class="col-12 col-lg-3 mb-10 s-to-center s-form__inform d-flex flex-column">
            <label class="s-form__label mb-10 flex-grow-1">{{ trans('salary.result_main') }}</label>
            <div class="s-bold">
              {{ resultOver | makePretty }}
              <span>тенге</span>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h5 class="s-combine-title mb-30">
          <span class="s-combine-title__label s-upper">{{ trans('salary.overtime_ratio') }}</span>
          <div class="s-combine-title__line">
            <button
              class="s-combine-title__button"
              type="button"
              @click="isActive.overtime = !isActive.overtime"
            >
              <span
                class="s-str-ico s-str-ico--wider"
                :class="`s-str-ico--thm-${(isActive.overtime ? 'collapse' : 'expand')}`"
              >
                {{ isActive.overtime ? trans('button.hide') : trans('button.show') }}
              </span>
            </button>
          </div>
        </h5>
        <div
          class="row mb-20 collapse"
          :class="{ 'is-active': isActive.overtime }"
        >
          <div
            class="col-12 col-lg mb-10"
            :class="{ 's-form__error': errors.standard_hours }"
          >
            <label class="s-form__label mb-10">
              {{ trans('salary.standard_hours') }}
              <i>*</i>
            </label>
            <sel2 v-model.number="selected.standard_hours">
              <option :value="null">
                {{ trans('notice.not_selected') }}
              </option>
              <option
                v-for="(option, id) in standard_hours"
                :value="id"
              >
                {{ option }}
              </option>
            </sel2>
            <div
              v-for="error in errors.standard_hours"
              v-show="errors.standard_hours"
              class="s-form__error-message mt-10"
            >
              {{ error }}
            </div>
          </div>
          <div
            class="col-12 col-lg mb-10"
            :class="{ 's-form__error': errors.actual_hours }"
          >
            <label class="s-form__label mb-10">
              {{ trans('salary.actual_hours') }}
              <i>*</i>
            </label>
            <input
              v-model.number="selected.actual_hours"
              v-mask="{mask: '999', placeholder: ''}"
              type="text"
              class="s-form__input"
            >
            <div
              v-for="error in errors.actual_hours"
              v-show="errors.actual_hours"
              class="s-form__error-message mt-10"
            >
              {{ error }}
            </div>
          </div>
        </div>
      </section>
      <section>
        <h5 class="s-combine-title mb-30">
          <span class="s-combine-title__label s-upper">{{ trans('salary.additions') }}</span>
          <div class="s-combine-title__line">
            <button
              class="s-combine-title__button"
              type="button"
              @click="isActive.additions = !isActive.additions"
            >
              <span
                class="s-str-ico s-str-ico--wider"
                :class="`s-str-ico--thm-${(isActive.additions ? 'collapse' : 'expand')}`"
              >
                {{ isActive.additions ? trans('button.hide') : trans('button.show') }}
              </span>
            </button>
          </div>
        </h5>
        <div
          class="collapse"
          :class="{ 'is-active': isActive.additions }"
        >
          <div class="mb-20">
            <label class="s-form__label mb-10">{{ trans('salary.overtime') }} <span class="sum_tip">{{ overtime_tip | makePretty }} </span></label>
            <input
              v-model.number="selected.overtime"
              v-mask="{mask: '999', placeholder: ''}"
              type="text"
              class="s-form__input"
            >
          </div>
          <div
            v-for="(select, key) in selects"
            class="mb-30"
          >
            <label class="s-form__label mb-10">{{ trans(`salary.${key}`) }} <span
              class="sum_tip"
              :data-key="key"
            >{{ sumTip(key) | makePretty }}</span></label>
            <sel2
              v-model.number="selected.selects[key]"
              @change="sumTip(key)"
            >
              <option :value="null">
                {{ trans('notice.not_selected') }}
              </option>
              <option
                v-for="(option, key) in select"
                :value="option.id"
              >
                {{ option.description }}
              </option>
            </sel2>
          </div>
          <ul class="mb-20">
            <li
              v-for="(check, key) in checkboxes"
              class="mb-10"
            >
              <input
                :id="'rad-' + key"
                v-model="selected.checkboxes[key]"
                type="checkbox"
                class="s-form__checkbox"
              >
              <label
                :for="'rad-' + key"
                class="s-form__label"
              >{{ trans(`salary.${key}`) }}</label>
              <span
                class="sum_tip"
                :data-key="key"
              >{{ sumTip(key, 'checkbox') | makePretty }}</span>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <div class="s-form__inform">
          <div class="s-bold">
            <span>{{ trans('salary.result_final') }}:</span>
            {{ resultFinal | makePretty }}
            <span>тенге</span>
          </div>
        </div>
        <button
          type="submit"
          class="s-btn s-btn--thm-blue"
          :class="{ 's-btn--disabled s-process': sending }"
          :disabled="sending"
        >
          {{ trans('button.save') }}
        </button>
      </section>
    </form>
    <div
      v-else
      id="preloader"
      class="preloader-wrapper"
      style="position: static;"
    >
      <svg
        class="svg"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
      >
        <circle
          class="path cls-1 path"
          stroke-width="10"
          stroke-miterlimit="10"
          cx="100"
          cy="100"
          r="94"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  name: 'SalaryCalc',
  filters: {
    makePretty(number) {
      if (!number || typeof number !== 'number') return number;
      return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
  props: {
    translator: {
      type: Object,
      default: () => ({}),
    },
    urls: {
      type: Object,
      default: () => ({}),
    },
    personnelId: {
      type: String,
      default: '',
    },
    chosen: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      position_class_id: {},
      experience: {},
      standard_hours: {},
      selects: {},
      checkboxes: {},
      selected: {
        position_class_id: null,
        experience: null,
        standard_hours: null,
        actual_hours: null,
        overtime: null,
        selects: {},
        checkboxes: {},
      },
      errors: {},
      multipliers: {
        percent: 17697,
        mrp: 2778,
        mrzp: 42500,
      },
      compReady: false,
      coeff: 0,
      isActive: {
        overtime: false,
        additions: false,
      },
      sending: false,
    };
  },
  computed: {
    overtime_tip() {
      const vm = this;
      return vm.selected.overtime * 1.5 * vm.resultMainHourly;
    },
    resultMain() {
      return _.round((this.coeff * this.multipliers.percent), 2);
    },
    resultMainHourly() {
      return _.round((this.resultMain / 72), 2);
    },
    resultOver() {
      if (this.standard_hours[this.selected.standard_hours]) {
        return _.round((this.resultMain * this.selected.actual_hours / this.standard_hours[this.selected.standard_hours]), 2);
      }
      return 0;
    },
    resultFinal() {
      const vm = this;
      let result = 0;
      // extra hours addition
      result += vm.selected.overtime * 1.5 * vm.resultMainHourly;

      // add all selected fields values, each multiplied by modificator
      _.forIn(vm.selected.selects, (selectId, key) => {
        if (selectId !== null && selectId !== '') {
          const index = _.findIndex(vm.selects[key], option => option.id === selectId);
          result += Number(vm.selects[key][index].raise_value) * vm.multipliers[vm.selects[key][index].raise_type];
        }
      });
      // add true checkboxes multiplied by modificator
      _.forIn(vm.selected.checkboxes, (cb, key) => {
        if (cb !== 0 && cb !== false) {
          result += Number(vm.checkboxes[key].raise_value) * vm.multipliers[vm.checkboxes[key].raise_type];
        }
      });
      return _.round((vm.resultOver + result), 2);
    },
    posWatcher() {
      return this.selected.position_class_id;
    },
    expWatcher() {
      return this.selected.experience;
    },
  },
  watch: {
    posWatcher() {
      this.pullCoeff();
    },
    expWatcher() {
      this.pullCoeff();
    },
  },
  mounted() {
    this.pullOptions();
  },
  methods: {
    sumTip(key, type = 'select') {
      const vm = this;

      if (type === 'checkbox') {
        const checked = vm.selected.checkboxes[key];

        if (!vm.checkboxes[key] || !checked) return 0;
        return Number(vm.checkboxes[key].raise_value) * vm.multipliers[vm.checkboxes[key].raise_type];
      }

      const id = Number(vm.selected.selects[key]);
      const item = vm.selects[key].find(x => x.id === id);

      if (!item) return 0;
      return Number(item.raise_value) * vm.multipliers[item.raise_type];
    },
    pullCoeff() {
      if (this.selected.position_class_id !== null
        && this.selected.position_class_id !== ''
        && this.selected.experience !== null
        && this.selected.experience !== '') {
        axios({
          method: 'post',
          url: this.urls.coeff,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': window.core_project.csrfToken || '',
            'X-localization': window.core_project.locale,
          },
          data: {
            position_classification_id: this.selected.position_class_id,
            experience: this.selected.experience,
          },
        })
          .then((response) => {
            this.coeff = Number(response.data.coefficient);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.coeff = 0;
      }
    },
    trans(key) {
      const keys = key.split('.');
      const l = keys.length;
      let i = 0;
      let currentKey = keys[i];
      let current = null;
      if (this.translator[currentKey] !== undefined) {
        current = this.translator[currentKey];
        i += 1;
        while (i < l) {
          currentKey = keys[i];
          if (current[currentKey] !== undefined) {
            current = current[currentKey];
          } else {
            break;
          }
          i += 1;
        }
      }
      if (typeof current === 'string') {
        return current;
      }
      return key;
    },
    pullOptions() {
      const vm = this;
      axios({
        method: 'get',
        url: this.urls.options,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': window.core_project.csrfToken || '',
          'X-localization': window.core_project.locale,
        },
      })
        .then((response) => {
        // set options to model
          _.forIn(response.data, (value, key) => {
            vm.$set(vm, key, value);
          });
          // set selects values to default
          _.forIn(vm.selects, (value, key) => {
            vm.$set(vm.selected.selects, key, null);
          });
          // set input values if set before
          if (vm.chosen) {
            vm.bindChosen();
          }
          // show component
          vm.compReady = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submitForm() {
      this.sending = true;
      axios({
        method: 'post',
        url: this.urls.submit,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': window.core_project.csrfToken || '',
          'X-localization': window.core_project.locale,
        },
        data: {
          personnel_id: this.personnel_id,
          position_class_id: this.selected.position_class_id,
          experience: this.selected.experience,
          standard_hours: this.selected.standard_hours,
          actual_hours: this.selected.actual_hours,
          overtime: this.selected.overtime,
          total: this.resultFinal,
          ...this.selected.checkboxes,
          // bug: sel2 wrapper transforms null values to ''
          ...this.selected.selects,
        },
      })
        .then((response) => {
          if (response.data.status === 'success') {
            window.location.href = this.urls.redirect;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.errors) {
            this.$set(this, 'errors', error.response.data.errors);
          } else {
            console.error(error);
          }
          this.sending = false;
        });
    },
    bindChosen() {
      const vm = this;
      _.forIn(vm.selects, (value, key) => {
        if (vm.chosen[key] !== null) {
          vm.$set(vm.selected.selects, key, vm.chosen[key]);
        }
      });
      _.forIn(vm.checkboxes, (value, key) => {
        if (vm.chosen[key] !== null) {
          vm.$set(vm.selected.checkboxes, key, vm.chosen[key]);
        }
      });
      vm.setSelected('position_class_id');
      vm.setSelected('experience');
      vm.setSelected('standard_hours');
      vm.setSelected('actual_hours');
      vm.setSelected('overtime');
    },
    setSelected(key) {
      this.$set(this.selected, key, this.chosen[key]);
    },
  },
};
</script>

<style lang="scss">
  .collapse {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
    position: relative;

    &.is-active {
      max-height: 300vh;
      transition: max-height 1s ease-in-out;
    }
  }

  .sum_tip {
    font-size: 1.4rem;
    font-weight: 600;
    color: #3045A7;
    margin-left: 1rem;
  }
</style>
