import { FormField, StoreTranslator } from '@common/mixins';
import FakeFile from '@common/src/FakeFile';
import _ from 'lodash';

export default {
  mixins: [StoreTranslator, FormField],
  props: {},
  data() {
    return {
      isConfirmed: false,
    };
  },
  methods: {
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
    fill(data) {
      _.set(data,
        this.nameToDot(this.field.name),
        this.photo);
    },
    openModal() {
      this.$sModal.open('v-model-take-photo', {
        component: 'v-model-take-photo',
        events: {
          photoConfirmed: this.saveImage,
        },
      });
    },
    saveImage(canvasPhoto) {
      this.isConfirmed = true;
      this.photo = canvasPhoto;
    },
  },
  mounted() {
    this.field.fill = this.fill;
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'webcam-photo']);
  },
  computed: {},
};
