<template>
  <div />
  <!--TODO-->
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};

</script>
