import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QBtn,
  QIcon,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import eventHub from '@vjs/config/eventHub';
import _ from 'lodash';
import { Helper } from '@common/src/helpers';
import { ERR_302 } from '@vjs/helpers/requestWrapper';

export default {
  name: 'VStepDocuments',

  components: {
    QForm,
    QInput,
    QBtn,
    QIcon,
    VFormInform,
  },

  props: {
    uriAction: {
      type: String,
      required: true,
    },
    isBinom: {
      type: Boolean,
      default: false,
    },
    numberAkt: {
      type: [Number, String],
    },
    isChildResident: {
      type: Boolean,
    },
    withDocPurchaseAgreement: {
      type: Boolean,
    },
    type: {
      type: Number,
      required: true,
    },
    className: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      form: {
        numberAkt: this.numberAkt,
        files: {
          birth_certificates: [],
          absentee_ballot: [],
          photo: [],
          medical_card_063: [],
          medical_card: [],
          purchase_agreement: [],
          certificate_of_basic_secondary_edu: [],
          document_with_place_of_residence: [],
          certificate_pmpk: [],
          disability_certificate: [],
          medical_advisory_commission_conclusion: [],
        },
      },
      activeFile: null,
      loading: false,
      consts: {
        EXTERNAL_TRANSFER: 4,
        CORRECTIONAL: 18,
        MEDICAL_TREATMENT: 20,
      },
    };
  },
  created() {
    eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },

  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  computed: {
    validate() {
      const files = _.cloneDeep(this.form.files);
      if (this.type === this.consts.CORRECTIONAL) {
        return !!files.certificate_pmpk.length;
      }
      if (this.type === this.consts.MEDICAL_TREATMENT) {
        return !!files.birth_certificates.length
          && !!files.disability_certificate.length
          && !!files.medical_advisory_commission_conclusion.length;
      }
      if (this.isBinom) {
        return (this.isFileRequired('photo') ? !!files.photo.length : true)
          && (this.isFileRequired('medical_card_063') ? !!files.medical_card_063.length : true)
          && (this.isFileRequired('medical_card') ? !!files.medical_card.length : true)
          && (this.isFileRequired('document_with_place_of_residence') ? !!files.document_with_place_of_residence.length : true)
          && (this.isFileRequired('certificate_of_basic_secondary_edu') ? !!files.certificate_of_basic_secondary_edu.length : true);
      }
      return !!files.absentee_ballot.length
        && (this.isFileRequired('document_with_place_of_residence') ? !!files.document_with_place_of_residence.length : true);
    },
    computedFileTypes() {
      if (this.type === this.consts.CORRECTIONAL) {
        return ['certificate_pmpk'];
      }
      const types = [];

      if (this.type === this.consts.MEDICAL_TREATMENT) {
        types.push('birth_certificates');
        types.push('disability_certificate');
        types.push('medical_advisory_commission_conclusion');
        return types;
      }

      if (!this.isBinom) {
        types.push('absentee_ballot');
      }
      if (!this.isChildResident) {
        types.push('document_with_place_of_residence');
      }
      if (this.isBinom) {
        // types.push('birth_certificates');
        types.push('photo');
        types.push('medical_card_063');
        types.push('medical_card');
        if (this.withDocPurchaseAgreement) {
          types.push('purchase_agreement');
        }
        if ([10, 11, 12].includes(this.className)) {
          types.push('certificate_of_basic_secondary_edu');
        }
      }
      // if (!this.isChildResident || (this.isChildResident && !this.numberAkt)) {
      //   types.push('birth_certificates');
      // }
      return types;
    },
  },
  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS', val);
    },
  },

  methods: {
    getMaxFiles(fileType) {
      if (this.type === this.consts.MEDICAL_TREATMENT) {
        return 1;
      }
      switch (fileType) {
        case 'absentee_ballot': return 1;
        case 'certificate_of_basic_secondary_edu': return 2;
        default: return 3;
      }
    },
    getFileLabel(fileType) {
      switch (fileType) {
        case 'birth_certificates': return this.trans('label.birth_certificates');
        case 'absentee_ballot': return this.trans('label.absentee_ballot');
        case 'photo': return this.trans('label.photo_3x4');
        case 'medical_card_063': return this.trans('label.medical_card_063');
        case 'medical_card': return this.trans('label.medical_card');
        case 'purchase_agreement': return this.trans('label.purchase_agreement');
        case 'certificate_of_basic_secondary_edu': return this.trans('label.certificate_of_basic_secondary_edu');
        case 'document_with_place_of_residence': return this.trans('label.document_with_place_of_residence');
        case 'certificate_pmpk': return this.trans('label.certificate_pmpk');
        case 'disability_certificate': return this.trans('label.disability_certificate');
        case 'medical_advisory_commission_conclusion': return this.trans('label.conclusion_medical_advisory_commission');
        default: return '';
      }
    },
    isFileRequired(fileType) {
      switch (fileType) {
        // case 'birth_certificates': return this.isBinom || (this.isChildResident && !this.numberAkt);
        case 'absentee_ballot':
        case 'certificate_pmpk':
          return !this.isBinom;
        case 'photo': return this.isBinom;
        case 'medical_card_063': return this.isBinom;
        case 'medical_card': return this.isBinom;
        case 'certificate_of_basic_secondary_edu': return [10, 11, 12].includes(this.className);
        case 'document_with_place_of_residence': return !this.isChildResident;
        case 'birth_certificates': return this.type === this.consts.MEDICAL_TREATMENT;
        case 'disability_certificate': return this.type === this.consts.MEDICAL_TREATMENT;
        case 'medical_advisory_commission_conclusion': return this.type === this.consts.MEDICAL_TREATMENT;
        default: return false;
      }
    },
    async requestForm() {
      this.$emit('loading', true);
      const data = {
        ...this.form,
        isChildResident: this.isChildResident,
        withoutNotice: true,
        type: this.type,
        isBinom: this.isBinom,
        className: this.className,
      };
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAction,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$emit('loading', false);
      } else if (res.errorType !== 302) {
        if (!this.isBinom) {
          this.$store.commit('SET_NEXT_STEP');
        }
        this.$emit('loading', false);
      }
    },
  },
};
