import Pagination from '@common/components/Pagination';
import NewTableFilters
from '@common/views/NewTableWithFilters/NewTableFilters';
import { eventBus } from '@common/mixins';
import { scrollTop } from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import QuestionCard from './QuestionCard';

export default {
  components: {
    Pagination,
    QuestionCard,
    NewTableFilters,
    VNoResults,
  },
  props: {
    uriGetItems: {
      type: String,
      required: true,
    },
    uriGetFilters: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      getItemsLink: this.uriGetItems,
      links: [],
      meta: {},
      loading: false,
    };
  },
  beforeCreate() {
    this.$trans.add([
      'test',
      'main',
    ]);
  },
  mounted() {
    this.activateMathJax();
  },
  updated() {
    this.activateMathJax();
  },
  methods: {
    activateMathJax() {
      // Активация MathJax, если MathJax найден
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
            processEscapes: true,
          },
        });
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
        ],
        () => {
          // Фикс от скачков формул во время отрисовки
          const mathTex = document.querySelectorAll('.math-tex');
          Array.from(mathTex).forEach((item) => {
            item.style.opacity = '1';
          });
        });
      }
    },
    async toggleLoader(enable = false) {
      await this.$trans.add('label');
      if (enable && !this.loading) {
        this.loading = true;
        this.$globalLoading.show();
      } else if (!enable && this.loading) {
        this.$globalLoading.hide();
        this.loading = false;
      }
    },
    updateItems({ rows, generatedLinks, meta }) {
      this.items = rows;
      this.links = generatedLinks;
      this.meta = meta;
    },
    async updatePage(link) {
      await eventBus.$emit('getItems', link);
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.wrapperRef.getBoundingClientRect().top),
        500,
      );
    },
  },
};
