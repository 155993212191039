import { Form } from '@common/mixins';
import { requestWrapper } from '@vjs/helpers';
import _ from 'lodash';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [Form],
  props: {
    config: {
      url: {
        type: String,
        required: true,
      },
      method: {
        type: String,
        required: true,
      },
    },
    fields: {
      type: Object,
      default: {},
    },
  },
  beforeCreate() {
    this.$trans.add(['label']);
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const data = {};

      _.forEach(this.fields, (field) => {
        data[field.name] = field.value;
      });

      const res = await requestWrapper.call(this, {
        method: this.config.method,
        url: this.config.url,
        data,
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
