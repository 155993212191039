import { eventBus, FormField, StoreTranslator } from '@common/mixins';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  mixins: [StoreTranslator, FormField],
  components: { Treeselect },
  beforeCreate() {
    this.$trans.add(['select']);
  },
  methods: {
    emitChange(value = null) {
      if (this.field.extraAttributes && this.field.extraAttributes.multiple) {
        this.$emit('input', value);
      } else if (value && value.hasOwnProperty('id')) {
        this.$emit('input', value.id);
        this.$emit('change', value.id);
      } else if (value) {
        this.$emit('input', value);
        this.$emit('change', value);
      } else {
        this.$emit('input', null);
        this.$emit('change', null);
      }
    },
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
    setEmpty() {
      const vm = this;
      vm.$set(vm, 'loadedValues', []);
      vm.setInitialSelectValue();
      vm.emitChange(this.selectValue);
    },
    loadValues() {
      const vm = this;
      if (vm.field.loadValues === undefined) {
        return;
      }
      const where = vm.field.loadValues.where ?? {};

      const config = {
        responseType: 'json',
        method: 'GET',
        params: where,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      if (vm.field.loadValues.headers) {
        _.each(vm.field.loadValues.headers, (value, key) => {
          config.headers[key] = value;
        });
      }
      let url = `/api/refs/${vm.field.loadValues.type}`;
      if (vm.field.loadValues.url) {
        url = vm.field.loadValues.url;
      }
      vm.$http.get(url, config).then(
        function (response) {
          const { data } = response;
          vm.$set(vm, 'loadedValues', data);
          vm.setInitialSelectValue();
          vm.emitChange(this.selectValue);
        },
        (response) => {
          console.log(response);
        },
      );
    },
    setInitialSelectValue() {
      this.localValue = !(this.field.value === '' || this.field.value === null)
        ? this.field.value
        : '';

      if (this.localValue !== '' && this.localValue !== null) {
        this.selectValue = this.localValue;
      } else {
        this.selectValue = undefined;
      }
    },
  },
  data() {
    return {
      loadedValues: [],
      selectValue: [],
    };
  },

  created() {
    eventBus.$on(`reload-refs:${this.field.name}`, () => {
      this.loadValues();
    });
  },
  mounted() {
    if (this.field.hasOwnProperty('loadValues')) {
      this.field.loadValues.reload = this.loadValues;
      this.field.loadValues.setEmpty = this.setEmpty;
      if (!this.field.loadValues.hasOwnProperty('autoLoad') || this.field.loadValues.autoLoad) {
        this.loadValues();
      }
    }
    this.setInitialSelectValue();
  },
  watch: {
    value(value) {
      // update value
      this.setInitialSelectValue();
    },
    // selectValue: function (value) {
    //   // update value
    //   if (value && value.hasOwnProperty('id')) {
    //     this.$emit('input', value.id);
    //   } else {
    //     this.$emit('input', null);
    //   }
    // },
  },
  computed: {
    values() {
      if (this.loadedValues.length) {
        return this.loadedValues;
      }
      if (this.field.values.length > 0) {
        this.field.values = _.forEach(this.field.values, (item) => {
          if (item.hasOwnProperty('nameTransKey')) {
            item.name = this.trans(item.nameTransKey);
          }
        });
      }
      return this.field.values;
    },
    defaultAttributes() {
      return {
        name: this.field.name,
        'minimum-results-for-search': 20,
        placeholder: this.trans('placeholder.choose'),
      };
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
};
