export default {
  props: {
    uriGetItems: {
      type: String,
      required: true,
    },
    uriGetFilters: {
      type: String,
      required: false,
    },
    uriExport: {
      type: String,
      required: false,
    },
    storageName: {
      type: String,
      required: false,
    },
    forceStorageFilters: Boolean,
    alternativeExport: Boolean,
    forceHideFilters: Boolean,
    forceCustomSearchPlaceholder: Boolean,
    columnFilter: Boolean,
    withoutAutoRenderFilters: Boolean,
    useCustomEventBus: Boolean,
    twoFilterAtRow: Boolean,
    withoutDefaultExportBtn: Boolean,
    exportName: String,
  },
};
