import { eventBus, FormField, StoreTranslator } from '@common/mixins';
import { QItem, QItemSection } from '@quasar/components';
import _ from 'lodash';

export default {
  components: {
    QItem,
    QItemSection,
  },
  mixins: [StoreTranslator, FormField],
  beforeCreate() {
    this.$trans.add(['select']);
  },
  data() {
    return {
      localFileValues: [],
      isModalOpen: false,
    };
  },
  computed: {
    isFileLabel() {
      if (this.field.labelTransKey) {
        return true;
      }

      if (this.field.label) {
        return true;
      }

      return false;
    },
    computedFileLabel() {
      if (_.isArray(this.localFileValues)) {
        if (this.localFileValues.length > 1) {
          return this.trans('label.files_count', { count: this.localFileValues.length });
        }
        if (this.localFileValues.length === 1) {
          return this.localFileValues[0].name;
        }
      }
      return this.trans('label.file_not_chosen');
    },
    defaultAttributes() {
      return {};
    },

    extraAttributes() {
      const attrs = this.field.extraAttributes;

      return {
        ...this.defaultAttributes,
        ...attrs,
      };
    },
  },
  mounted() {
    this.setInitialValue();
    eventBus.$on('user-files-modal-input', this.handleFilesModalInput);
    eventBus.$on('user-files-modal-close', this.handleFilesModalClose);
  },
  methods: {
    handleFilesModalClose() {
      this.isModalOpen = false;
    },
    handleFilesModalInput(files) {
      if (this.isModalOpen) {
        this.localFileValues = files;
        this.isModalOpen = false;
        this.emitChange();
      }
    },
    triggerFile() {
      this.isModalOpen = true;
      eventBus.$emit('open-user-files-modal', this.field);
    },
    clearFile() {
      this.localFileValues = [];
      this.emitChange();
    },
    showNewModal(config) {
      this.$sModal.open(config.id, {
        component: config.component,
        size: config.size,
        title: config.title,
        binds: config.binds,
      });
    },
    emitChange() {
      this.$emit('input', this.localFileValues);
      this.$emit('change', this.localFileValues);
    },
    setInitialValue() {
      this.localFileValues = this.field.value ?? [];
      this.emitChange();
    },
  },
  watch: {
    value() {
      // update value
      this.setInitialValue();
    },
  },
};
