import Vue from 'vue';

import TransitionMixin from '../../mixins/transition';
import QSpinner from '../spinner/QSpinner';

export default Vue.extend({
  name: 'QInnerLoading',

  mixins: [TransitionMixin],

  props: {
    showing: Boolean,
    color: String,

    size: {
      type: [String, Number],
      default: 42,
    },

    fixed: Boolean,
    screenLock: Boolean,

    dark: Boolean,
  },

  mounted() {
    if (this.showing && this.screenLock) {
      document.body.classList.add('modal-open');
    }
  },

  updated() {
    if (this.showing && this.screenLock) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  },

  unmounted() {
    document.body.classList.remove('modal-open');
  },

  render(h) {
    const content = this.$scopedSlots.default !== void 0
      ? this.$scopedSlots.default()
      : [
        h(QSpinner, {
          props: {
            size: this.size,
            color: this.color,
          },
        }),
      ];

    const classDyn = {
      'sn-inner-loading--dark': this.dark,
      's-pos-absolute-full': !this.fixed,
      's-pos-fixed-full': this.fixed,
    };

    return h('transition', {
      props: { name: this.transition },
    }, [
      this.showing === true ? h('div', {
        staticClass: 'sn-inner-loading sn--column sn--flex-center',
        class: classDyn,
        on: this.$listeners,
      }, content) : null,
    ]);
  },
});
