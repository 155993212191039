export const NINTH_ELEVEN_CLASSES = ['9', '11']; // Девятый класс и Одиннадцатый класс

export const SPECIAL_EDU_CONDITION_VAL_NO = '3'; // Значение: "Нет", Поле: 'special_edu_condition'
export const PERFORMANCE_LEVEL_NO_ANNUAL_ASSESSMENT = '5'; // Значение: "Не проставляют годовую оценку", Поле: performance_level
export const NATURAL_MATHEMATICAL_PROGRAM_SUB_VAL_NO = '-1'; // Значение: "Нет", Поле: natural_mathematical_program_subject
export const STUDIES_FOREIGN_LANGUAGE_NOT_STUDY = ['-1', '99']; // Значения: "Не изучает", Поле: studies_foreign_language

export const NATIVE_LANG_AS_INDEPENDENT_SUB_VAL_NO = '-1'; // Значение: "Нет", Поле: native_lang_as_independent_subject
export const OPTIONAL_STUDY_NATIVE_LANG_VAL_NO = '-1'; // Значение: "Нет", Поле: optional_study_native_lang
export const ATTENDS_CLUBS_THIS_ORG_VAL_NO = '-1'; // Значение: "Нет", Поле: attends_clubs_this_org
export const COMPETITIONS_CONTESTS_OLYMPIADS_EVENT_TYPE_NOT_PARTICIPATE = '07'; // Значение: "Не участвует", Поле: competitions_contests_olympiads_event_type

export const STANDS_ON_RECORD_INTRA_SCHOOL_ACC = '01'; // Значение: "Внутришкольный учёт", Поле: stands_on_record
export const STANDS_ON_RECORD_MINORS_ACC = '02'; // Значение: "Учёт отдела по делам несовершеннолетних", Поле: stands_on_record
export const STANDS_ON_RECORD_NOT_CONSIST = '03'; // Значение: "Не состоит", Поле: stands_on_record

export const ABNORMALITIES_VAL_NO = 14; // Значение: "Нет", Поле: abnormalities
export const EMPLOYMENT_TYPE_ID_LEFT_COUNTRY = [
  15,
  27,
  47,
]; // Значения: "Выбыл за пределы РК", Поле: employment_type_id
