import axios from 'axios';

export default {
  props: {
    testTypes: {
      default: null,
      type: Object,
    },
    school: String,
    regions: {
      default: [],
      type: Array,
    },
    child: Number,
    uriStatementStore: String,
    locations: {
      default: null,
      type: Object,
    },
    actualResidence: null,
    placeOfResidence: null,
  },
  data() {
    return {
      loading: false,

      selected: {},
      multiselectArray: {},
      selected_soc_status: null,

      languages: [],
      classes: [],
      departments: [],
      localities: [],
      actual_residence: this.actualResidence,
    };
  },
  computed: {
    directions() {
      if (!this.testTypes) return [];
      return Object.entries(this.testTypes).map(d => ({
        id: d[0],
        label: d[1],
      }));
    },
    preparedLocations() {
      if (!this.locations) return [];
      return Object.entries(this.locations).map(l => ({
        id: l[0],
        label: l[1],
      }));
    },
    formValid() {
      return !this.loading
        && Object.keys(this.selected).length === 5;
    },
  },
  methods: {
    async getLangs(value) {
      this.selected.test_type_id = value.id;
      this.languages = [];
      delete this.selected.lang;
      this.classes = [];
      delete this.selected.school_class;

      const { school } = this;
      try {
        this.loading = true;

        const { data: langs } = await axios.get(`/statement-options-api/getLangsByTestType/${school}/${value.id}`, {
          headers: {
            'X-localization': window.core_project.locale,
          },
        });
        this.languages = Object.entries(langs.langs).map(l => ({
          id: l[0],
          label: l[1],
        }));
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getClasses(value) {
      this.selected.lang = value.id;
      this.classes = [];
      delete this.selected.school_class;

      const { school, selected } = this;
      try {
        this.loading = true;

        const { data: classes } = await axios
          .get(`/statement-options-api/getClassesByLang/${school}/${selected.lang}/${selected.test_type_id}`, {
            headers: {
              'X-localization': window.core_project.locale,
            },
          });
        this.classes = classes.classes;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getDepartments(value) {
      this.departments = [];
      this.localities = [];
      delete this.selected.locality_id;

      try {
        this.loading = true;

        const { data: departments } = await axios.get('/departments', {
          params: {
            id: value.id,
          },
          headers: {
            'X-localization': window.core_project.locale,
          },
        });
        this.departments = departments;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getLocalities(value) {
      this.localities = [];
      delete this.selected.locality_id;

      try {
        this.loading = true;

        const { data: localities } = await axios.get('/localities', {
          params: {
            id: value.id,
          },
          headers: {
            'X-localization': window.core_project.locale,
          },
        });
        this.localities = localities;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async submit(e) {
      e.preventDefault();

      const {
        uriStatementStore, selected, child, selected_soc_status,
      } = this;
      selected.child_id = child;
      selected.soc_status = selected_soc_status ? 1 : 0;
      selected.actual_residence = this.actual_residence;
      try {
        const { data } = await axios.post(`${uriStatementStore}`,
          { ...selected }, { headers: { 'X-Requested-With': 'XMLHttpRequest' } });
        if (data.status === 'fail') {
          show_notice(data.message, 'error');
        } else if (data.status === 'success') {
          window.location.href = data.route;
        }
      } catch (err) {
        console.error(err);
        const { errors } = (err.response || {}).data;

        if (errors) {
          for (const key of Object.keys(errors)) {
            errors[key].forEach(error_message => show_notice(error_message, 'error'));
          }
        }
      }
    },
  },
};
