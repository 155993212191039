import Vue from 'vue';

import SizeMixin from '../../mixins/size';
import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QIcon',

  mixins: [SizeMixin],

  props: {
    name: String,
    color: String,
    size: String,
    bold: Boolean,
    boldest: Boolean,
    left: Boolean,
    right: Boolean,
    iconClass: {
      type: String,
      default: 's-ico',
    },
  },

  computed: {
    type() {
      let cls;
      const icon = this.name;

      if (!icon) {
        return {
          cls: undefined,
          content: undefined,
        };
      }

      const commonCls = `${this.iconClass} ${
        this.left === true ? ' s-pos-on-left' : ''
      }${this.right === true ? ' s-pos-on-right' : ''}`;

      if (icon.startsWith('img:') === true) {
        return {
          img: true,
          cls: commonCls,
          src: icon.substring(4),
        };
      }

      const content = ' ';

      // Only use our snation custom font

      // if (/^fa[s|r|l|b]{0,1} /.test(icon) || icon.startsWith('icon-') === true) {
      //   cls = icon;
      // } else if (icon.startsWith('bt-') === true) {
      //   cls = `bt ${icon}`;
      // } else if (icon.startsWith('eva-') === true) {
      //   cls = `eva ${icon}`;
      // } else if (/^ion-(md|ios|logo)/.test(icon) === true) {
      //   cls = `ionicons ${icon}`;
      // } else if (icon.startsWith('ion-') === true) {
      //   cls = `ionicons ion-${this.$q.platform.is.ios === true ? 'ios' : 'md'}${icon.substr(3)}`;
      // } else if (icon.startsWith('mdi-') === true) {
      //   cls = `mdi ${icon}`;
      // } else if (icon.startsWith('iconfont ') === true) {
      //   cls = `${icon}`;
      // } else if (icon.startsWith('ti-') === true) {
      if (icon.includes(`${this.iconClass}--thm-`)) {
        cls = `${icon}`;
      } else {
        cls = `${this.iconClass}--thm-${icon}`;
      }
      // } else {
      //   cls = 'material-icons';
      //
      //   if (icon.startsWith('o_') === true) {
      //     icon = icon.substring(2);
      //     cls += '-outlined';
      //   } else if (icon.startsWith('r_') === true) {
      //     icon = icon.substring(2);
      //     cls += '-round';
      //   } else if (icon.startsWith('s_') === true) {
      //     icon = icon.substring(2);
      //     cls += '-sharp';
      //   }
      //
      //   content = icon;
      // }

      let tmpCls = `${cls} ${commonCls}`;
      tmpCls += ` ${this.color !== undefined ? ` s-c-${this.color}` : ''}`;
      tmpCls += ` ${this.bold ? 's-ico--bold' : ''}`;
      tmpCls += ` ${this.boldest ? 's-ico--boldest' : ''}`;
      return {
        cls: tmpCls,
        content,
      };
    },

    style() {
      if (this.size !== undefined) {
        return { fontSize: this.size };
      }
    },
  },

  render(h) {
    return this.type.img === true
      ? h('img', {
        staticClass: this.type.cls,
        style: this.sizeStyle,
        on: this.$listeners,
        attrs: { src: this.type.src },
      })
      : h('i', {
        staticClass: this.type.cls,
        style: this.sizeStyle,
        on: this.$listeners,
        attrs: { 'aria-hidden': true },
      }, [
        this.type.content,
        slot(this, 'default'),
      ]);
  },
});
