import { QOptionGroup } from '@quasar/components/option-group';
import CardMixin from '../CardMixin';

export default {
  components: { QOptionGroup },
  mixins: [CardMixin],

  data() {
    return {};
  },

  computed: {
    genders() {
      return [
        {
          value: 'm',
          label: this.trans('label.male'),
        },
        {
          value: 'w',
          label: this.trans('label.female'),
        },
      ];
    },
  },
  methods: {
    async refreshBirthPlace() {
      this.$globalLoading.show();

      const action = this.actions.find(itm => itm.action === 'submit');
      await requestWrapper.call(this, {
        ...action,
        data: { birthPlace: this.fields.birthPlace },
      });
      this.$globalLoading.hide();
    },
    async blockCard() {
      if (this.disabled) {
        return;
      }
      this.$globalLoading.show();

      const action = this.actions.find(itm => itm.action === 'blockFaceId');
      await requestWrapper.call(this, {
        method: 'post',
        ...action,
      });
      this.$globalLoading.hide();
    },
  },
};
