import { Form, StoreTranslator } from '@common/mixins';
import _ from 'lodash';
import { QTab, QTabs } from '@quasar/components/tabs';


export default {
  mixins: [StoreTranslator, Form],
  components: {
    QTabs,
    QTab,
  },
  props: {
    types: {
      type: Array,
      required: true,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
    extraConfigsFields: {
      type: [Array, Object],
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      name: {
        value: '',
        name: 'name',
        component: 'q-text-field',
        labelTransKey: 'fields.name',
        required: true,
      },
      type: {
        value: [],
        options: this.types ?? [],
        name: 'type',
        component: 'v-select-from-list-checkbox-field',
      },
    };
    if (vm.resource !== undefined) {
      _.forEach(vm.resource, (value, attribute) => {
        if (Object.prototype.hasOwnProperty.call(fields, attribute)) {
          _.set(fields, `${attribute}.value`, value);
        }
      });
    }
    if (vm.hides) {
      _.forEach(vm.hides, (attribute) => {
        if (Object.prototype.hasOwnProperty.call(fields, attribute)) {
          _.set(fields, `${attribute}.extraAttributes.disabled`, true);
        }
      });
    }
    if (vm.extraConfigsFields) {
      _.forEach(vm.extraConfigsFields, (configs, attribute) => {
        if (Object.prototype.hasOwnProperty.call(fields, attribute)) {
          const field = fields[attribute];
          _.set(fields, attribute, _.assignIn(field, configs));
        }
      });
    }
    return {
      fields,
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields', 'warning_text', 'placeholder',
      'label', 'button', 'main',
      'notice', 'labor',
    ]);
  },
};
