import {
  QBtn,
  QTable,
  QTh,
  QTr,
  QTd,
  QPopupEdit,
  QInput,
  QIcon,
  QSelect, QCheckbox,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import NewTableFilters from '@common/views/NewTableWithFilters/NewTableFilters';
import DefaultTableProps
from '@common/views/NewTableWithFilters/mixins/DefaultTableProps';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [DefaultTableProps],
  components: {
    NewTableFilters,
    QCheckbox,
    QBtn,
    QTable,
    QTh,
    QTr,
    QTd,
    QInput,
    QIcon,
    QSelect,
    QPopupEdit,
  },
  props: {
    uriAction: String,
    uriRedirect: String,
    isEdit: Boolean,
  },
  data: () => ({
    isAutoFillPopupOpened: false,
    autoFillPopupValue: null,
    pagination: {
      rowsPerPage: 20,
    },
    items: [],
    cols: [],
    links: [],
    meta: {},
    loading: false,
  }),
  computed: {
    tablePropCols() {
      return this.cols[this.cols.length - 1] ?? [];
    },
    headerCols() {
      const result = _.cloneDeep(this.cols);
      result.pop();
      return result;
    },
  },
  async mounted() {
    await this.$trans.add([
      'placeholder',
      'label',
      'rfid',
      'button',
      'book',
    ]);
  },
  methods: {
    async toggleLoader(enable = false) {
      await this.$trans.add('label');
      if (enable && !this.loading) {
        this.loading = true;
        this.$globalLoading.show();
      } else if (!enable && this.loading) {
        this.$globalLoading.hide();
        this.loading = false;
      }
    },
    updateItems({
      rows, cols, generatedLinks, meta,
    }) {
      this.items = rows;
      this.cols = cols;
      this.links = generatedLinks;
      this.meta = meta;
    },
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAction,
        data: { data: this.items },
      });
      if (res.error && res.errorType !== 302) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
