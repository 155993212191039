import VNoResults from '@vjs/components/VNoResults';
import {
  QTable,
  QTh,
  QTr,
  QTd,
  QInput,
  QIcon,
  QCheckbox,
  QTooltip,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VInProgressAltBooksList',
  components: {
    VNoResults,
    QTable,
    QTh,
    QTr,
    QTd,
    QCheckbox,
    QTooltip,
    QInput,
    QIcon,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        action: null,
        getRows: null,
        create: null,
      }),
    },
  },
  data() {
    return {
      checkedRows: [],
      rows: [],
      filter: '',
      pagination: {
        rowsPerPage: 20,
      },
    };
  },
  async mounted() {
    await this.$trans.add(['label', 'table', 'button', 'book', 'placeholder']);
    await this.requestGetData(this.config.getRows, 'rows');
    this.rows = _.cloneDeep(this.rows).map((itm) => {
      const tmpItm = itm;
      tmpItm.isChecked = false;
      return tmpItm;
    });
  },

  computed: {
    checkableRows() {
      return this.rows.filter(itm => itm.isCheckable);
    },
    isAllRowsChecked() {
      if (this.checkedRows.length === this.checkableRows.length) {
        return true;
      }
      if (this.checkedRows.length === 0) {
        return false;
      }
      return null;
    },
    cols() {
      const cols = [
        { field: 'isChecked', width: 55 },
        {
          label: this.trans('book.author'),
          field: 'author',
        },
        {
          label: this.trans('book.name'),
          field: 'name',
        },
        {
          label: this.trans('book.publisher'),
          field: 'publisher',
        },
        {
          label: this.trans('book.category'),
          field: 'category',
        },
        {
          label: this.trans('book.direction.title'),
          field: 'direction',
        },
        {
          label: this.trans('book.instance_count'),
          field: 'instanceCount',
        },
        {
          label: this.trans('table.operations'),
          field: 'actions',
          width: 150,
        },
      ];

      return cols.map((col) => {
        const tmpCol = col;
        tmpCol.name = col.field;
        return tmpCol;
      });
    },
  },


  methods: {
    handleHeadCheckbox() {
      if (!this.isAllRowsChecked) {
        this.checkedRows = this.checkableRows.map(itm => itm.id);
      } else {
        this.checkedRows = [];
      }
    },
    async submit() {
      if (this.checkedRows.length === 0) {
        this.$notify({
          type: 'warn',
          text: this.trans('book.check_books_first'),
        });
      } else {
        this.$globalLoading.show();
        const res = await requestWrapper.call(this, {
          method: 'post',
          url: this.config.action,
          data: { ids: this.checkedRows },
        });

        if (res.error) {
          Helper.handlerResponseErrorNew(this, res);
          this.$globalLoading.hide();
        }
      }
    },
    async requestGetData(url, variableName) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, { url });

      if (!res.error) {
        this.$set(this, variableName, res.data ?? []);
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    handleModal(action) {
      if (action.type === 'button') {
        if (action.modal === 's-modal' && action['s-modal-config']) {
          const config = action['s-modal-config'];
          const binds = config?.binds ?? {};
          const events = {};
          if (config?.getItemsOnSuccess) {
            const func = async () => { await this.requestGetData(this.config.getRows, 'rows'); };
            events.onApprove = func;
            events.onSuccess = func;
          }
          this.$sModal.open(
            action['s-modal-config'].component,
            { ...action['s-modal-config'], binds, events },
          );
        }
      }
    },
  },
};
