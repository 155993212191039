import { QField, QInput, QOptionGroup } from '@quasar/components';
import _ from 'lodash';

export default {
  components: { QInput, QField, QOptionGroup },

  props: {
    value: {},
    field: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
    },
    errors: {
      type: [Object, Array],
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      localValue: [],
      search: null,
      options: _.cloneDeep(this.field.options) ?? [],
      optionsFiltered: _.cloneDeep(this.field.options) ?? [],
    };
  },
  mounted() {
    this.setInitialValue();
  },
  methods: {
    isOptionSelected(id) {
      return this.localValue.includes(id);
    },
    setInitialValue() {
      this.localValue = _.isArray(this.field.value) ? this.field.value : [];
    },
    addOption(option) {
      this.localValue.push(option.id);
    },
    deleteOption(key) {
      this.localValue.splice(key, 1);
    },
  },
  computed: {
    optionsSelected() {
      return this.optionsFiltered.filter(itm => this.localValue.includes(itm.id));
    },
    placeholder() {
      return this.field.extraAttributes?.placeholder ?? this.trans('placeholder.default_search_placeholder');
    },
    fieldLabel() {
      if (this.field.label === '') {
        return '';
      }

      return this.field.label
        || (this.field.labelTransKey && this.trans(this.field.labelTransKey));
    },
    isDisabled() {
      return _.get(this.field, 'disabled', false)
      || _.get(this.field, 'disable', false)
      || _.get(this.field, 'extraAttributes.disable', false)
      || _.get(this.field, 'extraAttributes.disabled', false);
    },
  },
  watch: {
    search(val) {
      if (val === '') {
        this.optionsFiltered = this.options;
        return;
      }

      const needle = val.toLowerCase();
      this.optionsFiltered = this.options.filter(item => _.lowerCase(item.name).indexOf(needle) > -1);
    },
    localValue(newVal) {
      this.$emit('input', newVal);
    },
    value() {
      this.setInitialValue();
    },
  },
};
