import cookie from 'cookie';
import PropMixin from './mixins/PropMixin';
import VAdaptiveBanner from './components/VAdaptiveBanner.vue';
import VBanner from './components/VBanner.vue';

export default {
  name: 'VAdBanners',
  mixins: [PropMixin],
  components: { VBanner, VAdaptiveBanner },

  props: {
    isScholar: Boolean,
  },
  data() {
    return {
      soloBanner: 'kitap',
      banners: [
        {
          name: 'kitap',
          image: 'kitap',
          iosUrl: 'https://apps.apple.com/ru/app/kitap/id1046439139',
          googleUrl: 'https://play.google.com/store/apps/details?id=com.ionicframework.kitapkcell',
        },
        {
          name: 'bilim',
          image: this.isScholar ? 'bilim_scholar' : 'bilim',
          iosUrl: 'https://apps.apple.com/kz/app/bilim/id1601509880',
          googleUrl: 'https://play.google.com/store/apps/details?id=kz.bilimland.bilim&hl=ru&gl=US',
        },
      ],
    };
  },

  mounted() {
    this.$trans.add('label');
    this.soloBanner = _.cloneDeep(cookie.parse(document.cookie)['next-advertisement-banner']);
    let soloBanner;

    switch (this.soloBanner) {
      case 'kitap': soloBanner = 'bilim'; break;
      default: soloBanner = 'kitap'; break;
    }
    document.cookie = cookie.serialize('next-advertisement-banner', soloBanner, {
      path: '/',
      maxAge: 900000,
    });
  },

  computed: {
    bannersComputed() {
      const width = _.cloneDeep(this.$q.screen.width);
      if (width < 1180) {
        return _.filter(this.banners, { name: this.soloBanner });
      }
      return this.banners;
    },
  },
};
