import Vue from 'vue';

import QIcon from '../icon/QIcon.js';
import RippleMixin from '../../mixins/ripple.js';
import { stopAndPrevent } from '../../utils/event.js';
import slot from '../../utils/slot.js';

export default Vue.extend({
  name: 'QChip',

  mixins: [RippleMixin],

  model: {
    event: 'remove',
  },

  props: {
    dense: Boolean,

    icon: String,
    iconRight: String,
    label: [String, Number],

    color: String,
    textColor: String,

    value: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Boolean,
      default: null,
    },

    square: Boolean,
    outline: Boolean,
    clickable: Boolean,
    removable: Boolean,

    tabindex: [String, Number],
    disable: Boolean,
  },

  computed: {
    classes() {
      const text = this.outline
        ? this.color || this.textColor
        : this.textColor;

      return {
        [`sn--bg-${this.color}`]: this.outline === false && this.color !== void 0,
        [`s-c-${text} sn-chip--colored`]: text,
        disabled: this.disable,
        'sn-chip--dense': this.dense,
        'sn-chip--outline': this.outline,
        'sn-chip--selected': this.selected,
        'sn-chip--clickable sn--cursor-pointer non-selectable sn-hoverable': this.isClickable,
        'sn-chip--square': this.square,
      };
    },

    hasLeftIcon() {
      return this.selected === true || this.icon !== void 0;
    },

    isClickable() {
      return this.disable === false && (this.clickable === true || this.selected !== null);
    },

    computedTabindex() {
      return this.disable === true ? -1 : this.tabindex || 0;
    },
  },

  methods: {
    __onKeyup(e) {
      e.keyCode === 13 /* ENTER */ && this.__onClick(e);
    },

    __onClick(e) {
      if (!this.disable) {
        this.$emit('update:selected', !this.selected);
        this.$emit('click', e);
      }
    },

    __onRemove(e) {
      if (e.keyCode === void 0 || e.keyCode === 13) {
        stopAndPrevent(e);
        !this.disable && this.$emit('remove', false);
      }
    },

    __getContent(h) {
      const child = [];

      this.isClickable && child.push(
        h('div', { staticClass: 'sn-focus-helper' }),
      );

      this.hasLeftIcon && child.push(
        h(QIcon, {
          staticClass: 'sn-chip__icon sn-chip__icon--left',
          props: { name: this.selected === true ? this.$q.iconSet.chip.selected : this.icon },
        }),
      );

      child.push(
        h('div', {
          staticClass: 'sn-chip__content sn--row sn--no-wrap sn--items-center sn-anchor--skip',
        }, this.label !== void 0 ? [this.label] : slot(this, 'default')),
      );

      this.iconRight && child.push(
        h(QIcon, {
          staticClass: 'sn-chip__icon sn-chip__icon--right',
          props: { name: this.iconRight },
        }),
      );

      this.removable && child.push(
        h(QIcon, {
          staticClass: 'sn-chip__icon sn-chip__icon--remove sn--cursor-pointer',
          props: { name: this.$q.iconSet.chip.remove },
          attrs: { tabindex: this.computedTabindex },
          nativeOn: {
            click: this.__onRemove,
            keyup: this.__onRemove,
          },
        }),
      );

      return child;
    },
  },

  render(h) {
    if (this.value === false) { return; }

    const data = this.isClickable ? {
      attrs: { tabindex: this.computedTabindex },
      on: {
        click: this.__onClick,
        keyup: this.__onKeyup,
      },
      directives: [{ name: 'ripple', value: this.ripple }],
    } : {};

    data.staticClass = 'sn-chip sn--row sn--inline sn--no-wrap sn--items-center';
    data.class = this.classes;

    return h('div', data, this.__getContent(h));
  },
});
