import {
  QTable,
  QTh,
  QTr,
  QTd,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import VMonitoringTreeBody from '../Components/VMonitoringTreeBody';

export default {
  name: 'VLibraryDebitCreditMonitoring',
  components: {
    QTable,
    QTh,
    QTr,
    QTd,
    VMonitoringTreeBody,
  },
  props: {
    uriGetItems: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rows: [],
      pagination: {
        rowsPerPage: 0,
      },
    };
  },
  async mounted() {
    await this.$trans.add(['label']);

    this.$globalLoading.show();
    const res = await requestWrapper.call(this, { url: this.uriGetItems });

    if (!res.error) {
      this.rows = res.data ?? [];
    } else {
      Helper.handlerResponseErrorNew(this, res);
    }

    this.$globalLoading.hide();
  },

  computed: {
    cols() {
      const cols = [
        {
          label: this.trans('label.library_monitoring.name'),
          field: 'name',
          width: 650,
          type: 'label',
        },
        {
          label: this.trans('label.library_monitoring.contingent_count'),
          field: 'contingent',
        },
        {
          label: this.trans('label.library_monitoring.books_count'),
          field: 'bookCopies',
        },
        {
          label: this.trans('label.library_monitoring.surplus'),
          field: 'surplus',
        },
        {
          label: this.trans('label.library_monitoring.deficit'),
          field: 'deficit',
        },
        {
          label: this.trans('label.library_monitoring.service_level_percent'),
          field: 'service_level_percent',
          type: 'label',
        },
      ];

      return cols.map((col) => {
        const tmpCol = col;
        tmpCol.name = col.field;
        return tmpCol;
      });
    },
  },

  methods: {
  },
};
