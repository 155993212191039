import { QPopupEdit } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import VInfoCard from '@common/components/VInfoCard';
import { NumberFormatter } from '@common/mixins';

export default {
  name: 'VLibraryActTransferShow',
  mixins: [NumberFormatter],
  components: {
    QPopupEdit,
    VInfoCard,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getData: null,
        update: null,
        submit: null,
        export: null,
      }),
    },
  },
  data() {
    return {
      info: {
        infoSection: [],
        docsInfoSection: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add(['label', 'button', 'book', 'placeholder', 'notice']);
    await this.requestGetData(this.config.getData, 'info');
  },

  computed: {
    cols() {
      const cols = [
        {
          label: '№',
          field: 'index',
        },
        {
          label: this.trans('book.library_act.show_author'),
          field: 'author',
        },
        {
          label: this.trans('book.library_act.copies_count'),
          field: 'count',
        },
        {
          label: this.trans('book.library_act.show_price'),
          field: 'price',
        },
        {
          label: this.trans('book.library_act.residual_price'),
          field: 'residual_price',
        },
      ];
      return cols;
    },
  },


  methods: {
    async handleSubmit() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.config.submit,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
    async handleUpdate() {
      if (this.info.docsInfoSection.filter(itm => !itm.residual_price).length) {
        this.$notify({
          type: 'warn',
          text: this.trans('book.fill_books_price_first'),
        });
      } else {
        this.$globalLoading.show();
        const data = {
          prices: this.info.docsInfoSection.map(itm => ({ id: itm.id, residual_price: itm.residual_price })),
        };
        const res = await requestWrapper.call(this, {
          method: 'post',
          url: this.config.update,
          data,
        });

        if (res.error) {
          Helper.handlerResponseErrorNew(this, res);
          this.$globalLoading.hide();
        }
      }
    },
    async requestGetData(url, variableName) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, { url });

      if (!res.error) {
        this.$set(this, variableName, res.data ?? []);
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
  },
};
