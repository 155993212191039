import Vue from 'vue';

import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QList',

  props: {
    bordered: Boolean,
    dense: Boolean,
    separator: Boolean,
    dark: Boolean,
    padding: Boolean,
  },

  computed: {
    classes() {
      return `sn-list${
        this.bordered === true ? ' sn-list--bordered' : ''
      }${this.dense === true ? ' sn-list--dense' : ''
      }${this.separator === true ? ' sn-list--separator' : ''
      }${this.dark === true ? ' sn-list--dark' : ''
      }${this.padding === true ? ' sn-list--padding' : ''}`;
    },
  },

  render(h) {
    return h('div', {
      staticClass: 'sn-list',
      class: this.classes,
      on: this.$listeners,
    }, slot(this, 'default'));
  },
});
