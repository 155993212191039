import Vue from 'vue';
import store from '@vjs/store';
import NpaList from '@vjs/mektep/views/Npa/NpaList.vue';

if (document.getElementById('v-npa')) {
  window.vNpa = new Vue({
    el: '#v-npa',
    components: { NpaList },
    store,
  });
}
