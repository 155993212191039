import { Helper } from '@common/src/helpers';
import _ from 'lodash';

export default {
  props: {
    accessEdit: {
      type: Boolean,
      default: false,
    },
    defaultTrainingSchedule: {
      type: [Number],
      default: null,
    },
    lessonConfig: {
      type: Object,
      required: true,
      default() {
        return {
          actionEdit: '',
          actionStore: '',
          actionDestroy: '',
        };
      },
    },
    actionDuplicateNextWeek: {
      type: String,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
      default() {
        return {
          data: '',
          refs: '',
          schedules: '',
          week: '',
          subclass: '',
        };
      },
    },
    exportActive: {
      required: false,
      default: false,
    },
    exportUrl: {
      required: false,
    },
    currentWeek: {
      type: Number,
      required: true,
    },
  },
  data() {
    const vm = this;

    return {
      loadCount: 0,
      processSend: false,
      current_lesson: {},
      training_schedule: vm.defaultTrainingSchedule,
      training_schedules: [],
      week: this.currentWeek,
      classnumber: null,
      weeks: [],
      days: [],
      refs: {
        practices: [],
        disciplines: [],
        teachers: [],
        lessons: [],
        types: [],
      },
      forms: {
        lesson: {
          errors: {},
          action: '',
          actionEdit: vm.lessonConfig.actionEdit,
          actionStore: vm.lessonConfig.actionStore,
          actionDestroy: vm.lessonConfig.actionDestroy,
          method: '',
          fields: {
            day_id: null,
            lesson: null,
            discipline_id: null,
            teacher_id: null,
            teacher_ids: [],
            subgroup_ids: [],
            cabinet: null,
            is_template: true,
            start_time: null,
            end_time: null,
          },
          afterCallback() {
            vm.loadData();
            $('#modal-schedule-lesson').modal('hide');
            vm.clearForm(this);
          },
        },
      },
      subGroups: [],
    };
  },
  beforeCreate() {
    this.$trans.add(['main', 'training_schedule', 'notice', 'button', 'placeholder', 'label', 'journal']);
  },
  mounted() {
    const vm = this;
    vm.loadSchedules();
    vm.loadData();
    vm.loadRefs();
  },

  destroyed() {
  },
  methods: {
    selectTeacher(group_index, teacher_id) {
      if (!teacher_id) {
        return;
      }
      const vm = this;
      const teacher_index = _.findIndex(vm.refs.teachers, ['id', teacher_id]);
      if (teacher_index > -1) vm.$set(vm.refs.teachers[teacher_index], '$isDisabled', true);

      const previousSelectedTeacher = vm.refs.subgroup_ids[group_index].teacher_id;

      if (previousSelectedTeacher) {
        const previous_teacher_index = _.findIndex(vm.refs.teachers, ['id', previousSelectedTeacher]);
        if (previous_teacher_index > -1) vm.$set(vm.refs.teachers[previous_teacher_index], '$isDisabled', false);
      }
      vm.refs.subgroup_ids[group_index].teacher_id = teacher_id;
    },
    clearForm(form) {
      const vm = this;
      const { fields } = form;
      _.forEach(fields, (value, key) => {
        fields[key] = null;
      });
      vm.$set(form, 'fields', fields);
      vm.$set(form, 'action', '');
      vm.$set(vm, 'subGroups', []);
      vm.$set(vm.refs, 'subgroup_ids', []);
      _.forEach(vm.refs.teachers, ((teacher) => {
        teacher.$isDisabled = false;
      }));
    },
    permissionCreate(lesson) {
      if (this.accessEdit) {
        return true;
      }

      let show = true;

      if (typeof lesson !== 'undefined' && lesson.length > 0) {
        lesson.forEach((item) => {
          if (item.is_template === 1) {
            show = false;
          }
        });
      }

      return show;
    },
    createLesson(dayId, lesson) {
      const vm = this;
      vm.clearForm(vm.forms.lesson);
      vm.$set(vm.forms.lesson, 'action', vm.forms.lesson.actionStore);
      vm.$set(vm.forms.lesson.fields, 'day_id', dayId);
      vm.$set(vm.forms.lesson.fields, 'lesson', lesson);
      vm.$set(vm.forms.lesson.fields, 'is_template', this.accessEdit);
      vm.$set(vm.forms.lesson, 'method', 'POST');
      $('#modal-schedule-lesson').modal('show');
    },
    editLesson(lesson) {
      const vm = this;

      vm.clearForm(vm.forms.lesson);
      vm.$set(vm.forms.lesson, 'method', 'PATCH');
      vm.$set(vm.forms.lesson, 'action', Helper.urlGenerate(
        vm.forms.lesson.actionEdit,
        { lesson: lesson.id },
      ));

      if (lesson.subgroup_teachers) {
        vm.$set(vm, 'subGroups', lesson.subgroup_teachers.map(subGroup => ({
          id: subGroup.subgroup.id,
          name: subGroup.subgroup.name,
        })));

        vm.$set(vm.refs, 'subgroup_ids', lesson.subgroup_teachers.map((subGroup) => {
          const teacher_index = _.findIndex(vm.refs.teachers, ['id', subGroup.teacher.id]);
          if (teacher_index > -1) vm.$set(vm.refs.teachers[teacher_index], '$isDisabled', true);

          return {
            subGroupId: subGroup.subgroup.id,
            teacher_id: subGroup.teacher.id,
          };
        }));
      }

      vm.$set(vm.forms.lesson.fields, 'discipline_id', lesson.discipline.id);
      const teachers = [];

      if (lesson.teacher) {
        vm.$set(vm.forms.lesson.fields, 'teacher_id', lesson.teacher.id);
      }

      if (lesson.other_teachers) {
        if (_.isArray(lesson.other_teachers)) {
          _.forEach(lesson.other_teachers, (other_teacher) => {
            teachers.push(other_teacher.teacher);
          });
        }
      }

      vm.$set(vm.forms.lesson.fields, 'is_template', lesson.is_template);
      vm.$set(vm.forms.lesson.fields, 'teacher_ids', teachers);
      vm.$set(vm.forms.lesson.fields, 'lesson', lesson.lesson_num);
      vm.$set(vm.forms.lesson.fields, 'day_id', lesson.training_schedule_day_id);
      vm.$set(vm.forms.lesson.fields, 'start_time', lesson.start_time);
      vm.$set(vm.forms.lesson.fields, 'end_time', lesson.end_time);

      if (lesson.cabinet !== undefined) {
        vm.$set(vm.forms.lesson.fields, 'cabinet', lesson.cabinet.name);
      }

      $('#modal-schedule-lesson').modal('show');

      this.checkDiscipline(this.forms.lesson.fields.discipline_id);
    },
    showDestroyLesson(lesson) {
      const vm = this;
      vm.$set(vm, 'current_lesson', lesson);
      $('#modal-schedule-lesson-confirm-destroy').modal('show');
    },
    duplicateNextWeek() {
      const vm = this;

      const config = {
        responseType: 'json',
        method: 'POST',
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
      const action = Helper.urlGenerate(vm.actionDuplicateNextWeek, {
        training_schedule: vm.training_schedule,
        week: vm.week,
      });
      vm.$http.post(action, [], config).then(
        (response) => {
          const { data } = response;
          if (data.status === 'success') {
            show_notice(data.message, 'notice');
            vm.week++;
            vm.loadData();
          }
        },
        (response) => {
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            $.each(response.data.errors, (i, val) => {
              errors = errors.concat(val);
            });
            show_notice(errors, 'error');
          } else {
            console.warn(response);
          }
        },
      );
    },
    destroyLesson() {
      const vm = this;

      const config = {
        responseType: 'json',
        method: 'DELETE',
        headers: {},
      };
      config.headers['X-CSRF-Token'] = window.core_project.csrfToken;

      vm.$http.delete(Helper.urlGenerate(vm.forms.lesson.actionDestroy, { lesson: vm.current_lesson.id }), config).then(
        (response) => {
          vm.loadData();
          $('#modal-schedule-lesson-confirm-destroy').modal('hide');
        },
        (response) => {
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            $.each(response.data.errors, (i, val) => {
              errors = errors.concat(val);
            });
            show_notice(errors, 'error');
          } else {
            console.warn(response);
          }
        },
      );
    },
    loadSchedules() {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urls.schedules, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm, 'training_schedules', data.data);
        },
        (response) => {
          console.warn(response);
        },
      );
    },
    loadData(week) {
      const vm = this;
      if (week) {
        vm.$set(vm, 'week', week);
      }
      const config = {
        responseType: 'json',
        params: {
          week: vm.week,
        },
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(Helper.urlGenerate(vm.urls.data, { training_schedule: vm.training_schedule }), config)
        .then(
          (response) => {
            const { data } = response;
            vm.$set(vm, 'days', data.days);
            vm.$set(vm, 'weeks', data.weeks);
            vm.$set(vm, 'accessEdit', data.accessEdit);
            vm.$set(vm, 'classnumber', data.class);
          },
          (response) => {
            console.warn(response);
          },
        );
    },
    selectSchedule() {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
      };
      vm.$http.get(Helper.urlGenerate(vm.urls.week, { training_schedule: vm.training_schedule }), config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm, 'week', data.currentWeek);
          vm.loadRefs();
          vm.loadData();
        },
        (response) => {
          console.warn(response);
        },
      );
    },
    loadRefs() {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(Helper.urlGenerate(vm.urls.refs, { training_schedule: vm.training_schedule }), config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm.refs, 'practices', data.practices);
          vm.$set(vm.refs, 'disciplines', data.disciplines);
          vm.$set(vm.refs, 'teachers', data.teachers);
          vm.$set(vm.refs, 'lessons', data.lessons);
          vm.$set(vm.refs, 'types', data.types);
        },
        (response) => {
          console.warn(response);
        },
      );
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};

      _.forEach(form.fields, (value, key) => {
        if (_.isArray(value)) {
          const values = [];
          _.forEach(value, (selectValue) => {
            values.push(selectValue.id);
          });
          _.set(data, key, values);
        } else {
          _.set(data, key, value);
        }
      });

      data.subgroup_ids = vm.refs.subgroup_ids;

      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return this.forms[form].errors[field] !== undefined;
    },
    getError(form, field) {
      return _.first(this.forms[form].errors[field]);
    },
    exportDownload() {
      const vm = this;

      const config = {
        responseType: 'blob',
        params: {
          week: vm.week,
        },
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      vm.$http.get(Helper.urlGenerate(vm.urls.export, { training_schedule: vm.training_schedule }), config)
        .then(
          (response) => {
            const filename = (response.headers.get('Content-Disposition') || '').split('filename=')[1];
            const result = document.createElement('a');
            result.href = window.URL.createObjectURL(response.body);
            result.download = filename;
            result.click();
          },
          (response) => {
            console.warn(response);
          },
        );
    },
    checkDiscipline(disciplineId) {
      const vm = this;
      const config = {
        responseType: 'json',
        params: {
          class: vm.classnumber,
        },
        method: 'GET',
      };
      // here
      vm.$http.get(Helper.urlGenerate(vm.urls.subclass, { discipline: disciplineId }), config).then(
        (response) => {
          const { data } = response;
          vm.subGroups = data;
          vm.$set(vm.refs, 'subgroup_ids', vm.subGroups.map(subGroup => ({
            subGroupId: subGroup.id,
            teacher_id: null,
          })));
        },
        (response) => {
          console.warn(response);
        },
      );
    },
  },
};
