import { QField, QOptionGroup } from '@quasar/components';
import _ from 'lodash';

export default {
  components: { QField, QOptionGroup },

  props: {
    value: {},
    field: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
    },
    errors: {
      type: [Object, Array],
      default() {
        return [];
      },
    },
  },
  data: () => ({
    localValue: [],
    lastSingleVal: null,
  }),
  mounted() {
    this.setInitialValue();
  },
  methods: {
    handleClick(singleVal) {
      this.lastSingleVal = singleVal;
      this.$emit('click', singleVal);
    },
    onChange() {
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
    setInitialValue() {
      this.localValue = _.isArray(this.field.value) ? this.field.value : [];
    },
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
  },
  computed: {
    fieldLabel() {
      if (this.field.label === '') {
        return '';
      }

      return this.field.label
        || (this.field.labelTransKey && this.trans(this.field.labelTransKey));
    },
    isDisabled() {
      return _.get(this.field, 'disabled', false)
      || _.get(this.field, 'disable', false)
      || _.get(this.field, 'extraAttributes.disable', false)
      || _.get(this.field, 'extraAttributes.disabled', false);
    },
  },
  watch: {
    localValue(newVal, oldVal) {
      this.$emit('change', newVal, oldVal, this.lastSingleVal);
      this.$emit('input', newVal, oldVal, this.lastSingleVal);
    },
    value() {
      this.setInitialValue();
    },
  },
};
