import CardMixin from '../CardMixin';
import CardTableMixin from '../CardTableMixin';

export default {
  mixins: [CardMixin, CardTableMixin],

  data() {
    return {
      newRowTemplate: {
        country: {},
        goal: {},
      },
    };
  },

  computed: {
    cols() {
      let result = [
        {
          label: this.trans('personnel.blocks.fields.trip_startDate'),
          name: 'startDate',
          field: 'startDate',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.trip_endDate'),
          name: 'endDate',
          field: 'endDate',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.country'),
          name: 'country',
          field: 'country',
          align: 'center',
        },
        {
          label: this.trans('personnel.blocks.fields.goal'),
          name: 'goal',
          field: 'goal',
          align: 'center',
        },
        {
          label: this.trans('label.operations'),
          name: 'actions',
          field: 'actions',
          align: 'center',
          width: 140,
        },
      ];
      if (this.disabled) {
        result = result.filter(itm => itm.field !== 'actions');
      }
      return result;
    },
  },
};
