import Vue from 'vue';

import mixin from './spinner-mixin';

export default Vue.extend({
  name: 'QSpinner',

  mixins: [mixin],

  props: {
    thickness: {
      type: Number,
      default: 5,
    },
  },

  render(h) {
    return h('svg', {
      staticClass: 'sn-spinner sn-spinner-mat',
      class: this.classes,
      on: this.$listeners,
      attrs: {
        width: this.size,
        height: this.size,
        viewBox: '25 25 50 50',
      },
    }, [
      h('circle', {
        staticClass: 'path',
        attrs: {
          cx: '50',
          cy: '50',
          r: '20',
          fill: 'none',
          stroke: 'currentColor',
          'stroke-width': this.thickness,
          'stroke-miterlimit': '10',
        },
      }),
    ]);
  },
});
