<template>
  <div>
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        v-s-modal="{
          id: 'v-modal-choose-language',
          component: 'v-modal-choose-language',
          size: 700,
          binds: {
            'redirect': redirect,
            'languages': languages,
          },
        }"
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    redirect: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
};
</script>
