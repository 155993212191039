import CreateBookCopiesModal from './CreateBookCopies';
import UserRejectStatement from './User/RejectStatement';
import DeleteContingentModal from './DeleteContingentModal';
import DeletePersonnelModal from './DeletePersonnelModal';
import ScheduleExaminationModal from './ScheduleExamination';
import Confirm from './Confirm';
import ConfirmWithMessage from './ConfirmWithMessage';
import ElectronicContractConfirm from './ElectronicContract/ElectronicContractConfirm';
import FindBook from './IssuanceOfBooksModals/FindBookModal';
import TableModal from './IssuanceOfBooksModals/TableModal';
import ConfirmGiveBook from './IssuanceOfBooksModals/ConfirmGiveBookModal';
import ChooseLanguage from './ElectronicContract/ChooseLanguage';
import CreateLibraryActWriteOff from './CreateLibraryActWriteOff';
import CreateLibraryActTransfer from './CreateLibraryActTransfer';
import StatementSubscribe from './StatementSubscribe';
import StatementReject from './StatementReject';
import SignNcalayer from './SignNcalayer';
import SelectUserFile from './SelectUserFiles';
import InfoTableWithActions from './InfoTableWithActions';
import PmpkConfirm from './PmpkConfirm';
import ConfirmWithFieldsModal from './ConfirmWithFields';

export default function (Vue) {
  Vue.component('v-model-user-reject-statement', UserRejectStatement);
  Vue.component('v-modal-delete-contingent', DeleteContingentModal);
  Vue.component('v-modal-delete-personnel', DeletePersonnelModal);
  Vue.component('v-modal-schedule-examination', ScheduleExaminationModal);
  Vue.component('v-modal-confirm', Confirm);
  Vue.component('v-modal-electronic-contract-confirm', ElectronicContractConfirm);
  Vue.component('v-modal-find-book', FindBook);
  Vue.component('v-modal-table', TableModal);
  Vue.component('v-modal-confirm-give-book', ConfirmGiveBook);
  Vue.component('v-modal-choose-language', ChooseLanguage);
  Vue.component('v-modal-create-library-act-write-off', CreateLibraryActWriteOff);
  Vue.component('v-modal-create-library-act-transfer', CreateLibraryActTransfer);
  Vue.component('v-modal-create-book-copies', CreateBookCopiesModal);
  Vue.component('v-modal-confirm-with-message', ConfirmWithMessage);
  Vue.component('v-modal-statement-subscribe', StatementSubscribe);
  Vue.component('v-modal-statement-reject', StatementReject);
  Vue.component('v-modal-sign-ncalayer', SignNcalayer);
  Vue.component('v-modal-select-user-files', SelectUserFile);
  Vue.component('v-modal-info-table-with-actions', InfoTableWithActions);
  Vue.component('v-modal-pmpk-confirm', PmpkConfirm);
  Vue.component('v-confirm-with-fields', ConfirmWithFieldsModal);
  Vue.component('v-modal-info-table-with-actions', InfoTableWithActions);
  Vue.component('v-modal-pmpk-confirm', PmpkConfirm);
}
