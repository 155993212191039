import _ from 'lodash';

export default {
  data() {
    return {
      validation: {},
    };
  },

  computed: {
    hasValidationFail() {
      const { validation } = this;
      return field => Array.isArray(_.get(validation, field)) && _.get(validation, field).length > 0;
    },
  },

  methods: {
    addValidationError(field, value) {
      if (!Array.isArray(this.validation[field])) {
        this.validation[field] = [];
      }

      this.validation = {
        ...this.validation,
        [field]: [...value],
      };
    },

    getValidationMessage(field) {
      const {
        validation,
        hasValidationFail,
      } = this;
      return (hasValidationFail(field) && _.head(_.get(validation, field))) || '';
    },

    flashValidationFail(field) {
      if (_.get(this.validation, field)) {
        _.unset(this.validation, field);
      }
    },

    flashValidationFailAll() {
      this.validation = {};
    },

    isDoubleSectionHasError([field1, field2]) {
      return this.hasValidationFail(field1) || this.hasValidationFail(field2);
    },
    getDoubleSectionErrorMessage([field1, field2]) {
      return `${this.getValidationMessage(field1)} ${this.getValidationMessage(field2)}`;
    },

    validateEmail(email) {
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
      return (typeof email === 'string' && email === '') || regex.test(email);
    },

    validateIin(iin) {
      const regex = /[0-9]{12}/;
      const b1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      const b2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
      const arr = [];
      let idx;
      let control = 0;

      if (!iin
        || (typeof iin === 'string' && iin.length !== 12)
        || !regex.test(iin)
      ) {
        return false;
      }

      for (idx = 0; idx < 12; idx += 1) {
        arr[idx] = parseInt(iin.substring(idx, idx + 1), 10);

        if (idx < 11) {
          control += arr[idx] * b1[idx];
        }
      }
      control %= 11;

      if (control === 10) {
        control = 0;
        for (idx = 0; idx < 11; idx += 1) {
          control += arr[idx] * b2[idx];
        }
        control %= 11;
      }

      return control === arr[11];
    },
  },
};
