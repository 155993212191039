import {
  QItem, QItemSection, QList, QBtnDropdown,
} from '@quasar/components';

export default {
  components: {
    QBtnDropdown, QList, QItem, QItemSection,
  },
  props: {
    message: { type: String, default: '' },
    white: { type: Boolean, default: false },
    qujat: { type: Boolean, default: false },
  },
  data() {
    const { white, qujat } = this;
    let color = 'bb-yellow';
    if (white) { color = 'white'; }
    if (qujat) { color = 'qujat-aqua'; }
    return {
      isActive: false,
      color,
    };
  },
  beforeCreate() { this.$trans.add('feedback'); },
};
