<template>
  <div>
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="blue"
        :label="trans('button.approve')"
        @click="request"
      />
    </div>
  </div>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: 'post',
    },
    userId: {
      type: Number,
      required: true,
    },
    bookCopyId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async request() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        data: { user_id: this.userId, bookCopyId: [this.bookCopyId], isIndex: true },
      });

      if (!res.error) {
        this.$sModal.closeAll();
        this.$nextTick(() => { eventBus.$emit('getItems'); });
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
