export default {
  name: 'VFormInform',

  props: {
    message: { type: String, default: '' },
    useFaIcons: { type: Boolean, default: false },
    icon: { type: String, default: 'info-alt' },
    color: { type: String, default: '' },
  },

  render(h) {
    let staticClass = 's-form__inform';
    if (this.color) {
      staticClass += ` s-form__inform--${this.color}`;
    }
    return h('div', {
      staticClass,
    }, [
      h('div', {
        staticClass: `s-str-ico${this.useFaIcons ? '-fa' : ''} s-str-ico${this.useFaIcons ? '-fa' : ''}--thm-${this.icon}`,
      }, [
        h('div', {
          domProps: {
            innerHTML: this.message,
          },
        }),
      ]),
      this.$slots.default,
    ]);
  },
};
