import { create, percent } from '@amcharts/amcharts4/core';
import { PieChart, PieSeries } from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';


export default {
  name: 'VPieChart',

  props: {
    innerRadius: Number,
    size: {
      type: Number,
      default: 250,
    },
    colors: {
      type: Array,
      required: false,
    },
    data: {
      type: Array,
      default: () => ([]),
    },
    strokeConfig: {
      type: Object,
      default: () => ({
        stroke: null,
        strokeWidth: null,
        strokeOpacity: null,
      }),
    },
  },

  data() {
    return {
      pieChart: null,
    };
  },

  mounted() {
    this.draw();
  },

  watch: {
    data() {
      this.draw();
    },
  },

  methods: {
    draw() {
      const { stroke, strokeWidth, strokeOpacity } = this.strokeConfig;

      this.pieChart = create(this.$refs.pieChart, PieChart);
      this.pieChart.data = this.data;
      this.pieChart.contentWidth = this.size;
      this.pieChart.contentHeight = this.size;

      if (this.pieChart.logo) {
        this.pieChart.logo.disabled = true;
      }

      if (this.innerRadius !== undefined) {
        this.pieChart.innerRadius = percent(35);
      }

      const pieSeries = this.pieChart.series.push(new PieSeries());
      pieSeries.dataFields.value = 'count';
      pieSeries.dataFields.category = 'name';
      pieSeries.labels.template.disabled = true;
      pieSeries.tooltip.label.wrap = true;
      pieSeries.tooltip.label.fontSize = 12;
      if (this.colors && this.colors?.length) {
        pieSeries.colors.list = [];
        _.forEach(this.colors, (color) => {
          pieSeries.colors.list.push(am4core.color(color));
        });
      }

      const nullValues = [null, undefined, ''];
      if (!nullValues.includes(stroke)) {
        pieSeries.slices.template.stroke = am4core.color(stroke);
      }
      if (!nullValues.includes(strokeWidth)) {
        pieSeries.slices.template.strokeWidth = strokeWidth;
      }
      if (!nullValues.includes(strokeOpacity)) {
        pieSeries.slices.template.strokeOpacity = strokeOpacity;
      }

      const hoverState = pieSeries.slices.template.states.getKey('hover');
      hoverState.properties.scale = 1;
    },
  },

  render(v) {
    return v('div', {
      staticClass: 's-diagram__pie',
      ref: 'pieChart',
    });
  },
};
