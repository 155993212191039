import {
  QForm,
  QSelect,
  QInput,
  QDialog,
  QBtn,
} from '@quasar/components';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';
import eventHub from '@vjs/config/eventHub';
import SUploader from '@vjs/components/SUploader/SUploader.vue';
import {
  api,
  url,
  genFormData,
} from '@vjs/helpers';

export default {
  name: 'Npa',
  components: {
    QForm,
    QSelect,
    QInput,
    QDialog,
    QBtn,
    VModal,
    SUploader,
  },
  directives: { ClosePopup },
  props: {
    addFileUrl: String,
    removeLink: String,
    identifier: String,
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    docsList: {
      type: Array,
      default: () => ([]),
    },
    yearsList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      /*
      * Status
      *   - read
      *   - edit
      * */
      status: 'read',
      modalUpload: false,
      selectedYear: '',
    };
  },
  methods: {
    switchStatus(status = 'read') {
      if (status === 'read') {
        this.status = status;
      } else if (status === 'edit') {
        this.status = status;
      }
    },
    handleRemoveDoc(docID) {
      eventHub.$emit('file-remove-success', {
        kind: this.identifier,
        docID,
      });
    },
    async sendDoc() {
      const formData = {
        kind: this.identifier,
        name: this.selectedYear,
        file: this.$refs.inputFile.$refs.input.files[0],
      };

      await api(
        this.addFileUrl,
        genFormData(formData),
        ({ data }) => {
          this.$notify({
            type: 'success',
            title: window.L.message,
            text: data.message,
            duration: 2000,
          });
          eventHub.$emit('file-add-success', {
            kind: this.identifier,
            payload: data.payload,
          });
          this.modalUpload = false;
          this.selectedYear = '';
        },
        {},
        (err) => {
          const { errors } = err.response.data;
          const validationFields = [];
          this.$refs.sendForm.$children.forEach((item) => {
            if (item.name !== undefined) {
              validationFields.push(item);
            }
          });
          validationFields.some((item) => {
            if (errors[item.name]) {
              item.innerError = true;
              item.innerErrorMessage = errors[item.name][0];
              return true;
            }
            return false;
          });
        },
        'post',
      );
    },
  },
};
