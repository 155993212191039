<template>
  <div class="s-egov-sign__item">
    <div class="s-egov-sign__head">
      <img
        class="s-egov-sign__img--egov"
        :src="`${staticUrl}/base/images/egov/egov-mobile.webp`"
      >
      <span class="s-egov-sign__title">{{ trans('egov_sign.qr.title') }}</span>
      <span class="s-egov-sign__subtitle">{{ trans('egov_sign.qr.subtitle') }}</span>
    </div>
    <div class="s-egov-sign__body s-egov-sign__body-qr">
      <div
        class="s-egov-sign__body--text"
        v-html="trans('egov_sign.qr.text')"
      />
      <div
        v-if="qr"
        class="s-egov-sign__qr--container"
      >
        <div
          v-if="qrExpired"
          class="s-egov-sign__qr--blur"
        >
          <q-btn
            class="s-egov-sign__qr--button"
            color="blue"
            no-caps
            :label="trans('egov_sign.qr.update-btn')"
            @click="$emit('update-qr')"
          />
        </div>
        <div v-html="qr" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    qr: {
      type: String,
      required: false,
    },
    qrExpired: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      staticUrl: window.core_project.cdnStatic,
    };
  },
};
</script>
