export default {
  props: {
    visibleColumns: Array,
  },

  computed: {
    computedCols() {
      const { sortBy, descending } = this.computedPagination;

      const cols = this.visibleColumns !== void 0
        ? this.columns.filter(col => col.required === true || this.visibleColumns.includes(col.name) === true)
        : this.columns;

      return cols.map((col) => {
        col.align = col.align || 'right';
        col.__iconClass = `sn-table__sort-icon sn-table__sort-icon--${col.align}`;
        col.__thClass = `sn--text-${col.align}${col.sortable ? ' sn--sortable' : ''}${col.name === sortBy ? ` sn--sorted ${descending ? 'sn--sort-desc' : ''}` : ''}`;
        col.__tdClass = `sn--text-${col.align}`;
        return col;
      });
    },

    computedColsMap() {
      const names = {};
      this.computedCols.forEach((col) => {
        names[col.name] = col;
      });
      return names;
    },
  },
};
