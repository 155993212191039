import {
  QSpinner,
  QBtn,
  QTooltip,
} from '@quasar/components';
import {
  convertToRem,
} from '@vjs/helpers/text';
import _ from 'lodash';
import VPhotoAlbumFullscreenModal from './VPhotoAlbumFullscreenModal';
import VPhotoAlbumEditModal from './VPhotoAlbumEditModal';
import VPhotoAlbumAddModal from './VPhotoAlbumAddModal';

const DEF_ARR = {
  imgLoading: true,
  imgLoaded: false,
  thumbLoading: true,
  thumbLoaded: false,
  width: 0,
  height: 0,
  vWidth: 270,
  vHeight: 270,
};

export default {
  name: 'VPhotoAlbum',

  components: {
    QSpinner,
    QBtn,
    QTooltip,
    VPhotoAlbumFullscreenModal,
    VPhotoAlbumEditModal,
    VPhotoAlbumAddModal,
  },

  props: {
    picList: {
      type: Array,
      required: true,
    },
    autoplay: Boolean,
    isEdit: Boolean,
    isCustom: Boolean,
    countOfVisibleThumbs: {
      type: Number,
      default: 3,
    },
    orgType: String,
  },

  data() {
    return {
      picItems: [],
      curPicIdx: 0,
      marginRight: 10,
      thumbWidth: 0,
      changingPic: false,
      animationStatus: false,
      modalFullPreview: false,
      scrollRowPosition: 0,
      oldPic: {
        img: '',
        thumb: '',
        ...DEF_ARR,
      },
      modalAdd: false,
      modalEdit: false,
      currentPic: null,
      disableEdit: false,
    };
  },

  async mounted() {
    await this.building();

    if (this.autoplay) {
      this.autoplayPic(true);
    }
    if (this.isEdit) {
      this.currentPic = this.picList[0];
    }
    this.$eventHub.$on('photo-deleted', this.changeCurrentPic);
  },

  watch: {
    screenWidth() {
      this.calculateThumbsWidth();
    },
    picList(val, oldVal) {
      this.building();
      if (this.isEdit && val.length > oldVal.length) {
        this.selectPic(val.length - 1);

        const handleAddNewPhoto = () => {
          let remains = 0;
          remains = this.picItems.length + (this.scrollRowPositionIndex - this.countOfVisibleThumbs);
          this.thumbSlide(true);
          if (remains > 0) {
            handleAddNewPhoto();
          }
        };
        handleAddNewPhoto();
      }
    },
  },

  computed: {
    screenWidth() {
      return this.$q.screen.width;
    },
    isSlideForwardDisabled() {
      const remains = this.picItems.length + (this.scrollRowPositionIndex - this.countOfVisibleThumbs);
      return remains && this.picItems.length > this.countOfVisibleThumbs;
    },
    isSlideBackDisabled() {
      const remains = Math.abs(this.scrollRowPositionIndex);
      return remains && this.picItems.length > this.countOfVisibleThumbs;
    },
    scrollRowPositionIndex() {
      return this.scrollRowPosition / this.picThumbFullWidth;
    },

    curPic() {
      if (this.picItems.length > 0 && this.picItems[this.curPicIdx]) {
        return this.picItems[this.curPicIdx];
      }

      return {
        img: '',
        thumb: '',
        ...DEF_ARR,
      };
    },

    background() {
      const { curPic, orgType } = this;
      return curPic.thumbLoaded
        ? `${curPic.thumb}`
        : `${window.core_project.staticUrl}/base/images/org_placeholders/${orgType}.svg`;
    },

    // Считает ширину и отступ миниатюры
    picThumbFullWidth() {
      return this.thumbWidth + this.marginRight;
    },
  },

  methods: {
    changeCurrentPic() {
      this.disableEdit = true;
      if (this.currentPic.id === this.picList[0].id) {
        this.currentPic = this.picList[1];
        this.$eventHub.$emit('renew-photos');
        return;
      }
      if (this.picList.length > 1 && this.currentPic.id === this.picList[this.picList.length - 1].id) {
        this.selectPic(this.curPicIdx - 1);
        this.currentPic = this.picList[this.curPicIdx];
        if (this.picList.length - 1 === this.countOfVisibleThumbs) {
          this.thumbSlide(false);
        }
        this.$eventHub.$emit('renew-photos');
        return;
      }
      this.currentPic = this.picList[this.curPicIdx];
      setTimeout(() => {
        this.disableEdit = false;
      }, 1000);
    },
    convertToRem,
    async building() {
      return new Promise(async (res) => {
        // Модификация входящего массива с изображениями
        this.picItems = this.picList.map(item => ({
          ...DEF_ARR,
          ...item,
        }));

        this.calculateThumbsWidth();

        // Асинхронная загрузка миниатюр
        await this.loadThumbsList();
        res(true);
      });
    },
    calculateThumbsWidth() {
      // Динамический расчёт ширины миниатюр
      if (this.picItems.length > 1) {
        this.$nextTick(() => {
          const {
            rowScrollWrapper,
          } = this.$refs;
          const calcMarginRight = this.marginRight * (this.countOfVisibleThumbs - 1);
          const calcTotalWidth = rowScrollWrapper.offsetWidth - calcMarginRight;
          this.thumbWidth = calcTotalWidth / this.countOfVisibleThumbs;
        });
      }
    },
    // Асинхронная загрузка миниатюр
    async loadThumbsList() {
      return new Promise(async (res) => {
        const arr = [];
        for (let idx = 0; idx < this.picItems.length; idx += 1) {
          arr.push(this.loadPic(this.picItems[idx]));
        }
        await Promise.all(arr);
        res(true);
      });
    },
    // Асинхронная загрузка изображения
    async loadPic(item, type = 'thumb') {
      const locItem = item;
      const pic = new Image();

      function locHandle(res) {
        return (event) => {
          let width = 0;
          let height = 0;
          let src = '';

          if (event.path && Array.isArray(event.path)) {
            width = event.path[0].width;
            height = event.path[0].height;
            src = event.path[0].src;
          } else if (event.target) {
            width = event.target.width;
            height = event.target.height;
            src = event.target.src;
          }

          locItem.width = width;
          locItem.height = height;
          locItem[`${type}Loading`] = false;
          locItem[`${type}Loaded`] = true;
          pic.removeEventListener('load', locHandle(res));
          res(src);
        };
      }

      function locCatch(res) {
        locItem[`${type}Loading`] = false;
        pic.removeEventListener('error', locCatch);
        res(true);
      }

      return new Promise((res) => {
        if (locItem[`${type}Loaded`]) {
          res(locItem[`${type}`]);
        }

        locItem[`${type}Loading`] = true;
        pic.addEventListener('load', locHandle(res));
        pic.addEventListener('error', () => locCatch(res));
        pic.src = locItem[type];
      });
    },
    async selectPic(idx) {
      if (this.isEdit) {
        this.currentPic = this.picList[idx];
      }
      if (
        (this.modalFullPreview
          || (this.picItems[idx] && this.picItems[idx].thumb !== this.curPic.thumb)
        )
        && !this.animationStatus
      ) {
        this.oldPic = this.curPic;
        this.changingPic = true;
        this.animationStatus = true;
        setTimeout(() => {
          this.animationStatus = false;
        }, 500);
        this.curPicIdx = idx;

        if (this.modalFullPreview) {
          await this.loadPic(this.picItems[idx], 'img');
          const calcViewportSize = this.calcPicProp(this.picItems[idx]);
          this.picItems[idx].vWidth = calcViewportSize.width;
          this.picItems[idx].vHeight = calcViewportSize.height;
        }

        this.$nextTick(() => {
          this.changingPic = false;
        });
      }
    },
    prevPic() {
      const newIdx = this.curPicIdx - 1;

      if (newIdx >= 0) {
        this.selectPic(newIdx);
      } else {
        this.selectPic(this.picItems.length - 1);
      }
    },
    nextPic() {
      const newIdx = this.curPicIdx + 1;

      if (newIdx < this.picItems.length) {
        this.selectPic(newIdx);
      } else {
        this.selectPic(0);
      }
    },
    openFullPreview() {
      this.modalFullPreview = true;
      this.selectPic(this.curPicIdx);
      setTimeout(() => {
        this.autoplayPic(false);
      }, 0);
      return false;
    },
    // Движение ленты с миниатюрами
    thumbSlide(direction) {
      let remains = 0;
      // absIndex определяет в какую сторону будет прокрутка миниатюр
      let absIndex = 1;

      if (direction) {
        remains = this.picItems.length + (this.scrollRowPositionIndex - this.countOfVisibleThumbs);
        absIndex = -1;
      } else {
        remains = Math.abs(this.scrollRowPositionIndex);
        absIndex = 1;
      }

      const calcPosition = count => this.scrollRowPosition + this.picThumbFullWidth * count * absIndex;

      if (remains >= this.countOfVisibleThumbs) {
        this.scrollRowPosition = calcPosition(this.countOfVisibleThumbs);
      } else if (remains < this.countOfVisibleThumbs && remains > 0) {
        this.scrollRowPosition = calcPosition(remains);
      }
    },
    autoplayPic(status) {
      if (status) {
        this.autoplayInterval = setInterval(() => {
          if (this.curPicIdx < this.picItems.length - 1) {
            this.selectPic(this.curPicIdx + 1);
            if (this.curPicIdx % this.countOfVisibleThumbs === 0) {
              this.thumbSlide(true);
            }
          } else {
            this.selectPic(0);
            this.scrollRowPosition = this.moveByIndex(0);
          }
        }, 7000);
      } else {
        clearInterval(this.autoplayInterval);
      }
    },
    calcPicProp(pic) {
      const viewport = {
        width: window.innerWidth - 24 * 2,
        height: window.innerHeight - 24 * 2,
      };
      const size = {
        width: 0,
        height: 0,
      };
      const { width, height } = pic;
      size.width = width;
      size.height = height;

      if (size.width > viewport.width) {
        size.width = viewport.width;
        size.height = Math.round(height / (width / size.width));
      }

      if (size.height > viewport.height) {
        size.height = viewport.height;
        size.width = Math.round(width / (height / size.height));
      }

      return size;
    },
    // Перемотка ленты на указанный item
    moveByIndex(itemIdx) {
      return -(this.picThumbFullWidth * itemIdx);
    },
  },
};
