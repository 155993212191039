import axios from 'axios';
import _ from 'lodash';

export const ERR_302 = 302;
export const ERR_401 = 401;
export const ERR_403 = 403;
export const ERR_404 = 404;
export const ERR_405 = 405;
export const ERR_418 = 418;
export const ERR_419 = 419;
export const ERR_422 = 422;
export const ERR_500 = 500;
export const ERR_MESSAGE = 666;
export const ERR_NETWORK = 'ERR_NETWORK';

export const errorCodes = {
  ERR_302,
  ERR_401,
  ERR_403,
  ERR_404,
  ERR_405,
  ERR_418,
  ERR_422,
  ERR_500,
  ERR_MESSAGE,
  ERR_NETWORK,
};

function catchNetworkError() {
  this.$notify({
    type: 'error',
    text: this.trans('notice.internet_connection_error_try_again'),
  });

  return {
    error: true,
    errorType: ERR_NETWORK,
  };
}

function catch302(data, external) {
  const response = {
    error: true,
    errorType: ERR_302,
    data,
  };

  if (response.data && response.data.redirect && !external) {
    response.error = false;
    window.location.href = response.data.redirect;
    return response;
  }

  return response;
}

function catch419(data, external) {
  const response = {
    error: true,
    errorType: ERR_419,
    data,
  };

  if (!external) {
    this.$notify({
      type: 'warn',
      text: this.trans('notice.form_has_expired'),
    });
  }

  return response;
}

function catch401(data) {
  return {
    error: true,
    errorType: ERR_401,
    data,
  };
}

function catch422(data, external) {
  const response = {
    error: true,
    errorType: ERR_422,
    data,
  };

  // Action для дополнительной обработки
  if (!data.errors && !data.action && this && this.$notify && !external) {
    this.$notify({
      type: 'error',
      title: this.trans('notice.message'),
      text: data.message,
    });
  } else if (this && this.addValidationError && !data.action && !external) {
    _.forEach(data.errors, (val, key) => {
      this.addValidationError(key, val);
    });
  }

  return response;
}

function catchDefault(code, data, external) {
  const response = {
    error: true,
    errorType: code,
    message: data.message,
    data,
  };

  if (!data.action && this && this.$notify && !external) {
    this.$notify({
      type: 'error',
      title: this.trans('notice.message'),
      text: response.message,
    });
  }

  console.error((data && data.response && data.response.data) || data);
  return response;
}

export default async function requestWrapper(options = {}, external = false) {
  try {
    const { formData, data, fullRes } = options;

    if (formData && data) {
      options.data = axios.toFormData(data);
    }

    const res = await axios(options);

    if (res.data.alert && res.data.alert.message) {
      this.$notify({
        type: 'success',
        title: this.trans('notice.message'),
        text: res.data.alert.message,
      });
    }

    if (fullRes) {
      return res;
    }

    return (res && res.data) || res;
  } catch (err) {
    if (err?.code === 'ERR_NETWORK') {
      return catchNetworkError.call(this);
    }

    if (err && err.response) {
      const {
        status,
        data,
      } = err.response;

      switch (status) {
        case ERR_302: return catch302.call(this, data, external);
        case ERR_419: return catch419.call(this, data, external);
        case ERR_401: return catch401.call(this, data, external);
        case ERR_422: return catch422.call(this, data, external);
        default: return catchDefault.call(this, status, data, external);
      }
    }

    return err;
  }
}
