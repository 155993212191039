import { Upload as TusUpload } from 'tus-js-client';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { Helper } from '@common/src/helpers';
import * as Sentry from '@sentry/browser';

export default {
  props: {
    value: [],
    disable: Boolean,
    endpoint: {
      type: String,
      default: '/tus/',
    },
    config: {
      type: Object,
      default: () => ({
        max: 3,
        size: undefined,
        accepts: [],
      }),
    },
  },
  data() {
    return {
      files: [],
      activeIndex: null,
      max: this.config.max,
    };
  },
  mounted() {
    this.setInitialValue();
    this.$trans.add('button', 'validation');
  },
  methods: {
    setInitialValue() {
      if (this.value) {
        this.files = this.value;
      }
    },
    onClickInputFile(key) {
      if (this.disable) return;
      if (![null, undefined].includes(key)) this.activeIndex = key;
      const el = this.$refs.input_file;
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    async onChangeInputFile(e) {
      if (this.disable) return;
      const vm = this;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      e.target.value = null;
      vm.$globalLoading.show();
      const { accepts } = this.config;
      const { size } = this.config;
      const extension = Helper.getFileExtension(file.name);
      if (_.isArray(accepts) && accepts.length) {
        const index = accepts.findIndex(accept => accept.toLowerCase() === extension.toLowerCase());
        if (index === -1) {
          vm.$globalLoading.hide();
          Helper.showNotice(
            this.trans('validation.mimes',
              {
                attribute: this.trans(this.label),
                values: accepts.join(', '),
              }),
          );

          return;
        }
      }
      if (typeof size !== 'undefined' && file.size > size) {
        vm.$globalLoading.hide();
        Helper.showNotice(this.trans('validation.max.file_mb', { max: size / 1024 / 1024 }));
        return;
      }
      const metaData = {
        origin_name: file.name,
        full_name: Helper.base64Encode(file.name),
        filename: `${uuidv4()}.${extension}`,
      };
      if (file.type) {
        metaData.filetype = file.type;
      }

      const upload = new TusUpload(file, {
        endpoint: vm.endpoint,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: metaData,
        chunkSize: 1024 * 1000 * 2,
        onError(error) {
          console.error(error);
          Sentry.captureException(error);

          const stringErr = String(error);
          const isCodeNA = stringErr.includes('response code: n/a');
          const isTextNA = stringErr.includes('response text: n/a');
          const isIdNA = stringErr.includes('request id: n/a');
          if (isCodeNA && isTextNA && isIdNA) {
            vm.showNetworkErrorNotice();
          } else {
            vm.showFileLoadErrorNotice();
          }
          vm.$globalLoading.hide();
        },
        onProgress(bytesUploaded, bytesTotal) {
          // let percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
        },
        onSuccess(xhr) {
          vm.$globalLoading.hide();

          const uuid = xhr.getHeader('X-File-UUID');
          if (uuid) {
            if (!_.find(vm.files, { uuid })) {
              if (![null, undefined].includes(vm.activeIndex)) {
                vm.$set(vm.files, vm.activeIndex, { uuid, name: upload.file.name });
                vm.activeIndex = null;
              } else {
                vm.files.push({
                  uuid,
                  name: upload.file.name,
                });
              }
              // Уведомление о том что выбранный файл уже был загружен
              // не знаю почему, но по другому уведомление не вызывается, поэтому сделал отдельной функцией
            } else {
              vm.showFileExistedNotice();
            }
          }
        },
      });

      upload.findPreviousUploads().then((previousUploads) => {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload
        upload.start();
      });
    },
    showFileExistedNotice() {
      Helper.showNotice(this.trans('notice.file_already_selected'));
    },
    showFileLoadErrorNotice() {
      Helper.showNotice(this.trans('notice.error_on_server'), 'error');
    },
    showNetworkErrorNotice() {
      Helper.showNotice(this.trans('notice.internet_connection_error_try_again'), 'error');
    },
  },
  watch: {
    value: {
      handler() {
        this.setInitialValue();
      },
      deep: true,
    },
    files: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
  },
};
