import Vue from 'vue';
import store from '@vjs/store';
import VTable from '@vjs/components/VTable/VTable.vue';

if (document.getElementById('v-table-app')) {
  window.vTable = new Vue({
    el: '#v-table-app',
    components: { VTable },
    store,
  });
}
