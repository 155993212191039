export function addZeroBefore(num = 0) {
  return num < 10 ? `0${num}` : num;
}

export function capitalizeByWords(str = '') {
  try {
    if (typeof str === 'string') {
      const locStr = str.trim();

      if (locStr.length > 0) {
        return locStr
          .toLowerCase()
          .split(' ')
          .map((w) => {
            if (w.length !== 0) {
              return `${w[0].toUpperCase()}${w.substr(1)}`;
            }

            return w;
          })
          .join(' ');
      }
    }

    return str;
  } catch (err) {
    console.error(err);
    return str;
  }
}

export function convertToRem(num) {
  return `${num / 10}rem`;
}
