import { FormField, StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator, FormField],
  methods: {
    getError() {
      const vm = this;
      return _.first(vm.errors);
    },
  },
  data() {
    return {};
  },
};
