import Notify from '@vjs/components/Notify/Notify.vue';
import {
  QSelect,
  QInput,
  QTable,
  QTr,
  QTd,
  QBtn,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';

export default {
  name: 'VApplicationTableEgov',

  components: {
    QSelect,
    QInput,
    QTable,
    QTr,
    QTd,
    QBtn,
    Notify,
  },

  props: {
    uriRequest: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      searchFio: '',
      searchIin: '',
      columns: [],
      rows: [],
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'table',
      'main',
      'statement',
    ]);
    await this.request();
  },

  computed: {
    filteredRows() {
      let { rows } = this;

      if (this.searchFio !== '') {
        rows = rows.filter((item) => {
          if (typeof item.fioStudent === 'string') {
            return item.fioStudent.toLowerCase().indexOf(this.searchFio.toLowerCase()) >= 0;
          }
          return false;
        });
      }

      if (this.searchIin !== '') {
        rows = rows.filter((item) => {
          if (typeof item.iinStudent === 'string') {
            return item.iinStudent.toLowerCase().indexOf(this.searchIin.toLowerCase()) >= 0;
          }
          return false;
        });
      }

      return rows;
    },
  },

  methods: {
    async request() {
      const res = await requestWrapper.call(this, {
        url: this.uriRequest,
      });

      if (!res.errors) {
        this.columns = res.data.columns;
        this.rows = res.data.rows;
      }
    },

    async recreateStatement(url, row) {
      const res = await requestWrapper.call(this, {
        url,
      });
      if (res && res.status) {
        this.$notify({
          type: res.status,
          text: res.message,
          duration: 1500,
        });
        if (res.status && res.status === 'success') {
          this.$set(row.reCreateStatement, 'allow_recreate', false);
        }
      }
    },
  },
};
