import Vue from 'vue';
import store from '@vjs/store';
import Notify from '@vjs/components/Notify/Notify.vue';
import VContingentCreate from '@app_mektep/views/VContingentApp/VContingentCreate/VContingentCreate.vue';

if (document.getElementById('v-contingent-app')) {
  window.VContingentApp = new Vue({
    el: '#v-contingent-app',
    components: { VContingentCreate, Notify },
    store,
  });
}
