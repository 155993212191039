<template>
  <div
    class="s-info-card"
    :class="{
      's-info-card--with-table': withTable,
      's-info-card--with-buttons': withButtons,
      'row': withButtons,
    }"
  >
    <div
      class="s-w-100 s-info-card--card"
      :class="withButtons ? 'col-lg-9 col-md-9 col-sm-12' : ''"
    >
      <div
        class="s-info-card--wrapper s-w-100"
        :class="{
          's-info-card--wrapper__no-border': noBorder,
        }"
      >
        <div
          class="s-info-card--title"
          :class="{
            's-info-card--title-light-icon': iconLight,
            's-info-card--title-lighter-icon': iconLighter,
            's-info-card--title-bold-icon': iconBolder,
          }"
        >
          <slot
            v-if="$slots.title"
            name="title"
          />
          <span
            v-else
            class="s-str-ico-fa"
            :class="{
              [`s-str-ico-fa--thm-${icon}`]: true,
              'sn--cursor-pointer d-flex align-items-center' : withSpoiler,
            }"
            @click="() => { if(withSpoiler) spoiler = !spoiler }"
          >
            {{ title }}
            <q-icon
              v-if="withSpoiler"
              style="margin-left: 0.5rem;"
              icon-class="s-ico-fa"
              size="3rem"
              :name="spoiler ? 'chevron-down' : 'chevron-up'"
            />
          </span>
        </div>
        <q-slide-transition>
          <div
            v-if="!withSpoiler || (withSpoiler && spoiler)"
            class="s-application-block__body s-pos-relative-position"
            style="overflow:hidden;"
          >
            <slot />
          </div>
        </q-slide-transition>
      </div>
    </div>
    <div
      v-if="$slots.buttons"
      class="col-lg-3 col-md-3 col-sm-12"
    >
      <div class="s-group s-group--wider">
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<script>
import { QIcon } from '@quasar/components/icon';

export default {
  name: 'VInfoCard',
  components: { QIcon },
  props: {
    title: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconLight: Boolean,
    iconLighter: Boolean,
    iconBolder: Boolean,
    withButtons: Boolean,
    withTable: Boolean,
    noBorder: Boolean,
    withSpoiler: Boolean,
  },
  data() {
    return {
      spoiler: false,
    };
  },
};
</script>
