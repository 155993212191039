import {
  QBtn,
  QTable,
  QTr,
  QTd,
  QTooltip,
  QIcon,
} from '@quasar/components';
import StatementDisposeDialog from '@app_mektep/components/Views/VStatementServiceList/Dialog/StatementDisposeDialog.vue';
import StatementServiceFilter from '@app_mektep/components/Views/VStatementServiceList/Filter/VStatementServiceFilter.vue';


export default {
  name: 'VStatementServiceListView',
  components: {
    QBtn,
    QTable,
    QTr,
    QTd,
    QTooltip,
    QIcon,
    StatementDisposeDialog,
    StatementServiceFilter,
  },
  props: {
    NotProcessedStatementServicesList: {
      type: String,
      default: '',
    },
    ProcessedStatementServicesList: {
      type: String,
      default: '',
    },
    ArchiveStatementServicesList: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rows: [],
      columns: [],
      statuses: [],
      itemsFiltered: [],
      serviceTypes: [],
      pagination: {
        rowsPerPage: 10,
      },
      filter: '',
      loading: false,
      currentTab: '',
      states: {
        unprocessed: 'unprocessed',
        processed: 'processed',
        archive: 'archive',
      },
      locale: '',
      disposeModal: false,
      clearFilters: false,
      currentDisposeUrl: '',
      defaultLocale: 'kz',
    };
  },
  methods: {
    getDataList(state) {
      let route = '';
      switch (state) {
        case this.states.processed:
          route = this.ProcessedStatementServicesList;
          break;
        case this.states.archive:
          route = this.ArchiveStatementServicesList;
          break;
        default:
          route = this.NotProcessedStatementServicesList;
          break;
      }

      this.$axios.get(route)
        .then((response) => {
          this.statuses = response.data.statuses;

          this.rows = JSON.parse(response.data.rows);
          this.itemsFiltered = this.rows;
          this.columns = JSON.parse(response.data.columns);
          this.serviceTypes = response.data.serviceTypes;
        })
        .catch((error) => {
          this.$notify({
            text: this.trans('notice.error_on_server'),
            type: 'error',
          });
        });

      this.changeTabName(state);
    },
    getDataByState(state) {
      this.getDataList(state);
    },
    changeTabName(state) {
      this.clearFilters = true;
      this.currentDisposeUrl = '';
      if (state) {
        this.currentTab = state;
        return;
      }
      this.currentTab = this.states.unprocessed;
    },
    isProcessed() {
      return this.currentTab === this.states.processed;
    },
    isUnprocessed() {
      return this.currentTab === this.states.unprocessed;
    },
    isArchive() {
      return this.currentTab === this.states.archive;
    },
    handleFilteredList(filteredData) {
      this.itemsFiltered = filteredData.items;
    },
    appropriateField(field) {
      const unwantedFields = [
        'state',
        'type',
        'dispose_url',
        'show_url',
        'viewed',
      ];

      return !unwantedFields.includes(field);
    },
    openDisposeModal() {
      this.disposeModal = true;
    },
    setCurrentDisposeUrl(data) {
      this.currentDisposeUrl = data;
    },
    setViewed(item) {
      item.viewed = true;
    },
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'table',
      'search',
      'notice',
    ]);
    await this.getDataList(this.state);

    const { core_project } = window;
    this.locale = core_project.locale || this.defaultLocale;
  },
};
