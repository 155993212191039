import InstaWrapper from '@common/components/MainPage/InstaWrapper';
import Banner from '@common/components/MainPage/Banner';
import { Statistic, Jumbotron, MainPageSection } from './components';

export default {
  name: 'VMainPage',
  components: {
    Jumbotron,
    Banner,
    MainPageSection,
    Statistic,
    InstaWrapper,
  },
  props: {
    config: {
      type: Object,
      default: {
        isBannerActive: false,
        isGovServicesSpecialActive: false,
        isBinomActive: false,
        uriInsta: '',
        uriStatic: '',
        uriOrgList: '',
        uriOrgRating: '',
        uriAdmissionResults: '',
        govServices: [],
        govServicesSpecial: [],
        govServicesBinom: [],
        regionStats: {},
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    sections() {
      const {
        isBinomActive,
        isGovServicesSpecialActive,
        govServices,
        govServicesBinom,
        govServicesSpecial,
        uriOrgList,
        uriAdmissionResults,
        uriOrgRating,
      } = this.config;


      const result = [];

      if (govServices.length) {
        result.push({ name: 'gov-services', features: govServices });
      }

      if (isGovServicesSpecialActive) {
        result.push({ name: 'gov-services-special', features: govServicesSpecial });
      }

      if (isBinomActive) {
        result.push({ name: 'binom', features: govServicesBinom });
      }

      return [
        ...result,
        { name: 'org-list', link: uriOrgList },
        { name: 'admission-results', link: uriAdmissionResults },
        { name: 'org-rating', link: uriOrgRating },
      ];
    },
  },
  methods: {
    checkIsAlt(key) {
      const { isBannerActive } = this.config;
      return (isBannerActive && key % 2 === 0) || (!isBannerActive && key % 2 === 1);
    },
  },
  beforeCreate() {
    this.$trans.add(['label', 'main', 'title']);
  },
};
