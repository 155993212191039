import { Form } from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [Form],
  props: {
    refs: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    uriBack: {
      type: String,
      required: true,
    },
  },
  beforeCreate() {
    this.$trans.add('book');
  },
  data() {
    return {
      fields: {
        name: {
          labelTransKey: 'book.statements.name',
          value: null,
          name: 'name',
          component: 'q-text-field',
          required: true,
        },
        direction: {
          labelTransKey: 'book.direction.title',
          value: null,
          values: this.refs.directions,
          name: 'direction',
          component: 'q-select-field',
          required: false,
        },
        is_from_to_class_number: {
          value: false,
          name: 'is_from_to_class_number',
          component: 'q-checkbox-field',
          checkboxLabelTransKey: 'book.is_from_to_class_number',
          required: false,
        },
        class_number: {
          labelTransKey: 'book.class_number',
          value: null,
          values: this.refs.classes,
          name: 'class_number',
          component: 'q-select-field',
          required: true,
          extraAttributes: {
            useInput: false,
          },
          v_if: () => !this.fields.is_from_to_class_number.value,
        },
        from_to_class_number: {
          labelTransKey: 'book.from_to_class_number',
          name: 'from_to_class_number',
          component: 'q-between-field',
          required: true,
          v_if: () => this.fields.is_from_to_class_number.value,
          configComponent: {
            component: 'q-select-field',
            extraAttributes: {
              useInput: false,
            },
            fieldFrom: {
              value: null,
              values: this.refs.classes,
              name: 'class_from',
            },
            fieldTo: {
              value: null,
              values: this.refs.classes,
              name: 'class_to',
            },
          },
        },
        edu_lang: {
          labelTransKey: 'label.edu_lang',
          value: null,
          values: this.refs.languages,
          name: 'edu_lang',
          component: 'q-select-field',
          required: true,
        },
        publisher: {
          labelTransKey: 'book.statements.publisher',
          value: null,
          name: 'publisher',
          component: 'q-text-field',
          required: true,
        },
        authors: {
          labelTransKey: 'book.statements.authors',
          value: null,
          name: 'authors',
          component: 'q-text-field',
          required: true,
        },
        year_of_publishing: {
          labelTransKey: 'book.statements.published_year',
          value: null,
          name: 'year_of_publishing',
          component: 'q-number-field',
          required: true,
          extraAttributes: { counter: false },
          config: { mask: '####' },
        },
        count_copies: {
          labelTransKey: 'book.statements.count_copies',
          value: null,
          name: 'count_copies',
          component: 'q-number-field',
          required: true,
          extraAttributes: { counter: false },
          config: { max: 9999 },
        },
      },
    };
  },
  methods: {
    async send() {
      this.$globalLoading.show();

      const data = {};

      _.forEach(this.fields, (field) => {
        if (field.v_if === undefined || field.v_if(this)) {
          if (field.component === 'q-between-field') {
            data[field.configComponent.fieldFrom.name] = field.configComponent.fieldFrom.value;
            data[field.configComponent.fieldTo.name] = field.configComponent.fieldTo.value;
          } else {
            data[field.name] = field.value;
          }
        }
      });

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }
      return true;
    },
  },
};
