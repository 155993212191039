import { requestWrapper } from '@vjs/helpers';
import {
  QForm,
  QInput,
  QBtn,
  QSlideTransition,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import Validation from '@vjs/mixins/Validation';
import { Helper } from '@common/src/helpers';
import eventHub from '@vjs/config/eventHub';
import OptionsMixin from '../mixins/OptionsMixin';

export default {
  name: 'VStepStatement',

  mixins: [OptionsMixin, Validation],

  components: {
    QForm,
    QInput,
    QBtn,
    QSlideTransition,
    VFormInform,
  },

  props: {
    uriAction: {
      type: String,
      required: true,
    },
    uriActualization: {
      type: String,
      default: '',
    },
    type: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    isDisabledActualization: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDisabledActualizationLocal: false,
      form: {
        from_school_name: null,
        to_region_id: null,
        to_department_id: null,
        to_school_id: null,
        to_language_id: null,
        to_class_id: null,
        actual_residence: null,
        appointment: null,
        ground: null,
      },
      options: {
        regions: [],
        departments: [],
        schools: [],
        languages: [],
        classes: [],
        children: [],
        appointments: [],
        grounds: [],
      },
      consts: {
        TYPE_SCHOOL_BINOM: 6, // школы бином
        TYPE_EXTERNAL_TRANSFER: 4, // переводы между школами
        TYPE_CORRECTIONAL: 18, // Прием документов и зачисление в специальные организации образования детей с ограниченными возможностями
        TYPE_MEDICAL_TREATMENT: 20, // Обследование и оказание психолого-медико-педагогической консультативной помощи детям с ограниченными возможностями
      },
    };
  },

  created() {
    eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },

  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  async mounted() {
    if (this.type === this.consts.TYPE_MEDICAL_TREATMENT) {
      await this.requestGetOptions({
        type: 'appointments', params: { code: 'APPOINTMENT' }, customUrl: this.uriDictionaryItems,
      });
      await this.requestGetOptions({
        type: 'grounds', params: { code: 'GROUND' }, customUrl: this.uriDictionaryItems,
      });
    }
    await this.requestGetOptions({ type: 'regions' });
    await this.requestGetOptions({ type: 'children' });
    this.form.actual_residence = this.actualResidenceChild;
  },

  computed: {
    isDisabledActualize() {
      if (this.selectedChild?.canActualize) {
        return this.selectedChild?.isDisabledActualize;
      }
      return this.isDisabledActualization;
    },
    selectedSchool() {
      return this.options.schools.find(item => item.id === this.form.to_school_id);
    },
    schoolIsBinom() {
      return this.selectedSchool?.sub_type === this.consts.TYPE_SCHOOL_BINOM;
    },
    selectedChild() {
      return this.options.children.length
        ? this.options.children.find(itm => itm.value === this.$store.state.vSteps.child_id)
        : [];
    },
    placeOfResidence() {
      return this.selectedChild?.placeOfResidence;
    },
    actualResidenceChild() {
      return this.selectedChild?.actualResidence;
    },
    isMissingAddress() {
      return this.selectedChild?.isMissingGovAddress;
    },
    isExternalTransfer() {
      return this.type === this.consts.TYPE_EXTERNAL_TRANSFER;
    },
    validate() {
      if (this.type === this.consts.TYPE_MEDICAL_TREATMENT) {
        return this.form.to_region_id !== null
          && this.form.to_department_id !== null
          && this.form.to_school_id !== null
          && this.form.actual_residence !== null
          && this.form.appointment !== null
          && this.form.ground !== null;
      }
      return this.form.to_region_id !== null
      && this.form.to_department_id !== null
      && this.form.to_school_id !== null
      && this.form.to_language_id !== null
      && this.form.to_class_id !== null;
    },
  },
  watch: {
    validate: {
      handler(val) {
        this.$store.commit('SET_ACCESS', val);
      },
      immediate: true,
    },
  },

  methods: {
    isTypes(types) {
      return types.includes(this.type);
    },
    async handleSelectItem(selectedItem = '') {
      if (selectedItem) {
        this.flashValidationFail(selectedItem);
        let customUrl = '';
        let noDataNotice = '';
        const urlParams = {};
        // eslint-disable-next-line default-case
        switch (selectedItem) {
          case 'to_region_id':
            this.form.to_department_id = null;
            this.form.to_school_id = null;
            this.form.to_language_id = null;
            this.form.to_class_id = null;

            this.options.departments = [];
            this.options.schools = [];
            this.options.languages = [];
            this.options.classes = [];

            urlParams.regionId = this.form.to_region_id;

            customUrl = Helper.urlGenerate(this.uriDepartments, urlParams);
            noDataNotice = this.trans('notice.statement_service_external_transfer.no_loaded_departments');
            await this.requestGetOptions({ type: 'departments', customUrl, noDataNotice });
            break;
          case 'to_department_id':
            this.form.to_school_id = null;
            this.form.to_language_id = null;
            this.form.to_class_id = null;
            this.options.schools = [];
            this.options.languages = [];
            this.options.classes = [];

            urlParams.departmentID = this.form.to_department_id;
            urlParams.childId = this.$store.state.vSteps.child_id;

            customUrl = Helper.urlGenerate(this.uriSchools, urlParams);
            noDataNotice = this.trans('notice.no_schools_with_available_classes');
            if (this.type === this.consts.TYPE_MEDICAL_TREATMENT) {
              noDataNotice = this.trans('notice.no_pmpc');
            }
            await this.requestGetOptions({ type: 'schools', customUrl, noDataNotice });
            break;
          case 'to_school_id':
            this.form.to_language_id = null;
            this.form.to_class_id = null;
            this.options.languages = [];
            this.options.classes = [];

            urlParams.school = this.form.to_school_id;
            urlParams.childId = this.$store.state.vSteps.child_id;

            customUrl = Helper.urlGenerate(this.uriLanguages, urlParams);
            noDataNotice = this.trans('notice.statement_service_external_transfer.no_loaded_languages');
            if (this.type === this.consts.TYPE_MEDICAL_TREATMENT) {
              noDataNotice = '';
            }
            await this.requestGetOptions({ type: 'languages', customUrl, noDataNotice });
            break;
          case 'to_language_id':
            this.form.to_class_id = null;
            this.options.classes = [];

            urlParams.school = this.form.to_school_id;
            urlParams.lang = this.form.to_language_id;
            urlParams.childId = this.$store.state.vSteps.child_id;

            customUrl = Helper.urlGenerate(this.uriClasses, urlParams);
            noDataNotice = this.trans('notice.statement_service_external_transfer.no_loaded_classes');
            await this.requestGetOptions({ type: 'classes', customUrl, noDataNotice });
            break;
        }
      }
    },
    async requestForm() {
      this.$emit('loading', true);
      // eslint-disable-next-line camelcase
      const { child_id } = this.$store.state.vSteps;
      const resData = {
        child_id,
        user_id: this.userId,
        type: this.type,
        from_school_id: -1,
        school_id: this.form.to_school_id,
        ...this.form,
      };
      if (this.isTypes([this.consts.TYPE_EXTERNAL_TRANSFER, this.consts.TYPE_CORRECTIONAL])) {
        resData.place_of_residence = this.placeOfResidence;
      }
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAction,
        data: resData,
      });

      if (res.error) {
        this.handleRequestError(res);
      } else {
        return;
      }
      this.$emit('loading', false);
    },
    async handleActualization() {
      this.$emit('loading', true);
      const resData = {};
      if (this.selectedChild?.canActualize) {
        resData.user_id = this.selectedChild?.value;
      }
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriActualization,
        data: resData,
      });

      if (res.error) {
        this.handleRequestError(res);
      } else {
        await this.requestGetOptions(
          { type: 'children' },
        );
        this.isDisabledActualizationLocal = true;
        this.showNotice(this.trans('notice.basic_saved_changes'), 'success');
      }
      this.$emit('loading', false);
    },
    showNotice(trans, type = 'error') {
      this.$notify({
        type,
        title: window.L.message,
        text: this.trans(trans),
      });
    },
  },
};
