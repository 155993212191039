import _ from 'lodash';
import Vue from 'vue';
import DefaultTableProps from './mixins/DefaultTableProps';
import NewTableFilters from './NewTableFilters';
import NewTable from './NewTable';

export default {
  name: 'NewTableWithFilters',
  mixins: [DefaultTableProps],
  components: {
    NewTableFilters,
    NewTable,
  },
  props: {
    adaptiveWhen: {
      type: Number,
      default: 1200,
    },
    withNoResultText: {
      type: Boolean,
      default: true,
    },
    separator: {
      type: String,
      default: 'horizontal',
    },
    dataConfig: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    clickableRows: Boolean,
    selectableRows: Boolean,
    hideBottom: Boolean,
    noResultTitle: String,
    noResultText: String,
  },
  data() {
    return {
      rows: [],
      meta: {},
      metaLinks: {},
      cols: [],
      bottomRow: [],
      bottomRowAdaptive: [],
      visibleCols: undefined,
      loading: false,
      eventBus: this.useCustomEventBus ? new Vue() : window.eventBus,
    };
  },
  beforeMount() {
    this.$trans.add([
      'label',
      'button',
      'placeholder',
      'table',
      'fields',
      'warning_text',
    ]);
  },
  computed: {
    paginationButtons() {
      return [
        {
          icon: 'angle-double-left',
          disabled: this.meta.current_page === 1,
          type: 'first',
        },
        {
          icon: 'angle-left', disabled: !this.metaLinks.prev, type: 'prev',
        },
        {
          icon: 'angle-right', disabled: !this.metaLinks.next, type: 'next',
        },
        {
          icon: 'angle-double-right',
          disabled: this.meta.last_page === 1 || this.meta.current_page === this.meta.last_page,
          type: 'last',
        },
      ];
    },
  },
  methods: {
    async toggleLoader(enable = false) {
      await this.$trans.add('label');
      if (enable && !this.loading) {
        this.loading = true;
        this.$globalLoading.show();
      } else if (!enable && this.loading) {
        this.$globalLoading.hide();
        this.loading = false;
      }
    },
    // eslint-disable-next-line
    handleGetItemsEvent({meta = {}, metaLinks = {}, rows = [], cols = [], bottomRow = [], bottomRowAdaptive = []}) {
      if (!_.isEmpty(meta)) {
        this.meta = meta;
      }
      if (!_.isEmpty(metaLinks)) {
        this.metaLinks = metaLinks;
      }
      if (!_.isEmpty(cols)) {
        this.cols = cols;
      }
      if (!_.isEmpty(bottomRow)) {
        this.bottomRow = bottomRow;
      }
      if (!_.isEmpty(bottomRowAdaptive)) {
        this.bottomRowAdaptive = bottomRowAdaptive;
      }
      this.rows = rows;
      this.$emit('hasItems', rows.length > 0);
    },
  },
};
