<template>
  <div class="row mb-10">
    <div
      v-for="(value, label) in items"
      :key="label"
      class="col-lg-4 col-md-4 col-sm-12 s-desc-item"
    >
      <span class="s-desc-item__label"> {{ label }}: </span>
      <span class="s-desc-item__value"> {{ value }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewTableStatisticRows',
  props: {
    items: {
      type: [Object, Array],
      default: () => ([]),
    },
  },
};
</script>
