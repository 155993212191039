export default {
  SET_CURRENT_STEP: (state, payload) => {
    state.currentStep = payload;
  },

  SET_NEXT_STEP: (state) => {
    state.currentStep += 1;
  },

  SET_ACCESS: (state, payload = false) => {
    state.access = payload;
  },

  SET_DATA: (state, payload = { name: '', data: {} }) => {
    if (payload.name) {
      state[payload.name] = payload.data;
    }
  },

  SET_SELECTED_CHILD: (state, payload = null) => {
    state.selected_child = payload;
  },
};
