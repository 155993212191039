import VFormInform from '@vjs/components/VFormInform';
import { Helper } from '@common/src/helpers';
import { eventBus, SelectUserFileHandler } from '@common/mixins';
import CardMixin from '../CardMixin';

export default {
  mixins: [CardMixin, SelectUserFileHandler],
  components: { VFormInform },

  props: {
    navigation: {
      type: Array,
      default: () => ([]),
    },
    isAllSpoilersActive: false,
    faceIdConfig: {
      type: Object,
      default: ({
        addPhoto: '',
        addAlbum: '',
        album: '',
        photo: '',
      }),
    },
  },

  data() {
    return {
      currentAlbumFile: null,
    };
  },

  computed: {
    spoilersBtnLabel() {
      return this.isAllSpoilersActive
        ? this.trans('personnel.blocks.buttons.hide_spoilers')
        : this.trans('personnel.blocks.buttons.open_spoilers');
    },
  },
  async mounted() {
    eventBus.$on('user-files-modal-input', this.handleFilesModalInput);
    eventBus.$on('user-files-modal-close', this.handleFilesModalClose);
  },
  methods: {
    async handlePhotoInput() {
      this.$globalLoading.show();
      const file = this.$refs.photoInput.files[0];
      const action = this.faceIdConfig.addPhoto;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: action,
        formData: true,
        data: { file },
      });
      if (!res.error) {
        this.faceIdConfig.photo = res.url;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
      this.$forceUpdate();
    },
    handleBtnPhoto() {
      const el = this.$refs.photoInput;
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    handleBtnSpoilers() {
      eventBus.$emit('handleAllSpoilers');
    },
    handleNavigation(key) {
      eventBus.$emit('handleNavigation', key);
    },
    triggerAlbum() {
      this.isModalOpen = true;
      eventBus.$emit('open-user-files-modal', this.currentAlbumFile);
    },
    handleFilesModalClose() {
      this.isModalOpen = false;
    },
    async handleFilesModalInput(files) {
      if (!this.isModalOpen) {
        return;
      }

      this.$globalLoading.show();
      this.currentAlbumFile = files[0];

      const action = this.faceIdConfig.addAlbum;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: action,
        formData: true,
        data: {
          album: this.currentAlbumFile.id,
        },
      });
      if (!res.error) {
        this.faceIdConfig.photo = res.url;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
      this.$forceUpdate();
    },
  },
};
