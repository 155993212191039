import { Loading } from '@quasar/plugins';

const Plugin = {
  install(_Vue) {
    const globalLoading = new _Vue({
      data: {
        count: 0,
        qGlobalLoading: Loading,
      },

      methods: {
        async show(hardTranslate = false) {
          if (!this.qGlobalLoading.isActive) {
            const hardMessage = window?.core_project.locale === 'ru'
              ? 'Пожалуйста дождитесь загрузки данных.'
              : 'Деректердің жүктелуін күтіңіз.';
            const message = (hardTranslate || !this.$trans.existsLoaded('label'))
              ? hardMessage
              : this.$trans.get('label.wait_for_load_loader');
            this.qGlobalLoading.show({ message });
          }
          this.count += 1;
        },
        async hide(force = false) {
          this.count = this.count - 1;
          if (force || this.count <= 0) {
            if (this.qGlobalLoading.isActive) {
              this.qGlobalLoading.hide();
            }
            this.count = 0;
          }
        },
      },
    });
    _Vue.prototype.$globalLoading = globalLoading;
    _Vue.globalLoading = globalLoading;
  },
};

export default Plugin;
